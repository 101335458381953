import React from "react";
import './dashboard.css';
import { connect } from "react-redux";
import Card from "../../../../components/common/Card.js";
import ArticleIcon from '@mui/icons-material/Article';
import PeopleIcon from '@mui/icons-material/People';
import Enrollment_accordion from './Enrollment_accordion.js';
import ProgressBarChart from "../../charts/ProgressBarChart.js";
import { ManageDashboardService } from "../../../../service/manage/dashboard/manage_dashboard.service.js";
import MenuIcon from '@mui/icons-material/Menu';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Watermark from '../../../../components/edsider/assets/images/watermark.png'
import { Link } from "react-router-dom";

class StudentDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            session_id: sessionStorage.getItem("manage_user_id"),
            table_log_id: sessionStorage.getItem("table_log_id"),
            dashboardData: [],
            dashboardDataLoading: false,
            suitableArray: [],
            statsArray: [],
            packageArray: [],
            subjectsAvailable: {},
            enrolledPapers: {},
            completedPapers: {},
        }
    }

    componentDidMount() {
        this.getDashboarStat()
    }

    getDashboarStat = async () => {
        this.setState({
            dashboardDataLoading: true,
        });
        await ManageDashboardService.getStatistics(
            100,
            this.state.session_id,
            this.state.table_log_id,
            "viewall",
            "EdSider-student"
        )
            .then((res) => {
                const suitableArray = this.buildStatChartArray(res.data)
                const statsArray = suitableArray.filter(item => item.chart_type.includes('Statistics'))
                const packageArray = suitableArray.filter(item => item.chart_type.includes('edstu-subscription'))
                const subjectsAvailable = suitableArray.find(item => item.chart_type.includes('edstu-sub-avail'))
                const title = subjectsAvailable?.chart_title.split('(')
                const openBracIndex = subjectsAvailable?.chart_title.indexOf('(')
                const closeBracIndex = subjectsAvailable?.chart_title.indexOf(')')
                const subjectsAvailableAlt = { ...subjectsAvailable, chart_title: title[0].substring(0, (title[0].length - 2)), medium: subjectsAvailable?.chart_title?.substring((openBracIndex + 1), closeBracIndex) }
                const enrolledPapers = suitableArray.find(item => item.chart_type.includes('edstu-enr-paper'))
                const completedPapers = suitableArray.find(item => item.chart_type.includes('edstu-comp-paper'))
                this.setState({
                    dashboardData: res.data,
                    dashboardDataLoading: false,
                    suitableArray: suitableArray,
                    statsArray: statsArray,
                    packageArray: packageArray,
                    subjectsAvailable: subjectsAvailableAlt,
                    enrolledPapers: enrolledPapers,
                    completedPapers: completedPapers,
                });
            })
            .catch((error) => {
                this.setState({ dashboardDataLoading: false });
            });
    }
    buildStatChartArray = (dashboardData) => {
        const groupedData = dashboardData.reduce((acc, obj) => {
            const key = `${obj.chart_type}_${obj.chart_id}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
        const arrays = Object.values(groupedData);
        const objectArray = arrays.map(arr => {
            const highestDisplayOrderObject = arr.reduce((maxObj, obj) => {
                if (!maxObj || obj.display_order > maxObj.display_order) {
                    return obj;
                } else {
                    return maxObj;
                }
            }, null);
            return {
                "array": arr,
                "chart_title": arr[0].chart_title,
                "chart_type": arr[0].chart_type,
                "chart_id": arr[0].chart_id,
                "display_order": highestDisplayOrderObject.display_order
            }
        })
        objectArray.sort((a, b) => a.display_order - b.display_order)
        return objectArray
    }


    render() {
        const {
            totalEnrolledCompletedExams,
            totalEnrolledExams,
            userName,
            dataLoading,
            welcomeText,
            welcomeParagraph,
            renderQuickGuide,
            paperEnrollemnt,
            stuListLoading,
            studentListParent,
            cardData
        } = this.props;

        const {
            packageArray,
            subjectsAvailable,
            enrolledPapers,
            completedPapers
        } = this.state
        const first3PackageDetail = packageArray.slice(0, 3)
        return (
            <div>
                <div class="row stdDash">
                    <div class="col-12 col-md-3 firstCol">
                        <div className="">
                            <div className="col-md-12 colPadding">
                                <div className="row mb-3 welcome-topic">
                                    {welcomeText}{userName}! 👋
                                </div>
                                <p className="welcomeParagraph displayMobile">{welcomeParagraph}</p>
                            </div>

                            <div className="col-sm-12 xs-12 colPadding displayMobile">
                                <div className="quickGuide whiteBgClr">
                                    <div className="col-md-12 colPadding">
                                        <div className="row">{renderQuickGuide()}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 colPadding">
                                <div className="row dataCards">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 colPadding">
                                        <div className="enrolled">
                                            <p className="card-text whiteTxt">Enrolled Papers</p>
                                            <h2 className="whiteTxt">
                                                {dataLoading ? (
                                                    <div className="stu-card-title pt-2 col-md-12">
                                                        <i className="fas fa-spinner fa-spin enrollPprsSpinner"></i>
                                                    </div>
                                                ) : (
                                                    <div className="stu-card-title pt-2 col-md-12">
                                                        {totalEnrolledExams}
                                                    </div>
                                                )}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 colPadding">
                                        <div className="completed">
                                            <p className="card-text whiteTxt">Completed Papers</p>
                                            <h2 className="whiteTxt">
                                                {dataLoading ? (
                                                    <div className="stu-card-title pt-2 col-md-12">
                                                        <i className="fas fa-spinner fa-spin enrollPprsSpinner"></i>
                                                    </div>
                                                ) : (
                                                    <div className="stu-card-title pt-2 col-md-12">
                                                        {totalEnrolledCompletedExams}
                                                    </div>
                                                )}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 colPadding">
                                <div className="ppr_details">
                                    <div className="row">
                                        <div className="availableSub"><MenuIcon />&nbsp;{subjectsAvailable?.chart_title}&nbsp;</div>
                                        <div className="medium">({subjectsAvailable?.medium})&nbsp;</div>
                                        <p className="ptag1">Across all grades</p>
                                    </div>
                                    <div className="subjectsAvailable">
                                        {
                                            !!subjectsAvailable?.array?.length ?
                                                subjectsAvailable?.array?.map(subject => {
                                                    return (
                                                        subject?.chart_x_value && (
                                                            <div>● {subject?.chart_x_value}</div>
                                                        )
                                                    );
                                                }) :
                                                <div>No Subjects Available</div>
                                        }
                                    </div>
                                    <div className="subjectsAvailable">
                                        <Enrollment_accordion requiredPapers={enrolledPapers} />
                                    </div>
                                    <div className="subjectsAvailable">
                                        <Enrollment_accordion requiredPapers={completedPapers} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-9 col-sm-12 col-xs-12 colPadding">
                        <div class="col-12 col-md-12 row colPadding">
                            <div class="col-12 col-md-7 col-sm-12 col-xs-12 colPadding">
                                <div className="middleclmn">
                                    <p className="welcomeParagraph displayWeb">{welcomeParagraph}</p>
                                    <div className="pprEnrollments whiteBgClr">
                                        <div className="mb-3">
                                            <div>
                                                <div className="alignItems row enrollTopic">
                                                    <ArticleIcon className="articleIcon" />
                                                    <div className="enrollTopics">Your Paper Enrollments</div>
                                                </div>
                                                <div className="enrollmentsDash">
                                                    {(!!paperEnrollemnt && !!paperEnrollemnt.length) ? (
                                                        <div className="shadowCard">
                                                            {paperEnrollemnt.map((data) =>
                                                                cardData(data)
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="watermark">Your Enrolled Papers Will Appear Here</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-5 colPadding">
                                <div className="colPadding displayWeb">
                                    <div className="quickGuide quickHeight whiteBgClr">
                                        <div className="col-md-12 colPadding">
                                            <div className="row">{renderQuickGuide()}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 colPadding">
                                    <div className="parentD whiteBgClr">
                                        <div className="col-md-12 d-flex align-items-center mb-5 colPadding">
                                            <h1 className="parent-heading m-0"><PeopleIcon />&nbsp;Parent Details</h1>
                                        </div>
                                        <p className="card-text">
                                            {stuListLoading ? (
                                                <div
                                                    className="stu-card-title pt-2 col-md-12"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                </div>
                                            ) : studentListParent.parents?.length >
                                                0 ? (
                                                studentListParent.parents?.map(
                                                    (parent, i) => (
                                                        <div className="col-md-12 colPadding">
                                                            <div className="row">
                                                                {/* <div className="col-md-5 colPadding"> */}
                                                                <div className="row parentData">
                                                                    Name:
                                                                    <div className="row parentData2">
                                                                        {parent.first_name ? parent.first_name : ""}
                                                                        &nbsp;
                                                                        {parent.last_name ? parent.last_name : ""}
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row parentData">
                                                                    Contact Number:
                                                                    <div className="row parentData2">
                                                                        {parent.phone_number
                                                                            ? parent.phone_number
                                                                            : "--"}
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row parentData">
                                                                    Email:
                                                                    <div className="row parentData2">
                                                                        {parent.email ? parent.email : ""}
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <p className="py-4 px-3">No Parents</p>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="subPackges whiteBgClr">
                            <div class="col-12 col-md-12 colPadding">
                                <div className="row subFirstLine">
                                    <div className="enrollTopics row"><MonetizationOnIcon />&nbsp;Subscription Packages</div>
                                    <div className="seeAll">
                                        <Link to={{
                                            pathname: "/subscriptions", state: {
                                                privilege_edit: 1,
                                                privilege_add: 1,
                                                privilege_delete: 1,
                                                nodeTitle: "Subscriptions",
                                                privilege_view: 1,
                                                mid: 100,
                                                main_table_id: 10,
                                                owner_restriction: null,
                                                lg_user_id: sessionStorage.getItem("manage_user_id"),
                                                lg_user_table_id: sessionStorage.getItem("table_log_id"),
                                                user_list_restriction: null,
                                            }
                                        }}>See All</Link>
                                    </div>
                                </div>
                                <div className="row  ">
                                    {
                                        first3PackageDetail?.map((packageDetail) => {
                                            return (

                                                <div className="row col-12 col-md-4 colPadding" >
                                                    <div className="subscription_card bronze">
                                                        {packageDetail?.chart_title && <ProgressBarChart packageDetail={packageDetail} />}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect()(StudentDashboard);