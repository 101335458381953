import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import edfocilogo from "../../../assets/images/logo.png";
import edsiderlogowhite from "../../../assets/images/edsiderlogowhite.png"
import cmclogo from "../../../assets/images/mid.png"
import RAlogo from "../../../assets/images/royal-logo.png"
import CGClogo from "../../../assets/images/cgc-logo.png";
import DropdownMenu from './DropdownMenu';
import './sidebar.css';
import config from '../../../helper/config';
import { customerConstants } from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

export default function HeaderMobile(props) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    if (window.KTLayoutAside) window.KTLayoutAside.init('kt_aside');
    if (window.KTLayoutHeaderTopbar) window.KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');
    let isAdmin = sessionStorage.getItem("userType") === "admin";
    let isTeacher = sessionStorage.getItem("userType") === "teacher";
    let isStudent = sessionStorage.getItem("userType") === "student";
    let isParent = sessionStorage.getItem("userType") === "parent";
    setIsAdmin(isAdmin);
    setIsTeacher(isTeacher);
    setIsStudent(isStudent);
    setIsParent(isParent)
  }, []);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isParent, setIsParent] = useState(false)
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const customer = getCustomerFeatureFlag();
  let logo, logoStyle;
  switch (customer) {
    case customerConstants.EXO_CUSTOMER1:
      logo = edsiderlogowhite;
      logoStyle = { width: "140px", height: "auto" };
      break;
    case customerConstants.EXO_CUSTOMER2:
      logo = cmclogo;
      logoStyle = { width: "190px", height: "auto" };
      break;
    case customerConstants.EDF_CUSTOMER2:
      logo = RAlogo;
      logoStyle = { width: "40px", height: "auto" };
      break;
    case customerConstants.EDF_CUSTOMER3:
      logo = CGClogo;
      logoStyle = { width: "40px", height: "auto" };
      break;
    default:
      logo = edfocilogo;
      logoStyle = { width: "140px", height: "auto" };
      break;
  }
  return (
    <div style={{ position: 'relative' }}>
      <div id="kt_header_mobile" className="header-mobile align-items-center header-mobile-fixed" >

        {/* begin::Logo */}
        <div className="brand-logo">
          {/* <img alt="Logo" className="logo" src="../assets/media/logos/logo-light.png" /> */}
          <img alt="Logo" className="logo" src={logo} />
        </div>
        {/* end::Logo */}

        {/* begin::Toolbar */}
        <div className="d-flex align-items-center">

          {/* begin::Header Menu Mobile Toggle */}
          <div className="position-relative">
            <button
              className="btn p-0 burger-icon ml-4"
              id="kt_header_mobile_toggle"
              onClick={toggleDropdown}
            >
              <span></span>
            </button>
          </div>
          {/* end::Header Menu Mobile Toggle */}

          {/* begin::Topbar Mobile Toggle */}
          <button className="btn btn-hover-text-primary p-0 ml-2"
            id="kt_header_mobile_topbar_toggle"
            // id="kt_quick_user_toggle"
          >
            <span className="svg-icon svg-icon-xl">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                  <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                </g>
              </svg>
              {/* end::Svg Icon */}

            </span>
          </button>
          {/* end::Topbar Mobile Toggle */}

        </div>
        {/* end::Toolbar */}

      </div>
      {isDropdownOpen && (
        <div>
          <DropdownMenu isAdmin={isAdmin} isTeacher={isTeacher} isStudent={isStudent} isParent={isParent} />
        </div>
      )}
    </div>
  )
}
