import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import CardTitle from "../../../components/common/CardTitle";
import AdminTemplate from "../../../components/admin/layouts/Template";
import Breadcrumbs from "./Breadcrumbs";
import HirearchyCard from "./HirearchyCard";
import TabSection from "./TabSection.js";
import Grid from "@mui/material/Grid";
import { Tabs, Tab } from "@mui/material";
import AddCard from "./AddCard";
import "./hirearchy.css";
import { TreeMainService } from "../../../service/manage/h_tree/tree_main.service";
import { history } from "../../../helper/history";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import { sweetStatusAlert, sweetConfirmAlert } from "../../../components/common/SweetAlert";
import Box from '@mui/material/Box';
import SimpleReactValidator from "simple-react-validator";
import { NodeGroupService } from "../../../service/settings/manage/node_group_title.service.js";
import "./Search.css"
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import { formFixture } from './FormFixture'
import config from '../../../helper/config';
import { customerConstants } from "../../../helper/constants.js";
import queryString from 'query-string';
import { CombankService } from "../../../service/manage/subscription/combank_pg.service.js";
import NodeUpdationModal from "./modals/NodeUpdationModal.js";
import NodeInsertionModal from "./modals/NodeInsertionModal.js";
import HierarchyCardModal from "./modals/HierarchyCardModal.js";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

class searchComponent extends Component {
  constructor(props) {
    super(props);
    this.createValidationsInstance();
    this.state = {
      hierarchyTreeData: [],
      breadcrumbData: [],
      nodeFormsDisplayData: [],

      cardData: [],
      nodesData: [],
      loadingNode: false,
      formModalLoading: true,
      showInsertModal: false,
      showUpdateModal: false,
      mcode: "",
      title: "",
      groupTitle: "",
      highlightCard: "",
      flg_node_hide: 0,
      pid: null,
      displayOrder: 1,
      data_id: null,
      insertType: "null",
      mid: "",
      user_id: null,
      user_table_id: null,
      isCardSectionVisible: false,
      isMobile: window.innerWidth <= 900,
      titles: [],
      selectedMidsParents: null,//array
      selectedMid: null,//obj
      selectedMidsChilds: null,//array
      selectedMidsForms: null,//array
      selectedMidsSelectedForm: null,//obj
      selectedFormDetail: null,
      formLoading: false,
      paymentStatus: undefined,
      hierarchyCardInsertion: false,
      isHideForExam: true,
      sawSibilingNodes: false
    };
    this.highlightedCardRef = React.createRef();

  };

  toggleCardSection = () => {
    this.setState((prevState) => ({
      isCardSectionVisible: !prevState.isCardSectionVisible
    }))
  }
  toggleSibilingNodeVisibility = () => {
    this.setState((prevState) => ({
      sawSibilingNodes: !prevState.sawSibilingNodes
    }))
  }

  setIsHideForExam = (bool) => {
    this.setState(() => ({
      isHideForExam: !!bool
    }))
  }

  setFormDetail = (formDetail) => {
    this.redirect(this.state.selectedMid.pid, formDetail.node_mid, formDetail.form_list_id)
    this.setState({
      selectedFormDetail: formDetail
    })
  }

  redirectToSubscriptions(query, isNotStudent = false) {
    const newQueryString = query.toString();
    const newUrl = newQueryString ? window.location.pathname + '?' + newQueryString : window.location.pathname;
    window.history.replaceState({}, document.title, newUrl);

    const state = {
      privilege_edit: 1,
      privilege_add: 1,
      privilege_delete: 1,
      nodeTitle: 'Subscriptions',
      mid: 100,
      main_table_id: 10,
      owner_restriction: null,
      lg_user_id: sessionStorage.getItem("manage_user_id"),
      lg_user_table_id: sessionStorage.getItem("table_log_id"),
      user_list_restriction: null,
      flg_list_active: isNotStudent ? 1 : 0,
      form_list_id: 29

    };

    history.push({
      pathname: "/subscriptions",
      state: { ...state },
    });
  }

  handlePaymentResponse() {
    const query = new URLSearchParams(window.location.search);
    const success = query.get('success');
    const cancel = query.get('cancel');
    const userType = sessionStorage.getItem("userType");
    if (success === 'true') {
      if (customerConstants.EXO_CUSTOMER2 === getCustomerFeatureFlag()) {
        query.delete('success');
        sweetStatusAlert("Success!", "Your payment was successful!", "success");
        this.redirectToSubscriptions(query);
      } else {
        CombankService.retrieveOrder(sessionStorage.getItem("checkout_session_id"))
          .then((response) => {
            sessionStorage.removeItem('checkout_session_id');
            query.delete('success');
            sweetStatusAlert("Success!", "Your payment was successful!", "success");
            if (userType !== 'admin') {
              if (userType === 'student') {
                this.redirectToSubscriptions(query);
              } else {
                this.redirectToSubscriptions(query, true);
              }
            } else {
              const newQueryString = query.toString();
              const newUrl = newQueryString ? window.location.pathname + '?' + newQueryString : window.location.pathname;
              window.history.replaceState({}, document.title, newUrl);
              return true;
            }
          });
      }
    } else if (cancel === 'true') {
      query.delete('cancel');
      sweetStatusAlert(
        "Failed!",
        "Your payment is failed!",
        "error"
      );
      if (userType !== 'admin') {
        if (userType === 'student') {
          this.redirectToSubscriptions(query);
        } else {
          this.redirectToSubscriptions(query, true);
        }
      } else {
        const newQueryString = query.toString();
        const newUrl = newQueryString ? window.location.pathname + '?' + newQueryString : window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
        return true;
      }
    }
    return false;
  }

  componentDidMount() {
    const status = this.handlePaymentResponse();
    let user_id = sessionStorage.getItem("manage_user_id")
    let user_table_id = sessionStorage.getItem("table_log_id")
    let mid = !!(history.location.state && history.location.state.pid) ? history.location.state.pid : (status ? 10 : 100)

    this.getAllNodeGroupTitls()
    // const queryParams = new URLSearchParams(window.location.search);
    let fmid = queryString.parse(this.props.location.search).fmid
    fmid = fmid && parseInt(fmid);
    let smid = queryString.parse(this.props.location.search).smid
    smid = smid && parseInt(smid);
    let formid = queryString.parse(this.props.location.search).formid
    formid = formid && parseInt(formid);
    mid = fmid ? fmid : mid
    this.getNodesAndFormsInfo(mid, 1, user_id, user_table_id, smid, formid)
    this.setState({
      user_id: user_id,
      user_table_id: user_table_id,
    })
    window.addEventListener("resize", this.handleResize)
    this.handlePaymentResponse();
  }

  componentDidUpdate(prevProps) {
    let mid = !!(history.location.state && history.location.state.pid) ? history.location.state.pid : this.state.paymentStatus ? 10 : 100
    let prevfmid = queryString.parse(prevProps.location.search).fmid
    prevfmid = prevfmid && parseInt(prevfmid);
    let currentfmid = queryString.parse(this.props.location.search).fmid;
    currentfmid = currentfmid && parseInt(currentfmid);
    mid = currentfmid ? currentfmid : mid

    let prevsmid = queryString.parse(prevProps.location.search).smid
    prevsmid = prevsmid && parseInt(prevsmid);
    let currentsmid = queryString.parse(this.props.location.search).smid;
    currentsmid = currentsmid && parseInt(currentsmid);

    let prevformid = queryString.parse(prevProps.location.search).formid
    prevformid = prevformid && parseInt(prevformid);
    let currentformid = queryString.parse(this.props.location.search).formid;
    currentformid = currentformid && parseInt(currentformid);

    if (prevfmid !== currentfmid && prevfmid !== undefined) {
      this.getNodesAndFormsInfo(mid, 1, this.state.user_id, this.state.user_table_id, currentsmid, currentformid)
    } else if (prevsmid !== currentsmid && prevsmid !== undefined && currentsmid !== undefined) {
      this.handleNodeSelection(currentformid, currentsmid)
    } else if (prevformid !== currentformid && prevformid !== undefined) {
      this.handleNodeSelection(currentformid, currentsmid)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth <= 900,
    })
  }

  handleApplicationSelect = (formData) => {
    if (!formData) {
      return undefined
    }
    let state = {
      pid: this.state.selectedMid.pid,
      mid: formData.node_mid,
      lg_user_id: formData.lg_user_id,
      lg_user_table_id: formData.lg_user_table_id,
      flg_list_active: formData.flg_list_active,
      privilege_view: formData.privilege_view,
      privilege_edit: formData.privilege_edit,
      privilege_add: formData.privilege_add,
      privilege_delete: formData.privilege_delete,
      owner_restriction: formData.owner_restriction,
      user_list_restriction: formData.user_list_restriction,
      form_display_title: formData.form_display_title,
      form_display_order: formData.form_display_order,
      form_list_id: formData.form_list_id,
      breadcrumb1: formData.breadcrumb1,
      breadcrumb2: formData.breadcrumb,
      main_table_id: formData.main_table_id,
      nodeTitle: this.state.selectedMid.title,
      node_mid_actual: formData.node_mid_actual,
      path: 'node',
      mcode: this.state.selectedMid.mcode
    };
    const findComponent = (id, state) => {
      return formFixture(state).find(item => id === item.formListId)
    }
    const selectedComponent = findComponent(formData.form_list_id, state)
    const nodeProps = {
      getDashboardStats: () => this.getNodesAndFormsInfo(
        this.state.selectedMid.pid,
        1,
        this.state.user_id,
        this.state.user_table_id,
        formData.node_mid,
        formData.form_list_id
      ),
      selectNode: () => this.getNodesAndFormsInfo(
        this.state.selectedMid.pid,
        1,
        this.state.user_id,
        this.state.user_table_id,
        formData.node_mid,
        formData.form_list_id
      ),
      setIsHideForExam: (bool) => this.setIsHideForExam(bool)
    }
    if (selectedComponent && selectedComponent.component) {
      return selectedComponent.component(nodeProps)
    }
    return <PageSpinner id="show" text="The Form is not Avaialble...!" />
  };

  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.validator.fieldValid('groupTitle') || !this.validator.fieldValid('mcode') || !this.validator.fieldValid('title')) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      sweetConfirmAlert()
        .then(async () => {
          this.setState({ isLoading: true });
          const child = {
            user_id: this.state.user_id,
            user_table_id: this.state.user_table_id,
            pid: this.state.pid,
            mcode: this.state.mcode,
            group_title: this.state.groupTitle,
            title: this.state.title,
            flg_node_hide: this.state.flg_node_hide,
            insert_type: this.state.insertType,
            display_order: !!this.state.displayOrder ? this.state.displayOrder : 0,
          };

          await TreeMainService.insertHierarchyNode(child)
            .then((response) => {
              this.setState({ isLoading: false });
              this.setState({ showInsertModal: false });
              sweetStatusAlert("Success!", 'Node added successfully', "success");
              if (this.state.hierarchyCardInsertion) {
                this.redirect(this.state.pid)
              }
              else {
                this.getNodesAndFormsInfo(
                  this.state.pid,
                  1,
                  this.state.user_id,
                  this.state.user_table_id
                );
              }
              this.setState(() => ({
                mcode: "",
                title: "",
                groupTitle: "",
                flg_node_hide: 0,
                pid: null,
                displayOrder: 1,
                data_id: null,
                insertType: "null",
                hierarchyCardInsertion: false
              }));
            })
            .catch((error) => {
              this.setState({ isLoading: false });
              sweetStatusAlert("Failed!", "Unable to insert the node", "error");
            });
        })

    }
  };

  handleChange = (event) => {
    let { name, value } = event.target
    if ((value < 0) && name === "displayOrder") {
      value = 0;
    }
    if (name === "flg_node_hide") {
      value = event.target.checked ? 1 : 0
    }
    this.setState({ [name]: value })
  }

  toggleModal = (isIntermediate = false, pid = this.state.pid, hierarchyCardInsertion = false) => {
    this.validator.hideMessages()
    this.validator.purgeFields();
    this.setState((prevState) => {
      if (!prevState.showInsertModal && isIntermediate) {
        return {
          showInsertModal: !prevState.showInsertModal,
          pid: this.state.breadcrumbData[
            this.state.breadcrumbData.length - 1
          ].nodes[0].mid,
          insertType: "L",
          hierarchyCardInsertion
        }
      }
      this.getAllNodeGroupTitls()
      return {
        showInsertModal: !prevState.showInsertModal,
        insertType: "null",
        pid: pid,
        hierarchyCardInsertion
      }
    })
    if (this.state.showInsertModal) {
      this.setState((prev) => ({
        pid: prev.data_id,
      }))
    }
  }

  getAllNodeGroupTitls() {
    NodeGroupService.getNodeGroups()
      .then((response) => {
        if (response.success) {
          this.setState({ titles: response.data })
        }
      })
  }

  handleUpdateSubmit = async (event) => {
    event.preventDefault();
    if (!this.validator.fieldValid('groupTitle') || !this.validator.fieldValid('mcode') || !this.validator.fieldValid('title')) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      const child = {
        mid: this.state.mid,
        mcode: this.state.mcode,
        group_title: this.state.groupTitle,
        title: this.state.title,
        flg_node_hide: this.state.flg_node_hide,
        display_order: !!this.state.displayOrder ? this.state.displayOrder : 0,
      }
      await TreeMainService.updateNodeDetails(child)
        .then((response) => {
          this.setState({ isLoading: false })
          this.setState({ showUpdateModal: false })
          sweetStatusAlert("Success!", "Node updated successfully", "success")
          this.getNodesAndFormsInfo(
            this.state.pid,
            1,
            this.state.user_id,
            this.state.user_table_id
          )
          this.setState(() => ({
            mcode: "",
            title: "",
            groupTitle: "",
            flg_node_hide: 0,
            pid: null,
            mid: null,
            displayOrder: 1,
            data_id: null,
          }))
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", "Unable to insert the node", "error");
        });
    }
  };

  toggleUpdateModal = (row) => {
    this.setState(
      (prevState) => ({
        showUpdateModal: !prevState.showUpdateModal,
      }),
      () => {
        // State has changed, perform your logic here based on the state change
        if (this.state.showUpdateModal) {
          this.getAllNodeGroupTitls()
          this.setState({
            mid: row.mid,
            title: row.title,
            mcode: row.mcode,
            groupTitle: row.group_title,
            displayOrder: row.display_order,
            flg_node_hide: row.flg_node_hide,
          })
        }
      }
    )
  }

  setNodeLoading = (param) => {
    this.setState({ loadingNode: param })
  }

  setFormFromFormsList = (selectedMidsForms = [], formId) => {
    return selectedMidsForms.find((form) => form.form_list_id === parseInt(formId))
  }
  handleNodeSelection = (formId, selectedNode, handleCardVisible) => {

    this.setState({
      selectedFormDetail: undefined,
      formLoading: true,
    }, () => {
      let selectedMidData = !!this.state.nodeFormsDisplayData.nodes.length ? this.state.nodeFormsDisplayData.nodes.find(midData => midData.mid === selectedNode) : undefined
      const selectedMid = selectedMidData ? selectedMidData : this.state.nodeFormsDisplayData.nodes[0]
      this.setState({ selectedMid, highlightCard: selectedMid.mid }, () => {
        handleCardVisible && handleCardVisible()
        if (selectedMid) {
          const selectedForm = this.setFormFromFormsList(selectedMid.forms, formId)
          const assignedForm = !!selectedForm ? selectedForm : (!!selectedMid.forms.length ? selectedMid.forms[0] : null)
          if (assignedForm) {
            const form_list_id = assignedForm ? assignedForm.form_list_id : undefined
            this.redirect(this.state.nodeFormsDisplayData.nodes[0].pid, selectedMid.mid, form_list_id)
          }
          this.setState({
            selectedMidsForms: selectedMid.forms,
            selectedFormDetail: assignedForm,
            formLoading: false,
          })
        }
      })
    })
  }

  getNodesAndFormsInfo = async (child_id, exclude_id, user_id, user_table_id, selectedNode = undefined, formId = undefined) => {
    this.setState((prev) => {
      if (prev.isCardSectionVisible) {
        return { isCardSectionVisible: false }
      }
    })
    this.redirect(child_id, selectedNode, formId)
    this.setState({ loadingNode: true, formLoading: true, isHideForExam: true });
    TreeMainService.getNodesInfo(child_id, 1, user_id, user_table_id)
      .then((res_) => {
        const nodeData = res_.data
        let hierarchyTreeData_, breadcrumbData_, nodeFormsDisplayData_, selectedMid_
        let success, res
        TreeMainService.getNodeFormsInfo(child_id, 1, user_id, user_table_id)
          .then((response) => {
            res = response
            success = true
          })
          .catch((error) => {
            res = error
            success = false
          })
          .finally(() => {
            const formsData = success ? res.data : []
            const nodesDataWithForms = nodeData.map(node => {
              const filteredFormsOfNode = formsData.filter(form => form.node_mid === node.mid)
              return { ...node, forms: filteredFormsOfNode }
            })
            const hierarchyTreeData = this.treeBuilder(nodesDataWithForms)
            const sortedHierarchyTreeData = hierarchyTreeData.sort((a, b) => a.id - b.id)
            const breadcrumbData = sortedHierarchyTreeData.filter((item, index) => item.grid_id === 0)
            const nodeFormsDisplayLevel = sortedHierarchyTreeData.filter((item, index) => item.grid_id === 1)[0]
            const selectedMidData = !!nodeFormsDisplayLevel.nodes.length ? nodeFormsDisplayLevel.nodes.find(midData => midData.mid === selectedNode) : undefined
            this.setState({
              hierarchyTreeData,
              breadcrumbData,
              nodeFormsDisplayData: nodeFormsDisplayLevel,
              selectedMid: selectedMidData ? selectedMidData : nodeFormsDisplayLevel.nodes[0],
              data_id: child_id,
              pid: child_id,
            }, () => {
              this.handleNodeSelection(formId, selectedNode, () => {
                const highlightedCard = this.highlightedCardRef;
                if (highlightedCard && highlightedCard.current) {
                  highlightedCard.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                  });
                }
              })
              this.setState({ loadingNode: false });
            });
          })

      })
      .catch((error) => {
        this.setState({ loadingNode: false });
      });
  }

  handleHierarchy = () => {

  }

  treeBuilder = (data_) => {
    const data = data_.map(item => ({ ...item, children: [] }))
    const allLevels = []
    const uniqueLevels = []
    const uniqueLevelsCount = []
    const levelIds = data.map(item => {
      const isAvailable = uniqueLevels.includes(item.level_id)
      if (!isAvailable) {
        uniqueLevels.push(item.level_id)
        uniqueLevelsCount.push({ id: item.level_id, count: 1, nodes: null, grid_id: item.grid_id })
      } else {
        const index = uniqueLevels.findIndex(level_id => level_id === item.level_id)
        uniqueLevelsCount[index].count = uniqueLevelsCount[index].count + 1
      }
      allLevels.push(item.level_id)
      return item.level_id
    });
    uniqueLevelsCount.sort((a, b) => b.id - a.id)
    const nodeData = uniqueLevelsCount.map(level => {
      const filteredItems = data.filter(item => item.level_id === level.id)
      const level_ = { ...level }
      level_['nodes'] = filteredItems
      return level_
    })
    nodeData.forEach((levelItem, index) => {
      if (nodeData.length - 1 > index) {
        nodeData[index + 1].nodes.forEach((p_node, i) => {
          const childNodes = levelItem.nodes.filter(node => {
            return p_node.mid === node.pid
          })
          const _p_node = { ...p_node, children: childNodes }
          nodeData[index + 1].nodes[i] = { ..._p_node }
        })
      }
    })
    return nodeData
  }

  redirect = (fmid = undefined, smid = undefined, formid = undefined) => {
    if (fmid && smid && formid) {
      history.push(`/manage-search?fmid=${fmid}&smid=${smid}&formid=${formid}`);
    } else if (fmid && smid && !formid) {
      history.push(`/manage-search?fmid=${fmid}&smid=${smid}`);
    } else if (fmid && !smid && !formid) {
      history.push(`/manage-search?fmid=${fmid}`);
    } else {
      history.push(`/manage-search`);
    }
  }

  render() {
    const {
      isLoading,
      loadingNode,
      isCardSectionVisible,
      isMobile,
      selectedMidsForms,
      selectedMid,
      selectedFormDetail,
      titles,
      isHideForExam,
      breadcrumbData,
      nodeFormsDisplayData,
      sawSibilingNodes
    } = this.state;

    this.validator.purgeFields();
    return (
      <>
        <AdminTemplate>
          <ContentWrapper>
            <Card>
              {/* <CardHeader>
                <CardTitle
                  cardLabel={
                    breadcrumbData.length > 0 &&
                    breadcrumbData[breadcrumbData.length - 1].nodes[0].title
                  }
                />
              </CardHeader> */}
              {isLoading ? (
                <PageSpinner id="show" text="Loading, Please wait!" />
              ) : (
                <CardBody>
                  {this.state.breadcrumbData?.length > 0 && (
                    <Breadcrumbs
                      user_id={this.state.user_id}
                      user_table_id={this.state.user_table_id}
                      toggleModal={this.toggleModal}
                      breadcrumbData={this.state.breadcrumbData}
                      getNodesAndFormsInfo={this.getNodesAndFormsInfo}
                      redirect={this.redirect}
                    />
                  )}
                  <div className="d-flex row">
                    <Grid container spacing={1} style={{ padding: "10px" }}>
                      {/* Left column with HirearchyCard components */}
                      {/* {isHideForExam && !loadingNode && <Grid style={isMobile ? {} : { display: 'flex', flexDirection: 'row', height:'12vh', maxHeight: '16vh'}}
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        className={`card-section fade-in"  ${this.state.isCardSectionVisible
                            ? ""
                            : "card-section-hidden"
                          }`}
                      >
                        <div style={{ marginBottom: '8px' }}>
                          {this.state.nodeFormsDisplayData?.nodes?.length > 0 &&
                            <HirearchyCard
                              singleNodeParentParent={nodeFormsDisplayData.nodes[0].length === 1 ? breadcrumbData[breadcrumbData.length - 1].nodes[0] : undefined}
                              cardData={selectedMid}
                              redirect={() => this.redirect(selectedMid.pid, selectedMid.mid, selectedMid.forms[0].form_list_id)}
                              redirectParent={this.redirect}
                              toggleUpdateModal={this.toggleUpdateModal}
                              toggleInsertModal={this.toggleModal}
                              user_id={this.state.user_id}
                              highlightCard={this.state.highlightCard}
                              user_table_id={this.state.user_table_id}
                              setNodeLoading={this.setNodeLoading}
                              setHierarchyCardInsertion={() => { this.setState({ hierarchyCardInsertion: true }) }}
                              titles={titles}
                              getNodesAndFormsInfo={this.getNodesAndFormsInfo}
                            />
                          }
                        </div>

                        {this.state.nodeFormsDisplayData?.nodes?.length > 0 && <div class="d-flex align-items-center justify-content-center" style={{ marginBottom: '15px' }}>
                          {sawSibilingNodes ? <ArrowBackIosNewIcon onClick={this.toggleSibilingNodeVisibility} /> : <ArrowForwardIosIcon onClick={this.toggleSibilingNodeVisibility} />}
                        </div>}
                        
                      </Grid>} */}
                      {isHideForExam && <Grid style={isMobile ? {} : { display: 'flex', flexDirection: 'row', height: '12vh', maxHeight: '16vh', overflow: 'auto' }}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={`card-section fade-in"  ${this.state.isCardSectionVisible
                            ? ""
                            : "card-section-hidden"
                          }`}
                      >
                        {loadingNode ? (
                          <AddCard toggleModal={this.toggleModal}
                            loading={loadingNode} />
                        ) : ((
                          <>
                            {this.state.nodeFormsDisplayData?.nodes?.length > 0 &&
                              this.state.nodeFormsDisplayData?.nodes.map(
                                (cardData, index) => {
                                  return (
                                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', gap: '16px' }}>
                                      <HirearchyCard
                                        ref={this.state.highlightCard === cardData.mid ? this.highlightedCardRef : undefined}
                                        singleNodeParentParent={nodeFormsDisplayData.nodes[0].length === 1 ? breadcrumbData[breadcrumbData.length - 1].nodes[0] : undefined}
                                        key={index}
                                        cardData={cardData}
                                        redirect={() => this.redirect(cardData.pid, cardData.mid, cardData.forms[0]?.form_list_id)}
                                        redirectParent={this.redirect}
                                        toggleUpdateModal={this.toggleUpdateModal}
                                        toggleInsertModal={this.toggleModal}
                                        user_id={this.state.user_id}
                                        highlightCard={this.state.highlightCard}
                                        user_table_id={this.state.user_table_id}
                                        setNodeLoading={this.setNodeLoading}
                                        setHierarchyCardInsertion={() => { this.setState({ hierarchyCardInsertion: true }) }}
                                        titles={titles}
                                        getNodesAndFormsInfo={this.getNodesAndFormsInfo}
                                      />
                                    </div>
                                  )
                                }
                              )}
                            {!!this.state.breadcrumbData[
                              this.state.breadcrumbData.length - 1
                            ] &&
                              !!this.state.breadcrumbData[
                                this.state.breadcrumbData.length - 1
                              ].nodes[0].flg_add_node_sibling && (
                                <AddCard toggleModal={this.toggleModal} />
                              )}
                          </>
                        ))}
                      </Grid>}

                      {/* Right column with Tabs */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={isHideForExam ? 12 : 12}
                        lg={isHideForExam ? 12 : 12}
                        className="fade-in"
                      >
                        <div className="tab-section">
                          {isHideForExam && <Box
                            sx={{ borderBottom: 1, borderColor: "divider" }}
                          >
                            {this.state.formLoading ?
                              <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                              >
                                <Tab
                                  key={0}
                                  label={'Loading...'}
                                />
                              </Tabs> :
                              (selectedMidsForms && selectedMid && <TabSection
                                formArray={selectedMidsForms}
                                pid={selectedMid.pid}
                                title={selectedMid.title}
                                setFormDetail={this.setFormDetail}
                                selectedFormDetail={selectedFormDetail}
                              />)
                            }
                          </Box>}
                          {this.state.formLoading ? (
                            <div></div>
                          ) : (
                            <div className="" style={{ maxHeight: "90vh", overflowY: "auto" }}>
                              {(!!selectedMidsForms && selectedMidsForms.length > 0) ? (
                                this.handleApplicationSelect(selectedFormDetail)) : (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "20px" }}>
                                  <h2>
                                    There are currently no contents to view in this selection
                                  </h2>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </Grid>
                      <button
                        type="button"
                        className="menu-icon"
                        onClick={this.toggleCardSection}
                        style={{ display: !isMobile ? 'none' : 'inline-block', position: 'absolute', top: '8px', right: '8px' }}
                      >
                        <GridViewRoundedIcon />
                      </button>
                    </Grid>
                  </div>
                </CardBody>
              )}
            </Card>
          </ContentWrapper>

          {/* Card Section model */}
          <HierarchyCardModal
            breadcrumbData={breadcrumbData}
            nodeFormsDisplayData={nodeFormsDisplayData}
            isCardSectionVisible={isCardSectionVisible}
            setToInvisible={() => this.setState({ isCardSectionVisible: false })}
            setHierarchyCardInsertion={() => { this.setState({ hierarchyCardInsertion: true }) }}
            redirect={this.redirect}
            toggleUpdateModal={this.toggleUpdateModal}
            toggleModal={this.toggleModal}
            user_id={this.state.user_id}
            highlightCard={this.state.highlightCard}
            user_table_id={this.state.user_table_id}
            setNodeLoading={this.setNodeLoading}
            titles={titles}
            getNodesAndFormsInfo={this.getNodesAndFormsInfo}
          />


          {/*Defining insert model*/}
          <NodeInsertionModal
            showInsertModal={this.state.showInsertModal}
            toggleModal={this.toggleModal}
            insertType={this.state.insertType}
            handleChange={this.handleChange}
            validator={this.validator}
            mcode={this.state.mcode}
            title={this.state.title}
            groupTitle={this.state.groupTitle}
            displayOrder={this.state.displayOrder}
            addBtnStyle={addBtnStyle}
            closeBtnStyle={closeBtnStyle}
            handleSubmit={this.handleSubmit}
            titles={titles}
          />

          {/*Defining update model*/}
          <NodeUpdationModal
            showUpdateModal={this.state.showUpdateModal}
            toggleUpdateModal={this.toggleUpdateModal}
            handleChange={this.handleChange}
            validator={this.validator}
            mcode={this.state.mcode}
            title={this.state.title}
            groupTitle={this.state.groupTitle}
            displayOrder={this.state.displayOrder}
            addBtnStyle={addBtnStyle}
            closeBtnStyle={closeBtnStyle}
            flg_node_hide={this.state.flg_node_hide}
            handleUpdateSubmit={this.handleUpdateSubmit}
            titles={titles}
          />
        </AdminTemplate>
      </>
    )
  }
}

export default searchComponent;
