import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./Paper.css";

// Styled ListItem for spacing
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function PaperListChips({ filterValues, chipClick, clearAll }) {
  const [chipData, setChipData] = useState(filterValues);

  useEffect(() => {
    setChipData(filterValues);
  }, [filterValues]);

  const handleDelete = (chipToDelete) => () => {
    chipClick(chipToDelete);
  };

  const handleClearAll = () => {
    clearAll();
  };

  // Group chips by the 'filter' attribute
  const groupedChips = chipData.reduce((acc, chip) => {
    const filterType = chip.filter;
    if (!acc[filterType]) acc[filterType] = [];
    acc[filterType].push(chip);
    return acc;
  }, {});

  const shouldShowClearAll = chipData.some(
    (chip) => chip.filter !== "Paper Status" || chip.name !== "All"
  );
  

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "1rem",
        gap: "1rem",
        backgroundColor: "#f5f5f5", // Optional: Add background color for better distinction
      }}
      component="div"
    >
      {/* Chips grouped in a single container */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {Object.keys(groupedChips).map((filterType, groupIndex) => (
          <React.Fragment key={groupIndex}>
            {/* Section label */}
            <Typography variant="body1" component="span" fontWeight="bold">
              {filterType}:
            </Typography>
            {/* Chips for this filter group */}
            {groupedChips[filterType].map((data, chipIndex) => (
              <ListItem key={chipIndex} style={{ display: "inline" }}>
                <Chip
                  label={data.name}
                //   onDelete={handleDelete(data)}
                  {...(data.filter !== "Paper Status" && { onDelete: handleDelete(data) })}
                  color="default"
                />
              </ListItem>
            ))}
          </React.Fragment>
        ))}
      </div>

      {/* Clear All button */}
      {shouldShowClearAll  && (
        <Button
          variant="outlined"
          className="ClearbuttonFilter"
          onClick={handleClearAll}
          sx={{ alignSelf: "flex-end" }}
        >
          Clear All
        </Button>
      )}
    </Paper>
  );
}
