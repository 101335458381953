import React from 'react';
import { connect } from 'react-redux';
import CreateButton from '../../components/common/CreateButton.js';
import { Redirect } from 'react-router-dom';
import { routes } from '../../helper/routes.js';
import { history } from '../../helper/history.js';
import { libraryActions } from '../../actions/question/libraryActions.js';
import ModalHeader from '../../components/common/modal/ModalHeader.js';
import ContentWrapper from '../../components/common/AssessmentContentWrapper.js';
import AdminTemplate from '../../components/admin/layouts/Template.js';
import SelfGradeModal from '../../components/common/modal/SelfGradeModal.js';
import ModalBody from '../../components/common/modal/ModalBody.js';
import Card from '../../components/common/Card.js';
import CardHeader from '../../components/common/CardHeader.js';
import CardBody from '../../components/common/CardBody.js';
import CardTitle from '../../components/common/AssessmentCardTitle.js';
import CardToolbar from '../../components/common/SelfGradeCardToolBar.js';
import Breadcrumb from '../../components/common/Breadcrumb.js';
import BreadcrumbItem from '../../components/common/BreadcrumbItem.js';
import Button from '../../components/common/form/Button.js';
import SearchFormGroup from '../../components/common/table/SearchFormGroup.js';
import Select from '../../components/common/form/AssessmentSelect.js';
import Option from '../../components/common/form/Option.js';
import CheckBox from '../../components/common/form/CheckBox.js';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '../../components/common/form/FormGroup.js';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Form from "react-bootstrap/Form";
import { ADD_QUESTION_FILTERS, questionMCQAnswerTypeConstants } from '../../helper/constants.js';
import CheckBoxInline from '../../components/common/form/CheckBoxInline.js';
import { Tooltip } from '@mui/material';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../components/common/MaterialCustomTablePagination.js";
import { createActions } from "../../actions/assessment/createActions";
import { createActions as questionCreateActions } from "../../actions/question/createActions";
import { AzureStorage } from '../../helper/azureStorage.js';
import Spinner from '../../components/common/Spinner.js';
import { questionActions } from '../../actions/assessment/questionActions.js';
import { Editor } from 'react-draft-wysiwyg';
import ViewEditor from '../../components/common/editor/ViewEditor.js';
import { convertFromRaw, EditorState } from "draft-js";
import { questionTypeConstants } from "../../helper/constants";
import ReactForm from "react-bootstrap/Form";
import ReactSpinner from "react-bootstrap/Spinner";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../helper/config";
import { customerConstants } from "../../helper/constants.js";
import SetupAssessment from './SetupAssessment.js';
import RadioInline from '../../components/common/form/RadioInline.js';
import moment from "moment";
import Radio from '../../components/common/form/Radio.js';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    // cancelClass = "cmc-btn-default"
    // confirmClass = "cmc-btn-primary"
  default:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class FinalizeNewQuestion extends React.Component {

    constructor(props) {
        super(props);

        this.ref_tagify = React.createRef()
        this.state = {
            selectedQuestions: [],
            addSuccess: false,
            editorQuestionState: EditorState.createEmpty(),
            questionsData: [],
            selectedQuestionsData: [],
            checkedQuestion: [],
            questions: [],
            attachment: '',
            addQuestions: [],
            limit: 300,
            offset: 0,
            search_term: "",
            currentPage: 0,
            sort_column: "created_at",
            sort_direction: "DESC",
            rowsPerPage: 300,
            isTableDataLoading: false,
            isViewModalLoading: false,
            count: 10,
            category_id: null,
            states: '',
            filters: [],
            selectedFilters: [],
            selectedGrades: null,
            selectedChapters: null,
            selectedsubjects: null,
            selectedAuthors: null,
            selectedYears: null,
            selectedCategories: null,
            activeTab: 1,
            selectedTerms: null,
            selectedSources: null,
            selectedTypes: null,
            selectedDifficulties: null,
            selectedLanguages: null,
            selectedTeachers: null,
            teachers: []
        }
        this.onAddQuestion = this.onAddQuestion.bind(this);
        this.onViewFinalizeNewQuestion = this.onViewFinalizeNewQuestion.bind(this);
        this.onDownloadAttachment = this.onDownloadAttachment.bind(this);
    }

    async componentDidMount() {
        if (window.KTSelect2) window.KTSelect2.init();
        if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
        if (window.KTSweetAlert2Demo) window.KTSweetAlert2Demo.init();
        if (window.KTIONRangeSlider) window.KTIONRangeSlider.init();
        if (window.KTApp.initComponents) window.KTApp.initComponents();
        if (window.$) window.$('.selectpicker').selectpicker();
        if (typeof window.KTLayoutStickyCard !== 'undefined') {
            window.KTLayoutStickyCard.update();
        } else {
            if (window.KTLayoutStickyCard) window.KTLayoutStickyCard.init('kt_page_sticky_card');
        }
        await this.props.getQuestionMetaData();
        await this.fetchAllAddQuestions();
        this.setState({ count: this.props.totalAddQuestionsCount });
        await this.props.questions;
        let questions = this.props.questions
        questions.map((val) => {
            return val["checked"] = false
        })
        this.setState({ questionsData: questions, questions: questions })
        this.onDatatableActionEvent()

        await this.props.getQuestionCategories();

        window.onpopstate = function (event) {
            history.push(routes.setup_assessment)
            window.location.reload()
        };
        await this.props.getQuestionTypes();
        await this.props.getQuestionDifficulty();
    }

    onDatatableActionEvent() {
        window.$(document).on('click', '.view-question-btn', (e) => {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.onViewFinalizeNewQuestion(this.state.addQuestions[idx])
            window.$('#view-details').modal('show')
        })
    }

    fetchAllAddQuestions = async () => {
        const {
            limit,
            offset,
            sort_column,
            sort_direction,
            search_term,
            category_id,
            selectedCategories,
            selectedAuthors,
            selectedChapters,
            selectedGrades,
            selectedDifficulties,
            selectedLanguages,
            selectedTypes,
            selectedTerms,
            selectedYears,
            selectedsubjects,
            selectedSources,
            selectedTeachers
        } = this.state;
        this.setState({ isTableDataLoading: true });
        await this.props.getAllMyQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectedCategories, selectedAuthors, selectedChapters,
            selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources, selectedTeachers);
        this.setState({ addQuestions: this.props.questions });
        this.setState({ questionsData: this.props.questions });
        this.setState({ teachers: this.props.teachers });
        let questions = this.state.questionsData;
        for (let question of questions) {
            let selected = this.questionSelected(question.question_id);
            if (selected) {
                question.checked = true;
            }
            else {
                question.checked = false;
            }
        }
        this.setState({ limit: this.state.rowsPerPage });
        this.setState({ isTableDataLoading: false });
    };

    async onViewFinalizeNewQuestion(element, e) {

        this.setState({
            editorQuestionState: '',
            editorAnswerStates: '',
            editorAnswerStateDescriptive: ''
        })
        let tempAnswerStates = [];
        let tempAnswer = [];
        this.setState({ isViewModalLoading: true });
        await this.props.getLibraryQuestionContent(element);
        if(this.props?.libraryQuestionContent){
            let states = await JSON.parse(this.props?.libraryQuestionContent);
            this.setState({states: states});
            let tempQuestionState = EditorState.createWithContent(convertFromRaw(states[0]));
            this.setState({ editorQuestionState: tempQuestionState });
        }

        if (element?.qtype === questionTypeConstants.DESCRIPTIVE.id) {
            await this.props.getLibraryAnswerContent(element);
            let states = await JSON.parse(this.props?.libraryAnswerContent);
            let Ans = EditorState.createWithContent(convertFromRaw(states[0]))
            this.setState({ editorAnswerStateDescriptive: Ans })

        } if (element?.qtype === questionTypeConstants.SUBQUESTION.id && element?.is_subquestion === "true") {

            await this.props.getLibraryAnswerContent(element);
            let states = await JSON.parse(this.props?.libraryAnswerContent);
            let Ans = EditorState.createWithContent(convertFromRaw(states[0]))
            this.setState({ editorAnswerStateDescriptive: Ans })

        }
        else if (element?.qtype === questionTypeConstants.MCQ.id) {
            this.state.states.shift();
            this.state.states.forEach(state => {
                tempAnswerStates.push(EditorState.createWithContent(convertFromRaw(state)))
            });
            this.props.libraryQuestion.answer.split(',').map((answer) => {
                tempAnswer.push(parseInt(answer) > 0);
            });
            this.setState({
                editorAnswerStates: tempAnswerStates,
                selectedChoices: tempAnswer
            })

        } else if (element?.qtype === questionTypeConstants.SUBMISSION.id) {
            await this.props.getLibraryAnswerContent(element);
            let attachmentName = this.props?.libraryAnswerContent.replace(/^\[(.+)\]$/, "$1").replace(/"\"$/, "$1").split(',');
            this.setState({
                attachment: attachmentName
            });
        }
        this.setState({ isViewModalLoading: false });
    }

    getQuestionTypeName(qType, is_subquestion) {
        if (qType === questionTypeConstants.MCQ.id) {
            return questionTypeConstants.MCQ.name;
        } else if (qType === questionTypeConstants.DESCRIPTIVE.id) {
            return questionTypeConstants.DESCRIPTIVE.name
        } else if (qType === questionTypeConstants.SUBMISSION.id) {
            return questionTypeConstants.SUBMISSION.name;
        } else if (qType === questionTypeConstants.SUBQUESTION.id && is_subquestion === "true") {
            return questionTypeConstants.SUBQUESTION.name;
        } else if (qType === questionTypeConstants.SUBQUESTION.id && is_subquestion === null) {
            return questionTypeConstants.SUBQUESTION.name + " (Main-Question)";
        }
    }

    getQuestionCategory(category_id) {
        let categoryname
        this.props.questionCategories.map(category => {

            if (category.category_id === category_id) {
                return categoryname = category.name
            }
        })
        return categoryname
    }

    getQuestionDifficulty(difficulty_id) {
        if (difficulty_id !== null) {
            let questionDifficulties = this.props.allQuestionMetaData.find(item => 'difficulty_types' in item)?.difficulty_types;
            const difficulty = questionDifficulties?.find(
                (difficulty) => difficulty.difficulty_id == difficulty_id
            );
            return difficulty?.difficulty_type;
        }
    }

    getColor(type) {
        switch (type) {
            case "Easy":
                return "label label-lg label-light-success label-inline";
            case "Medium":
                return "label label-lg label-light-warning label-inline";
            default:
                return "label label-lg label-light-danger label-inline";

        }
    }

    getShare(share) {
        if (share > 0) {
            return <span className="label label-lg label-light-primary label-inline">Shared</span>
        } else {
            return ""
        }
    }

    getDate(date) {
        let createdTime = new Date(date).getTime();
        let currentTime = new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000;

        let difference = currentTime - createdTime;

        let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24

        let hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60

        let minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60

        if (daysDifference == 0 && hoursDifference == 0 && minutesDifference == 0) {
            return 'Created Just now';
        }
        else {
            return 'Created ' + daysDifference + 'd ' + hoursDifference + 'h ' + minutesDifference + 'm ' + 'ago';
        }
    }

    handleChangeQuestionCategory = (event) => {
        if (event.target.value == 0) {
            this.setState(
                {
                    category_id: null,
                    isTableDataLoading: true
                },
                () => {
                    this.fetchAllAddQuestions();
                }
            )
        } else {
            this.setState(
                {
                    category_id: event.target.value,
                    isTableDataLoading: true
                },
                () => {
                    this.fetchAllAddQuestions();
                }
            )
        }
    }

    questionExists(question_id) {
        return this.props.assessmentQuestions.some(function (el) {
            return el.question_id == question_id;
        });
    }

    questionSelected(question_id) {
        return this.state.selectedQuestionsData.some(function (el) {
            return el.question_id == question_id;
        });
    }

    questionsExists(question_ids) {
        return this.props.assessmentQuestions.includes()
    }

    isSelectedQuestionMCQ(question_id) {
        var mcqQuestions = this.props.questions.filter((question) => question.qtype === questionTypeConstants.MCQ.id);
        return mcqQuestions.some(function (el) {
            return el.question_id == question_id;
        });
    }

    onSelectQuestion(e) {
        var questions = this.state.selectedQuestions
        var allQuestions = this.state.addQuestions
        var selectedQuestionsData = this.state.selectedQuestionsData
        var added = this.questionExists(e.target.value)
        var selected = this.questionSelected(e.target.value);
        // var isSelectedQuestionMCQ = this.isSelectedQuestionMCQ(e.target.value)
        let question = allQuestions.filter(val => { return val.question_id == e.target.value })
        question[0].checked = true
        if (e.target.checked) {
            if (question[0]?.is_subquestion === "true") {
                window.Swal.fire({
                    title: 'Warning!',
                    text: 'Add the main question and sub questions will be automatically added',
                    icon: 'warning',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                      },
                      onOpen: (modalElement) => {
                          if (customer === customerConstants.EXO_CUSTOMER2) {
                              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                          }
                      }
                });
                e.target.checked = false
                question[0].checked = false
            } else if (!added) {
                questions.push(parseInt(e.target.value))
                if (question[0]?.sub_questions !== null) {
                    let ques = question[0]?.sub_questions?.split(",")
                    for (let j = 0; j <= ques?.length - 1; j++) {
                        questions.push(parseInt(ques[j]))
                    }

                }
                selectedQuestionsData.push(question[0])
                this.setState({
                    selectedQuestionsData: selectedQuestionsData
                })
            }
            if (added) {
                window.Swal.fire({
                    title: 'Warning!',
                    text: 'Question is already added!',
                    icon: 'warning',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                });
                e.target.checked = false
                question[0].checked = false
            }
            if (!added && selected) {
                window.Swal.fire({
                    title: 'Warning!',
                    text: 'Question is already selected!',
                    icon: 'warning',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                });
            }
            // if (!isSelectedQuestionMCQ) {
            //     window.Swal.fire('Warning!', 'Can only add MCQ question!', 'warning');
            //     e.target.checked = false
            //     question[0].checked = false
            // }
        } else {
            var index = questions.indexOf(question[0].question_id);
            if (index !== -1) {
                questions.splice(index, 1);
            }
            if (question[0]?.sub_questions !== null) {
                let ques = question[0]?.sub_questions?.split(",")
                for (let j = 0; j <= ques.length - 1; j++) {
                    var ind = questions.findIndex((val) => (val?.question_id === parseInt(ques[j])))
                    questions.splice(ind, 1);
                }

            }
            question[0].checked = false
            //questions.pop(question[0].question_id);
            selectedQuestionsData.pop(question[0]);
        }
        this.setState({
            selectedQuestions: questions,
            selectedQuestionsData: selectedQuestionsData
        })
    }

    setCheckedQuestion = (val) => {
        if (this.state.selectedQuestions.includes(val)) {
            return true
        } else {
            return false
        }
    }

    async onAddQuestion() {
        const assessment_id = this.props.assessment.assessment_id;
        const questions = this.state.selectedQuestions;
        const assessor_id = this.props.assessment.user_role_id;
        window.Swal.showLoading()
        await this.props.addQuestions({ assessment_id, questions, assessor_id });
        if (!this.props.add_loading && this.props.error) {
            window.Swal.fire({
                title: 'Failed!',
                text: 'Failed to add new question',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        } else {
            this.props.refreshforAddingQuestions()
            this.props.getAssessmentQuestions(assessment_id);
            this.setState({assessment_id: assessment_id});
            window.Swal.fire({
                title: 'Success!',
                text: 'Added new question',
                icon: 'success',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
            this.setState({ addSuccess: true });
        }
        if (this.props.error == '') {
            this.props.viewAddQuestions(this.props.assessment)
            this.fetchAllAddQuestions();
            this.setState({ limit: this.props.totalAddQuestionsCount });
            this.props.getAllLibraryQuestions();
            this.props.getAssessmentQuestions(assessment_id);
            this.props.refreshforAddingQuestions()

        }
    }

    handleCheckAll = (e) => {
        let questions = this.state.questionsData
        let selectedQuestions = this.state.selectedQuestions;
        let selectedQuestionsData = this.state.selectedQuestionsData;

        if (e.target.checked == true) {
            for (let object of questions) {
                var added = this.questionExists(object.question_id)
                var selected = this.questionSelected(object.question_id)
                //var isSelectedQuestionMCQ = this.isSelectedQuestionMCQ(object.question_id)
                if (!added && !selected) {
                    object.checked = true;
                    selectedQuestions.push(object.question_id)
                    selectedQuestionsData.push(object)
                    this.setState({
                        questionsData: questions,
                        selectedQuestions: selectedQuestions,
                        selectedQuestionsData: selectedQuestionsData
                    })
                }
                if (added) {
                    window.Swal.fire({
                        title: 'Warning!',
                        text: 'Current page contains already added questions!',
                        icon: 'warning',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    });
                    object.checked = false
                    e.target.checked = false
                }
                if (!added && selected) {
                    object.checked = true;
                    this.setState({
                        selectedQuestionsData: selectedQuestionsData
                    })
                }
                // if (!isSelectedQuestionMCQ) {
                //     window.Swal.fire('Warning!', 'Can only add MCQ question!', 'warning');
                //     object.checked = false
                //     e.target.checked = false
                // }

            }
        } else {
            for (let object of questions) {
                object.checked = false;
                selectedQuestions.pop(object.question_id)
                selectedQuestionsData.pop(object);
            }
            this.setState({
                questionsData: questions,
                selectedQuestions: selectedQuestions,
                selectedQuestionsData: selectedQuestionsData
            })
        }
    };

    onCreationQuestion(e) {
        var questions = this.state.selectedQuestions
        var added = this.questionExists(e.target.value)
        let question = [{ ...this.state.checkedQuestion }]
        if (e.target.checked) {
            if (!added) {
                questions.push(e.target.value)
            } else {
                window.Swal.fire({
                    title: 'Warning!',
                    text: 'Question is already added!',
                    icon: 'warning',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                });
                e.target.checked = false
            }
        } else {
            var index = questions.indexOf(e.target.value);
            if (index !== -1) {
                questions.splice(index, 1);
            }
        }

        this.setState({
            selectedQuestions: questions
        })
    }

    async onDownloadAttachment(e, attach) {
        e.preventDefault()
        let fileReader;
        async function handleFileRead() {
            const content = fileReader.result;

        }
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        let containerName = config.storageBlobConfigs.containerName;
        // let content = await AzureStorage.downloadBlob("".concat(this.props.libraryQuestion.created_by, "-sa-", this.props.libraryQuestion.answer), attach);
        let content = await AzureStorage.downloadBlob(containerName, attach);
        fileReader.readAsArrayBuffer(content);
    }

    getCreatedAtLocal(time) {
        if(time != null){
            let timeStamp = new Date(time.concat("Z"))
            let d = new Date(timeStamp)
            // let startDate = d.toDateString();
            // let startTime = d.toLocaleTimeString('en-SL');
            let  local = moment(timeStamp).local().format('YYYY-MM-DDTHH:mm')
            return local;
        }
        else{
            return "N/A";
        }
    }

    onFilterInput = (v, r) => {
        let categoryOptions
        let languageOptions
        let subjectOptions
        let sourceOptions
        let authorOptions
        let gradeOptions
        let yearOptions
        let termOptions
        let chapterOptions
        let typeOptions
        let difficultyOptions
        let questionCreatedTeacherOptions
        let selectedFilters = []
        this.setState({
            filters: v,
            filterValues: []
        })

        if (r === "removeOption") {
            this.setState({
                selectedAuthors: null,
                selectedsubjects: null,
                selectedChapters: null,
                selectedTerms: null,
                selectedCategories: null,
                selectedYears: null,
                selectedLanguages: null,
                selectedDifficulties: null,
                selectedTypes: null,
                selectedSources: null,
                selectedGrades: null,
                selectedTeachers: null
            }, () => {
                this.fetchAllAddQuestions();
            })

        }
        if (v.includes("Category")) {
            let questionCategories = this.props.allQuestionMetaData.find(item => 'categories' in item).categories;
            categoryOptions = questionCategories?.map((val) => {
                return {
                    filter: "Category",
                    name: val.name,
                    category_id: val.category_id
                }
            })
            selectedFilters = [...selectedFilters, ...categoryOptions]
        } if (v.includes("Language")) {
            let questionLanguages = this.props.allQuestionMetaData.find(item => 'languages' in item).languages;
            languageOptions = questionLanguages?.map((val) => {
                return {
                    filter: "Language",
                    name: val.language,
                    language_id: val.language_id
                }
            })
            selectedFilters = [...selectedFilters, ...languageOptions]
        } if (v.includes("Source")) {
            let questionSources = this.props.allQuestionMetaData.find(item => 'sources' in item).sources;
            sourceOptions = questionSources?.map((val) => {
                return {
                    filter: "Source",
                    name: val.source,
                    source_id: val.source_id
                }
            })
            selectedFilters = [...selectedFilters, ...sourceOptions]
        } if (v.includes("Subject")) {
            let questionSubjects = this.props.allQuestionMetaData.find(item => 'subjects' in item).subjects;
            subjectOptions = questionSubjects?.map((val) => {
                return {
                    filter: "Subject",
                    name: val.subject,
                    subject_id: val.subject_id
                }
            })
            selectedFilters = [...selectedFilters, ...subjectOptions]
        } if (v.includes("Type")) {
            let questionTypes = this.props.allQuestionMetaData.find(item => 'types' in item).types;
            typeOptions = questionTypes?.map((val) => {
                return {
                    filter: "Type",
                    name: val.qtype,
                    type_id: val.type_id
                }
            })
            selectedFilters = [...selectedFilters, ...typeOptions]
        } if (v.includes("Author")) {
            let questionAuthors = this.props.allQuestionMetaData.find(item => 'authors' in item).authors;
            authorOptions = questionAuthors?.map((val) => {
                return {
                    filter: "Author",
                    name: val.author,
                    author_id: val.author_id
                }
            })
            selectedFilters = [...selectedFilters, ...authorOptions]
        } if (v.includes("Year")) {
            let questionYears = this.props.allQuestionMetaData.find(item => 'years' in item).years;
            yearOptions = questionYears?.map((val) => {
                return {
                    filter: "Year",
                    name: val.year,
                    year_id: val.year_id
                }
            })
            selectedFilters = [...selectedFilters, ...yearOptions]
        } if (v.includes("Difficulty")) {
            let questionDifficulties = this.props.allQuestionMetaData.find(item => 'difficulty_types' in item).difficulty_types;
            difficultyOptions = questionDifficulties?.map((val) => {
                return {
                    filter: "Difficulty",
                    name: val.difficulty_type,
                    difficulty_id: val.difficulty_id
                }
            })
            selectedFilters = [...selectedFilters, ...difficultyOptions]
        } if (v.includes("Grade")) {
            let questionGrades = this.props.allQuestionMetaData.find(item => 'grades' in item).grades;
            gradeOptions = questionGrades?.map((val) => {
                return {
                    filter: "Grade",
                    name: val.grade,
                    grade_id: val.grade_id
                }
            })
            selectedFilters = [...selectedFilters, ...gradeOptions]
        } if (v.includes("Term")) {
            let questionTerms = this.props.allQuestionMetaData.find(item => 'terms' in item).terms;
            termOptions = questionTerms?.map((val) => {
                return {
                    filter: "Term",
                    name: val.term,
                    term_id: val.term_id
                }
            })
            selectedFilters = [...selectedFilters, ...termOptions]
        } if (v.includes("Chapter")) {
            let questionChapters = this.props.allQuestionMetaData.find(item => 'chapters' in item).chapters;
            chapterOptions = questionChapters?.map((val) => {
                return {
                    filter: "Chapter",
                    name: val.chapter,
                    chapter_id: val.chapter_id
                }
            })
            selectedFilters = [...selectedFilters, ...chapterOptions]
        } if (v.includes("Created By")) {
            let questionCreatedTeachers = this.state.teachers;
            questionCreatedTeacherOptions = questionCreatedTeachers?.map((val) => {
                return {
                    filter: "Created By",
                    name: val.first_name + ' ' + val.last_name,
                    teacher_user_id: parseInt(val.user_id)
                }
            })
            selectedFilters = [...selectedFilters, ...questionCreatedTeacherOptions]
        }
        
        this.setState({
            selectedFilters: selectedFilters
        })

    }

    onSelectFilter = (e) => {
        let selectedGrades = []
        let selectedChapters = []
        let selectedsubjects = []
        let selectedAuthors = []
        let selectedYears = []
        let selectedCategories = []
        let selectedTerms = []
        let selectedSources = []
        let selectedTypes = []
        let selectedDifficulties = []
        let selectedLanguages = []
        let selectedTeachers = []

        e.map((val) => {
            if (val.filter === "Category") {
                selectedCategories.push(val?.category_id)
            } if (val.filter === "Type") {
                selectedTypes.push(val?.type_id)
            } if (val.filter === "Difficulty") {
                selectedDifficulties.push(val?.difficulty_id)
            } if (val.filter === "Language") {
                selectedLanguages.push(val?.language_id)
            } if (val.filter === "Source") {
                selectedSources.push(val?.source_id)
            } if (val.filter === "Subject") {
                selectedsubjects.push(val?.subject_id)
            } if (val.filter === "Author") {
                selectedAuthors.push(val?.author_id)
            } if (val.filter === "Year") {
                selectedYears.push(val?.year_id)
            } if (val.filter === "Term") {
                selectedTerms.push(val?.term_id)
            } if (val.filter === "Chapter") {
                selectedChapters.push(val?.chapter_id)
            } if (val.filter === "Grade") {
                selectedGrades.push(val?.grade_id)
            } if (val.filter === "Created By") {
                selectedTeachers.push(val?.teacher_user_id)
            }
        })

        this.setState({
            filterValues: e,
            selectedAuthors: selectedAuthors.length > 0 ? selectedAuthors : null,
            selectedsubjects: selectedsubjects.length > 0 ? selectedsubjects : null,
            selectedChapters: selectedChapters.length > 0 ? selectedChapters : null,
            selectedTerms: selectedTerms.length > 0 ? selectedTerms : null,
            selectedCategories: selectedCategories.length > 0 ? selectedCategories : null,
            selectedYears: selectedYears.length > 0 ? selectedYears : null,
            selectedLanguages: selectedLanguages.length > 0 ? selectedLanguages : null,
            selectedDifficulties: selectedDifficulties.length > 0 ? selectedDifficulties : null,
            selectedTypes: selectedTypes.length > 0 ? selectedTypes : null,
            selectedSources: selectedSources.length > 0 ? selectedSources : null,
            selectedGrades: selectedGrades.length > 0 ? selectedGrades : null,
            selectedTeachers: selectedTeachers.length > 0 ? selectedTeachers : null

        }, () => {
            this.fetchAllAddQuestions();
        })

    }

    handlePageChange = (event, newPage) => {
        const { rowsPerPage } = this.state;
        const newOffset = newPage * rowsPerPage;
        this.setState(
            {
                limit: rowsPerPage,
                offset: newOffset,
                isTableDataLoading: true,
                currentPage: newPage,
            },
            () => {
                this.fetchAllAddQuestions();
            }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        this.setState(
            {
            offset: newOffset,
            isTableDataLoading: true,
            currentPage: newPage
            },
            () => {
            this.fetchAllAddQuestions();
            }
        );
    };

    //handle the data fetching as per the data per page limit gets changes
    handleRowsPerPageChange = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage: newLimit });
        const newPage = Math.floor(this.state.offset / newLimit);
        this.setState(
            {
                limit: newLimit,
                offset: newLimit * newPage,
                isTableDataLoading: true,
                currentPage: newPage,
            },
            () => {
                this.fetchAllAddQuestions();
            }
        );
    };

    handleSortChange = (sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { sort_column: currentSortColumn, sort_direction } = this.state;
        const newSortDirection =
            currentSortColumn === sort_column && sort_direction === "ASC"
                ? "DESC"
                : "ASC";

        this.setState(
            {
                sort_column,
                sort_direction: newSortDirection,
                offset: 0, // Reset offset when changing sorting
                currentPage: 0,
                isTableDataLoading: true,
            },
            () => {
                this.fetchAllAddQuestions();
            }
        );
    };

    handleSearch = (event) => {
        const limit = this.state.limit;
        const offset = this.state.offset;
        const search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

        if (search_term !== "") {
            this.setState({ limit: this.props.totalAddQuestionsCount });
        }

        if (search_term === "" || regex.test(search_term)) {
            this.setState(
                {
                    limit,
                    offset,
                    search_term,
                    isTableDataLoading: true,
                    offset: 0,
                    currentPage: 0,
                    searchError: false,
                },
                () => {
                    // Call the debounced version of fetchStudentData
                    // this.debouncedHandleSearch();
                    this.fetchAllAddQuestions();
                }
            );
        } else {
            this.setState({ search_term, searchError: true });
        }
    };

    render() {
        if (this.state.addSuccess) {
            return <SetupAssessment assessment={this.props.assessment}/>
        }
        
        var categories = this.props.allCategories
        var qCategoryNames = [];

        categories.map((el, i) => {
            qCategoryNames.push(el);
        });

        var categoryOptions = [];
        categoryOptions.push(
            <Option key={0} optionName={"Nothing Selected"} value={0} selected="selected" />
        );

        for (let category of categories) {
            categoryOptions.push(<Option key={category.category_id} optionName={category.name} value={category.category_id} />);
        }

        return (
            <AdminTemplate>
                <ContentWrapper>
                    {/* begin::Card */}
                    {this.props.add_loading &&
                        <Spinner id="show" text="Loading, Please wait!" />}
                    {/* {this.props.qus_loading && <Spinner id="show" text="Please wait.." />}
                    {this.props.dropDownDataloading && <Spinner id="show" text="Please wait.." />} */}
                    {/* {this.state.isViewModalLoading && <Spinner id="show" text="Please wait.." />} */}
                    {this.props.assessment.title != undefined ?
                        <Card>
                            {/* {this.state.addFail && <Alert className="alert alert-custom alert-danger fade show" alertIcon="flaticon-warning" alertText="A simple primary alert—check it out!" />} */}
                            <CardHeader>
                                <CardTitle cardLabel={this.props.assessment.title}>
                                    <Breadcrumb>
                                        {/* <BreadcrumbItem to={routes.view_assessment} breadcrumbItemName="Go Back to Assessments Library" /> */}
                                        <BreadcrumbItem to={routes.setup_assessment} breadcrumbItemName="Go Back to Finalize" />
                                        <BreadcrumbItem to={routes.finalize_new_question} active="active" breadcrumbItemName="Add new question" />
                                    </Breadcrumb>
                                </CardTitle>
                                <CardToolbar>
                                    <CreateButton createButtonTitle="create new question" to={routes.create_question} />
                                    <Button className="btn btn-primary mr-2" btnName="Add question" onClick={this.onAddQuestion} disabled={!this.state.selectedQuestions.length} />
                                </CardToolbar>
                            </CardHeader>
                            <CardBody>
                                {/* <TableFilter> */}
                                {/* <div className="row pt-5">
                                    <div className="col-md-3 d-flex justify-content-center">
                                        <SearchFormGroup
                                            labelName="Select All"
                                            className="col-lg-12 m-style text-nowrap"
                                            labelColStyle="col-lg-6 col-6 col-form-label"
                                            formColStyle="col-lg-6"
                                        >
                                            <CheckBoxInline>
                                                <CheckBox
                                                    onChange={this.handleCheckAll}
                                                />
                                            </CheckBoxInline>
                                        </SearchFormGroup>
                                    </div>

                                    <div className="col-md-5">
                                        <SearchFormGroup
                                            labelName="Category"
                                            className="col-md-12 m-style text-nowrap"
                                            labelColStyle="col-md-3 col-form-label"
                                        >
                                            <Select
                                                selectWrap="col-md-9"
                                                selectId="question-category"
                                                id="question-category"
                                                className="form-control selectpicker"
                                                dataLiveSearch="false"
                                                dataSize="5"
                                                defaultValue={categoryOptions[0]}
                                                multiple={false}
                                                spinner={this.props.dropDownDataloading && categoryOptions.length <= 0}
                                                disabled={this.props.dropDownDataloading && categoryOptions.length <= 0}
                                                children={categoryOptions}
                                                onChange={this.handleChangeQuestionCategory}
                                            ></Select>
                                        </SearchFormGroup>
                                    </div>

                                </div> */}

                                {/* <SearchFormGroup
                                    labelName="Type"
                                    className="col-md-3 m-style"
                                    labelColStyle="col-lg-12 col-form-label"
                                >

                                    <Select
                                        selectWrap="col-lg-12"
                                        selectId="question-type"
                                        id="question-type"
                                        className="form-control selectpicker"
                                        dataLiveSearch="true"
                                        dataSize="5"
                                        defaultValue={typeOptions[0]}
                                        multiple={false}
                                        spinner={this.props.loading && typeOptions.length <= 0}
                                        disabled={this.props.loading && typeOptions.length <= 0}
                                        children={typeOptions}
                                        onChange={this.handleChangeQuestionType}
                                    ></Select>

                                </SearchFormGroup>

                                <SearchFormGroup
                                    labelName="Difficulty"
                                    className="col-md-3 m-style"
                                    labelColStyle="col-lg-12 col-form-label"
                                >

                                    <Select
                                        selectWrap="col-lg-12"
                                        selectId="question-difficulty"
                                        id="question-difficulty"
                                        className="form-control selectpicker"
                                        dataLiveSearch="true"
                                        dataSize="5"
                                        defaultValue={difficultyOptions[0]}
                                        multiple={false}
                                        spinner={this.props.loading && difficultyOptions.length <= 0}
                                        disabled={this.props.loading && difficultyOptions.length <= 0}
                                        children={difficultyOptions}
                                        onChange={this.handleChangeQuestionDifficulty}
                                    ></Select>

                                </SearchFormGroup> */}
                                {/* </TableFilter> */}
                            </CardBody>

                            <CardBody>
                            <div className="row py-5 d-flex align-items-center">
                                <div className="col-md-3 d-flex justify-content-center">
                                    <SearchFormGroup
                                        labelName="Select All"
                                        className="col-lg-12 m-style text-nowrap"
                                        labelColStyle="col-lg-6 col-6 col-form-label"
                                        formColStyle="col-lg-6"
                                    >
                                        <CheckBoxInline>
                                            <CheckBox
                                                onChange={this.handleCheckAll}
                                            />
                                        </CheckBoxInline>
                                    </SearchFormGroup>
                                 </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <FormGroup labelName="Filter By">
                                                <Autocomplete
                                                    multiple
                                                    id="checkboxes-filter"
                                                    options={ADD_QUESTION_FILTERS}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => option}
                                                    onChange={(e, v, r) => this.onFilterInput(v, r)}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            <Checkbox
                                                                style={{ marginRight: 8, color: "#5458AF" }}
                                                                checked={selected}
                                                            />
                                                            {option}
                                                        </li>
                                                    )}
                                                    className="col-lg-8 col-md-8 p-0"
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select Filter" />
                                                    )}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6">
                                            {this.state.filters.length > 0 && (
                                                <FormGroup labelName="">
                                                    <Autocomplete
                                                        multiple
                                                        id="grouped-demo"
                                                        className="col-lg-8 col-md-8 p-0"
                                                        options={this.state.selectedFilters}
                                                        groupBy={(option) => option.filter}
                                                        onChange={(e, v) => this.onSelectFilter(v)}
                                                        getOptionLabel={(option) => option.name}
                                                        sx={{ width: 300 }}
                                                        value={this.state.filterValues}
                                                        renderInput={(params) => <TextField {...params} label="Filters" />}
                                                    />
                                                </FormGroup>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-3 mb-2">
                                    <Form.Group
                                        controlId="exampleForm.ControlInput1"
                                        className="mb-2"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="Search"
                                            onChange={this.handleSearch}
                                            className="col-md-12 col-12"
                                        />
                                    </Form.Group>
                                </div>
                                </div>
                                {(this.state.isTableDataLoading || this.props.qus_loading || this.props.dropDownDataloading) ? (
                                    <div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="thead"></TableCell>
                                                    <TableCell className="thead">No</TableCell>
                                                    <TableCell className="thead">Question Title</TableCell>
                                                    <TableCell className="thead">Question Category</TableCell>
                                                    <TableCell className="thead">Question Type</TableCell>
                                                    <TableCell className="thead">Question Difficulty</TableCell>
                                                    <TableCell className="thead">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                        <div className="loading-container mb-5 mt-10">
                                            <ReactSpinner animation="border" role="status" style={{ display: 'block', margin: 'auto' }}>
                                                <span className="sr-only">Loading...</span>
                                            </ReactSpinner>
                                        </div>
                                    </div>
                                ) : (
                                    this.props.questions.length > 0 ?
                                        <div>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="thead">Select</TableCell>
                                                        <TableCell className="thead">No</TableCell>
                                                        <TableCell
                                                            className="thead"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => this.handleSortChange("title")}
                                                        >
                                                            Question Title{" "}
                                                            {this.state.sort_column === "title" && (
                                                                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            className="thead"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => this.handleSortChange("category_id")}
                                                        >
                                                            Question Category{" "}
                                                            {this.state.sort_column === "category_id" && (
                                                                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            className="thead"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => this.handleSortChange("qtype")}
                                                        >
                                                            Question Type{" "}
                                                            {this.state.sort_column === "qtype" && (
                                                                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            className="thead"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => this.handleSortChange("difficulty_id")}
                                                        >
                                                            Question Difficulty{" "}
                                                            {this.state.sort_column === "difficulty_id" && (
                                                                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell className="thead">Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.addQuestions.map((el, i) => (
                                                        <TableRow key={i} checkBoxID={i}>
                                                            <TableCell className="tData">
                                                                {<input type="checkbox" value={el.question_id} onChange={(e) => this.onSelectQuestion(e)} checked={el.checked} key={el.question_id} />}
                                                            </TableCell>
                                                            <TableCell className="tData">{i + 1}</TableCell>



                                                            <TableCell className="tData">
                                                                <Tooltip title={el.title} placement="bottom-start" arrow>
                                                                    <div>
                                                                        {el.title.length > 20 ? `${el.title.substring(0, 20)}...` : el.title}
                                                                        {/* <span className="d-block text-muted pt-2 font-size-sm">{this.getDate(el.created_at)}</span> */}
                                                                    </div>
                                                                </Tooltip>
                                                            </TableCell>


                                                            <TableCell className="tData">{this.getQuestionCategory(el.category_id)}</TableCell>
                                                            <TableCell className="tData">{this.getQuestionTypeName(el.qtype, el?.is_subquestion)}</TableCell>
                                                            <TableCell className="tData">
                                                                {<span className={this.getColor(this.getQuestionDifficulty(el.difficulty_id))}>{this.getQuestionDifficulty(el.difficulty_id)}</span>}
                                                            </TableCell>
                                                            <TableCell className="tData">
                                                                {<div>
                                                                    <Button
                                                                        onClick={(e) => this.onViewFinalizeNewQuestion(el, e)}
                                                                        className="btn btn-icon action-btn btn-sm m-1 view-question-btn flaticon-eye-icon"
                                                                        dataIndex={i}
                                                                        title="View Details"
                                                                    />
                                                                </div>}
                                                            </TableCell>
                                                        </TableRow>

                                                    ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </div> :
                                        <div>
                                            <div>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="thead"></TableCell>
                                                            <TableCell className="thead">No</TableCell>
                                                            <TableCell className="thead">Question Title</TableCell>
                                                            <TableCell className="thead">Question Category</TableCell>
                                                            <TableCell className="thead">Question Type</TableCell>
                                                            <TableCell className="thead">Question Difficulty</TableCell>
                                                            <TableCell className="thead">Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </div>
                                            <div className="text-center mb-5 mt-10">
                                                <label>No Questions Available!</label>
                                            </div>
                                        </div>
                                )}
                                <div className="d-flex justify-content-end">
                                    <MaterialCustomTablePagination
                                        totalCount = {this.state.count}
                                        currentPage = {this.state.currentPage}
                                        limit = {this.state.rowsPerPage}
                                        handlePageChange = {this.handlePageChange}
                                        handleRowsPerPageChange = {this.handleRowsPerPageChange}
                                        handlePageOffsetChange = {this.handlePageOffsetChange}
                                        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100, 150, 200, 250, 300]}
                                        offset = {this.state.offset}
                                    />
                                </div>
                            </CardBody>
                        </Card> : <div><Redirect to={routes.view_assessment} /></div>}
                    {/* end::Card */}

                    {/* <Modal modalTitle="View Questions" modalId="view-details">
                    <ModalBody>
                        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                            <div className="row">
                                <h6 className="font-weight-bolder mb-3 col-md-3">Questions</h6>
                                <div className="text-dark-50 line-height-lg col-md-9">
                                    <div>
                                        <Editor
                                            editorState={this.state.editorQuestionState}
                                            toolbarClassName='hide-toolbar'
                                            toolbarHidden={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-light-primary font-weight-bold" dataDismiss="modal" btnName="Close" onClick={(e) => this.setState({ viewDetails: '' })} />
                    </ModalFooter>
                </Modal> */}

                    {/* View Question Modal */}
                    <SelfGradeModal modalId="view-details" dataBackDrop="static" dataKeyboard="false">
                        <ModalHeader>
                            <h4 className="modal-title text-muted">{this.props.libraryQuestion?.title}</h4>
                            <Button className="btn btn-light-primary font-weight-bold" dataDismiss="modal" btnName="Close" />
                        </ModalHeader>
                        {(this.props.qus_loading || this.state.isViewModalLoading) ? (
                        <div>
                            <div className="loading-container mb-5 mt-10">
                                <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                                >
                                    <span className="sr-only">Loading...</span>
                                </ReactSpinner>
                            </div>
                            </div>
                            ) : (
                        <ModalBody>
                            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                {/* begin::Section */}
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-3">{this.props.libraryQuestion?.sub_questions !== null ? "Main Question" : this.props.libraryQuestion?.is_subquestion === "true" ? "Sub Question" : "Question"}</h6>
                                    <div className="text-dark-50 line-height-lg col-md-9">
                                        <div>
                                            <ViewEditor
                                                editorState={this.state.editorQuestionState}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* end::Section */}
                                {/* begin::Section */}
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-lg-3 col-md-12">{this.props.libraryQuestion?.sub_questions === null ? "Model Answer" : ""}</h6>
                                    <div className="text-dark-50 line-height-lg col-lg-9 col-md-12">
                                        <div>
                                            {this.props.libraryQuestion?.qtype === questionTypeConstants.DESCRIPTIVE.id && this.props?.libraryAnswerContent &&
                                                <div>
                                                    <ViewEditor
                                                        editorState={this.state.editorAnswerStateDescriptive}
                                                    />
                                                </div>

                                            }
                                            {this.props.libraryQuestion?.qtype === questionTypeConstants.SUBQUESTION.id && this.props?.libraryQuestion?.is_subquestion === "true" &&
                                                <div>
                                                    <ViewEditor
                                                        editorState={this.state.editorAnswerStateDescriptive}
                                                    />
                                                </div>

                                            }
                                            {this.props.libraryQuestion?.qtype === questionTypeConstants.MCQ.id && this.props?.libraryQuestionContent &&
                                                <div>

                                                    {this.state.editorAnswerStates?.length > 0 &&
                                                        this.state?.editorAnswerStates.map((editorState, i) => (
                                                            <div className="view-questions-answer d-flex">

                                                                {this.props.libraryQuestion?.answer_type_id === questionMCQAnswerTypeConstants.One.id ? 
                                                                    <RadioInline className="col-md-1">
                                                                        <Radio checked={this.state?.selectedChoices[i]} />
                                                                    </RadioInline>
                                                                    :
                                                                    <CheckBoxInline className="col-md-1">
                                                                        <CheckBox checked={this.state?.selectedChoices[i]} />
                                                                    </CheckBoxInline>
                                                                }
                                                                <div className="RichEditor-root">
                                                                    <ViewEditor
                                                                        editorState={this.state.editorAnswerStates[i]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            {this.props.libraryQuestion?.qtype === questionTypeConstants.SUBMISSION.id && this.state.attachment &&
                                                <div className="RichEditor-root col-lg-8">
                                                    {this.state.attachment.map(attach => {
                                                        if (attach.replace(/"/g, '') != "[]") {
                                                            return <div>
                                                                <Button className="btn btn-outline-primary primary-button mr-2" btnIconStyle="flaticon-interface-3" inlineStyle={{ innerWidth: '400px' }}
                                                                    btnName={attach.replace(/"/g, '')} onClick={(e) => {
                                                                        this.onDownloadAttachment(e, attach.replace(/"/g, ''))
                                                                    }} />
                                                                <br></br></div>
                                                        }
                                                        else {
                                                            return <div>
                                                            </div>
                                                        }
                                                    })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.props?.libraryQuestion?.category_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Category :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props?.allQuestionMetaData.find(item => 'categories' in item).categories 
                                            .find(category => category.category_id === this.props?.libraryQuestion?.category_id)?.name
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props?.libraryQuestion?.qtype && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Type :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props?.allQuestionMetaData.find(item => 'types' in item).types
                                            .find(type => type.type_id === this.props?.libraryQuestion?.qtype)?.qtype
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props?.libraryQuestion?.language_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Language :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props?.allQuestionMetaData.find(item => 'languages' in item).languages
                                            .find(language => language.language_id === this.props?.libraryQuestion?.language_id)?.language
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props?.libraryQuestion?.difficulty_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Difficulty :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props?.allQuestionMetaData.find(item => 'difficulty_types' in item).difficulty_types
                                            .find(difficulty => difficulty.difficulty_id === this.props?.libraryQuestion?.difficulty_id)?.difficulty_type
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props?.libraryQuestion?.grade_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Grade :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props?.allQuestionMetaData.find(item => 'grades' in item).grades
                                            .find(grade => grade.grade_id === this.props?.libraryQuestion?.grade_id)?.grade
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props?.libraryQuestion?.subject_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Subject :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props?.allQuestionMetaData.find(item => 'subjects' in item).subjects
                                            .find(subject => subject.subject_id === this.props?.libraryQuestion?.subject_id)?.subject
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props?.libraryQuestion?.year_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Year :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props?.allQuestionMetaData.find(item => 'years' in item).years
                                            .find(year => year.year_id === this.props?.libraryQuestion?.year_id)?.year
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props?.libraryQuestion?.term_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Term/Semester :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props?.allQuestionMetaData.find(item => 'terms' in item).terms
                                            .find(term => term.term_id === this.props?.libraryQuestion?.term_id)?.term
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props?.libraryQuestion?.chapter_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Chapter/Module/Unit :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props?.allQuestionMetaData.find(item => 'chapters' in item).chapters
                                            .find(chapter => chapter.chapter_id === this.props?.libraryQuestion?.chapter_id)?.chapter
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props?.libraryQuestion?.source_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Source :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props?.allQuestionMetaData.find(item => 'sources' in item).sources
                                            .find(source => source.source_id === this.props?.libraryQuestion?.source_id)?.source
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props?.libraryQuestion?.author_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                            Author :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'authors' in item).authors
                                            .find(author => author.author_id === this.props?.libraryQuestion?.author_id)?.author
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.libraryQuestion.first_name && this.props.libraryQuestion.last_name && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Created By :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.libraryQuestion.first_name + " " + this.props.libraryQuestion.last_name
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.libraryQuestion.created_at && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Created At :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.getCreatedAtLocal(this.props.libraryQuestion.created_at)
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}
                        </ModalBody>
                        )}
                    </SelfGradeModal>

                </ContentWrapper>

            </AdminTemplate>
        )

    }
}

function mapStateToProps(state) {
    return {
        assessment: state.questionReducer.assessment,
        question: state.questionReducer.question,
        add_loading: state.questionReducer.loading,
        libraryQuestion: state.questionsLibraryReducer.question,
        libraryQuestionContent: state.questionsLibraryReducer.questionContent,
        libraryAnswerContent: state.questionsLibraryReducer.answerContent,
        dropDownDataloading: state.questionsCreateReducer.loading,
        qus_loading: state.questionsLibraryReducer.loading,
        questionContent: state.questionReducer.questionContent,
        answerContent: state.questionsLibraryReducer.answerContent,
        questions: state.questionsLibraryReducer.publicQuestions,
        totalAddQuestionsCount: state.questionsLibraryReducer.totalPublicCount,
        teachers: state.questionsLibraryReducer.teachers,
        assessmentQuestions: state.questionReducer.assessmentQuestions,
        error: state.questionReducer.errorAddQuestions,
        questionCategories: state.questionsCreateReducer.allCategories,
        allCategories: state.questionsCreateReducer.allCategories,
        allQuestionTypes: state.questionsCreateReducer.allQuestionTypes,
        allDifficulty: state.questionsCreateReducer.allDifficulty,
        allQuestionMetaData: state.questionsCreateReducer.allQuestionMetaData,
    };
}

const mapActionsToProps = {
    getAllLibraryQuestions: libraryActions.getAllQuestions,
    getQuestionContent: questionActions.getQuestionContent,
    getLibraryQuestionContent: libraryActions.getQuestionContent,
    getLibraryAnswerContent: libraryActions.getAnswerContent,
    addQuestions: questionActions.addQuestions,
    viewAddQuestions: questionActions.viewAddQuestions,
    getAssessmentQuestions: questionActions.getAssessmentQuestions,
    getAllAddQuestions: questionActions.getAllAddQuestions,
    getAllMyQuestions: libraryActions.getAllPublicQuestions,
    refreshforAddingQuestions: questionActions.refreshforAddingQuestions,
    getQuestionTypes: createActions.getQuestionTypes,
    getQuestionCategories: createActions.getQuestionCategories,
    getQuestionDifficulty: createActions.getQuestionDifficulty,
    getQuestionMetaData: questionCreateActions.getQuestionMetaData,
}

export default connect(mapStateToProps, mapActionsToProps)(FinalizeNewQuestion);
