import jsPDF from 'jspdf';
import {store} from "../../helper/redux_store";
import { mcqActions } from "../../actions/question/mcqActions";
import { descriptiveActions } from "../../actions/question/descriptiveActions";
import { exportImportFormatConstants, questionTypeConstants } from "../../helper/constants";
import { history } from '../../helper/history.js';
import { libraryActions } from "../../actions/question/libraryActions";
import DataHandler from "../../handlers/DataHandler";
import { namespace } from "../../helper/namespace";
import config from '../../helper/config';
import {customerConstants} from "../../helper/constants.js";
import { routes } from '../../helper/routes.js';
import { EditorState, convertToRaw } from 'draft-js';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, confirmClass, cancelClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

async function MCQExport(questionContents, selectedQuestions, format) {
    let exportData = '';
    let exportDataChunk = '';
    selectedQuestions.forEach((selectedQuestion, index) => {
        let content = JSON.parse(questionContents[index]);
        let question = content[0]['blocks'][0]['text'];
        let qcount = Object.keys(content[0]['blocks']).length;
        for( let i=1; i < qcount; i++){
            question = question +'\n'+ content[0]['blocks'][i]['text']
        }
        let choices = [];
        content.shift();
        content.forEach((object) => {
            let optionalAnswers=[];
            let q= Object.keys(object['blocks']).length;
            for( let i=0; i < q; i++){
                optionalAnswers.push(object['blocks'][i]['text']) }
        choices.push(optionalAnswers);
        });

        if (format === 'AIKEN') {
            exportDataChunk = _toAikenFormat(question.trim(), choices, selectedQuestion, questionTypeConstants.MCQ.id);
        } else if (format === 'GIFT') {
            exportDataChunk = _toGIFTFormat(question.trim(), choices, selectedQuestion, questionTypeConstants.MCQ.id);
        } else {
            exportDataChunk = _toAikenFormat(question.trim(), choices, selectedQuestion, questionTypeConstants.MCQ.id);
        }

        if (exportData === '')
            exportData = exportDataChunk
        else
            exportData = exportData + '\n' + exportDataChunk;
    })

    if (format === exportImportFormatConstants.AIKEN) {
        await writeToTextFile('mcq_export', exportData);
    } else if (format === exportImportFormatConstants.GIFT) {
        await writeToTextFile('mcq_export', exportData);
    }
    else {
        await writeToPDFFile(exportData);
    }
}

async function DescExport(questionContents, answerContents, selectedQuestions, format) {
    let exportData = '';
    let exportDataChunk = '';
    selectedQuestions.forEach((selectedQuestion, index) => {
        let content = JSON.parse(questionContents[index]);
        let answerContent = JSON.parse(answerContents[index]);
        //let question = content[0]['blocks'][0]['text'];
        let question = content[0]['blocks'][0]['text'];
        let qcount = Object.keys(content[0]['blocks']).length;
        for( let i=1; i < qcount; i++){
            question = question +'\n'+ content[0]['blocks'][i]['text']
        }
        //let answer = answerContent[0]['blocks'][0]['text'];
        let answer = answerContent[0]['blocks'][0]['text'];
        let count = Object.keys(answerContent[0]['blocks']).length;
        for( let i=1; i < count; i++){
            answer = answer +'\n'+ answerContent[0]['blocks'][i]['text']
        }

        // if (answerContent[0]['blocks'][0]['text'] != ''){
        // answer = answer + '\n'+count +'\n\t'+ answerContent[0]['blocks'][0]['text'];}

        if (format === 'AIKEN') {
            exportDataChunk = _toAikenFormat(question.trim(), answer.trim(), selectedQuestion, questionTypeConstants.DESCRIPTIVE.id);
        } else if (format === 'GIFT') {
            exportDataChunk = _toGIFTFormat(question.trim(), answer.trim(), selectedQuestion, questionTypeConstants.DESCRIPTIVE.id);
        } else {
            exportDataChunk = _toAikenFormat(question.trim(), answer.trim(), selectedQuestion, questionTypeConstants.DESCRIPTIVE.id);
        }

        if (exportData === '')
            exportData = exportDataChunk
        else
            exportData = exportData + '\n' + exportDataChunk;
    })

    if (format === exportImportFormatConstants.AIKEN) {
        await writeToTextFile('desc_export', exportData);
    } else if (format === exportImportFormatConstants.GIFT) {
        await writeToTextFile('desc_export', exportData);
    }
    else {
        await writeToPDFFile(exportData);
    }
}

async function MCQImport(category, questionType, file, format, difficulty) {
    await readFromFile(category, questionType, file, format, difficulty)
}

async function DescriptiveImport(category, questionType, file, format, difficulty) {
    await readFromFile(category, questionType, file, format, difficulty)
}

async function readFromFile(category, questionType, file, format, difficulty) {
    let fileReader;

    async function handleFileRead() {
        const content = fileReader.result;
        if (format === exportImportFormatConstants.GIFT) {
            if (content.match(/::/g) && content.slice(0,2) == '::') {
                await createQuestions(content, category, questionType, file, exportImportFormatConstants.GIFT, difficulty)
            } else {
                window.Swal.fire({
                    title: namespace.FAILED,
                    text: namespace.WRONG_FORMAT,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                      },
                      onOpen: (modalElement) => {
                          if (customer === customerConstants.EXO_CUSTOMER2) {
                              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                          }
                      }
                }).then((result)=>{
                    if (result){
                    //window.location.reload();
                    history.push(routes.questions_library)
                }
                });    
            }
        } else if (format === exportImportFormatConstants.AIKEN) {
            if (content.match(/ANSWER/g)) {
                await createQuestions(content, category, questionType, file, exportImportFormatConstants.AIKEN, difficulty)
            } else {
                window.Swal.fire({
                    title: namespace.FAILED,
                    text: namespace.WRONG_FORMAT,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                      },
                      onOpen: (modalElement) => {
                          if (customer === customerConstants.EXO_CUSTOMER2) {
                              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                          }
                      }
                }).then((result)=>{
                    if (result){
                    //window.location.reload();
                    history.push(routes.questions_library)
                }
                });    
            }
        }
    }

    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
}

async function createQuestions(content, category, questionType, file, format, difficulty) {

    if (questionType === questionTypeConstants.MCQ.id) {
        let question = {
            questionKey: '',
            title: '',
            questionContent: '',
            qType: '',
            categoryId: '',
            createdBy: '',
            answer: '',
            difficulty_id: '',
            privilege_id: '',
            answer_type_id: '',
            guidence: ''
        }
        question.categoryId = category;
        question.qType = questionType;
        question.difficulty_id = difficulty;
        question.privilege_id = 1;
        question.answer_type_id = 1;

        const emptyEditorState = EditorState.createEmpty();
        const rawContent = convertToRaw(emptyEditorState.getCurrentContent());
        question.guidence = [rawContent]

        let user = DataHandler.getFromSession("user_id")
        question.createdBy = user;
        let questions = content.split(/\n\s*\n/);
        let data;

        for (let questionItem of questions) {
            if(questionItem.length>0){
                question.questionKey = Date.now() + "";
                //question.title = 'MCQ Import ' + question.questionKey;

                if (format === exportImportFormatConstants.AIKEN) {
                    data = _fromAIKENFormat(questionItem, questionType);
                } else if (format === exportImportFormatConstants.GIFT) {
                    data = _fromGIFTFormat(questionItem, questionType);
                    if (data.title)
                        question.title = data.title;
                }
                question.answer = data.answer;
                question.questionContent = data.questionContent;
                question.title = data.title;
                await saveQuestion(question);
            }
        }
    } else if (questionType === questionTypeConstants.DESCRIPTIVE.id) {
        let question = {
            questionKey: '',
            title: '',
            questionContent: '',
            qType: '',
            categoryId: '',
            createdBy: '',
            answerContent: '',
            answerKey: '',
            difficulty_id: '',
            privilege_id: '',
            guidence: '',
            answer_type_id: ''
        }
        question.categoryId = category;
        question.qType = questionType;
        question.difficulty_id = difficulty;
        question.privilege_id = 1;
        question.answer_type_id = null;
        
        const emptyEditorState = EditorState.createEmpty();
        const rawContent = convertToRaw(emptyEditorState.getCurrentContent());
        question.guidence = [rawContent]

        let user = DataHandler.getFromSession("user_id")
        question.createdBy = user;
        let questions = content.split(/\n\s*\n/);
        let data;
        for (let questionItem of questions) {
            if(questionItem.length >0){
                question.questionKey = Date.now() + "";
                question.answerKey = Date.now() + "a"
                if (format === exportImportFormatConstants.AIKEN) {
                    data = _fromAIKENFormat(questionItem, questionType);
                } else if (format === exportImportFormatConstants.GIFT) {
                    data = _fromGIFTFormat(questionItem, questionType);
                    if (data.title)
                        question.title = data.title;
                }
                question.answerContent = data.answerContent;
                question.questionContent = data.questionContent;
                question.title = data.title
                await saveQuestion(question);
            }
        }
    }

   await store.dispatch(libraryActions.getAllQuestions());
}
//aiken doesnt have qus title
function _fromAIKENFormat(questionItem, questionType) {
    if (questionType === questionTypeConstants.MCQ.id) {
        let questionTokens = questionItem.split("\n");
        let questionProblem = questionTokens[0];
        let title = questionProblem.split(' ').slice(0, 6).join(' ') + '...';
        questionTokens = questionTokens.filter(function(e){return e}); 
        let questionAnswer = questionTokens[questionTokens.length - 1];
        questionAnswer = questionAnswer.split('ANSWER: ');
        questionAnswer = questionAnswer[1].split(',');
        let answer = _answerToBooleanString(questionAnswer, questionTokens.length - 2);
        let questionChoices = [];
        for (let i = 1; i < questionTokens.length - 1; i++) {
            questionChoices.push(questionTokens[i].substring(3));
        }
        let questionContent = _editorContentGenerator(questionProblem, questionChoices);
        return { answer, questionContent, title };
    } else if (questionType === questionTypeConstants.DESCRIPTIVE.id) {
        let questionTokens = questionItem.split("ANSWER");
        let questionProblem = questionTokens[0];
        let title = questionProblem.split(' ').slice(0, 6).join(' ') + '...';
        let questionAnswer = questionTokens[1].substring(2);
        let questionContent = _editorDescGenerator(questionProblem);
        let answerContent = _editorDescGenerator(questionAnswer)
        return { answerContent, questionContent, title };
    }


}

function _fromGIFTFormat(questionItem, questionType) {
    let tokens;
    let title;
    let answer = '';
    let questionProblem;
    let questionChoices = [];

    questionItem = questionItem.replace(/[\r\n]+/g, " ");
    tokens = questionItem.match("::(.*)::");
    if (tokens != null)
        title = tokens[1];
    questionProblem = questionItem.match("}(.*)")[1].trim();
    
    if (questionProblem) {
        if (title != null) {
            questionProblem = questionItem.split('::' + title + '::')[1];
            questionProblem = questionProblem.match("(.*){")[1].trim() + ' _______ ' + questionItem.match("}(.*)")[1].trim();
        } else {
            questionProblem = questionItem.match("(.*){")[1].trim() + ' _______ ' + questionItem.match("}(.*)")[1].trim();
        }
    } else {
        if (title != null) {
            questionProblem = questionItem.split('::' + title + '::')[1];
            questionProblem = questionProblem.match("(.*){")[1].trim();
        } else {
            questionProblem = questionItem.match("(.*){")[1].trim();
        }
    }
    if (title != null) {
        questionProblem = questionItem.split('::' + title + '::')[1];
        questionProblem = questionProblem.match("(.*){")[1].trim();
    } else {

    }

    let choices = questionItem.match("{(.*)}")[1].trim();
    let choiceTokens = choices.split(/\s+/);
    let answerDesc
    if (questionType === questionTypeConstants.MCQ.id) {
        if (choiceTokens.length > 0) {
            let bool;
            let choice;
            for (let i = 0; i < choiceTokens.length; i++) {
                if (choiceTokens[i].substring(0, 1) === '=') {
                    choice = choiceTokens[i].substring(1, choiceTokens[i].length);
                    if (answer !== '')
                        answer = answer + ',';
                    bool = 100;
                    while (true) {
                        if (i < choiceTokens.length - 1) {
                            if (choiceTokens[i + 1].substring(0, 1) !== '=' && choiceTokens[i + 1].substring(0, 1) !== '~' && choiceTokens[i + 1].substring(0, 1) !== '#') {
                                choice = choice + ' ' + choiceTokens[i + 1]
                            } else {
                                break;
                            }
                            i++;
                        } else {
                            break;
                        }
                    }
                    choice = choice.trim();
                    questionChoices.push(choice);
                    answer = answer + bool.toString();
                } else if (choiceTokens[i].substring(0, 1) === '~') {
                    choice = choiceTokens[i].substring(1, choiceTokens[i].length);
                    if (answer !== '')
                        answer = answer + ',';
                    bool = 0;
                    while (true) {
                        if (i < choiceTokens.length - 1) {
                            if (choiceTokens[i + 1].substring(0, 1) !== '~' && choiceTokens[i + 1].substring(0, 1) !== '=' && choiceTokens[i + 1].substring(0, 1) !== '#') {
                                choice = choice + ' ' + choiceTokens[i + 1]
                            } else {
                                break;
                            }
                            i++;
                        } else {
                            break;
                        }
                    }
                    choice = choice.trim();
                    questionChoices.push(choice);
                    answer = answer + bool.toString();
                }
            }
        }
        let correctans = 0;
        let finalAns = ''
        let answers = answer.split(',')
        answers.map(ans => {
            if(ans === '100'){
                correctans= correctans+1
            }
        })
        answers.map(ans => {
            if(ans === '100'){
                ans = (parseInt(ans)/correctans).toFixed(2)
                finalAns=finalAns+ans.toString()+','
            }else{
                finalAns = finalAns+ans+','
            }
        })
        answer = finalAns
        let questionContent = _editorContentGenerator(questionProblem, questionChoices);
        return { title, answer, questionContent };
    } else if (questionType === questionTypeConstants.DESCRIPTIVE.id) {
        answerDesc = choices.replace("=", "")
        let questionContent = _editorDescGenerator(questionProblem);
        let answerContent = _editorDescGenerator(answerDesc);
        return { title, answerContent, questionContent };
    }
}

async function saveQuestion(question) {
    if (question.qType === questionTypeConstants.MCQ.id) {
        await store.dispatch(mcqActions.createQuestion(question));
    } else if (question.qType === questionTypeConstants.DESCRIPTIVE.id) {
        await store.dispatch(descriptiveActions.createQuestion(question));
    }

}

async function writeToTextFile(name, content) {
    const element = document.createElement("a");
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = name + ".txt";
    document.body.appendChild(element);
    element.click();
}

async function writeToPDFFile(text) {
    var lMargin=15; //left margin in mm
    var rMargin=15; //right margin in mm
    var pdfInMM=210;  // width of A4 in mm

    let doc = new jsPDF("p","mm","a4");
    var lines =doc.splitTextToSize(text, (pdfInMM-lMargin-rMargin));
    doc.setFont('helvetica')
    doc.text(lMargin,20,lines);

    doc.save('content.pdf')
}

function _toAikenFormat(question, choices, selectedQuestion, qType) {
    if (qType === questionTypeConstants.MCQ.id) {
        let exportData = '';
        let csvQuestion = question;
        let csvChoices = '';
        let char = 'A';
        let choicesArray = [];
        choices.forEach((choice) => {
            choicesArray.push(char);
            if (csvChoices !== '')
                csvChoices = csvChoices + '\n';
            csvChoices = csvChoices + char + '. ' + choice;
            char = _nextChar(char);
        });

        let correctChoice = '';
        selectedQuestion['answer'].split(',').forEach((answer, i) => {
            if (parseInt(answer) > 0) {
                if (correctChoice !== '')
                    correctChoice = correctChoice + ', ';
                correctChoice = correctChoice + choicesArray[i];
            }
        });
        let csvAnswer = 'ANSWER: ' + correctChoice;


        exportData = csvQuestion + '\n' + csvChoices + '\n' + csvAnswer + '\n';
        return exportData;
    } else if (qType = questionTypeConstants.DESCRIPTIVE.id) {
        let exportData = '';
        let csvQuestion = question;
        let csvAnswer = choices;

        exportData = csvQuestion + '\n' + 'ANSWER: ' + csvAnswer + '\n';
        return exportData;
    }

}

function _toGIFTFormat(question, choices, selectedQuestion, qType) {
    if (qType === questionTypeConstants.MCQ.id) {
        let exportData = '';
        let giftTitle = '::' + selectedQuestion['title'];
        let giftQuestion = '::' + question;
        let giftChoices = '';

        let correctChoiceArray = [];
        selectedQuestion['answer'].split(',').forEach((answer, i) => {
            correctChoiceArray.push((parseInt(answer) > 0));
        });

        choices.forEach((choice, index) => {
            if (giftChoices === '')
                giftChoices = '{\n';

            if (correctChoiceArray[index]) {
                giftChoices = giftChoices + '=' + choice.toString().trim() + '\n';
            } else {
                giftChoices = giftChoices + '~' + choice.toString().trim() + '\n';
            }

            if (index === (choices.length - 1))
                giftChoices = giftChoices + '}';
        });

        exportData = giftTitle.trim() + giftQuestion.trim() + ' ' + giftChoices + '\n';
        return exportData;
    } else if (qType === questionTypeConstants.DESCRIPTIVE.id) {
        let exportData = '';
        let giftTitle = '::' + selectedQuestion['title'];
        let giftQuestion = '::' + question+'{';
        let giftAnswer = choices;

        exportData = giftTitle.trim() + giftQuestion.trim() + ' \n' + '=' + giftAnswer + '\n' + '}' + '\n';
        return exportData;
    }

}


function _editorContentGenerator(question, choices) {
    let editorContent = []
    let block = {
        "blocks": [
            {
                "key": _randomString(5, '#a'),
                "text": question,
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [],
                "data": {}
            }
        ],
        "entityMap": {}
    }
    editorContent.push(block);
    for (let choice of choices) {
        block = {
            "blocks": [
                {
                    "key": _randomString(5, '#a'),
                    "text": choice,
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                }
            ],
            "entityMap": {}
        }
        editorContent.push(block);
    }
    return editorContent;
}

function _editorDescGenerator(question) {
    let editorContent = []
    let block = {
        "blocks": [
            {
                "key": _randomString(5, '#a'),
                "text": question,
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [],
                "data": {}
            }
        ],
        "entityMap": {}
    }
    editorContent.push(block);

    return editorContent;
}

function _answerToBooleanString(answer, noChoices) {
    let booleanString = '';
    let char = "A";
    let bool;
    let answercount = answer.length
    for (let i = 0; i < noChoices; i++) {
        if (answer.length > 0) {
            for (let character of answer) {
                bool = (char === character.trim());
                if(bool){
                    bool =(100/answercount).toFixed(2)
                }else{
                    bool = 0
                }
                if (bool)
                    break;
            }
        } else {
            bool = (char === answer);
        }
        if (booleanString !== '')
            booleanString = booleanString + ',';
        booleanString = booleanString + bool.toString()
        char = _nextChar(char);
    }
    return booleanString;
}

function _randomString(length, chars) {
    let mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    let result = '';
    for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
    return result;
}

function _nextChar(c) {
    return String.fromCharCode(c.charCodeAt(0) + 1);
}

export {
    MCQExport,
    DescExport,
    MCQImport,
    DescriptiveImport
}