export const routes = {
    //common routes
    auth_response: "/authresp",
    accept_terms: "/accept-terms",
    base_url: "/",
    login_url: "/login",

    //assessment routes
    view_assessment: "/assessments-library",
    view_assessment_pass_info: ({isPublic})=>({
        state:{
            isPublic:isPublic
        }
    }),
    finalize: "/finalize",
    setup_assessment: "/setup-assessment",
    student_view: "/student-view",
    grade: "/grade",
    grade_report: "/grade-report",
    teacher_grade_report: "/teacher-grade-report",
    settings_view: "/assessment-settings",

    //question routes
    questions_library: "/questions-library",
    new_question: "/new-question",
    create_question: "/create-question",
    edit_question: "/edit-question",
    finalize_new_question: "/finalize-new-question",

    //manage routes
    admin_url: "/ESadmin",
    teacher_url: "/Educator",
    sign_in_url: "/sign-in",
    profile: "/profile",

    manage_teacher: "/manage-teacher",
    manage_student: "/manage-student",
    manage_cgc_student: "/manage-cgc-student",
    manage_admin: "/manage-admin",
    manage_parent: "/manage-parent",

    manage_invitations: "/manage-invitations",
    manage_dashboard: "/manage-dashboard",
    manage_enrollment: "/manage-enrollment",
    manage_teacher_enrollment: "/manage-teacher-enrollment",
    manage_attendance: "/manage-attendance",
    manage_about: "/manage-about",
    manage_organization_attribute: "/manage-organization-attribute",
    manage_search: "/manage-search",
    calendar_app: "/calendar-app",
    main_calendar: "/main-calendar",
    feedback_form: "/feedback-form",

    paper_list: "/paper-list",
    paper_mapping: "/paper-mapping",

    form_attachment: "/form-attachment",
    manage_form: "/manage-form",
    manage_node: "/manage-node",
    user_list: "/user-list",

    manage_documents: "/manage-documents",
    manage_marksheet: "/manage-marksheet",
    manage_announcement: "/manage-annonucement",
    announcements: "/announcements",

    payment_gateway: "/payment-gateway",
    manage_payment: "/manage-payment",
    manage_subscription_main: "/manage-subscription-main",

    quiz_mapping: "/quiz-mapping",
    manage_settings: "/manage-settings",
    manage_sitemap: "/manage-sitemap",
    manage_chat: "/manage-chat",
    manage_progress: "/manage-progress",

    register_student: "/register-student",
    my_requests: "/my-requests",
    search_papers: "/search-papers",
    parent_student_signup:"/parent-student-signup",
    registration_form_view: "/register",
    terms_and_conditions_view_from_registration_form: "/terms-and-conditions",
    terms_and_conditions_view: "/conditions",
    find_papers: "/find-papers",

    //candidate routes
    enrolled_paper_list: "/enrolled-paper-list",
    paper_details: "/paper-details",
    self_grade: "/self-grade",
    paper_grade_report: "/paper-grade-report",
    paper_auto_grade_report: "/paper-auto-grade-report",
    view_question_paper: "/view-question-paper",
    paper_review: "/paper-review",
    subscriptions: "/subscriptions",
}