import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CountryService } from "../../../service/settings/manage/country.service.js";
import { ProvinceService } from "../../../service/settings/manage/province.service.js";
import { CityService } from "../../../service/settings/manage/city.service.js";
import { GenderService } from "../../../service/settings/manage/gender.service.js";
import { IdTypeService } from "../../../service/settings/manage/id_type.service.js";
import { StatusTypesService } from "../../../service/settings/manage/status_type.service.js";
import { StatusService } from "../../../service/settings/manage/status.service.js";
import Spinner from "react-bootstrap/Spinner";
import "../../../components/admin/css/common.css";
import { history } from "../../../helper/history";
import debounce from "lodash.debounce";
import { UserManagementService } from "../../../service/user_management/user_management.service";

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import sweetAlert, {
  sweetStatusAlert,
} from "../../../components/common/SweetAlert";
import { AdminService } from "../../../service/manage/registration/admin.service";
import SimpleReactValidator from "simple-react-validator";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants.js";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DatePicker from "react-datepicker";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class ManageAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      admins: [],
      first_name: "",
      last_name: "",
      nic: "",
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      created_on: "",
      created_by: "",
      idValue: null,
      idTypes: [],
      user_code: "",
      genders: [],
      titles: [],
      countries: [],
      provinces: [],
      cities: [],
      adm_statuses: [],
      statusTypes: [],
      AdmStatustype: "Availability",
      showInsertModal: false,
      isLoading: false,
      isTableDataLoading: true,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      totalAdmins: 10,
      currentPage: 0,
      isModified: false,
      isAddressesLoading: false,
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      sortColumn: "user_code", // Default sort column
      sortDirection: "desc",
      errorProvince: [],
      errorCity: [],
      addressValidity: [],
      hidden: false,
      node_mid: null,
      user_list_table_id: null,
      session_adm_id: null,
      country_id: "",
      province_id: "",
      city_id: "",
      address_1: "",
      address_2: "",
      postalcode: "",
      user_id: "",
      is_lg_user: "",
      breadcrumb2: null,
      nodeTitle: null,
      main_table_id: null,
    };
    // Debounced version of handleSearch function
    this.debouncedHandleSearch = debounce(this.fetchAllAdmins, 2000);

    this.createValidationsInstance();
    this.formInitialState = {
      first_name: "",
      last_name: "",
      nic: "",
      idValue: null,
      idType_id: "",
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      created_on: "",
      created_by: "",
      postalcode: "",
    };
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        address: {
          message: "The :attribute must be a valid address",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z-/:0-9,\s]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
        },
        dateBefore: {
          message: "The :attribute must be before current date",
          rule: (val, params, validator) => {
            const selectedDate = new Date(val);
            const currentDate = new Date();
            return selectedDate < currentDate;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        phone_number: {
          message: "The :attribute must be a valid phone number",
          rule: (val, params, validator) => {
            const isTenDigitsStartingWithZero =
              val.length === 10 && val.startsWith("0");
            const isNineDigits = val.length === 9 && !val.startsWith("0");
            return isTenDigitsStartingWithZero || isNineDigits;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
      },
    });
    this.updateValidator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.loadInitialData();
    this.genarateAdminIdPrefix();
  }

  genarateAdminIdPrefix() {
    const id_prefix_year = String(new Date().getFullYear() % 100);

    const user_base_prefix = "A" + id_prefix_year;

    this.setState({ user_code: user_base_prefix });
  }

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\-.\s&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchAdminData
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  loadInitialData() {
    let privilege_edit = !!this.props.state.privilege_edit;
    let privilege_add = !!this.props.state.privilege_add;
    let privilege_delete = !!this.props.state.privilege_delete;
    this.setState(
      {
        addVisibility: privilege_add,
        editVisibility: privilege_edit,
        deleteVisibility: privilege_delete,
        user_list_table_id: sessionStorage.getItem("table_log_id"),
        session_adm_id: sessionStorage.getItem("manage_user_id"),
        breadcrumb2: this.props.state.breadcrumb2,
        nodeTitle: this.props.state.nodeTitle,
        main_table_id: this.props.state.main_table_id,
      },
      () => {
        this.fetchAllAdmins();
        this.getAllGenders();
        this.getAllIdTypes();
        this.getAllStatusTypes();
        this.getAllContries();
      }
    );
  }

  getAllContries() {
    CountryService.getAll().then((res) => {
      this.setState({ countries: res.data });
    });
  }

  getAllIdTypes() {
    IdTypeService.getAllIdTypes().then((res) => {
      this.setState({ idTypes: res.data});
    });
  }

  getAllGenders() {
    GenderService.getAllGenders().then((res) => {
      this.setState({ genders: res.data });
    });
  }

  getAllStatusTypes() {
    StatusTypesService.getAll().then((res) => {
      this.setState({ statusTypes: res.data }, () => {
        this.getAllStatuses();
      });
    });
  }

  getAllStatuses() {
    const adm_type_id = this.getRespectiveStatusTypeID(
      this.state.AdmStatustype
    );
    StatusService.getByTypeId(adm_type_id).then((res) => {
      this.setState({ adm_statuses: res.data });
    });
  }

  fetchAllAdmins = () => {
    const {
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      user_list_table_id,
      session_adm_id,
    } = this.state;
    const {
      mid,
      owner_restriction,
      lg_user_id,
      lg_user_table_id,
      user_list_restriction,
    } = this.props.state;
    let create_user_id =
      !!owner_restriction && lg_user_id ? lg_user_id : "null";
    let create_user_table_id =
      !!owner_restriction && lg_user_table_id ? lg_user_table_id : "null";
    let user_table_id =
      !!user_list_restriction && user_list_table_id
        ? user_list_table_id
        : "null";
    let user_id =
      !!user_list_restriction && session_adm_id ? session_adm_id : "null";

    AdminService.getAll(
      user_id,
      user_table_id,
      create_user_id,
      create_user_table_id,
      mid,
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term
    )
      .then((response) => {
        if (response.success) {
          if (this.props.state.path === "profile") {
            this.setState(
              {
                admins: response.data.admins.filter(
                  (admin) => admin.is_lg_user
                ),
              },
              () => {
                this.toggleUpdateAdminModal(this.state.admins[0]);
              }
            );
          } else {
            this.setState({
              admins: response.data.admins,
              totalAdmins: response.data.total_count, // Update totalAdmins with the correct count
            });
          }
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching admins Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        sweetStatusAlert(
          "Failed!",
          "Error fetching admins Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllAdmins();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllAdmins();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllAdmins();
      }
    );
  };

  handleSortChange = (sortColumn) => {
    // If the same column is clicked, toggle the sort direction
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.fetchAllAdmins();
      }
    );
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(this.formInitialState);
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      this.setState(this.formInitialState);
      toggleFunction();
    }
  };

  handleDatePickerChange = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = ("0" + dateObject.getDate()).slice(-2);

    const formattedDateString = `${year}-${month}-${day}`;
    this.setState(() => ({ dob: formattedDateString, isModified: true }));
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
    if (name === "country_id") {
      const country_id = value;
      this.setState({
        cities: [],
        provinces: [],
        city_id: "",
        province_id: "",
      });
      this.getProvinceForCountry(country_id);
    }
    if (name === "province_id") {
      const province_id = value;
      this.setState({ cities: [], city_id: "" });
      this.getCitiesForProvince(province_id);
    }
  };

  getProvinceForCountry = (country_id) => {
    if (country_id) {
      ProvinceService.getProvinceForCountry(country_id).then((response) => {
        if (response.success) {
          this.setState({ provinces: response.data });
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      });
    }
  };

  getCitiesForProvince = (province_id) => {
    if (province_id) {
      CityService.getCitiesForProvince(province_id).then((response) => {
        if (response.success) {
          this.setState({ cities: response.data });
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      });
    }
  };

  toggleUpdateAdminModal = (admin) => {
    this.setState(
      (prevState) => ({
        showModal: !prevState.showModal,
        first_name: "",
        last_name: "",
        nic: "",
        dob: "",
        email: "",
        idValue: null,
        idType_id: "",
        phone_number: "",
        gender_id: "",
        status_id: "",
        created_on: "",
        created_by: "",
        address_1: "",
        address_2: "",
        city_id: "",
        province_id: "",
        postalcode: "",
        country_id: "",
        user_code: "",
      }),
      async () => {
        // State has changed, perform your logic here based on the state change
        if (this.state.showModal) {
          this.setState({
            first_name: admin.first_name,
            last_name: admin.last_name,
            nic: admin.nic,
            email: admin.email,
            idValue: admin?.idValue ? admin.idValue : null,
            idType_id: admin?.idType ? admin.idType : "",
            phone_number: admin.phone_number
              ? admin.phone_number.toString()
              : admin.phone_number,
            dob: admin.dob ? admin.dob : "",
            gender_id: admin.gender_id ? admin.gender_id : "",
            created_on: admin.created_on ? admin.created_on : "",
            created_by: admin.created_by ? admin.created_by : "",
            status_id: admin.user_status_id ? admin.user_status_id : "",
            user_id: admin.user_id,
            node_mid: admin.node_mid,
            address_1: admin.address_1,
            address_2: admin.address_2,
            city_id: admin.city_id,
            province_id: admin.province_id,
            postalcode: admin.postalcode,
            country_id: admin.country_id,
            is_lg_user: admin.is_lg_user,
            user_code: admin.user_code,
          });
          this.getProvinceForCountry(admin.country_id);
          this.getCitiesForProvince(admin.province_id);
        }
      }
    );
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      try {
        const {
          user_id,
          first_name,
          last_name,
          idValue,
          idType_id,
          email,
          phone_number,
          dob,
          gender_id,
          created_on,
          created_by,
          status_id,
          node_mid,
          country_id,
          province_id,
          city_id,
          address_1,
          address_2,
          postalcode,
          main_table_id,
          user_code,
        } = this.state;

        const adminItem = {
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone_number: parseInt(phone_number),
          dob: !!dob ? dob : null,
          idValue: idValue,
          idType: parseInt(idType_id),
          gender_id: parseInt(gender_id),
          created_on: created_on === "" ? null : created_on,
          created_by: created_by,
          user_status_id: parseInt(status_id),
          node_mid: node_mid,
          country_id: parseInt(country_id),
          province_id: parseInt(province_id),
          city_id: parseInt(city_id),
          address_1: address_1,
          address_2: address_2,
          postalcode: parseInt(postalcode),
          create_user_table_id: sessionStorage.getItem("table_log_id"),
          create_user_id: sessionStorage.getItem("manage_user_id"),
          user_table_id: main_table_id,
          node_mid: this.props.state.mid,
          user_code: user_code,
        };
        const adminResponse = await AdminService.create(
          JSON.stringify(adminItem)
        );
        if (adminResponse.success) {
          this.fetchAllAdmins();

          this.validator.purgeFields();
          this.validator.hideMessages();
          this.setState(this.formInitialState);

          try {
            this.setState({ isLoading: false, showInsertModal: false });
            sweetStatusAlert(
              "Success!",
              "Admin added successfully..!",
              "success"
            );
          } catch (error) {
            this.setState({ isLoading: false });
            sweetStatusAlert("Failed!", "Failed to create new admin", "error");
          }
        } else {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", adminResponse.message, "error");
        }
      } catch (e) {
        this.setState({ isLoading: false });
        sweetStatusAlert("Failed!", "Failed to create new admin", "error");
      }
    }
  };

  //Handle Edit Submit
  handleEditSubmit = async (event) => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      try {
        this.setState({ isLoading: true });
        const {
          session_adm_id,
          user_id,
          first_name,
          last_name,
          nic,
          idValue,
          idType_id,
          email,
          phone_number,
          dob,
          gender_id,
          created_on,
          created_by,
          status_id,
          node_mid,
          country_id,
          province_id,
          city_id,
          address_1,
          address_2,
          postalcode,
          is_lg_user,
          user_code,
        } = this.state;

        const adminItem = {
          first_name: first_name,
          last_name: last_name,
          nic: nic,
          email: email,
          phone_number: parseInt(phone_number),
          dob: !!dob ? dob : null,
          idValue: idValue,
          idType: parseInt(idType_id),
          gender_id: parseInt(gender_id),
          created_on: created_on === "" ? null : created_on,
          created_by: created_by,
          user_status_id: parseInt(status_id),
          node_mid: node_mid,
          country_id: parseInt(country_id),
          province_id: parseInt(province_id),
          city_id: parseInt(city_id),
          address_1: address_1,
          address_2: address_2,
          postalcode: parseInt(postalcode),
          user_code: user_code,
        };

        const adminId = user_id;
        const adminResponse = await AdminService.update(adminItem, adminId);

        if (adminResponse.success) {
          this.fetchAllAdmins();
          if (!!is_lg_user) {
            this.props.setUserName(first_name, last_name);
          }
          this.setState({
            isLoading: false,
            showModal: false,
            isModified: false,
          });
          sweetStatusAlert(
            "Success!",
            "Admin information updated successfully..!",
            "success"
          );

          this.validator.purgeFields();
          this.validator.hideMessages();
          this.setState(this.formInitialState);
        } else {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", adminResponse.message, "error");
        }
      } catch (e) {
        this.setState({ isLoading: false });
        sweetStatusAlert("Failed!", e, "error");
      }
    }
  };

  getRespectiveStatusTypeID(name) {
    const type = this.state.statusTypes.find((item) => item.name === name);
    return type ? type.type_id : "";
  }

  toggleAddAdminModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        first_name: "",
        last_name: "",
        nic: "",
        dob: "",
        email: "",
        phone_number: "",
        gender_id: "",
        status_id: "",
        created_on: "",
        created_by: "",
        address_1: "",
        address_2: "",
        city_id: "",
        province_id: "",
        postalcode: "",
        country_id: "",
        user_code: "",
      }),
      () => {
        this.genarateAdminIdPrefix();
      }
    );
  };

  render() {
    const {
      isLoading,
      countries,
      limit,
      admins,
      totalAdmins,
      isTableDataLoading,
      currentPage,
      first_name,
      last_name,
      nic,
      email,
      phone_number,
      dob,
      idValue,
      idTypes,
      idType_id,
      gender_id,
      created_on,
      created_by,
      status_id,
      genders,
      adm_statuses,
      editVisibility,
      deleteVisibility,
      country_id,
      province_id,
      city_id,
      address_1,
      address_2,
      postalcode,
      provinces,
      cities,
      breadcrumb2,
      nodeTitle,
      addVisibility,
      user_code,
      offset,
    } = this.state;
    const path = history.location.pathname;
    const formattedBreadcrumb = breadcrumb2
      ? breadcrumb2.replaceAll(">", " > ")
      : "";
    this.validator.purgeFields();
    let fromProfile = false;
    if (this.props.state.path === "profile") {
      fromProfile = true;
    }
    return (
      <div style={{ height: "90vh", overflow: "auto" }}>
        <ContentWrapper disabled={path === "/manage-search"}>
          <Card>
            <CardHeader>
              {this.props.state.path !== "node" && !fromProfile && (
                <p
                  className="col-md-6"
                  style={{ fontSize: "10.5625px", color: "#00000099" }}
                >
                  {breadcrumb2 || nodeTitle
                    ? `(${breadcrumb2 && breadcrumb2}${(nodeTitle ? nodeTitle:'')})`
                    : undefined}
                </p>
              )}
              <div className="col-md-12 d-flex justify-content-between px-0">
                {!fromProfile && (
                  <div>
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mb-2"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        onChange={this.handleSearch}
                        isInvalid={this.state.searchError}
                        style={{ width: "300px" }}
                        className="search-bar"
                      />
                      {this.state.searchError && (
                        <Form.Control.Feedback type="invalid">
                          Invalid input
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </div>
                )}
                {addVisibility && !fromProfile && (
                  <Button
                    className="insert-btn"
                    variant="primary"
                    onClick={this.toggleAddAdminModal}
                  >
                    Add Admin
                  </Button>
                )}
              </div>
            </CardHeader>
            <CardBody>
              {/* <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    // style={{ width: "18%" }}
                    className="col-md-3 col-12"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group> */}
              {isLoading && (
                <PageSpinner id="show" text="Loading, Please wait!" />
              )}
              {isTableDataLoading ? (
                <div className="loading-container">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/* <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSortChange("adm_id")}
                          >
                            Admin ID{" "}
                            {this.state.sortColumn === "adm_id" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </TableCell> */}
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("user_code")}
                        >
                          User Code{" "}
                          {this.state.sortColumn === "user_code" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("first_name")}
                        >
                          First Name{" "}
                          {this.state.sortColumn === "first_name" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("last_name")}
                        >
                          Last Name{" "}
                          {this.state.sortColumn === "last_name" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("email")}
                        >
                          Email{" "}
                          {this.state.sortColumn === "email" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("phone_number")}
                        >
                          Phone Number{" "}
                          {this.state.sortColumn === "phone_number" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell className="thead">Status</TableCell>
                        {(editVisibility || deleteVisibility) && (
                          <TableCell className="thead">Actions</TableCell>
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {admins.map((adminItem) => (
                        <TableRow key={adminItem.user_id}>
                          {/* <TableCell className="tData">
                              {adminItem.adm_id}
                            </TableCell> */}
                          <TableCell className="tData">
                            {adminItem.user_code}
                          </TableCell>
                          <TableCell className="tData">
                            {adminItem.first_name}
                          </TableCell>
                          <TableCell className="tData">
                            {adminItem.last_name}
                          </TableCell>
                          <TableCell className="tData">
                            {adminItem.email}
                          </TableCell>
                          <TableCell className="tData">
                            {adminItem.phone_number}
                          </TableCell>
                          {adminItem.status ? (
                            <TableCell className="tData">
                              <span
                                className={`label label-lg ${
                                  adminItem.status === "Active"
                                    ? "label-light-success"
                                    : "label-light-danger"
                                } label-inline`}
                              >
                                {adminItem.status}
                              </span>
                            </TableCell>
                          ) : (
                            <TableCell className="tData"></TableCell>
                          )}
                          {(editVisibility || deleteVisibility) && (
                            <TableCell>
                              <div className="btn-group">
                                {editVisibility && (
                                  <Button
                                    onClick={() =>
                                      this.toggleUpdateAdminModal(adminItem)
                                    }
                                    className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                    title="Edit Details"
                                  />
                                )}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  {!fromProfile && (
                    <MaterialCustomTablePagination
                      totalCount={parseInt(totalAdmins)}
                      currentPage={currentPage}
                      limit={limit}
                      handlePageChange={this.handlePageChange}
                      handleRowsPerPageChange={this.handleRowsPerPageChange}
                      handlePageOffsetChange={this.handlePageOffsetChange}
                      offset={offset}
                      rowsPerPageOptions={[5, 10, 20]}
                    />
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </ContentWrapper>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          onHide={() =>
            this.handleSweetAlert(this.toggleUpdateAdminModal, "showModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Admin Information</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              <Form>
                <Row>
                  <Modal.Title className="pl-4 mb-4">
                    Personal Details
                  </Modal.Title>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={first_name}
                      placeholder="First Name"
                      onChange={this.handleChange}
                    />
                    {this.validator.message(
                      "first_name",
                      first_name,
                      "required|alpha_space|min:2|max:15",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The admin first name is required.",
                        },
                      }
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      value={last_name}
                      placeholder="Last Name"
                      onChange={this.handleChange}
                    />
                    {this.validator.message(
                      "last_name",
                      last_name,
                      "required|alpha_space|min:2|max:15",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The admin last name is required.",
                        },
                      }
                    )}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>ID Type*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="idType_id"
                    value={idType_id}
                    onChange={this.handleChange}
                    defaultValue={""}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {!!idTypes.length &&
                      idTypes.map((idTypeItem) => (
                        <option
                          key={idTypeItem.idType_id}
                          value={idTypeItem.idType_id}
                        >
                          {idTypeItem.idType}
                        </option>
                      ))}
                  </Form.Select>
                  {this.validator.message(
                    "idType_id",
                    idType_id,
                    "required",
                    {
                      className: "text-danger",
                      messages: {
                        required: "The ID Type is required.",
                      },
                    }
                  )}
                </Form.Group>
                {idType_id && 
                  <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>ID Value*</Form.Label>
                      <Form.Control
                      type="text"
                      name="idValue"
                      value={idValue}
                      placeholder="ID Value"
                      onChange={this.handleChange}
                    />
                    {this.validator.message(
                      "idValue",
                      idType_id ? idValue : null,
                      "required|min:4",
                      {
                        className: "text-danger",
                        messages: {
                          required: "Please enter the ID Value.",
                        },
                      }
                    )}
                    </Form.Group>
                }
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label ref={this.dobRef}>DOB*</Form.Label>
                    <DatePicker
                      className="py-2 form-control col-md-12" // Add Bootstrap form control class
                      selected={
                        this.state.dob ? new Date(this.state.dob) : undefined
                      }
                      name="dob"
                      onChange={this.handleDatePickerChange}
                      dateFormat="dd/MM/yyyy" // Set custom date format
                      placeholderText="Date of Birth"
                      wrapperClassName="react-datePicker"
                    />
                    {this.validator.message("dob", dob, "required|dateBefore", {
                      className: "text-danger",
                      messages: {
                        required: "The date of birth is required.",
                      },
                    })}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      placeholder="example@gmail.com"
                      onChange={this.handleChange}
                      disabled
                    />
                    {this.validator.message("email", email, "required|email", {
                      className: "text-danger",
                      messages: {
                        required: "The email is required.",
                      },
                    })}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Gender*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="gender_id"
                      value={gender_id}
                      onChange={this.handleChange}
                      defaultValue={""}
                    >
                      <option hidden value="">
                        Select
                      </option>
                      {genders?.map((gender) => (
                        <option key={gender.gender_id} value={gender.gender_id}>
                          {gender.name}
                        </option>
                      ))}
                    </Form.Select>
                    {this.validator.message(
                      "gender_id",
                      gender_id,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The gender is required.",
                        },
                      }
                    )}
                  </Form.Group>
                </Row>
                {/* <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Created On</Form.Label>
                      <Form.Control
                        className="py-2"
                        type="date"
                        name="created_on"
                        value={created_on}
                        placeholder="Date of creation"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "created_on",
                        created_on,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The created by is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Created By</Form.Label>
                      <Form.Control
                        type="text"
                        name="created_by"
                        value={created_by}
                        placeholder="Created By"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "created_by",
                        created_by,
                        "required|alpha_space",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The created by is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row> */}

                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Phone Number*</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone_number"
                      value={phone_number}
                      placeholder="Phone Number"
                      onChange={this.handleChange}
                    />
                    {this.validator.message(
                      "phone_number",
                      phone_number,
                      "required|numeric|between:9,10|phone_number",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The phone number is required.",
                        },
                      }
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Status*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="status_id"
                      value={status_id}
                      onChange={this.handleChange}
                      defaultValue={""}
                    >
                      <option hidden value=""></option>
                      {adm_statuses?.map((status) => (
                        <option key={status.status_id} value={status.status_id}>
                          {status.name}
                        </option>
                      ))}
                    </Form.Select>
                    {this.validator.message(
                      "status_id",
                      status_id,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The status is required.",
                        },
                      }
                    )}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                    className="pt-5"
                  >
                    <Form.Label>Admin ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="user_code"
                      value={user_code ? user_code : ""}
                      placeholder="Admin ID"
                      disabled
                    />
                    {this.validator.message(
                      "user_code",
                      user_code,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The admin ID is required.",
                        },
                      }
                    )}
                  </Form.Group>
                </Row>

                <Row className="pt-5">
                  <Modal.Title className="pl-4 mb-4">
                    Residential Details
                  </Modal.Title>
                </Row>

                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Address Line 1</Form.Label>
                    <Form.Control
                      type="text"
                      name="address_1"
                      placeholder="Address 1"
                      onChange={this.handleChange}
                      value={address_1}
                    />
                    {/* {this.validator.message(
                        "address_1",
                        address_1,
                        "address|required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Address 1 is required.",
                            address: "The address 1 must be a valid address",
                          },
                        }
                      )} */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Address Line 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="address_2"
                      placeholder="Address 2"
                      onChange={this.handleChange}
                      value={address_2}
                    />
                    {/* {this.validator.message(
                        "address_2",
                        address_2,
                        "address|required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Address 2 is required.",
                            address: "The address 2 must be a valid address",
                          },
                        }
                      )} */}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Country</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="country_id"
                      onChange={this.handleChange}
                      value={country_id}
                    >
                      <option value="">Select</option>
                      {countries?.map((country) => (
                        <option
                          value={country.country_id}
                          key={country.country_id}
                        >
                          {country.name}
                        </option>
                      ))}
                    </Form.Select>
                    {/* {this.validator.message(
                        "country_id",
                        country_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Country is required.",
                          },
                        }
                      )} */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Province/District</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="province_id"
                      onChange={this.handleChange}
                      value={province_id}
                    >
                      <option value="">Select</option>
                      {provinces?.map((province) => (
                        <option
                          value={province.province_id}
                          key={province.province_id}
                        >
                          {province.name}
                        </option>
                      ))}
                    </Form.Select>
                    {/* {this.validator.message(
                        "province_id",
                        province_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Province is required.",
                          },
                        }
                      )} */}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="city_id"
                      onChange={this.handleChange}
                      value={city_id}
                    >
                      <option value="">Select</option>
                      {cities?.map((city) => (
                        <option value={city.city_id} key={city.city_id}>
                          {city.name}
                        </option>
                      ))}
                    </Form.Select>
                    {/* {this.validator.message("city_id", city_id, "required", {
                        className: "text-danger",
                        messages: {
                          required: "The City is required.",
                        },
                      })} */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="postalcode"
                      placeholder="Postal Code"
                      onChange={this.handleChange}
                      value={postalcode}
                    />
                    {/* {this.validator.message(
                        "postalcode",
                        postalcode,
                        "numeric|required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The postal code is required.",
                            numeric: "The postal code must be a number",
                          },
                        }
                      )} */}
                  </Form.Group>
                </Row>

                <Modal.Footer>
                  <Button
                    className="modal-btn"
                    style={addBtnStyle}
                    onClick={this.handleEditSubmit}
                  >
                    Save changes
                  </Button>
                  <Button
                    className="modal-btn"
                    style={closeBtnStyle}
                    id="modal-close-button"
                    onClick={() =>
                      this.handleSweetAlert(
                        this.toggleUpdateAdminModal,
                        "showModal"
                      )
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </div>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() =>
            this.handleSweetAlert(this.toggleAddAdminModal, "showInsertModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Admin</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              {isLoading ? (
                <div
                  className="stu-card-title pt-2 col-md-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i className="fas fa-spinner fa-spin"></i>
                </div>
              ) : (
                <Form>
                  <Row>
                    <Modal.Title className="pl-4 mb-4">
                      Personal Details
                    </Modal.Title>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>First Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        value={first_name}
                        placeholder="First Name"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "first_name",
                        first_name,
                        "required|alpha_space|min:2|max:15",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The admin first name is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        value={last_name}
                        placeholder="Last Name"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "last_name",
                        last_name,
                        "required|alpha_space|min:2|max:15",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The admin last name is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="my-5">
                  <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>ID Type*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="idType_id"
                    value={idType_id}
                    onChange={this.handleChange}
                    defaultValue={""}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {!!idTypes.length &&
                      idTypes.map((idType) => (
                        <option
                          key={idType.idType_id}
                          value={idType.idType_id}
                        >
                          {idType.idType}
                        </option>
                      ))}
                  </Form.Select>
                  {this.validator.message(
                    "idType_id",
                    idType_id,
                    "required",
                    {
                      className: "text-danger",
                      messages: {
                        required: "The ID Type is required.",
                      },
                    }
                  )}
                </Form.Group>
                {idType_id && 
                  <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>ID Value*</Form.Label>
                      <Form.Control
                      type="text"
                      name="idValue"
                      value={idValue}
                      placeholder="ID Value"
                      onChange={this.handleChange}
                    />
                    {this.validator.message(
                      "idValue",
                      idType_id ? idValue : null,
                      "required|min:4",
                      {
                        className: "text-danger",
                        messages: {
                          required: "Please enter the ID Value.",
                        },
                      }
                    )}
                    </Form.Group>
                }
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label ref={this.dobRef}>DOB*</Form.Label>
                      <div className="col-md-12 px-0">
                        <DatePicker
                          className="py-2 form-control col-md-12" // Add Bootstrap form control class
                          selected={
                            this.state.dob
                              ? new Date(this.state.dob)
                              : undefined
                          }
                          name="dob"
                          onChange={this.handleDatePickerChange}
                          dateFormat="dd/MM/yyyy" // Set custom date format
                          placeholderText="Date of Birth"
                          ref={this.dobRef}
                          wrapperClassName="react-datePicker"
                        />
                      </div>
                      {this.validator.message(
                        "dob",
                        dob,
                        "required|dateBefore",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The date of birth is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Email*</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={email}
                        placeholder="example@gmail.com"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "email",
                        email,
                        "required|email",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The email is required.",
                          },
                        }
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Gender*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="gender_id"
                        value={gender_id}
                        onChange={this.handleChange}
                        defaultValue={""}
                      >
                        <option hidden value="">
                          Select
                        </option>
                        {genders?.map((gender) => (
                          <option
                            key={gender.gender_id}
                            value={gender.gender_id}
                          >
                            {gender.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message(
                        "gender_id",
                        gender_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The gender is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>
                  {/* <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Created On</Form.Label>
                      <Form.Control
                        className="py-2"
                        type="date"
                        name="created_on"
                        value={created_on}
                        placeholder="Date of creation"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "created_on",
                        created_on,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The created by is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Created By</Form.Label>
                      <Form.Control
                        type="text"
                        name="created_by"
                        value={created_by}
                        placeholder="Created By"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "created_by",
                        created_by,
                        "required|alpha_space",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The created by is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row> */}

                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Phone Number*</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone_number"
                        value={phone_number}
                        placeholder="Phone Number"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "phone_number",
                        phone_number,
                        "required|numeric|between:9,10|phone_number",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The phone number is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Status*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="status_id"
                        value={status_id}
                        onChange={this.handleChange}
                        defaultValue={""}
                      >
                        <option hidden value="">
                          Select
                        </option>
                        {adm_statuses?.map((status) => (
                          <option
                            key={status.status_id}
                            value={status.status_id}
                          >
                            {status.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message(
                        "status_id",
                        status_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The status is required.",
                          },
                        }
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                      className="mt-5"
                    >
                      <Form.Label>Admin ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="user_code"
                        value={user_code ? user_code : ""}
                        placeholder="Admin ID"
                        disabled
                      />
                      {this.validator.message(
                        "user_code",
                        user_code,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The admin ID is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="pt-5">
                    <Modal.Title className="pl-4 mb-4">
                      Residential Details
                    </Modal.Title>
                  </Row>

                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Address Line 1</Form.Label>
                      <Form.Control
                        type="text"
                        name="address_1"
                        placeholder="Address 1"
                        onChange={this.handleChange}
                        value={address_1}
                      />
                      {/* {this.validator.message(
                        "address_1",
                        address_1,
                        "address|required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Address 1 is required.",
                            address: "The address 1 must be a valid address",
                          },
                        }
                      )} */}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Address Line 2</Form.Label>
                      <Form.Control
                        type="text"
                        name="address_2"
                        placeholder="Address 2"
                        onChange={this.handleChange}
                        value={address_2}
                      />
                      {/* {this.validator.message(
                        "address_2",
                        address_2,
                        "address|required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Address 2 is required.",
                            address: "The address 2 must be a valid address",
                          },
                        }
                      )} */}
                    </Form.Group>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Country</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="country_id"
                        onChange={this.handleChange}
                        value={country_id}
                      >
                        <option value="">Select</option>
                        {countries?.map((country) => (
                          <option
                            value={country.country_id}
                            key={country.country_id}
                          >
                            {country.name}
                          </option>
                        ))}
                      </Form.Select>
                      {/* {this.validator.message(
                        "country_id",
                        country_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Country is required.",
                          },
                        }
                      )} */}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Province/District</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="province_id"
                        onChange={this.handleChange}
                        value={province_id}
                      >
                        <option value="">Select</option>
                        {provinces?.map((province) => (
                          <option
                            value={province.province_id}
                            key={province.province_id}
                          >
                            {province.name}
                          </option>
                        ))}
                      </Form.Select>
                      {/* {this.validator.message(
                        "province_id",
                        province_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Province is required.",
                          },
                        }
                      )} */}
                    </Form.Group>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>City</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="city_id"
                        onChange={this.handleChange}
                        value={city_id}
                      >
                        <option value="">Select</option>
                        {cities?.map((city) => (
                          <option value={city.city_id} key={city.city_id}>
                            {city.name}
                          </option>
                        ))}
                      </Form.Select>
                      {/* {this.validator.message("city_id", city_id, "required", {
                        className: "text-danger",
                        messages: {
                          required: "The City is required.",
                        },
                      })} */}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="postalcode"
                        placeholder="Postal Code"
                        onChange={this.handleChange}
                        value={postalcode}
                      />
                      {/* {this.validator.message(
                        "postalcode",
                        postalcode,
                        "numeric|required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The postal code is required.",
                            numeric: "The postal code must be a number",
                          },
                        }
                      )} */}
                    </Form.Group>
                  </Row>

                  <Modal.Footer>
                    <Button
                      className="mb-4"
                      variant="primary"
                      style={addBtnStyle}
                      onClick={this.handleSubmit}
                    >
                      Save changes
                    </Button>
                    <Button
                      className="mb-4"
                      id="modal-close-button"
                      style={closeBtnStyle}
                      onClick={() =>
                        this.handleSweetAlert(
                          this.toggleAddAdminModal,
                          "showInsertModal"
                        )
                      }
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Modal.Body>
          </div>
        </Modal>
      </div>
    );
  }
}
