import React from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css';
import { routes } from '../../../helper/routes';
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";
import DataHandler from '../../../handlers/DataHandler.js';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

const customer = getCustomerFeatureFlag()
let customerPaperText, iconColor, isCMC, isEdFoci
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    customerPaperText = "Papers"
    iconColor = "var(--maincolor)"
    isCMC = false
    isEdFoci = false
    break
  case customerConstants.EXO_CUSTOMER2:
    customerPaperText = "Past Papers"
    iconColor = "var(--maincolor-cmc)"
    isCMC = true
    isEdFoci = false
    break
  default:
    customerPaperText = "Hierarchy"
    iconColor = "var(--maincolor)"
    isCMC = false
    isEdFoci = true
    break
}

const DropdownMenu = (props) => {

  const dropdownStyle = {
    backgroundColor: iconColor,
    marginTop: '55px',
    position: 'fixed',
    width: '180px',
    zIndex: '1000',
    listStyleType: 'none',
    padding: 0,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    right: '0', 
  };

  const menuItemStyle = {
    margin: '15px',
  };

  const linkStyle = {
    textDecoration: 'none',
    color: '#fff',
    fontSize: '14px',
  };

  let isASAD = DataHandler.getFromSession('isASAD');

  return (
    <ul className="kt_header_mobile_toggle" id="kt_aside_toggle" style={dropdownStyle}>
      <li style={menuItemStyle}>
        <Link to="/manage-dashboard" style={linkStyle}>
          Dashboard
        </Link>
      </li>
      {(props.isAdmin || props.isParent) && 
      (<li style={menuItemStyle}>
        <Link to="/manage-search" style={linkStyle}>
          {customerPaperText}
        </Link>
      </li>
      )}
      {/* {(props.isAdmin) && (<li style={menuItemStyle}>
        <Link to="/search-papers" style={linkStyle}>
          {customerPaperText}
        </Link>
      </li>)} */}
      {props.isStudent && (<li style={menuItemStyle}>
        <Link to={routes.find_papers} style={linkStyle}>
          {customerPaperText}
        </Link>
      </li>)}
      {/* {!!props.isAdmin && (<li style={menuItemStyle}>
        <Link to="/manage-Invitations" style={linkStyle}>
          Students
        </Link>
      </li>)} */}
      {!!props.isAdmin && (<li style={menuItemStyle}>
        <Link 
          to={{
            pathname: "/manage-student", 
            state: {
              privilege_edit: 1,
              privilege_add: 1,
              privilege_delete: 1,
              breadcrumb2: "Students",
              nodeTitle: "",
              privilege_view: 1,
              mid: 100,
              main_table_id: 10,
              owner_restriction: null,
              lg_user_id: sessionStorage.getItem("manage_user_id"),
              lg_user_table_id: sessionStorage.getItem("table_log_id"),
              user_list_restriction: null,
            }}
          } 
          style={linkStyle}
        >
          Students
        </Link>
      </li>)}
      {!!props.isAdmin && (<li style={menuItemStyle}>
        <Link 
          to={{
            pathname: "/manage-teacher", 
            state: {
              privilege_edit: 1,
              privilege_add: 1,
              privilege_delete: 1,
              breadcrumb2: 'teachers',
              nodeTitle: '',
              mid: 100,
              main_table_id:10,
              owner_restriction: null,
              lg_user_id: sessionStorage.getItem("table_log_id"),
              lg_user_table_id: sessionStorage.getItem("manage_user_id"),
              user_list_restriction: null
            }}
          } 
          style={linkStyle}
        >
          Teachers
        </Link>
      </li>)}
      {!!props.isAdmin && !isCMC && !isEdFoci && (<li style={menuItemStyle}>
        <Link 
          to={{
            pathname: "/manage-parent", 
            state: {
              privilege_edit: 1,
              privilege_add: 1,
              privilege_delete: 1,
              breadcrumb2: 'parents',
              nodeTitle: '',
              mid: 100,
              main_table_id:3,
              owner_restriction: null,
              lg_user_id: sessionStorage.getItem("table_log_id"),
              lg_user_table_id: sessionStorage.getItem("manage_user_id"),
              user_list_restriction: null
            }}
          } 
          style={linkStyle}
        >
          Parents
        </Link>
      </li>)}
      {!!props.isTeacher && isASAD && (<li style={menuItemStyle}>
        <Link to={routes.questions_library} style={linkStyle}>
          Questions
        </Link>
      </li>)}
      {!!props.isTeacher && isASAD && (<li style={menuItemStyle}>
        <Link to={routes.view_assessment} style={linkStyle}>
          Assessments
        </Link>
      </li>)}
      {!!props.isAdmin && (<li style={menuItemStyle}>
        <Link to="/manage-settings" style={linkStyle}>
          Settings
        </Link>
      </li>)}
      {!!props.isTeacher && isASAD && (<li style={menuItemStyle}>
        <Link to={routes.settings_view} style={linkStyle}>
          Settings
        </Link>
      </li>)}
      {(!!props.isTeacher || !!props.isStudent) && (<li style={menuItemStyle}>
        <Link 
          to={{
            pathname: "/announcements", 
            state: {
              privilege_edit: props.isTeacher?1:null,
              privilege_add:props.isTeacher?1:null,
              privilege_delete: null,
              privilege_view:1,
              breadcrumb2: 'announcement',
              nodeTitle: '',
              mid:100,
              main_table_id:props.isTeacher?10:2,
              owner_restriction: null,
              lg_user_id: sessionStorage.getItem("table_log_id"),
              lg_user_table_id: sessionStorage.getItem("manage_user_id"),
              user_list_restriction: null,
              flg_list_active:null
            }}
          } 
          style={linkStyle}
        >
          Annoucement
        </Link>
      </li>)}
      {!!props.isStudent && !isEdFoci && (<li style={menuItemStyle}>
        <Link 
          to={{
            pathname: "/subscriptions", 
            state: {
              privilege_edit: 1,
              privilege_add: 1,
              privilege_delete: 1,
              nodeTitle: 'Subscriptions',
              mid: 100,
              main_table_id:10,
              owner_restriction: null,
              lg_user_id: sessionStorage.getItem("manage_user_id"),
              lg_user_table_id: sessionStorage.getItem("table_log_id"),
              user_list_restriction: null
            }}
          } 
          style={linkStyle}
        >
          Subscriptions
        </Link>
      </li>)}
      {!!props.isParent && !isEdFoci && sessionStorage.getItem("parent_group_id") && sessionStorage.getItem("parent_group_user_table_id") && (<li style={menuItemStyle}>
        <Link 
          to={{
            pathname: "/subscriptions", 
            state: {
              privilege_edit: 1,
              privilege_add: 1,
              privilege_delete: 1,
              nodeTitle: 'Subscriptions',
              mid: 100,
              main_table_id:10,
              owner_restriction: null,
              lg_user_id: sessionStorage.getItem("manage_user_id"),
              lg_user_table_id: sessionStorage.getItem("table_log_id"),
              user_list_restriction: null,
              flg_list_active:1,
              form_list_id:29
            }}
          } 
          style={linkStyle}
        >
          Subscriptions
        </Link>
      </li>)}
      {!!props.isAdmin && isEdFoci && (
      <li style={menuItemStyle}>
        <Link to="/manage-sitemap" style={linkStyle}>
          Site Map
        </Link>
      </li>
      )}
      {!!props.isAdmin && isEdFoci && (
      <li style={menuItemStyle}>
        <Link to="/main-calendar" style={linkStyle}>
          Calendar
        </Link>
      </li>
      )}
    </ul>
  );
};

export default DropdownMenu;




