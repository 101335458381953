import React, { createContext } from "react"
import { Route, Router, Switch, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { history } from "./helper/history"
import { alertActions } from "./actions/user_management/alertActions.js"
import { Message } from "semantic-ui-react"
import { AuthResp } from "./components/auth/AuthResp"
import { AcceptTerms } from "./components/terms/AcceptTerms"
import { routes } from "./helper/routes.js"
import config from "./helper/config.js"
import { customerConstants } from "./helper/constants.js"
import "./assets/css/App.css"
import PrivateRoute from "./components/auth/PrivateRoute";
import withTemplate from "./components/admin/layouts/TemplateHOC";

//Landing Page App Components
/*EdFoci*/
import EdFociLandingPage from "./components/main/edfoci/LandingPage.js"
/*EdSider*/
import EdSiderStudentParentLandingPage from "./components/main/edsider/StudentParentLandingPage.js"
import EdsiderAdminLandingPage from "./components/main/edsider/AdminLandingPage.js"
import EdsiderTeacherLandingPage from "./components/main/edsider/TeacherLandingPage.js"
import ParentStudentLandingPage from "./pages/manage/invitation/edsider/ParentStudentLandingPage.js"
import ParentStudentSignInPage from "./pages/manage/invitation/edsider/ParentStudentSignIn.js"

/*CMC*/
import CMCStudentParentLandingPage from "./components/main/cmc/StudentParentLandingPage.js"
import CMCAdminLandingPage from "./components/main/cmc/AdminLandingPage"
import CMCTeacherLandingPage from "./components/main/cmc/TeacherLandingPage"
import SignIn from "./components/cmc/components/SignIn"
/* Royal Academy */
import RoyalAcademyLandingPage from "./components/main/royalacademy/LandingPage.js"
/*CGC*/
import CGCCoverPage from "./components/main/cgc/CoverPage.js"
import CGCLoginPage from "./components/main/cgc/LandingPage.js"

//Manage Invitation App Components
/*EdSider*/
import RegisterStudent from "./pages/manage/invitation/edsider/RegisterStudent.js"
import EdSiderTermsAndConditions from "./pages/manage/terms-and-conditions/edsider/TermsAndConditions.js";
import EdSiderConditions from "./pages/manage/terms-and-conditions/edsider/Conditions.js";
import EdSiderRegistrationForm from "./pages/manage/invitation/edsider/RegistrationForm.js";
import EdSiderStudentRequest from "./pages/manage/invitation/edsider/StudentRequest.js";
import MyRequests from "./pages/manage/invitation/edsider/MyRequests.js"
/*CMC*/
import CMCConditions from "./pages/manage/terms-and-conditions/cmc/Conditions";
import CMCTermsAndConditions from "./pages/manage/terms-and-conditions/cmc/TermsAndConditions";
import CMCRegistrationForm from "./pages/manage/invitation/cmc/RegistrationForm";
import CMCStudentRequest from "./pages/manage/invitation/cmc/StudentRequest";

//Manage User Regsitration Components
import ManageTeacher from "./pages/manage/teacher/ManageTeacher";
import ManageStudent from "./pages/manage/student/Student";
import ManageAdmin from "./pages/manage/admin/ManageAdmin";
import ManageParent from "./pages/manage/parent/Parent";
import MyProfile from "./pages/manage/profile/MyProfile.js";
import CGCManageStudent from "./pages/manage/student/CgcStudent.js"

//Manage Dashboard Components
import EdSiderManageDashboard from "./pages/manage/dashboard/edsider/EdSiderManageDashboard";
import CMCManageDashboard from "./pages/manage/dashboard/cmc/CMCManageDashboard";
import EdFociManageDashboard from "./pages/manage/dashboard/edfoci/EdFociManageDashboard";
import CGCManageDashboard from "./pages/manage/dashboard/cgc/CGCManageDashboard.js"

//Manage Search and Privilege App Components
import ManageSearchComponent from "./pages/manage/hierarchy/NodeBuilder.js";
import ManageFormAttachment from "./pages/manage/privilege/ManageFormAttachment.js";
import ManageForm from "./pages/manage/privilege/ManageForm";
import ManageUserList from "./pages/manage/privilege/ManageUserList.js";
import ManageNode from "./pages/manage/privilege/ManageNode";

//Manage Paper Components
import PaperMapping from "./pages/manage/paper/PaperMapping.js";
import QuizMapping from "./pages/manage/quiz/QuizMapping.js";
import EdFociPaperList from "./pages/manage/paper/edfoci/EdFociPaperList.js";
import EdSiderPaperList from "./pages/manage/paper/edsider/EdSiderPaperList.js";
import CMCPaperList from "./pages/manage/paper/cmc/CMCPaperList.js";

//Manage App Components
import ManageAbout from "./pages/manage/about/ManageAbout.js";
import ManageAnnouncement from "./pages/manage/announcement/ManageAnnouncement.js";
import ManageOrganizationAttribute from "./pages/manage/attribute/ManageOrganizationAttribute.js";
import ManageAttendance from "./pages/manage/attendance/ManageAttendance";
import ManageEnrollment from "./pages/manage/enrollment/ManageEnrollment.js";
import ManageTeacherEnrollment from "./pages/manage/enrollment/cgc/ManageTeacherEnrollment.js"
import ManageFeedback from "./pages/manage/feedback/ManageFeedback.js";
import ManageDocument from "./pages/manage/lms/ManageDocument.js";
// import ManageMarksheet from "./pages/manage/mark-sheet/ManageMarkSheet.js";
import MarkEntrySheet from "./pages/manage/mark-sheet/MarkEntrySheet.js"
import ManageProgress from "./pages/manage/progress/ManageProgress";
import ManageChat from "./pages/manage/chat/ManageChat";
import ManageSitemap from "./pages/manage/sitemap/ManageSitemap.js";

//Manage Calendar App Components
import ManageCalendar from "./pages/manage/calendar/ManageCalendar.js";
import ManageMainCalendar from "./pages/manage/calendar/ManageMainCalendar.js";

//Manage Payment App Components
import ManagePayment from "./pages/manage/payment/ManagePayment.js";
import ManageSamplePaymentForm from "./pages/manage/payment/ManageSamplePayment.js";

//Manage Subscription App Components
import EdSiderManageSubscription from "./pages/manage/subscription/edsider/ManageSubscription.js";
import CMCManageSubscription from "./pages/manage/subscription/cmc/ManageSubscription.js";

//Question App Components
import NewQuestion from "./pages/question/NewQuestion.js"
import EditQuestion from "./pages/question/EditQuestion.js"
import QuestionsLibrary from "./pages/question/QuestionsLibrary.js"
import CreateAddNewQuestion from "./pages/question/CreateAddNewQuestion.js"

//Assessment App Components
import AssessmentLibrary from "./pages/assessment/AssessmentLibrary.js"
import StudentView from "./pages/assessment/StudentView.js"
import GradeReport from "./pages/assessment/GradeReport.js"
import TeacherGradeReport from "./pages/assessment/TeacherGradeReport.js"
import Grade from "./pages/assessment/Grade.js"
import Finalize from "./pages/assessment/Finalize.js"
import SetupAssessment from "./pages/assessment/SetupAssessment.js"
import FinalizeNewQuestion from "./pages/assessment/FinalizeNewQuestion.js"

//Candidate App Components
import EnrolledPaperList from "./pages/candidate/EnrolledPaperList.js"
import EnrolledPaperDetailsNew from "./pages/candidate/EnrolledPaperDetailsNew.js"
import PaperGradeReport from "./pages/candidate/PaperGradeReport.js"
import PaperAutoGradeReport from "./pages/candidate/PaperAutoGradeReport.js"
import PaperQuestions from "./pages/candidate/PaperQuestions.js"
import SelfGrade from "./pages/candidate/SelfGrade.js";
import PaperReview from "./pages/candidate/PaperReview.js";

//Settings App Components
import AssessmentQuestionSettings from "./pages/settings/AssessmentQuestionSettings.js"
import EdFociManageSettings from "./pages/settings/EdFociManageSettings.js";
import EdSiderManageSettings from "./pages/settings/EdSiderManageSettings.js";

import EdsierPaperSearch from "./pages/manage/paper/edsider/EdsierPaperSearch.js"
import EdsiderFindPapers from "./pages/manage/paper/edsider/EdsiderFindPapers.js"

{/*Adding the Template to imported components*/ }
const ManageAdminWithTemplate = withTemplate(ManageAdmin)
const ManageStudentWithTemplate = withTemplate(ManageStudent)
const CGCManageStudentWithTemplate = withTemplate(CGCManageStudent)
const ManageParentWithTemplate = withTemplate(ManageParent)
const ManageTeacherWithTemplate = withTemplate(ManageTeacher)

const ManageFormWithTemplate = withTemplate(ManageForm)
const ManageNodeWithTemplate = withTemplate(ManageNode)
const ManageFormAttachmentListWithTemplate = withTemplate(ManageFormAttachment)

const PaperMappingWithTemplate = withTemplate(PaperMapping)
const EnrolledPaperListWithTemplate = withTemplate(EnrolledPaperList)
const QuizMappingWithTemplate = withTemplate(QuizMapping)

const RegisterStudents = withTemplate(RegisterStudent)
const StudentsRequests = withTemplate(MyRequests)

const AnnouncementWithTemplate = withTemplate(ManageAnnouncement)
const ManageAboutWithTemplate = withTemplate(ManageAbout)
const ManageAnnouncementWithTemplate = withTemplate(ManageAnnouncement)
const ManageOrganizationAttributeWithTemplate = withTemplate(ManageOrganizationAttribute)
const ManageCalendarWithTemplate = withTemplate(ManageCalendar)
const ManageFeedbackWithTemplate = withTemplate(ManageFeedback)
const ManageProgressWithTemplate = withTemplate(ManageProgress)
const ManageChatWithTemplate = withTemplate(ManageChat)
const ManageMarksheetWithTemplate = withTemplate(MarkEntrySheet)
const ManagePaymentWithTemplate = withTemplate(ManagePayment)
const ManageDocumentWithTemplate = withTemplate(ManageDocument)
const ManageEnrollmentWithTemplate = withTemplate(ManageEnrollment)
const ManageTeacherEnrollmentWithTemplate = withTemplate(ManageTeacherEnrollment)
const ManageAttendanceWithTemplate = withTemplate(ManageAttendance)

export const ThemeContext = createContext(null)

{/*Function to get the customer feature flag*/ }
const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      theme: "",
    }
    const location = history.location
    history.listen((newLocation) => {
      if (newLocation.hash !== location.hash) {
        return
      }
      this.props.clearAlerts()
    })
  }

  render() {
    const { alert } = this.props
    {/*Getting the customer feature flag*/ }
    const customer = getCustomerFeatureFlag()

    let LandingPage, AdminLandingPage, TeacherLandingPage, LoginPage;
    let RegistrationFormPage, TermsAndConditionsPage, ConditionsPage;
    let PaperListPage, StudentRequestPage, DashboardPage
    let ParentStudentLanding, ParentStudentSignIn;
    let ManageSettingsPage;
    let ManageSubscription;

    {/*Assigning custom components based on customer flag*/ }
    switch (customer) {
      case customerConstants.EXO_CUSTOMER1:
        LandingPage = EdSiderStudentParentLandingPage;
        AdminLandingPage = EdsiderAdminLandingPage;
        TeacherLandingPage = EdsiderTeacherLandingPage;
        RegistrationFormPage = EdSiderRegistrationForm;
        TermsAndConditionsPage = EdSiderTermsAndConditions;
        ConditionsPage = EdSiderConditions;
        PaperListPage = EdSiderPaperList;
        StudentRequestPage = EdSiderStudentRequest;
        DashboardPage = EdSiderManageDashboard
        ParentStudentLanding = ParentStudentLandingPage;
        ParentStudentSignIn = ParentStudentSignInPage;
        ManageSettingsPage = EdSiderManageSettings;
        ManageSubscription = EdSiderManageSubscription
        break;
      case customerConstants.EXO_CUSTOMER2:
        LandingPage = CMCStudentParentLandingPage;
        AdminLandingPage = CMCAdminLandingPage;
        TeacherLandingPage = CMCTeacherLandingPage;
        RegistrationFormPage = CMCRegistrationForm;
        TermsAndConditionsPage = CMCTermsAndConditions;
        ConditionsPage = CMCConditions;
        PaperListPage = CMCPaperList;
        StudentRequestPage = CMCStudentRequest;
        DashboardPage = CMCManageDashboard;
        ParentStudentLanding = CMCRegistrationForm;
        ParentStudentSignIn = CMCRegistrationForm;
        ManageSettingsPage = EdSiderManageSettings;
        ManageSubscription = CMCManageSubscription
        break;
      case customerConstants.EDF_CUSTOMER2:
        LandingPage = RoyalAcademyLandingPage;
        RegistrationFormPage = EdSiderRegistrationForm;
        TermsAndConditionsPage = EdSiderTermsAndConditions;
        ConditionsPage = EdSiderConditions;
        PaperListPage = EdFociPaperList;
        StudentRequestPage = EdSiderStudentRequest;
        DashboardPage = EdFociManageDashboard;
        ParentStudentLanding = EdSiderRegistrationForm;
        ParentStudentSignIn = EdSiderRegistrationForm;
        ManageSettingsPage = EdFociManageSettings;
        ManageSubscription = EdSiderManageSubscription
        break;
      case customerConstants.EDF_CUSTOMER3: //CGC
        LandingPage = CGCCoverPage;
        LoginPage = CGCLoginPage
        RegistrationFormPage = EdSiderRegistrationForm;
        TermsAndConditionsPage = EdSiderTermsAndConditions;
        ConditionsPage = EdSiderConditions;
        PaperListPage = EdFociPaperList;
        StudentRequestPage = EdSiderStudentRequest;
        DashboardPage = CGCManageDashboard;
        ParentStudentLanding = EdSiderRegistrationForm;
        ParentStudentSignIn = EdSiderRegistrationForm;
        ManageSettingsPage = EdFociManageSettings;
        ManageSubscription = EdSiderManageSubscription
        break;
      default:
        LandingPage = EdFociLandingPage;
        RegistrationFormPage = EdSiderRegistrationForm;
        TermsAndConditionsPage = EdSiderTermsAndConditions;
        ConditionsPage = EdSiderConditions;
        PaperListPage = EdFociPaperList;
        StudentRequestPage = EdSiderStudentRequest;
        DashboardPage = EdFociManageDashboard;
        ParentStudentLanding = EdSiderRegistrationForm;
        ParentStudentSignIn = EdSiderRegistrationForm;
        ManageSettingsPage = EdFociManageSettings;
        ManageSubscription = EdSiderManageSubscription
        break;
    }

    const PaperListWithTemplate = withTemplate(PaperListPage)
    const ManageSubscriptionWithTemplate = withTemplate(ManageSubscription)

    return (
      <ThemeContext.Provider>
        <div id={customer === customerConstants.EXO_CUSTOMER2 ? "cmc" : ""}>
          {alert.message && alert.type === "alert-danger" && (
            <Message error header={alert.message} />
          )}
          <Router history={history}>
            <Switch>
              {/*AuthResponse and Accept Terms Private Routes*/}
              <Route path={routes.auth_response} component={withRouter(AuthResp)} />
              <Route path={routes.accept_terms} component={withRouter(AcceptTerms)} />

              {/*Landing Pages Private Routes*/}
              <Route exact path={routes.base_url} component={ParentStudentSignIn} />
              <Route exact path={routes.login_url} component={LoginPage} />
              <Route exact path={routes.admin_url} component={AdminLandingPage} />
              <Route exact path={routes.teacher_url} component={TeacherLandingPage} />
              <Route exact path={routes.sign_in_url} component={SignIn} />

              {/*Manage Users Private Routes*/}
              <PrivateRoute
                path={routes.manage_admin}
                component={withRouter(ManageAdminWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_student}
                component={withRouter(ManageStudentWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_cgc_student}
                component={withRouter(CGCManageStudentWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_parent}
                component={withRouter(ManageParentWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_teacher}
                component={withRouter(ManageTeacherWithTemplate)}
              />
              <PrivateRoute
                path={routes.profile}
                component={withRouter(MyProfile)}
              />
              {/*Manage Initations Private Routes*/}
              <PrivateRoute
                path={routes.manage_invitations}
                component={withRouter(StudentRequestPage)}
              />
              {/*EdSider Registration Flow Public Routes*/}
              <Route
                path={routes.registration_form_view}
                component={withRouter(RegistrationFormPage)}
              />
              <Route path={routes.terms_and_conditions_view_from_registration_form}
                component={withRouter(TermsAndConditionsPage)}
              />
              <Route
                path={routes.terms_and_conditions_view}
                component={withRouter(ConditionsPage)}
              />
              <Route
                path={routes.parent_student_signup}
                component={withRouter(ParentStudentLanding)}
              />
              <PrivateRoute
                path={routes.register_student}
                component={withRouter(RegisterStudents)}
              />
              <PrivateRoute
                path={routes.my_requests}
                component={withRouter(StudentsRequests)}
              />
              <PrivateRoute
                path={routes.search_papers}
                component={withRouter(EdsierPaperSearch)}
              />
              <PrivateRoute
                path={routes.find_papers}
                component={withRouter(EdsiderFindPapers)}
              />
              {/*Manage Dasboard Private Route*/}
              <PrivateRoute
                path={routes.manage_dashboard}
                component={withRouter(DashboardPage)}
              />
              {/*Manage Search*/}
              <PrivateRoute
                path={routes.manage_search}
                component={ManageSearchComponent}
              />
              {/*Manage Privilege Forms Private Routes*/}
              <PrivateRoute
                path={routes.form_attachment}
                component={withRouter(ManageFormAttachmentListWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_form}
                component={withRouter(ManageFormWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_node}
                component={withRouter(ManageNodeWithTemplate)}
              />
              <PrivateRoute
                path={routes.user_list}
                component={withRouter(ManageUserList)}
              />
              {/*Manage Forms Private Routes*/}
              <PrivateRoute
                path={routes.manage_about}
                component={withRouter(ManageAboutWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_announcement}
                component={withRouter(AnnouncementWithTemplate)}
              />
              <PrivateRoute
                path={routes.announcements}
                component={withRouter(ManageAnnouncementWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_enrollment}
                component={withRouter(ManageEnrollmentWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_teacher_enrollment}
                component={withRouter(ManageTeacherEnrollmentWithTemplate)}
              />
              <PrivateRoute
                path={routes.enrolled_paper_list}
                component={withRouter(EnrolledPaperListWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_attendance}
                component={withRouter(ManageAttendanceWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_organization_attribute}
                component={withRouter(ManageOrganizationAttributeWithTemplate)}
              />
              <PrivateRoute
                path={routes.calendar_app}
                component={withRouter(ManageCalendarWithTemplate)}
              />
              <PrivateRoute
                path={routes.main_calendar}
                component={withRouter(ManageMainCalendar)}
              />
              <PrivateRoute
                path={routes.feedback_form}
                component={withRouter(ManageFeedbackWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_documents}
                component={withRouter(ManageDocumentWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_marksheet}
                component={withRouter(ManageMarksheetWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_sitemap}
                component={withRouter(ManageSitemap)}
              />
              <PrivateRoute
                path={routes.manage_chat}
                component={withRouter(ManageChatWithTemplate)}
              />
              <PrivateRoute
                path={routes.manage_progress}
                component={withRouter(ManageProgressWithTemplate)}
              />
              {/*Manage Payment Private Routes*/}
              <PrivateRoute
                path={routes.payment_gateway}
                component={withRouter(ManageSamplePaymentForm)}
              />
              <PrivateRoute
                path={routes.manage_payment}
                component={withRouter(ManagePaymentWithTemplate)}
              />
              {/*Manage Subscription Private Routes*/}
              <PrivateRoute
                path={routes.manage_subscription_main}
                component={withRouter(ManageSubscriptionWithTemplate)}
              />
              <PrivateRoute
                path={routes.subscriptions}
                component={withRouter(ManageSubscriptionWithTemplate)}
              />
              {/*Candidate Private Routes*/}
              <PrivateRoute
                path={routes.paper_details}
                component={withRouter(EnrolledPaperDetailsNew)}
              />
              <PrivateRoute
                path={routes.self_grade}
                component={withRouter(SelfGrade)}
              />
              <PrivateRoute
                path={routes.paper_grade_report}
                component={withRouter(PaperGradeReport)}
              />
              <PrivateRoute
                path={routes.paper_auto_grade_report}
                component={withRouter(PaperAutoGradeReport)}
              />
              <PrivateRoute
                path={routes.view_question_paper}
                component={withRouter(PaperQuestions)}
              />
              <PrivateRoute
                path={routes.paper_review}
                component={withRouter(PaperReview)}
              />
              <PrivateRoute
                path={routes.paper_list}
                component={withRouter(PaperListWithTemplate)}
              />
              <PrivateRoute
                path={routes.paper_mapping}
                component={withRouter(PaperMappingWithTemplate)}
              />
              <PrivateRoute
                path={routes.quiz_mapping}
                component={withRouter(QuizMappingWithTemplate)}
              />
              {/*Question Private Routes*/}
              <PrivateRoute
                path={routes.new_question}
                component={withRouter(NewQuestion)}
              />
              <PrivateRoute
                path={routes.questions_library}
                component={withRouter(QuestionsLibrary)}
              />
              <PrivateRoute
                path={routes.edit_question}
                component={withRouter(EditQuestion)}
              />
              <PrivateRoute
                path={routes.create_question}
                component={withRouter(CreateAddNewQuestion)}
              />
              {/*Assessment Private Routes*/}
              <PrivateRoute
                path={routes.view_assessment}
                component={withRouter(AssessmentLibrary)}
              />
              <PrivateRoute
                path={routes.student_view}
                component={withRouter(StudentView)}
              />
              <PrivateRoute
                path={routes.grade}
                component={withRouter(Grade)}
              />
              <PrivateRoute
                path={routes.grade_report}
                component={withRouter(GradeReport)}
              />
              <PrivateRoute
                path={routes.teacher_grade_report}
                component={withRouter(TeacherGradeReport)}
              />
              <PrivateRoute
                path={routes.finalize_new_question}
                component={withRouter(FinalizeNewQuestion)}
              />
              <PrivateRoute
                path={routes.finalize}
                component={withRouter(Finalize)}
              />
              <PrivateRoute
                path={routes.setup_assessment}
                component={withRouter(SetupAssessment)}
              />
              {/*Settings Private Routes*/}
              <PrivateRoute
                path={routes.manage_settings}
                component={withRouter(ManageSettingsPage)}
              />
              <PrivateRoute
                path={routes.settings_view}
                component={withRouter(AssessmentQuestionSettings)}
              />
            </Switch>

          </Router>
        </div>
      </ThemeContext.Provider>
    );
  }
}

function mapState(state) {
  const { alert, authentication } = state
  return { alert, authentication }
}

const actionCreators = {
  clearAlerts: alertActions.error,
}

const connectedApp = connect(mapState, actionCreators)(App)
export { connectedApp as App }
