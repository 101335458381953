import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import ContentWrapper from "../../../components/common/ContentWrapper";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import StickyCardBody from "../../../components/common/StickyCardBody";
import CardBody from "../../../components/common/CardBody";
import { AssessmentService } from "../../../service/candidate/assessmentService";
import { sweetStatusAlert } from "../../../components/common/SweetAlert";
import { PaperService } from "../../../service/manage/paper/paper_service";
import { history } from "../../../helper/history";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { PAPER_FILTERS } from "../../../helper/constants";
import { assessmentService } from "../../../service/assessment/assessmentService";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ReactSelect from "react-select";
import debounce from "lodash.debounce";
import { MarksheetService } from "../../../service/manage/mark_sheet/marksheet.service.js";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

export default class PaperMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AssessmentList: [],
      selectedRows: new Set(),
      isLoading: true,
      node_mid: null,
      addedPapers: [],
      limit: 25,
      offset: 0,
      search_term: "",
      totalAssessments: 0,
      breadcrumb2: null,
      nodeTitle: null,
      currentPage: 0,
      sortColumn: "title",
      sortDirection: "desc",
      addVisibility: false,
      deleteVisibility: false,
      addingExamsLoading: false,
      deletingPapersLoading: false,
      deleteids: [],
      filterValues: [],
      filters: [],
      selectedFilters: [],
      selectedGrades: null,
      selectedChapters: null,
      selectedsubjects: null,
      selectedAuthors: null,
      selectedYears: null,
      selectedCategories: null,
      selectedTerms: null,
      selectedDifficulties: null,
      selectedLanguages: null,
      selectedStatuses: null,
      allCategories: [],
      allLanguages: [],
      allSubjects: [],
      allAuthors: [],
      allYears: [],
      allDifficulties: [],
      allGrades: [],
      allTerms: [],
      allChapters: [],
      allAssessmentStatuses: [],
      selectedTeacher: "",
      Teacherlist: [],
      selectedOption: "",
      isTeacherListLoading: false,
      teacherSearchTerm: "",
      addPaper: false,
      deletePaper: false,
      selectedPaper: "",
      showDetailModal: false
    };
    this.debouncedHandleTeacherSearch = debounce(this.fetchAllTeachers, 1000);
  }

  componentDidMount() {
    sessionStorage.setItem("selectedTeacher", null);
    this.getAllAssessments();
    this.getPaperData();
    this.loadInitialData();
    this.fetchAllAssessmentSettings();
    this.fetchAllTeachers();
  }

  fetchAllAssessmentSettings() {
    this.setState({
      isLoading: true,
    });

    const fetchAssessmentMetaData =
      assessmentService.getAllAssessmentMetaData();

    Promise.all([fetchAssessmentMetaData])
      .then((response) => {
        const [assessmentMetaDataResponse] = response;

        this.setState({
          allCategories: assessmentMetaDataResponse.data.find(
            (item) => "categories" in item
          ).categories,
          allLanguages: assessmentMetaDataResponse.data.find(
            (item) => "languages" in item
          ).languages,
          allSubjects: assessmentMetaDataResponse.data.find(
            (item) => "subjects" in item
          ).subjects,
          allAuthors: assessmentMetaDataResponse.data.find(
            (item) => "authors" in item
          ).authors,
          allYears: assessmentMetaDataResponse.data.find(
            (item) => "years" in item
          ).years,
          allDifficulties: assessmentMetaDataResponse.data.find(
            (item) => "difficulty_types" in item
          ).difficulty_types,
          allGrades: assessmentMetaDataResponse.data.find(
            (item) => "grades" in item
          ).grades,
          allTerms: assessmentMetaDataResponse.data.find(
            (item) => "terms" in item
          ).terms,
          allChapters: assessmentMetaDataResponse.data.find(
            (item) => "chapters" in item
          ).chapters,
          allAssessmentStatuses: assessmentMetaDataResponse.data.find(
            (item) => "statuses" in item
          ).statuses,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  getPaperCategory(category_id) {
    if (category_id !== null) {
        const category = this.state.allCategories?.find(
            (category) => category.category_id == category_id
        );
        return category?.name;
    }
    else{
        return "N/A";
    }
  }

  getPaperLanguage(language_id) {
    if (language_id !== null) {
        const language = this.state.allLanguages?.find(
            (language) => language.language_id == language_id
        );
        return language?.language;
    }
    else{
        return "N/A";
    }
  }

  getPaperSubject(subject_id) {
    if (subject_id !== null) {
        const subject = this.state.allSubjects?.find(
            (subject) => subject.subject_id == subject_id
        );
        return subject?.subject;
    }
    else{
        return "N/A";
    }
  }

  getPaperAuthor(author_id) {
    if (author_id !== null) {
        const author = this.state.allAuthors?.find(
            (author) => author.author_id == author_id
        );
        return author?.author;
    }
    else{
        return "N/A";
    }
  }

  getPaperYear(year_id) {
    if (year_id !== null) {
        const year = this.state.allYears?.find(
            (year) => year.year_id == year_id
        );
        return year?.year;
    }
    else{
        return "N/A";
    }
  }

  getPaperDifficulty(difficulty_id) {
    if (difficulty_id !== null) {
        const difficulty = this.state.allDifficulties?.find(
            (difficulty) => difficulty.difficulty_id == difficulty_id
        );
        return difficulty?.difficulty_type;
    }
    else{
        return "N/A";
    }
  }

  getPaperGrade(grade_id) {
    if (grade_id !== null) {
        const grade = this.state.allGrades?.find(
            (grade) => grade.grade_id == grade_id
        );
        return grade?.grade;
    }
    else{
        return "N/A";
    }
  }

  getPaperTerm(term_id) {
    if (term_id !== null) {
        const term = this.state.allTerms?.find(
            (term) => term.term_id == term_id
        );
        return term?.term;
    }
    else{
        return "N/A";
    }
  }

  getPaperChapter(chapter_id) {
    if (chapter_id !== null) {
        const chapter = this.state.allChapters?.find(
            (chapter) => chapter.chapter_id == chapter_id
        );
        return chapter?.chapter;
    }
    else{
        return "N/A";
    }
  }

  getPaperStatus(status_id) {
    if (status_id !== null) {
        const status = this.state.allAssessmentStatuses?.find(
            (status) => status.status_id == status_id
        );
        return status?.status;
    }
    else{
        return "N/A";
    }
  }

  getCreatedAtLocal(time) {
    if(time != null){
        let timeStamp = new Date(time.concat("Z"))
        let d = new Date(timeStamp)
        // let startDate = d.toDateString();
        // let startTime = d.toLocaleTimeString('en-SL');
        let  local = moment(timeStamp).local().format('YYYY-MM-DDTHH:mm')
        return local;
    }
    else{
        return "N/A";
    }
  }

  getColor(state) {
    switch (state) {
      case "Initial":
        return "label label-lg label-light-primary label-inline";
      case "Ready":
        return "label label-lg label-light-warning label-inline";
      case "Review":
        return "label label-lg label-light-default label-inline";
      case "Published":
        return "label label-lg label-success label-inline";
      case "Retired":
        return "label label-lg label-light-danger label-inline";
      default:
        return "label label-lg label-light-info label-inline";
    }
  }

  loadInitialData() {
    const {
      privilege_view,
      privilege_add,
      privilege_delete,
      breadcrumb2,
      nodeTitle,
    } = this.props.state;
    this.getVisibilityCheck(privilege_view, privilege_add, privilege_delete);
    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle: nodeTitle,
    });
  }

  getVisibilityCheck(privilege_view, privilege_add, privilege_delete) {
    let addVisibility = false;
    let deleteVisibility = false;

    if (privilege_view === 1 && privilege_add === 1) {
      if (privilege_delete === 1) {
        addVisibility = true;
        deleteVisibility = true;
      } else {
        addVisibility = true;
      }
    } else if (privilege_view === 1 && privilege_add === null) {
      if (privilege_delete === 1) {
        addVisibility = true;
        deleteVisibility = true;
      } else {
        addVisibility = true;
      }
    }

    this.setState({
      addVisibility,
      deleteVisibility,
    });
  }

  async getPaperData() {
    try {
      const node_mid = this.props.state.mid;
      const paperData = await PaperService.getById(node_mid);
      this.setState({ paperData });

      if (paperData.data && paperData.data.length > 0) {
        const addedPapers = paperData.data.map((paper) => paper.assessment_id);
        this.setState({ addedPapers });
      }
    } catch (error) {}
  }

  handleCheckboxChange = (assessment_id) => {
    this.setState((prevState) => {
      const selectedRows = new Set(prevState.selectedRows);

      if (selectedRows.has(assessment_id)) {
        selectedRows.delete(assessment_id);
        if (selectedRows.size <= 0) {
          this.setState({ addPaper: false, deletePaper: false });
        }
      } else {
        selectedRows.add(assessment_id);
        if (selectedRows.size > 0) {
          if (this.state.addedPapers.includes(assessment_id)) {
            this.setState({ addPaper: true });
          } else {
            if (selectedRows.size > 0) {
              if (!this.state.addPaper) {
                this.setState({ deletePaper: true });
              }
            }
          }
        }
      }

      return { selectedRows };
    });
  };

  handleAddAllExams = async (event) => {
    event.preventDefault();
    let lg_user_id = sessionStorage.getItem("manage_user_id");
    let lg_user_table_id = sessionStorage.getItem("table_log_id");

    this.setState({ addingExamsLoading: true });

    try {
      const node_mid = this.props.state.mid;

      const examList = [...this.state.selectedRows].map((assessment_id) => ({
        node_mid,
        create_user_id: lg_user_id,
        create_user_table_id: lg_user_table_id,
        assessment_id,
        assess_name: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).title,
        attempt_no: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).no_of_attempts,
        language_id: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).language_id,
        difficulty_id: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).difficulty_id,
        category_id: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).category_id,
        grade_id: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).grade_id,
        author_id: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).author_id,
        chapter_id: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).chapter_id,
        subject_id: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).subject_id,
        term_id: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).term_id,
        year_id: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).year_id,
        status_id: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).status_id,
      }));
      await PaperService.addAll(JSON.stringify(examList)).then((response) => {
        if (response.success) {
          this.setState({ addPaper: false, deletePaper: false });
          sweetStatusAlert("Success!", response.message.success, "success");
          this.getPaperData();
        } else {
          sweetStatusAlert("Failed!", response.message.error, "error");
        }
      });

      this.setState({ selectedRows: new Set() });
      this.setState({ addingExamsLoading: false });
    } catch (error) {
      this.setState({ addingExamsLoading: false });
    }
  };

  async deletePapers(paperIds, nodeMid) {
    this.setState({ deletingPapersLoading: true });
    try {
      const response = await PaperService.deleteAll(paperIds, nodeMid);

      if (response.success) {
        this.setState({ addPaper: false, deletePaper: false });
        sweetStatusAlert("Success!", response.message.success, "success");

        const updatedAddedPapers = this.state.addedPapers.filter(
          (paperId) => !paperIds.includes(paperId)
        );

        this.setState({
          selectedRows: new Set(),
          deletingPapersLoading: false,
          addedPapers: updatedAddedPapers,
        });
      } else {
        sweetStatusAlert("Failed!", response.message.error, "error");
        this.setState({ deletingPapersLoading: false });
      }
    } catch (error) {
      this.setState({ deletingPapersLoading: false });
    }
  }

  getAllAssessments() {
    const {
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedDifficulties,
      selectedGrades,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects,
      selectedStatuses
    } = this.state;

    this.setState({ isLoading: true });

    AssessmentService.getfilterdAllAssesmentsWithPaginationAndSorting(
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedGrades,
      selectedDifficulties,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects,
      selectedStatuses,
      sessionStorage.getItem("selectedTeacher") !== undefined ? sessionStorage.getItem("selectedTeacher") : null
    )
      .then((res) => {
        this.setState({
          AssessmentList: res.data.assessments,
          isLoading: false,
          totalAssessments: res.data.total_count,
        });
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Assessments. Please refresh the screen!",
          "error"
        );
        this.setState({ isLoading: false });
      });
  }

  handleViewClick = (e, paper) => {
    e.preventDefault();
    this.setState({
      selectedPaper: paper, 
      showDetailModal: true,
    });
  };

  toggleDetailModal = () => {
    this.setState((prevState) => ({
      showDetailModal: !prevState.showDetailModal,
    }));
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllAssessments();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllAssessments();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllAssessments();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.getAllAssessments();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleSortChange = (sortColumn) => {
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0,
        currentPage: 0,
        isLoading: true,
      },
      () => {
        this.getAllAssessments();
      }
    );
  };

  areAllAddedPapersSelected() {
    // Check if all "Added ✔" papers are selected
    return this.state.AssessmentList.every((assessment) =>
      this.state.addedPapers.includes(assessment.assessment_id)
    );
  }

  handleSelectAllAddedPapers() {
    // Select or deselect all "Added ✔" papers
    const selectedRows = new Set(this.state.selectedRows);
    if (this.areAllAddedPapersSelected()) {
      // Deselect all
      this.state.AssessmentList.filter((assessment) =>
        this.state.addedPapers.includes(assessment.assessment_id)
      ).forEach((assessment) => selectedRows.delete(assessment.assessment_id));
    } else {
      // Select all
      this.state.AssessmentList.filter((assessment) =>
        this.state.addedPapers.includes(assessment.assessment_id)
      ).forEach((assessment) => selectedRows.add(assessment.assessment_id));
    }

    this.setState({ selectedRows });
  }

  handleDelete = async() => {
    const selectedPaperIds = [...this.state.selectedRows];

    if (selectedPaperIds.length > 0) {
      // Check if all selected papers are marked as "Added ✔" before deleting
      const areAllSelectedAddedPapers = selectedPaperIds.every((paperId) =>
        this.state.addedPapers.includes(paperId)
      );

      if (areAllSelectedAddedPapers) {
        const nodeMid = this.props.state.mid;

        this.setState({ deletingPapersLoading: true });
        await PaperService.getPaper(nodeMid, selectedPaperIds).then((res) => {
          this.setState((prevState) => {
            const newDeleteIdsEnroll = res.data["paper_list"];
            const newDeleteIdsPapersa = res.data["papers"];

            const ar = [];
            const statusIds = [];
            if (!!newDeleteIdsEnroll.length) {
              newDeleteIdsPapersa.forEach((pa_paper) => {
                newDeleteIdsEnroll.forEach((en_paper) => {
                  if (pa_paper[0].assessment_id === en_paper[0].assessment_id) {
                    if (en_paper[0].paper_status === 1) {
                      statusIds.push(en_paper[0].assessment_id);
                      sweetStatusAlert(
                        "Can not remove the paper!",
                        "Paper has been already enrolled!",
                        "warning"
                      );
                      this.setState({
                        selectedRows: new Set(),
                      });
                    }
                  }
                  ar.push(pa_paper[0].assessment_id);
                });
              });
            } else {
              newDeleteIdsPapersa.forEach((pa_paper) => {
                ar.push(pa_paper[0].assessment_id);
              });
            }
            let uniqueArray = [...new Set(ar)];
            let uniqueStatusArray = [...new Set(statusIds)];
            const filteredArray = uniqueArray.filter(
              (item) => !uniqueStatusArray.includes(item)
            );
            if (!!filteredArray.length) {
              this.deletePapers(filteredArray, nodeMid);
            }
            this.setState({ deletingPapersLoading: false });
          });
        });
      } else {
        sweetStatusAlert(
          "Error!",
          "You can only delete papers that have been marked as 'Added ✔'.",
          "error"
        );
      }
    } else {
      sweetStatusAlert("Warning!", "Select papers to delete.", "warning");
    }
  }

  onFilterInput = (v, r) => {
    let categoryOptions;
    let languageOptions;
    let subjectOptions;
    let authorOptions;
    let gradeOptions;
    let yearOptions;
    let termOptions;
    let chapterOptions;
    let difficultyOptions;
    let assessmentStatusOptions;
    let selectedFilters = [];
    this.setState({
      filters: v,
      filterValues: [],
    });

    if (r === "removeOption") {
      this.setState(
        {
          selectedAuthors: null,
          selectedsubjects: null,
          selectedChapters: null,
          selectedTerms: null,
          selectedCategories: null,
          selectedYears: null,
          selectedLanguages: null,
          selectedDifficulties: null,
          selectedGrades: null,
          selectedStatuses: null
        },
        () => {
          this.getAllAssessments();
        }
      );
    }
    if (v.includes("Category")) {
      categoryOptions = this.state.allCategories?.map((val) => {
        return {
          filter: "Category",
          name: val.name,
          category_id: val.category_id,
        };
      });
      selectedFilters = [...selectedFilters, ...categoryOptions];
    }
    if (v.includes("Language")) {
      languageOptions = this.state.allLanguages?.map((val) => {
        return {
          filter: "Language",
          name: val.language,
          language_id: val.language_id,
        };
      });
      selectedFilters = [...selectedFilters, ...languageOptions];
    }
    if (v.includes("Subject")) {
      subjectOptions = this.state.allSubjects?.map((val) => {
        return {
          filter: "Subject",
          name: val.subject,
          subject_id: val.subject_id,
        };
      });
      selectedFilters = [...selectedFilters, ...subjectOptions];
    }
    if (v.includes("Author")) {
      authorOptions = this.state.allAuthors?.map((val) => {
        return {
          filter: "Author",
          name: val.author,
          author_id: val.author_id,
        };
      });
      selectedFilters = [...selectedFilters, ...authorOptions];
    }
    if (v.includes("Year")) {
      yearOptions = this.state.allYears?.map((val) => {
        return {
          filter: "Year",
          name: val.year,
          year_id: val.year_id,
        };
      });
      selectedFilters = [...selectedFilters, ...yearOptions];
    }
    if (v.includes("Difficulty")) {
      difficultyOptions = this.state.allDifficulties?.map((val) => {
        return {
          filter: "Difficulty",
          name: val.difficulty_type,
          difficulty_id: val.difficulty_id,
        };
      });
      selectedFilters = [...selectedFilters, ...difficultyOptions];
    }
    if (v.includes("Grade")) {
      gradeOptions = this.state.allGrades?.map((val) => {
        return {
          filter: "Grade",
          name: val.grade,
          grade_id: val.grade_id,
        };
      });
      selectedFilters = [...selectedFilters, ...gradeOptions];
    }
    if (v.includes("Term")) {
      termOptions = this.state.allTerms?.map((val) => {
        return {
          filter: "Term",
          name: val.term,
          term_id: val.term_id,
        };
      });
      selectedFilters = [...selectedFilters, ...termOptions];
    }
    if (v.includes("Chapter")) {
      chapterOptions = this.state.allChapters?.map((val) => {
        return {
          filter: "Chapter",
          name: val.chapter,
          chapter_id: val.chapter_id,
        };
      });
      selectedFilters = [...selectedFilters, ...chapterOptions];
    }
    if (v.includes("Status")) {
      assessmentStatusOptions = this.state.allAssessmentStatuses?.filter(status => status.status_id !== 1).filter(status => status.status_id !== 5).map((val) => {
        return {
          filter: "Status",
          name: val.status,
          status_id: val.status_id
        };
      });
      selectedFilters = [...selectedFilters, ...assessmentStatusOptions];
    }
    this.setState({
      selectedFilters: selectedFilters,
    });
  };
  onSelectFilter = (e) => {
    let selectedGrades = [];
    let selectedChapters = [];
    let selectedsubjects = [];
    let selectedAuthors = [];
    let selectedYears = [];
    let selectedCategories = [];
    let selectedTerms = [];
    let selectedDifficulties = [];
    let selectedLanguages = [];
    let selectedStatuses = [];

    e.map((val) => {
      if (val.filter === "Category") {
        selectedCategories.push(val?.category_id);
      }
      if (val.filter === "Difficulty") {
        selectedDifficulties.push(val?.difficulty_id);
      }
      if (val.filter === "Language") {
        selectedLanguages.push(val?.language_id);
      }
      if (val.filter === "Subject") {
        selectedsubjects.push(val?.subject_id);
      }
      if (val.filter === "Author") {
        selectedAuthors.push(val?.author_id);
      }
      if (val.filter === "Year") {
        selectedYears.push(val?.year_id);
      }
      if (val.filter === "Term") {
        selectedTerms.push(val?.term_id);
      }
      if (val.filter === "Chapter") {
        selectedChapters.push(val?.chapter_id);
      }
      if (val.filter === "Grade") {
        selectedGrades.push(val?.grade_id);
      }
      if (val.filter === "Status") {
        selectedStatuses.push(val?.status_id);
      }
    });

    this.setState(
      {
        filterValues: e,
        selectedAuthors: selectedAuthors.length > 0 ? selectedAuthors : null,
        selectedsubjects: selectedsubjects.length > 0 ? selectedsubjects : null,
        selectedChapters: selectedChapters.length > 0 ? selectedChapters : null,
        selectedTerms: selectedTerms.length > 0 ? selectedTerms : null,
        selectedCategories:
          selectedCategories.length > 0 ? selectedCategories : null,
        selectedYears: selectedYears.length > 0 ? selectedYears : null,
        selectedLanguages:
          selectedLanguages.length > 0 ? selectedLanguages : null,
        selectedDifficulties:
          selectedDifficulties.length > 0 ? selectedDifficulties : null,
        selectedGrades: selectedGrades.length > 0 ? selectedGrades : null,
        selectedStatuses: selectedStatuses.length > 0 ? selectedStatuses : null,
      },
      () => {
        this.getAllAssessments();
      }
    );
  };

  fetchAllTeachers = async () => {
    const { mid, lg_user_id, lg_user_table_id, form_list_id } =
      this.props.state;

    this.setState({ isTeacherListLoading: true });
    await MarksheetService.getAllEnrolledStudents(
      mid,
      form_list_id,
      lg_user_id,
      lg_user_table_id,
      5000,
      0,
      "user_code",
      "desc",
      this.state.teacherSearchTerm
    )
      .then((response) => {
        if (response.success) {
          this.setState({ Teacherlist: response.data.users });
          this.setState({ isTeacherListLoading: false });
        } else {
          sweetStatusAlert("Failed!", response.message.error, "error");
          this.setState({ isTeacherListLoading: false });
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching teachers. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleTeacherSearch = (event) => {
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;
    if (event === "" || regex.test(event)) {
      let num = event;
      this.setState(
        {
          teacherSearchTerm: num,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleTeacherSearch();
        }
      );
    } else {
      this.setState({ teacherSearchTerm: event, searchError: true });
    }
  };
  render() {
    const {
      AssessmentList,
      addedPapers,
      currentPage,
      totalAssessments,
      limit,
      addVisibility,
      deleteVisibility,
      addingExamsLoading,
      deletingPapersLoading,
      Teacherlist,
      isTeacherListLoading,
      offset,
      addPaper,
      deletePaper,
      selectedTeacher
    } = this.state;
    const path = history.location.pathname;

    return (
      <>
        <div style={{ overflow: "auto", height: "90vh" }}>
          <ContentWrapper disabled={path === "/manage-search"}>
            <Card>
              <CardHeader>
                <div className="col-md-12 px-0 d-flex justify-content-between">
                  <div className="row d-flex" style={{ width: "100%" }}>
                    <div className="col-md-2 col-12 d-flex align-items-center px-0">
                      <div className="w-100 mr-1 py-1">
                        <ReactSelect
                          placeholder="Select Teacher"
                          styles={{
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          options={Teacherlist.map((teacher) => ({
                            value: teacher.user_id,
                            label: `${teacher.data_concat}`,
                          }))}
                          value={this.state.selectedOption}
                          isLoading={isTeacherListLoading}
                          onChange={(selectedOption) => {
                            const selectedValue = selectedOption?.value;

                            if (!selectedValue) {
                              this.setState({
                                selectedTeacher: "",
                                selectedOption: "",
                              });
                              sessionStorage.setItem("selectedTeacher", null);
                              this.getAllAssessments();
                            }
                            let teacher = this.state.Teacherlist.find(
                              (teacher) => teacher.user_id === selectedValue
                            );

                            this.setState({
                              isTableDataLoading: true,
                              selectedOption: selectedOption,
                            });

                            if (teacher) {
                              const selectedTeacher = teacher.user_id;
                              if (selectedTeacher) {
                                this.setState({ selectedTeacher }, () => {
                                  sessionStorage.setItem("selectedTeacher", selectedTeacher);
                                  this.getAllAssessments();
                                });
                              }
                            }
                          }}
                          onInputChange={(inputValue) => {
                            if (inputValue) {
                              this.handleTeacherSearch(inputValue);
                            }
                          }}
                          isClearable={true}
                        />
                      </div>
                    </div>
                    {selectedTeacher !== "" && 
                      <div className="col-md-9 col-12 px-0">
                        <div className="row w-100">
                          <Autocomplete
                            multiple
                            size="small"
                            id="checkboxes-filter"
                            options={PAPER_FILTERS}
                            disableCloseOnSelect
                            getOptionLabel={(option) => (
                              <li
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  listStyle: "none",
                                }}
                              >
                                {option}
                              </li>
                            )}
                            onChange={(e, v, r) => this.onFilterInput(v, r)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} style={{ fontFamily: "Poppins" }}>
                                <Checkbox
                                  style={{ marginRight: 8, color: "#5458AF" }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            className="col-lg-6 col-md-6 col-12 px-0 py-2"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Filter"
                                className="col-md-12"
                              />
                            )}
                          />
                          {this.state.filters.length > 0 && (
                            <Autocomplete
                              multiple
                              size="small"
                              id="grouped-demo"
                              className="col-lg-6 col-md-6 col-12 px-md-1 px-sm-0 py-2"
                              options={this.state.selectedFilters}
                              groupBy={(option) => option.filter}
                              onChange={(e, v) => this.onSelectFilter(v)}
                              getOptionLabel={(option) => (
                                <li
                                  style={{
                                    fontFamily: "Poppins, sans-serif",
                                    listStyle: "none",
                                  }}
                                >
                                  {option.name}
                                </li>
                              )}
                              sx={{
                                width: 300,
                                fontFamily: "Poppins, sans-serif",
                              }}
                              value={this.state.filterValues}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Filters"
                                  className="col-md-12"
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </CardHeader>
              {selectedTeacher !== "" && (
              <StickyCardBody>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4 px-0">
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mb-2"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        onChange={this.handleSearch}
                        isInvalid={this.state.searchError}
                        className="col-md-12 col-12"
                      />
                      {this.state.searchError && (
                        <Form.Control.Feedback type="invalid">
                          Invalid input
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-md-8 px-0">
                    <div className="w-100">
                      <div className="col-md-12 px-0 d-flex justify-content-end">
                        {addVisibility &&
                          this.state.selectedRows.size > 0 &&
                          deletePaper && (
                            <Button
                              className="insert-btn btn-primary text-nowrap"
                              variant="contained"
                              onClick={this.handleAddAllExams}
                              style={{ height: "44px" }}
                            >
                              {addingExamsLoading ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                "Add Selected Papers"
                              )}
                            </Button>
                          )}
                        {deleteVisibility &&
                          this.state.selectedRows.size > 0 &&
                          addPaper && (
                            <Button
                              className="insert-btn btn-danger text-nowrap ml-2"
                              variant="contained"
                              onClick={() => this.handleDelete()}
                              style={{ height: "44px" }}
                            >
                              {deletingPapersLoading ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                "Remove Selected Papers"
                              )}
                            </Button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </StickyCardBody>
              )}
              {selectedTeacher !== "" && (
              <CardBody>
                {(addingExamsLoading || this.state.isLoading || deletingPapersLoading) ? (
                  <PageSpinner id="show" text="Loading, Please wait!" />
                ) : (
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            className="thead"
                            onClick={() => this.handleSortChange("title")}
                          >
                            Paper Title
                            {this.state.sortColumn === "title" && (
                              <span>
                                {this.state.sortDirection === "asc" ? (
                                  <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                                ) : (
                                  <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                                )}
                              </span>
                            )}
                          </TableCell>
                          <TableCell className="thead">
                            Attempts Allowed
                          </TableCell>
                          <TableCell className="thead">
                            More Details
                          </TableCell>
                          {/* <TableCell className="thead">Created At</TableCell> */}
                          {addVisibility && (
                            <TableCell className="thead">
                              Select to Add Papers
                            </TableCell>
                          )}
                          {deleteVisibility && (
                            <TableCell className="thead">
                              Remove Added Papers
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {AssessmentList?.map((assessment) => (
                          <TableRow key={assessment.assessment_id}>
                            <TableCell className="tData">
                              {assessment.title}
                            </TableCell>
                            <TableCell className="tData">
                              {assessment.no_of_attempts}
                            </TableCell>
                            <TableCell className="tData">
                              <Button
                                onClick={(e) => this.handleViewClick(e, assessment)}
                                className="btn btn-icon btn-primary btn-sm m-1 view-assement-btn flaticon-eye-icon"
                                title="View Paper Details"
                              />
                            </TableCell>
                            {/* <TableCell className="tData">
                              {new Date(
                                assessment.created_at
                              ).toLocaleDateString()}
                            </TableCell> */}
                            {addVisibility && (
                              <TableCell className="tData" disabled={addPaper}>
                                {addedPapers.includes(
                                  assessment.assessment_id
                                ) ? (
                                  <span className="stu-variant-class label label-lg label-light-success label-inline text-nowrap">
                                    Added ✔
                                  </span>
                                ) : (
                                  <Checkbox
                                    checked={this.state.selectedRows.has(
                                      assessment.assessment_id
                                    )}
                                    onChange={() =>
                                      this.handleCheckboxChange(
                                        assessment.assessment_id
                                      )
                                    }
                                    style={{
                                      color: addPaper ? "#E6E6E6" : "#5458AF",
                                    }}
                                    disabled={addPaper}
                                  />
                                )}
                              </TableCell>
                            )}
                            {deleteVisibility && (
                              <TableCell className="tData">
                                {addedPapers.includes(
                                  assessment.assessment_id
                                ) && (
                                  <Checkbox
                                    checked={this.state.selectedRows.has(
                                      assessment.assessment_id
                                    )}
                                    onChange={() =>
                                      this.handleCheckboxChange(
                                        assessment.assessment_id
                                      )
                                    }
                                    style={{
                                      color: deletePaper
                                        ? "#E6E6E6"
                                        : "#5458AF",
                                    }}
                                    disabled={deletePaper}
                                  />
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <MaterialCustomTablePagination
                      totalCount={parseInt(totalAssessments)}
                      currentPage={currentPage}
                      limit={limit}
                      handlePageChange={this.handlePageChange}
                      handleRowsPerPageChange={this.handleRowsPerPageChange}
                      handlePageOffsetChange={this.handlePageOffsetChange}
                      rowsPerPageOptions={[5, 10, 20]}
                      offset={offset}
                    />
                  </div>
                )}
              </CardBody>
              )}
            </Card>
          </ContentWrapper>
          <Modal 
            size="lg"
            show={this.state.showDetailModal}
            onHide={this.toggleDetailModal}
          >
          <Modal.Header>
            <h5 className="modal-title">{this.state.selectedPaper?.title}</h5>
            <Button variant="secondary" onClick={this.toggleDetailModal}>
              Close
            </Button>
          </Modal.Header>
          {this.state.selectedPaper && (
            <Modal.Body>
              <div className="form" id="kt_form">
                {this.state.selectedPaper?.grade_id !== null && 
                  <>
                    <div className="row">
                      <h6 className="font-weight-bolder mb-3 col-md-4">
                        Grade :
                      </h6>
                      <div className="text-dark-50 line-height-lg col-md-5">
                        <div className="inline-flex full-width align-center">
                          {this.getPaperGrade(this.state.selectedPaper?.grade_id)}
                        </div>
                      </div>
                    </div>
                  </>
                }
                {this.state.selectedPaper?.category_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Category :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperCategory(this.state.selectedPaper?.category_id)}
                      </div>
                    </div>
                  </div>
                </>
              }     
              {this.state.selectedPaper?.language_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Language:
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperLanguage(this.state.selectedPaper?.language_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.difficulty_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Difficulty :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperDifficulty(this.state.selectedPaper?.difficulty_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.subject_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Subject :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperSubject(this.state.selectedPaper?.subject_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.chapter_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Chapter/Module/Unit :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperChapter(this.state.selectedPaper?.chapter_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.author_id !== null &&
                <>
                  <div className="separator separator-dashed my-5"></div> 
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Author :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperAuthor(this.state.selectedPaper?.author_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.year_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Year :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperYear(this.state.selectedPaper?.year_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.term_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Term/Semester :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperTerm(this.state.selectedPaper?.term_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.status_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Status :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        <div className={this.getColor(this.state.selectedPaper?.assessment_state)}>
                          {this.getPaperStatus(this.state.selectedPaper?.status_id)}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.first_name !== null && this.state.selectedPaper?.last_name !== null &&
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Created By :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.state.selectedPaper?.first_name + " " + this.state.selectedPaper?.last_name}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.created_at !== null &&
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Created At :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getCreatedAtLocal(this.state.selectedPaper?.created_at)}
                      </div>
                    </div>
                  </div>
                </>
              }
              </div>
            </Modal.Body>
          )}
        </Modal>
        </div>
      </>
    );
  }
}
