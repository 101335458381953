import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import Card from "../../../components/common/Card.js";
import CardHeader from "../../../components/common/CardHeader.js";
import CardBody from "../../../components/common/CardBody.js";
import CardTitle from "../../../components/common/CardTitle.js";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { StudentAddressTypeService } from "../../../service/settings/manage/student_address_type.service.js";
import { StudentAddressService } from "../../../service/manage/registration/student_address.service.js";
import { CountryService } from "../../../service/settings/manage/country.service.js";
import { ProvinceService } from "../../../service/settings/manage/province.service.js";
import { CityService } from "../../../service/settings/manage/city.service.js";
import { GenderService } from "../../../service/settings/manage/gender.service.js";
import { IdTypeService } from "../../../service/settings/manage/id_type.service.js";
import { StatusTypesService } from "../../../service/settings/manage/status_type.service.js";
import { StatusService } from "../../../service/settings/manage/status.service.js";
import Spinner from "react-bootstrap/Spinner";
import "../../../components/admin/css/common.css";
import { history } from "../../../helper/history.js";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import debounce from "lodash.debounce";
import sweetAlert, {
  sweetConfirmAlert,
  sweetStatusAlert,
} from "../../../components/common/SweetAlert.js";
import { StudentService } from "../../../service/manage/registration/student.service.js";
import { UserManagementService } from "../../../service/user_management/user_management.service.js";
import SimpleReactValidator from "simple-react-validator";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import Tooltip from "@mui/material/Tooltip";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { GradeService } from "../../../service/settings/manage/grade.service.js";
import AddStudentModal from "../dashboard/AddStudentModal";
import { customerConstants } from "../../../helper/constants.js";
import config from "../../../helper/config.js";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DatePicker from "react-datepicker";
import QRgenerator from "../attendance/QRgenerator.js";
import StudentsStatModel from "../dashboard/StudentStatModel.js";
import { toInteger } from 'lodash';
import { assessmentService } from '../../../service/assessment/assessmentService.js';


const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

const customer = getCustomerFeatureFlag();
let isEdfoci, addBtnStyle, closeBtnStyle;
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    isEdfoci = false;
    break;
  case customerConstants.EXO_CUSTOMER2:
    isEdfoci = false;
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    isEdfoci = true;
    break;
}

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    "& .MuiSpeedDial-root": {
      marginLeft: "0% !important", // Override with !important
    },
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
    "& .MuiSpeedDial-root": {
      marginLeft: "0% !important", // Override with !important
    },
  },
}));

export default class ManageStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      students: [],
      first_name: "",
      last_name: "",
      idValue: null,
      idTypes: [],
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      qrmodal: false,
      created_on: "",
      created_by: "",
      genders: [],
      titles: [],
      countries: [],
      stu_statuses: [],
      statusTypes: [],
      StuStatustype: "Availability",
      showInsertModal: false,
      studentAddressTypes: [],
      studentAddresses: [],
      isLoading: false,
      isTableDataLoading: true,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      totalStudents: 10,
      currentPage: 0,
      isModified: false,
      isAddressesLoading: false,
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      sortColumn: "user_code",
      sortDirection: "desc",
      errorProvince: [],
      errorCity: [],
      addressValidity: [],
      hidden: false,
      studentAddressesAdd: [],
      node_mid: null,
      user_list_table_id: null,
      session_stu_id: null,
      grade: null,
      medium:null,
      school: "",
      main_table_id: null,
      breadcrumb2: null,
      nodeTitle: null,
      user_code: "",
      grades: [],
      selectedStudent: [],
      isAddStudentModalOpen: false,
      is_lg_user: false,
      studentStatsModal: false,
      selectedStudentStat: null,
      grades_with_limit:[],
      mediums:[]
    };

    //Initiating the ref object for each fields on the forms of both modals
    this.firsNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.phoneNumberRef = React.createRef();
    this.dobRef = React.createRef();
    this.dobWrapRef = React.createRef();
    this.genderIdRef = React.createRef();
    this.idTypeIdRef = React.createRef();
    this.createdByRef = React.createRef();
    this.statusIdRef = React.createRef();
    this.statusIdRef = React.createRef();
    this.addressesRefs = [];
    this.handleValidityFocus = this.handleValidityFocus.bind(this);

    // Debounced version of handleSearch function
    this.debouncedHandleSearch = debounce(this.fetchAllStudents, 2000);

    this.createValidationsInstance();
    this.formInitialState = {
      first_name: "",
      last_name: "",
      idValue: null,
      idType_id: "",
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      created_on: "",
      created_by: "",
      postalcode: "",
    };
  }

  toggleStudentStatsModal = (student) => {
    this.setState((prevState) => {
      return {
        selectedStudentStat: student,
        studentStatsModal: !prevState.studentStatsModal,
      };
    });
  };

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        address: {
          message: "The :attribute must be a valid address",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z-/:0-9,\s]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
        },
        dateBefore: {
          message: "The :attribute must be before current date",
          rule: (val, params, validator) => {
            const selectedDate = new Date(val);
            const currentDate = new Date();
            return selectedDate < currentDate;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        phone_number: {
          message: "The :attribute must be a valid phone number",
          rule: (val, params, validator) => {
            const isTenDigitsStartingWithZero =
              val.length === 10 && val.startsWith("0");
            const isNineDigits = val.length === 9 && !val.startsWith("0");
            return isTenDigitsStartingWithZero || isNineDigits;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
      },
    });
    this.updateValidator = new SimpleReactValidator();
  }

  async getAllStudentAddressTypes() {
    this.setState({ isAddressesLoading: true });
    this.addressesRefs = [];
    let types;
    try {
      const res = await StudentAddressTypeService.getAllWithoutPagination();
      types = res.data;
    } catch (error) {
      types = [];
    }
    const addressValidity = [];
    types.forEach((type) => {
      addressValidity.push(false);
      this.addressesRefs.push({
        country_id: React.createRef(),
        province_id: React.createRef(),
        city_id: React.createRef(),
        address_1: React.createRef(),
        address_2: React.createRef(),
        postalcode: React.createRef(),
      });
    });

    const studentAddresses = this.state.studentAddresses;
    this.setState(
      (prevState) => ({
        studentAddressTypes: types,
        addressValidity: addressValidity,
        studentAddresses: types.map((type, index) => {
          let obj = {
            ...type,
            ...studentAddresses[index],
            visibility: true,
          };
          delete obj["display_order"];
          return obj;
        }),
      }),
      () => {
        const { studentAddresses } = this.state;

        const studentAddressVisibility = studentAddresses.map((item, index) => {
          if (index > 0) {
            item.visibility = false;
          }
          return item;
        });
        this.setState({ studentAddressesAdd: studentAddressVisibility });

        const studentAddressUpdateVisibility = studentAddresses.map(
          (item, index) => {
            if (index > 0) {
              if (
                !item.country_id ||
                item.country_id === null ||
                item.country_id === undefined
              ) {
                item.visibility = false;
              } else {
                item.visibility = true;
              }
            }
            return item;
          }
        );
        this.setState({ studentAddresses: studentAddressUpdateVisibility });
      }
    );

    this.setState({ isAddressesLoading: false }); // Set isLoading to false after data is fetched
  }

  componentDidMount() {
    this.loadInitialData();
    this.genarateStudentIdPrefix();
  }

  genarateStudentIdPrefix() {
    const id_prefix = this.props.state?.mcode;
    const id_prefix_year = String(new Date().getFullYear() % 100);

    const user_base_prefix = id_prefix + id_prefix_year + "S";

    this.setState({ user_code: user_base_prefix });
  }

  loadInitialData() {
    let privilege_edit = !!this.props.state.privilege_edit;
    let privilege_add = !!this.props.state.privilege_add;
    let privilege_delete = !!this.props.state.privilege_delete;
    this.setState(
      {
        addVisibility: privilege_add,
        editVisibility: privilege_edit,
        deleteVisibility: privilege_delete,
        user_list_table_id: sessionStorage.getItem("table_log_id"),
        session_stu_id: sessionStorage.getItem("manage_user_id"),
        main_table_id: this.props.state.main_table_id,
        breadcrumb2: this.props.state.breadcrumb2,
        nodeTitle: this.props.state.nodeTitle,
      },
      () => {
        this.fetchAllStudents();
        this.getAllGenders();
        this.getAllIdTypes();
        this.getAllStatusTypes();
        this.getAllContries();
        this.getAllStudentAddressTypes();
      }
    );
  }

  getAllContries() {
    CountryService.getAll().then((res) => {
      this.setState({ countries: res.data });
    });
  }

  getAllGenders() {
    GenderService.getAllGenders().then((res) => {
      this.setState({ genders: res.data });
    });
  }

  getAllIdTypes() {
    IdTypeService.getAllIdTypes().then((res) => {
      this.setState({ idTypes: res.data});
    });
  }

  getAllStatusTypes() {
    StatusTypesService.getAll().then((res) => {
      this.setState({ statusTypes: res.data }, () => {
        this.getAllStatuses();
      });
    });
  }

  getAllStatuses() {
    const stu_type_id = this.getRespectiveStatusTypeID(
      this.state.StuStatustype
    );
    StatusService.getByTypeId(stu_type_id).then((res) => {
      this.setState({ stu_statuses: res.data });
    });
  }

  fetchAllStudents = () => {
    const {
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      user_list_table_id,
      session_stu_id,
    } = this.state;
    const {
      mid,
      owner_restriction,
      lg_user_id,
      lg_user_table_id,
      user_list_restriction,
    } = this.props.state;
    let create_user_id =
      !!owner_restriction && lg_user_id ? lg_user_id : "null";
    let create_user_table_id =
      !!owner_restriction && lg_user_table_id ? lg_user_table_id : "null";
    let user_table_id =
      !!user_list_restriction && user_list_table_id
        ? user_list_table_id
        : "null";
    let stu_id =
      !!user_list_restriction && session_stu_id ? session_stu_id : "null";

    StudentService.getAllStuRegWithPaginationAndSorting(
      stu_id,
      user_table_id,
      create_user_id,
      create_user_table_id,
      mid,
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term
    )
      .then((response) => {
        if (response.success) {
          if (this.props.state.path === "profile") {
            this.setState(
              {
                students: response?.data?.students?.filter(
                  (std) => std.is_lg_user
                ),
              },
              () => {
                this.toggleUpdateStudentModal(this.state.students[0]);
              }
            );
          } else {
            this.setState({
              students: response.data.students,
              totalStudents: response.data.count,
            });
          }
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        sweetStatusAlert(
          "Failed!",
          "Error fetching students Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllStudents();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllStudents();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllStudents();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleSortChange = (sortColumn) => {
    // If the same column is clicked, toggle the sort direction
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.fetchAllStudents();
      }
    );
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(this.formInitialState);
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      this.setState(this.formInitialState);
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  handleDatePickerChange = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = ("0" + dateObject.getDate()).slice(-2);

    const formattedDateString = `${year}-${month}-${day}`;
    this.setState(() => ({ dob: formattedDateString, isModified: true }));
  };

  handleChangeAddress = async (index, event) => {
    const { name, value } = event.target;
    const { studentAddresses } = this.state;

    const updatedAddresses = [...studentAddresses];
    if (name.includes("country_id")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        country_id: value,
      };
    }
    if (name.includes("province_id")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        province_id: value,
      };
    }
    if (name.includes("city_id")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        city_id: value,
      };
    }
    if (name.includes("address_1")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        address_1: value,
      };
    }
    if (name.includes("address_2")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        address_2: value,
      };
    }
    if (name.includes("postalcode")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        postalcode: value,
      };
    }
    this.setState({ studentAddresses: updatedAddresses });

    if (name.includes("country_id")) {
      const country_id = value;
      updatedAddresses[index].provinces = [];
      updatedAddresses[index].cities = [];
      const provinces = await this.getProvincesForCountry(country_id);
      if (provinces) {
        updatedAddresses[index].provinces = provinces;
      } else {
        updatedAddresses[index].province_id = null;
        updatedAddresses[index].city_id = null;
      }
    }

    if (name.includes("province_id")) {
      const province_id = value;
      updatedAddresses[index].cities = [];
      const cities = await this.getCitiesForProvince(province_id);
      if (cities) {
        updatedAddresses[index].cities = cities;
      } else {
        updatedAddresses[index].city_id = null;
      }
    }

    this.setState({ studentAddresses: updatedAddresses }, () => {
      this.handleAddressFeildValidity();
    });
  };

  getProvincesForCountry = async (country_id, is_initial = false) => {
    if (country_id && country_id !== "Select") {
      const response = await ProvinceService.getProvinceForCountry(country_id);
      if (response.success) {
        return response.data;
      } else {
        if (
          Object.keys(response.data).length === 0 &&
          response.success === false &&
          response.message === "Provinces/District not found" &&
          is_initial === true
        ) {
          return undefined;
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      }
    } else {
      return null;
    }
  };

  getCitiesForProvince = async (province_id, is_initial = false) => {
    if (province_id && province_id !== "Select") {
      const response = await CityService.getCitiesForProvince(province_id);
      if (response.success) {
        return response.data;
      } else {
        if (
          Object.keys(response.data).length === 0 &&
          response.success === false &&
          response.message === "Cities not found" &&
          is_initial === true
        ) {
          return undefined;
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      }
    } else {
      return null;
    }
  };

  handleUpdatedAddress = async (index, name, value) => {
    const { studentAddresses } = this.state;
    const updatedAddresses = [...studentAddresses];

    if (name === "country_id") {
      const country_id = value;
      if (updatedAddresses[index]) {
        updatedAddresses[index].provinces = [];
        updatedAddresses[index].cities = [];
        try {
          const provinces = await this.getProvincesForCountry(country_id, true);
          if (provinces) {
            updatedAddresses[index].provinces = provinces;
          } else {
            updatedAddresses[index].province_id = null;
            updatedAddresses[index].city_id = null;
          }
        } catch (error) {
          this.setApiErrorArray(country_id, index, "province");
        }
        this.setState({ studentAddresses: updatedAddresses });
      }
    }

    if (name === "province_id") {
      const province_id = value;
      if (updatedAddresses[index]) {
        updatedAddresses[index].cities = [];
        try {
          const cities = await this.getCitiesForProvince(province_id, true);
          if (cities) {
            updatedAddresses[index].cities = cities;
          } else {
            updatedAddresses[index].city_id = null;
          }
        } catch (error) {
          this.setApiErrorArray(province_id, index, "city");
        }
        this.setState({ studentAddresses: updatedAddresses });
      }
    }
  };

  // Re fetch the necessary data based on the error array of city and province
  reFetchErrorApi = async () => {
    let countryPromises, provincePromise;
    if (this.state.errorProvince.length > 0) {
      countryPromises = this.state.errorProvince.map(
        async ({ country_id, index }) => {
          return await this.handleUpdatedAddress(
            index,
            "country_id",
            country_id
          );
        }
      );
    }
    if (this.state.errorCity.length > 0) {
      provincePromise = this.state.errorCity.map(
        async ({ province_id, index }) => {
          return await this.handleUpdatedAddress(
            index,
            "province_id",
            province_id
          );
        }
      );
    }
    await Promise.all([countryPromises, provincePromise]);
  };

  getStudentAddressById = async (student_id) => {
    this.setState({
      isAddressesLoading: true,
      errorCity: [],
      errorProvince: [],
    });

    const addresses = await StudentAddressService.getStudentAddressById(
      student_id
    );
    this.setState({ studentAddresses: addresses.data });

    await this.getAllStudentAddressTypes();

    const promises =
      addresses.data &&
      addresses.data.length > 0 &&
      addresses.data.map(async (address, index) => {
        const countryPromise = await this.handleUpdatedAddress(
          index,
          "country_id",
          address.country_id
        );
        const provincePromise = await this.handleUpdatedAddress(
          index,
          "province_id",
          address.province_id
        );
        return await Promise.all([countryPromise, provincePromise]);
      });

    if (promises) {
      await Promise.all(promises);
    }

    this.reFetchErrorApi();

    this.setState({ isAddressesLoading: false });
  };

  //Setting the error array of city and province state based on the api calls made on the getAllStudentAddressById function
  setApiErrorArray = (id, index, type) => {
    if (type === "province") {
      if (
        !this.state.errorProvince.includes({ country_id: id, index: index })
      ) {
        this.setState((prev) => ({
          errorProvince: [
            ...prev.errorProvince,
            { country_id: id, index: index },
          ],
        }));
      }
    } else if (type === "city") {
      if (!this.state.errorCity.includes({ province_id: id, index: index })) {
        this.setState((prev) => ({
          errorCity: [...prev.errorCity, { province_id: id, index: index }],
        }));
      }
    }
  };

  toggleUpdateStudentModal = (student) => {
    this.setState(
      (prevState) => ({
        showModal: !prevState.showModal,
        first_name: "",
        last_name: "",
        idValue: null,
        idType_id: "",
        dob: "",
        email: "",
        phone_number: "",
        gender_id: "",
        status_id: "",
        created_on: "",
        created_by: "",
        school: null,
        grade: null,
        medium:null,
        user_code: "",
        is_lg_user: false,
      }),
      async () => {
        // State has changed, perform your logic here based on the state change
        if (this.state.showModal) {
          this.setState({
            first_name: student?.first_name,
            last_name: student?.last_name,
            idValue: student?.idValue ? student.idValue : null,
            idType_id: student?.idType ? student.idType : "",
            email: student?.email,
            phone_number: student?.phone_number
              ? student?.phone_number.toString()
              : student?.phone_number,
            dob: student?.dob ? student.dob : "",
            gender_id: student?.gender_id ? student.gender_id : "",
            school: !!student?.school ? student.school : null,
            grade: !!student?.grade ? student.grade : null,
            medium: !!student?.medium ? student.medium : null,
            created_on: student?.created_on ? student.created_on : "",
            created_by: student?.created_by ? student.created_by : "",
            status_id: student?.user_status_id ? student.user_status_id : "",
            stu_id: student?.stu_id,
            studentAddresses: [],
            node_mid: student?.node_mid,
            user_code: student?.user_code,
            is_lg_user: student?.is_lg_user,
          });
          this.getAllGradesWithLimit()
          this.getMedium()
          await this.getStudentAddressById(student?.stu_id);
        }
      }
    );
  };

  //Handle Edit Submit
  handleEditSubmit = async (event) => {
    event.preventDefault();
    this.handleAddressFeildValidity();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.handleValidityFocus("update");
      this.forceUpdate();
    } else {
      try {
        this.setState({ isLoading: true });
        const {
          stu_id,
          first_name,
          last_name,
          idValue,
          idType_id,
          email,
          phone_number,
          dob,
          gender_id,
          created_on,
          created_by,
          status_id,
          studentAddresses,
          node_mid,
          school,
          grade,
          medium,
          main_table_id,
          user_code,
          is_lg_user,
        } = this.state;

        const studentItem = {
          first_name: first_name,
          last_name: last_name,
          idValue: idValue,
          idType: parseInt(idType_id),
          email: email,
          phone_number: parseInt(phone_number),
          dob: dob === "" ? null : dob,
          gender_id: parseInt(gender_id),
          school: school,
          grade: grade,
          medium:medium,
          created_on: created_on === "" ? null : created_on,
          created_by: created_by,
          user_status_id: parseInt(status_id),
          node_mid: node_mid,
          user_code: user_code,
        };
        const studentId = stu_id;
        const studentResponse = await StudentService.update(
          JSON.stringify(studentItem),
          studentId
        );

        if (studentResponse.success) {
          if (!!is_lg_user) {
            this.props.setUserName(first_name, last_name);
          }
          const filteredAddressesWithStudentId = studentAddresses.map(
            (address) => {
              const {
                name,
                description,
                cities,
                provinces,
                country_id,
                province_id,
                city_id,
                visibility,
                ...rest
              } = address; // Destructure the name and description fields from the address object
              return {
                ...rest, // Spread the rest of the address fields
                student_id: studentId, // Add the student_id field
                country_id: parseInt(country_id), // Convert the country_id to an integer
                province_id: country_id ? parseInt(province_id) : null,
                city_id: province_id ? parseInt(city_id) : null,
                status: "active",
                student_address_type_id: address.student_address_type_id, // Add the student_address_type_id field
              };
            }
          );

          if (filteredAddressesWithStudentId.length > 0) {
            await StudentAddressService.update(
              JSON.stringify(filteredAddressesWithStudentId)
            );
          }

          this.setState({ isLoading: false, showModal: false });
          sweetStatusAlert(
            "Success!",
            "Student information updated successfully..!",
            "success"
          );

          this.fetchAllStudents();
          this.validator.purgeFields();
          this.validator.hideMessages();
          this.setState(this.formInitialState);

          const studentUserItem = { user_email: email };
          await UserManagementService.updateByStuId(
            JSON.stringify(studentUserItem),
            studentId,
            main_table_id
          );
        } else {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", studentResponse.message, "error");
        }
      } catch (e) {
        this.setState({ isLoading: false });
        sweetStatusAlert(
          "Failed!",
          "Failed to update the student info!",
          "error"
        );
      }
    }
  };

  toggleAddStudentModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        first_name: "",
        last_name: "",
        dob: "",
        email: "",
        phone_number: "",
        gender_id: "",
        status_id: "",
        created_on: "",
        created_by: "",
        studentAddresses: [],
        grade: null,
        medium:null,
        school: "",
      }),
      () => {
        // State has changed, perform your logic here based on the state change
        if (this.state.showInsertModal) {
          this.getAllGradesWithLimit()
          this.getMedium()
          this.getAllStudentAddressTypes();
        }
      }
    );
  };

  handleValidityFocus = (submission_type) => {
    if (!this.validator.fieldValid("first_name")) {
      this.firsNameRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("last_name")) {
      this.lastNameRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("email")) {
      this.emailRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("phone_number")) {
      this.phoneNumberRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("dob")) {
      this.dobRef.current.setFocus();
      this.dobWrapRef.current.scrollIntoView();
      return;
    } else if (!this.validator.fieldValid("gender_id")) {
      this.genderIdRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("idType_id")) {
      this.idTypeIdRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("created_by")) {
      this.createdByRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("status_id")) {
      this.statusIdRef.current.focus();
      return;
    }

    for (const [index, address] of this.addressesRefs.entries()) {
      if (
        !this.validator.fieldValid(`${submission_type}_country_id${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.country_id.current.focus();
        break;
      } else if (
        !this.validator.fieldValid(`${submission_type}_province_id${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.province_id.current.focus();
        break;
      } else if (
        !this.validator.fieldValid(`${submission_type}_city_id${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.city_id.current.focus();
        break;
      } else if (
        !this.validator.fieldValid(`${submission_type}_address_1${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.address_1.current.focus();
        break;
      } else if (
        !this.validator.fieldValid(`${submission_type}_address_2${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.address_2.current.focus();
        break;
      } else if (
        !this.validator.fieldValid(`${submission_type}_postalcode${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.postalcode.current.focus();
        break;
      }
    }
  };

  handleAddressFeildValidity = () => {
    this.state.studentAddresses.forEach((address, index) => {
      if (
        address.country_id ||
        address.province_id ||
        address.city_id ||
        address.address_1 ||
        address.address_2 ||
        address.postalcode
      ) {
        this.setState((prev) => {
          const updatedAddressValidity = [...prev.addressValidity];
          updatedAddressValidity[index] = true;
          return {
            addressValidity: updatedAddressValidity,
          };
        });
      } else {
        this.setState((prev) => {
          const updatedAddressValidity = [...prev.addressValidity];
          updatedAddressValidity[index] = false;
          return {
            addressValidity: updatedAddressValidity,
          };
        });
      }
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.handleAddressFeildValidity();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.handleValidityFocus("add");
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      try {
        const {
          first_name,
          last_name,
          email,
          phone_number,
          dob,
          grade,
          medium,
          school,
          gender_id,
          created_on,
          created_by,
          status_id,
          studentAddresses,
          search_term,
          session_stu_id,
          user_list_table_id,
          main_table_id,
          user_code,
        } = this.state;

        const newStudent = {
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone_number: parseInt(phone_number),
          dob: dob,
          gender_id: parseInt(gender_id),
          created_on: created_on === "" ? null : created_on,
          created_by: created_by,
          user_status_id: parseInt(status_id),
          node_mid: !!this.props.state.mid ? this.props.state.mid : null,
          create_user_id: session_stu_id,
          create_user_table_id: user_list_table_id,
          user_table_id: main_table_id,
          grade: grade,
          medium:medium,
          school: school,
          user_code: user_code,
        };
        const studentResponse = await StudentService.create(
          JSON.stringify(newStudent)
        );
        if (studentResponse.success) {
          const studentId = studentResponse.data.stu_id;
          const studentEmail = studentResponse.data.email;
          const filteredAddressesWithStudentId = studentAddresses.map(
            (address) => {
              const {
                name,
                description,
                cities,
                provinces,
                country_id,
                province_id,
                city_id,
                visibility,
                ...rest
              } = address; // Destructure the name and description fields from the address object
              return {
                ...rest, // Spread the rest of the address fields
                student_id: studentId, // Add the student_id field
                country_id: parseInt(country_id), // Convert the country_id to an integer
                province_id: parseInt(province_id),
                city_id: parseInt(city_id),
                status: "active",
              };
            }
          );

          if (filteredAddressesWithStudentId.length > 0) {
            await StudentAddressService.create(
              JSON.stringify(filteredAddressesWithStudentId)
            );
          }
          this.setState({ isLoading: false, showInsertModal: false });
          sweetStatusAlert(
            "Success!",
            "New student added successfully!",
            "success"
          );
          this.validator.purgeFields();
          this.validator.hideMessages();
          this.setState(this.formInitialState);

          const {
            mid,
            owner_restriction,
            lg_user_id,
            lg_user_table_id,
            user_list_restriction,
            session_stu_id,
            user_list_table_id,
          } = this.props.state;
          let create_user_id =
            !!owner_restriction && lg_user_id ? lg_user_id : "null";
          let create_user_table_id =
            !!owner_restriction && lg_user_table_id ? lg_user_table_id : "null";
          let user_table_id =
            !!user_list_restriction && user_list_table_id
              ? user_list_table_id
              : "null";
          let stu_id =
            !!user_list_restriction && session_stu_id ? session_stu_id : "null";

          // Fetch the newly added student separately and append it to the current students list
          await StudentService.getAllStuRegWithPaginationAndSorting(
            stu_id,
            user_table_id,
            create_user_id,
            create_user_table_id,
            mid,
            10,
            0,
            "stu_id",
            "desc",
            search_term
          )
            .then((response) => {
              if (response.success) {
                const updatedStudents = [
                  response.data.students[0],
                  ...this.state.students,
                ];
                this.setState({ students: updatedStudents });
              }
            })
            .catch((error) => {
              sweetStatusAlert(
                "Failed!",
                "Error occured while fetching the students!",
                "error"
              );
            });
        } else {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", studentResponse.message, "error");
        }
      } catch (e) {
        this.setState({ isLoading: false });
        sweetStatusAlert("Failed!", "Failed to create new student", "error");
      }
    }
  };

  getRespectiveStatusTypeID(name) {
    const type = this.state.statusTypes.find((item) => item.name === name);
    return type ? type.type_id : "";
  }

  clearAddress = (index) => {
    const { studentAddresses } = this.state;
    const keysToClear = [
      "country_id",
      "province_id",
      "city_id",
      "address_1",
      "address_2",
      "postalcode",
    ];

    if (index >= 0 && index < studentAddresses.length) {
      // Clear the values in the specified keys for the object at indexToClear
      keysToClear.forEach((key) => {
        studentAddresses[index][key] = null; // You can set it to null or any other desired value
      });
      this.setState({ studentAddresses: studentAddresses });
    }
  };

  toggleAddress = (id) => {
    const { studentAddressesAdd } = this.state;
    this.setState({ hidden: true });
    const updateStuAddress = studentAddressesAdd.map((item, index) => {
      if (index === id) {
        if (item.visibility) {
          sweetConfirmAlert().then(() => {
            item.visibility = !item.visibility;
            this.clearAddress(id);
            this.setState({ hidden: false });
          });
        } else {
          item.visibility = !item.visibility;
        }
      }
      if (!item.visibility) {
        this.setState({ hidden: false });
      }
      return item;
    });
    this.setState({ studentAddressesAdd: updateStuAddress });
  };

  toggleUpdateAddress = (id) => {
    const { studentAddresses } = this.state;
    this.setState({ hidden: true });
    const updateStuAddress = studentAddresses.map((item, index) => {
      if (index === id) {
        if (item.visibility) {
          sweetConfirmAlert().then(() => {
            item.visibility = !item.visibility;
            this.clearAddress(id);
            this.setState({ hidden: false });
          });
        } else {
          item.visibility = !item.visibility;
        }
      }
      if (!item.visibility) {
        this.setState({ hidden: false });
      }
      return item;
    });
    this.setState({ studentAddresses: updateStuAddress });
  };

  getAllGrades = () => {
    GradeService.getGrades().then((response) => {
      if (response.success) {
        this.setState({ grades: response.data });
      }
    });
  };

  toggleAddStudentSeparateModal = (studentId) => {
    this.setState(
      (prevState) => ({
        isAddStudentModalOpen: !prevState.isAddStudentModalOpen,
      }),
      async () => {
        if (!this.state.isAddStudentModalOpen && studentId) {
          const {
            mid,
            owner_restriction,
            lg_user_id,
            lg_user_table_id,
            user_list_restriction,
            session_stu_id,
            user_list_table_id,
          } = this.props.state;
          let create_user_id =
            !!owner_restriction && lg_user_id ? lg_user_id : "null";
          let create_user_table_id =
            !!owner_restriction && lg_user_table_id ? lg_user_table_id : "null";
          let user_table_id =
            !!user_list_restriction && user_list_table_id
              ? user_list_table_id
              : "null";
          let stu_id =
            !!user_list_restriction && session_stu_id ? session_stu_id : "null";

          // Fetch the newly added student separately and append it to the current students list
          await StudentService.getAllStuRegWithPaginationAndSorting(
            stu_id,
            user_table_id,
            create_user_id,
            create_user_table_id,
            mid,
            10,
            0,
            "stu_id",
            "desc",
            this.state.search_term
          )
            .then((response) => {
              if (response.success) {
                const updatedStudents = [
                  response.data.students[0],
                  ...this.state.students,
                ];
                this.setState({ students: updatedStudents });
              }
            })
            .catch((error) => {
              sweetStatusAlert(
                "Failed!",
                "Error occured while fetching the students!",
                "error"
              );
            });
        }
      }
    );
  };

  createArrayWithEqualIdGroups = (arr) => {
    const idGroupMap = {};

    // Group objects by id
    arr.forEach((obj) => {
      const id = obj.chart_id;
      idGroupMap[id] = idGroupMap[id] || [];
      idGroupMap[id].push(obj);
    });

    const result = Object.values(idGroupMap).filter(
      (group) => group.length > 1
    );

    return result;
  };

  getAllGradesWithLimit = async() => {
    await GradeService.getGradesWithLimit().then((response) => {
      if (response.success) {
        this.setState({ grades_with_limit: response.data });
      }
    });
  };

  getMedium=()=>{
    assessmentService.getAssessmentLanguage()
        .then((response)=>{
            if(response.success){
                this.setState({mediums:response.data.languages})
            }
            
        })
  }

  render() {
    const {
      studentAddresses,
      isLoading,
      countries,
      limit,
      idValue,
      idTypes,
      idType_id,
      students,
      totalStudents,
      isTableDataLoading,
      currentPage,
      first_name,
      last_name,
      email,
      phone_number,
      dob,
      grade,
      medium,
      school,
      gender_id,
      created_on,
      created_by,
      status_id,
      genders,
      stu_statuses,
      isAddressesLoading,
      editVisibility,
      addVisibility,
      deleteVisibility,
      hidden,
      studentAddressesAdd,
      nodeTitle,
      user_code,
      studentStatsModal,
      grades,
      grades_with_limit,
      mediums,
    } = this.state;
    let breadcrumb2 =
      this.state.breadcrumb2 || nodeTitle
        ? `${this.state.breadcrumb2 && this.state.breadcrumb2}${
            (nodeTitle ? nodeTitle:'')
          }`
        : undefined;
    const path = history.location.pathname;
    let fromProfile = false;
    if (this.props.state.path === "profile") {
      fromProfile = true;
    }
    this.validator.purgeFields();
    let toggleUpdateClassName = `btn btn-icon btn-action btn-sm m-1 edit-assement-btn ${
      !!editVisibility ? "flaticon-edit-icon" : "flaticon-eye-icon"
    }`;
    const formattedBreadcrumb = breadcrumb2
      ? breadcrumb2.replaceAll(">", " > ")
      : "";
    return (
      <div style={{ height: "90vh", overflow: "auto" }}>
        <ContentWrapper disabled={path === "/manage-search"}>
          <StudentsStatModel
            studentStatsModal={studentStatsModal}
            toggleStudentStatsModal={this.toggleStudentStatsModal}
            closeBtnStyle={closeBtnStyle}
            selectedStudent={this.state.selectedStudentStat}
            // iconColor={iconColor}
            createArrayWithEqualIdGroups={this.createArrayWithEqualIdGroups}
          />
          <Card>
            <CardHeader>
              <div>
                {/* <CardTitle
                  cardLabel={`${this.props.state.form_display_title}`}
                /> */}

                {this.props.state.path !== "node" && (
                  <p
                    className="pb-4"
                    style={{ fontSize: "10.5625px", color: "#00000099" }}
                  >
                    {formattedBreadcrumb
                      ? `${formattedBreadcrumb && formattedBreadcrumb}`
                      : ""}
                  </p>
                )}
                {!fromProfile && (
                  <Form.Group
                    controlId="exampleForm.ControlInput1"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      onChange={this.handleSearch}
                      isInvalid={this.state.searchError}
                      style={{ width: "300px" }}
                      className="search-bar"
                    />
                    {this.state.searchError && (
                      <Form.Control.Feedback type="invalid">
                        Invalid input
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}
              </div>
              <div>
                {addVisibility && !fromProfile && (
                  <Button
                    className="insert-btn"
                    variant="primary"
                    onClick={this.toggleAddStudentSeparateModal}
                  >
                    Add Student
                  </Button>
                )}
              </div>
              {/* </div> */}

              {/* <div className="d-flex justify-content-end"> */}
              {/* <div>
                  {!addVisibility &&
                    sessionStorage.getItem("userType") === "student" &&
                    !students[0]?.reference_id &&
                    !isTableDataLoading && (
                      <Button
                        className="insert-btn"
                        variant="primary"
                        onClick={this.toRegisterPgae}
                      >
                        Add parent contact
                      </Button>
                    )}
                </div>
                <div>
                  {!addVisibility &&
                    sessionStorage.getItem("userType") === "student" &&
                    students[0]?.reference_id && (
                      <Button
                        className="insert-btn"
                        variant={
                          !this.state.parentLoader && !this.state.parentDetails
                            ? "light"
                            : "primary"
                        }
                        onClick={this.viewParent}
                        disabled={this.state.parentLoader}
                      >
                        {this.state.parentLoader ? (
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              minWidth: "50px",
                            }}
                          >
                            <i className="fas fa-spinner fa-spin"></i>
                          </div>
                        ) : !this.state.parentLoader &&
                          !this.state.parentDetails ? (
                          <div style={{ color: "red", fontStyle: "italic" }}>
                            Failed to get parent details
                          </div>
                        ) : (
                          "View parent details"
                        )}
                      </Button>
                    )}
                </div> */}
            </CardHeader>
            <CardBody>
              {/* <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    // style={{ width: "18%" }}
                    className="col-md-3 col-12"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group> */}
              {isLoading && (
                <PageSpinner id="show" text="Loading, Please wait!" />
              )}
              {isTableDataLoading ? (
                <div className="loading-container">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/* <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSortChange("created_on")}
                          >
                            Created On{" "}
                            {this.state.sortColumn === "created_on" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </TableCell> */}
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("user_code")}
                        >
                          User Code{" "}
                          {this.state.sortColumn === "user_code" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("first_name")}
                        >
                          First Name{" "}
                          {this.state.sortColumn === "first_name" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("last_name")}
                        >
                          Last Name{" "}
                          {this.state.sortColumn === "last_name" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("email")}
                        >
                          Email{" "}
                          {this.state.sortColumn === "email" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        {/* <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.handleSortChange("phone_number")
                            }
                          >
                            Phone Number{" "}
                            {this.state.sortColumn === "phone_number" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </TableCell> */}
                        <TableCell className="thead">Status</TableCell>
                        <TableCell className="thead">Actions</TableCell>
                        <TableCell className="thead">QR Code</TableCell>
                        <TableCell className="thead">Statistics</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {!!students.length &&
                        students.map((studentItem) => (
                          <TableRow key={studentItem.stu_id}>
                            {/* <TableCell className="tData">
                            {studentItem.created_on}
                          </TableCell> */}
                            {/* <TableCell className="tData">
                            {studentItem.user_code}
                          </TableCell> */}
                            <TableCell className="tData">
                              {studentItem.user_code}
                            </TableCell>
                            <TableCell className="tData">
                              {studentItem.first_name}
                            </TableCell>
                            <TableCell className="tData">
                              {studentItem.last_name}
                            </TableCell>
                            <TableCell className="tData">
                              {studentItem.email}
                            </TableCell>
                            {/* <TableCell className="tData">
                              {studentItem.phone_number}
                            </TableCell> */}
                            {studentItem.status ? (
                              <TableCell className="tData">
                                {/* <span className="label label-lg label-light-success label-inline"> */}
                                <span
                                  className={`label label-lg ${
                                    studentItem.status === "Active"
                                      ? "label-light-success"
                                      : "label-light-danger"
                                  } label-inline`}
                                >
                                  {studentItem.status}
                                </span>
                              </TableCell>
                            ) : (
                              <TableCell className="tData"></TableCell>
                            )}
                            <TableCell>
                              <div className="btn-group">
                                <Button
                                  onClick={() =>
                                    this.toggleUpdateStudentModal(studentItem)
                                  }
                                  className={toggleUpdateClassName}
                                  title="Edit Details"
                                />
                              </div>
                              {/* {deleteVisibility && (
                                  <Button
                                    className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                    title="Delete Details"
                                  />
                                )} */}
                            </TableCell>
                            <TableCell>
                              <div className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn">
                                <IconButton
                                  onClick={() => {
                                    this.setState({
                                      qrmodal: true,
                                      selectedStudent: studentItem,
                                    });
                                  }}
                                >
                                  <QrCode2Icon
                                    style={{
                                      color: "#A2A5B5",
                                      fontSize: "20px",
                                    }}
                                  />
                                </IconButton>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn">
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={() =>
                                    this.toggleStudentStatsModal(studentItem)
                                  }
                                >
                                  <InsertChartIcon
                                    sx={{
                                      fontSize: 20,
                                      backgroundColor: "transparent",
                                      color: "#a2a5b5",
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {!fromProfile && (
                    <MaterialCustomTablePagination
                      totalCount={parseInt(totalStudents)}
                      currentPage={currentPage}
                      limit={limit}
                      handlePageChange={this.handlePageChange}
                      handleRowsPerPageChange={this.handleRowsPerPageChange}
                      handlePageOffsetChange={this.handlePageOffsetChange}
                      rowsPerPageOptions={[5, 10, 20]}
                      offset={this.state.offset}
                    />
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </ContentWrapper>

        <AddStudentModal
          state={this.props.state}
          isAddStudentModalOpen={this.state.isAddStudentModalOpen}
          toggleAddStudentModal={this.toggleAddStudentSeparateModal}
        />

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() =>
            this.handleSweetAlert(this.toggleAddStudentModal, "showInsertModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Student</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              <Form>
                <Row>
                  <Modal.Title className="pl-4 mb-4">
                    Personal Details
                  </Modal.Title>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      defaultValue={first_name}
                      placeholder="First Name"
                      onChange={this.handleChange}
                      ref={this.firsNameRef}
                    />
                    {this.validator.message(
                      "first_name",
                      first_name,
                      "required|alpha_space|min:2|max:128",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The student first name is required.",
                        },
                      }
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      defaultValue={last_name}
                      placeholder="Last Name"
                      onChange={this.handleChange}
                      ref={this.lastNameRef}
                    />
                    {this.validator.message(
                      "last_name",
                      last_name,
                      "required|alpha_space|min:2|max:128",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The student last name is required.",
                        },
                      }
                    )}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  {/* <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>NIC*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="NIC"
                        onChange={this.handleChange}
                        ref={this.lastNameRef}
                      />
                      {this.validator.message(
                        "last_name",
                        last_name,
                        "required|alpha_space|min:2|max:15",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The student last name is required.",
                          },
                        }
                      )}
                    </Form.Group> */}
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>DOB</Form.Label>
                    <div className="col-md-12 px-0">
                      <DatePicker
                        className="py-2 form-control col-md-12" // Add Bootstrap form control class
                        selected={new Date(this.state.dob)}
                        name="dob"
                        onChange={this.handleDatePickerChange}
                        dateFormat="dd/MM/yyyy" // Set custom date format
                        placeholderText="Date of Birth"
                        ref={this.dobRef}
                        wrapperClassName="react-datePicker"
                      />
                    </div>
                    {/* {this.validator.message(
                        "dob",
                        dob,
                        "required|dateBefore",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The date of birth is required.",
                          },
                        }
                      )} */}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      defaultValue={email}
                      placeholder="example@gmail.com"
                      onChange={this.handleChange}
                      ref={this.emailRef}
                      disabled
                    />
                    {this.validator.message("email", email, "required|email", {
                      className: "text-danger",
                      messages: {
                        required: "The email is required.",
                      },
                    })}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Gender*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="gender_id"
                      value={gender_id}
                      onChange={this.handleChange}
                      ref={this.genderIdRef}
                      defaultValue={""}
                    >
                      {!!genders.length &&
                        genders.map((gender) => (
                          <option
                            key={gender.gender_id}
                            value={gender.gender_id}
                          >
                            {gender.name}
                          </option>
                        ))}
                    </Form.Select>
                    {this.validator.message(
                      "gender_id",
                      gender_id,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The gender is required.",
                        },
                      }
                    )}
                  </Form.Group>
                </Row>

                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Phone Number*</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone_number"
                      defaultValue={phone_number}
                      placeholder="Phone Number"
                      onChange={this.handleChange}
                      ref={this.phoneNumberRef}
                    />
                    {this.validator.message(
                      "phone_number",
                      phone_number,
                      "required|numeric|between:9,10|phone_number",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The phone number is required.",
                        },
                      }
                    )}
                  </Form.Group>
                  {!isEdfoci && (
                    <>
                      <Row className="my-5">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="exampleForm.ControlInput2"
                        >
                          <Form.Label>Grade*</Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="grade"
                            value={this.state.grade}
                            onChange={this.handleChange}
                          >
                            <option value="">Select</option>
                            {grades.length > 0 ? (
                              grades.map((grade) => (
                                <option
                                  key={grade.grade_id}
                                  value={grade.grade}
                                >
                                  {grade.grade}
                                </option>
                              ))
                            ) : (
                              <option value="">No grades available</option>
                            )}
                          </Form.Select>
                          {this.validator.message("grade", grade, "required", {
                            className: "text-danger",
                            messages: {
                              required: "The grade is required.",
                            },
                          })}
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>School</Form.Label>
                          <Form.Control
                            className="py-2"
                            type="text"
                            name="school"
                            defaultValue={school}
                            placeholder="School"
                            onChange={this.handleChange}
                          />
                          {this.validator.message("school", school, "max:60", {
                            className: "text-danger",
                            messages: {
                              required: "The school is required.",
                            },
                          })}
                        </Form.Group>
                      </Row>
                    </>
                  )}
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Status*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="status_id"
                      value={status_id}
                      onChange={this.handleChange}
                      ref={this.statusIdRef}
                      defaultValue={""}
                    >
                      {stu_statuses?.map((status) => (
                        <option key={status.status_id} value={status.status_id}>
                          {status.name}
                        </option>
                      ))}
                    </Form.Select>
                    {this.validator.message(
                      "status_id",
                      status_id,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The status is required.",
                        },
                      }
                    )}
                  </Form.Group>
                </Row>

                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Student ID </Form.Label>
                    <Form.Control
                      type="text"
                      name="user_code"
                      value={user_code ? user_code : ""}
                      placeholder="Student ID"
                      disabled
                    />
                    {this.validator.message(
                      "user_code",
                      user_code,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The student ID is required.",
                        },
                      }
                    )}
                  </Form.Group>
                </Row>

                <Row className="pt-5">
                  <Modal.Title className="pl-4 mb-4">
                    Residential Details
                  </Modal.Title>
                </Row>
                {isAddressesLoading ? (
                  <div className="loading-container">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <div>
                    {studentAddressesAdd.length > 0 &&
                      studentAddressesAdd.map((type, index) => {
                        const add_country_id = `add_country_id${index}`;
                        const add_province_id = `add_province_id${index}`;
                        const add_city_id = `add_city_id${index}`;
                        const add_address_1 = `add_address_1${index}`;
                        const add_address_2 = `add_address_2${index}`;
                        const add_postalcode = `add_postalcode${index}`;

                        return type.visibility ? (
                          <div key={index}>
                            <Row>
                              <Col md="11">
                                <Modal.Title className="h6">
                                  {type.name}
                                </Modal.Title>
                                {/* <div className="mt-5">
                                    &nbsp; (please fill all the fields)
                                  </div> */}
                              </Col>
                              <Col md="1">
                                {index > 0 && (
                                  <Tooltip title={"Remove " + type.name}>
                                    <IconButton
                                      onClick={() => this.toggleAddress(index)}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Col>
                            </Row>
                            <Row className="my-5">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Address Line 1</Form.Label>
                                <Form.Control
                                  type="text"
                                  name={add_address_1}
                                  placeholder="Address 1"
                                  onChange={(event) =>
                                    this.handleChangeAddress(index, event)
                                  }
                                  value={
                                    studentAddresses[index]?.address_1 || ""
                                  }
                                  ref={this.addressesRefs[index].address_1}
                                />
                                {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_address_1,
                                      studentAddresses[index]?.address_1 || "",
                                      "address|required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required:
                                            "The Address 1 is required.",
                                          address:
                                            "The address 1 must be a valid address",
                                        },
                                      }
                                    )} */}
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="exampleForm.ControlInput2"
                              >
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control
                                  type="text"
                                  name={add_address_2}
                                  placeholder="Address 2"
                                  onChange={(event) =>
                                    this.handleChangeAddress(index, event)
                                  }
                                  value={
                                    studentAddresses[index]?.address_2 || ""
                                  }
                                  ref={this.addressesRefs[index].address_2}
                                />
                                {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_address_2,
                                      studentAddresses[index]?.address_2 || "",
                                      "address|required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required:
                                            "The Address 2 is required.",
                                          address:
                                            "The address 2 must be a valid address",
                                        },
                                      }
                                    )} */}
                              </Form.Group>
                            </Row>
                            <Row className="my-5">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="exampleForm.ControlInput2"
                              >
                                <Form.Label>Country</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name={add_country_id}
                                  onChange={(event) =>
                                    this.handleChangeAddress(index, event)
                                  }
                                  value={
                                    studentAddresses[index]?.country_id || ""
                                  }
                                  ref={this.addressesRefs[index].country_id}
                                >
                                  <option value="">Select</option>
                                  {!!countries.length &&
                                    countries.map((country) => (
                                      <option
                                        value={country.country_id}
                                        key={country.country_id}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                </Form.Select>
                                {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_country_id,
                                      studentAddresses[index]?.country_id || "",
                                      "required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required: "The Country is required.",
                                        },
                                      }
                                    )} */}
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="exampleForm.ControlInput3"
                              >
                                <Form.Label>Province/District</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name={add_province_id}
                                  onChange={(event) =>
                                    this.handleChangeAddress(index, event)
                                  }
                                  value={
                                    studentAddresses[index]?.province_id || ""
                                  }
                                  ref={this.addressesRefs[index].province_id}
                                >
                                  <option value="">Select</option>
                                  {studentAddresses[index]?.provinces?.map(
                                    (province) => (
                                      <option
                                        value={province.province_id}
                                        key={province.province_id}
                                      >
                                        {province.name}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                                {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_province_id,
                                      studentAddresses[index]?.province_id ||
                                        "",
                                      "required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required: "The Province is required.",
                                        },
                                      }
                                    )} */}
                              </Form.Group>
                            </Row>
                            <Row className="my-5">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="exampleForm.ControlInput3"
                              >
                                <Form.Label>City</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name={add_city_id}
                                  onChange={(event) =>
                                    this.handleChangeAddress(index, event)
                                  }
                                  value={studentAddresses[index]?.city_id || ""}
                                  ref={this.addressesRefs[index].city_id}
                                >
                                  <option value="">Select</option>
                                  {studentAddresses[index]?.cities?.map(
                                    (city) => (
                                      <option
                                        value={city.city_id}
                                        key={city.city_id}
                                      >
                                        {city.name}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                                {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_city_id,
                                      studentAddresses[index]?.city_id || "",
                                      "required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required: "The City is required.",
                                        },
                                      }
                                    )} */}
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="exampleForm.ControlInput3"
                              >
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="postalcode"
                                  placeholder="Postal Code"
                                  onChange={(event) =>
                                    this.handleChangeAddress(index, event)
                                  }
                                  value={
                                    studentAddresses[index]?.postalcode || ""
                                  }
                                  ref={this.addressesRefs[index].postalcode}
                                />
                                {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_postalcode,
                                      studentAddresses[index]?.postalcode || "",
                                      "numeric|required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required:
                                            "The postal code is required.",
                                          numeric:
                                            "The postal code must be a number",
                                        },
                                      }
                                    )} */}
                              </Form.Group>
                            </Row>
                          </div>
                        ) : (
                          <></>
                        );
                      })}

                    {studentAddressesAdd.length > 0 && (
                      <Box
                        sx={{
                          position: "relative",
                          mt: 3,
                          height: 320,
                          ml: 40,
                        }}
                      >
                        <StyledSpeedDial
                          ariaLabel="SpeedDial playground example"
                          hidden={hidden}
                          icon={<SpeedDialIcon />}
                          direction={"down"}
                          FabProps={{
                            sx: {
                              bgcolor: "#5458AF",
                              "&:hover": {
                                bgcolor: "#5458AF",
                              },
                            },
                          }}
                        >
                          {studentAddressesAdd?.map(
                            (action, index) =>
                              !action.visibility && (
                                <SpeedDialAction
                                  key={action.student_address_type_id}
                                  icon={<AddCircleIcon />}
                                  tooltipTitle={
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      {action.name}
                                    </div>
                                  }
                                  tooltipPlacement="right"
                                  onClick={() => this.toggleAddress(index)}
                                  tooltipOpen
                                />
                              )
                          )}
                        </StyledSpeedDial>
                      </Box>
                    )}
                  </div>
                )}
                {/* <Row>
                   <Modal.Title className="pl-4 mt-4 h6">
                   Shipping Address
                   </Modal.Title>
                   <Button
                style={{ marginLeft: '10px', backgroundColor: '#EDEEF7', padding: '1px', borderRadius: '10%', height:'20px', marginTop: '15px',border: 'none' }}
                onClick={() => {
                  // Handle button click
                }}
              >
                <AddIcon style={{ color: '#5458AF' }} />
              </Button>
                   </Row>



                   <Row>
                   <div>
                    
      <FormControlLabel
        control={
          <Checkbox
            name="useSameAddress"
            color="primary"
            style={{ marginLeft: '13px'}}
          />
        }
        label={
          <Typography variant="body1">
            Use same as permanent address.
            
            
          </Typography>
        }
      />
    </div>
                   </Row>


                   <Row>
                   <Modal.Title className="pl-4 mt-4 h6">
                   Billing Address
                   </Modal.Title>
                   <Button
                style={{ marginLeft: '10px', backgroundColor: '#EDEEF7', padding: '1px', borderRadius: '10%', height:'20px', marginTop: '15px', border: 'none'}}
                onClick={() => {
                  // Handle button click
                }}
              >
                <AddIcon style={{ color: '#5458AF' }} />
              </Button>
                  
                   </Row>


                   <Row>
                   <div>
      <FormControlLabel
        control={
          <Checkbox
            name="useSameAddress"
            color="primary"
            style={{ marginLeft: '13px'}}
          />
        }
        label={
          <Typography variant="body1">
            Use same as permanent address.
            
          </Typography>
        }
      />
    </div>
                   </Row> */}

                <Modal.Footer>
                  <Button
                    className="mb-4"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.handleSubmit}
                  >
                    Save changes
                  </Button>
                  <Button
                    className="mb-4"
                    id="modal-close-button"
                    style={closeBtnStyle}
                    onClick={() =>
                      this.handleSweetAlert(
                        this.toggleAddStudentModal,
                        "showInsertModal"
                      )
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </div>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          onHide={() =>
            this.handleSweetAlert(this.toggleUpdateStudentModal, "showModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Student Information</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              <Form>
                <Row>
                  <Modal.Title className="pl-4 mb-4">
                    Personal Details
                  </Modal.Title>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      defaultValue={first_name}
                      placeholder="First Name"
                      onChange={this.handleChange}
                      ref={this.firsNameRef}
                    />
                    {this.validator.message(
                      "first_name",
                      first_name,
                      "required|alpha_space|min:2|max:128",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The student first name is required.",
                        },
                      }
                    )}
                  </Form.Group>
                  {/* <div>fname</div> */}
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      defaultValue={last_name}
                      placeholder="Last Name"
                      onChange={this.handleChange}
                      ref={this.lastNameRef}
                    />
                    {this.validator.message(
                      "last_name",
                      last_name,
                      "required|alpha_space|min:2|max:128",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The student last name is required.",
                        },
                      }
                    )}
                  </Form.Group>
                  {/* <div>laname</div> */}
                </Row>
                <Row className="my-5" ref={this.dobWrapRef}>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>ID Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="idType_id"
                    value={idType_id}
                    onChange={this.handleChange}
                    ref={this.idTypeIdRef}
                    defaultValue={""}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {!!idTypes.length &&
                      idTypes.map((idType) => (
                        <option
                          key={idType.idType_id}
                          value={idType.idType_id}
                        >
                          {idType.idType}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                { idType_id && (
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>ID Value</Form.Label>
                    <Form.Control
                      type="text"
                      name="idValue"
                      value={idValue}
                      placeholder="ID Value"
                      onChange={this.handleChange}
                    />
                    {this.validator.message(
                      "idValue",
                      idType_id ? idValue : null,
                      "required|min:4",
                      {
                        className: "text-danger",
                        messages: {
                          required: "Please enter the ID Value.",
                        },
                      }
                    )}
                  </Form.Group>
                )}
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>DOB*</Form.Label>
                    <div className="col-md-12 px-0">
                      <DatePicker
                        className="py-2 form-control col-md-12" // Add Bootstrap form control class
                        selected={
                          this.state.dob ? new Date(this.state.dob) : undefined
                        }
                        name="dob"
                        onChange={this.handleDatePickerChange}
                        dateFormat="dd/MM/yyyy" // Set custom date format
                        placeholderText="Date of Birth"
                        ref={this.dobRef}
                        wrapperClassName="react-datePicker"
                      />
                    </div>

                    {this.validator.message("dob", dob, "required|dateBefore", {
                      className: "text-danger",
                      messages: {
                        required: "The date of birth is required.",
                      },
                    })}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      defaultValue={email}
                      placeholder="example@gmail.com"
                      onChange={this.handleChange}
                      ref={this.emailRef}
                      disabled
                    />
                    {this.validator.message("email", email, "required|email", {
                      className: "text-danger",
                      messages: {
                        required: "The email is required.",
                      },
                    })}
                  </Form.Group>
                  {/* <div>email</div> */}
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Gender*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="gender_id"
                      value={gender_id}
                      onChange={this.handleChange}
                      ref={this.genderIdRef}
                      defaultValue={""}
                    >
                      {!!genders.length &&
                        genders.map((gender) => (
                          <option
                            key={gender.gender_id}
                            value={gender.gender_id}
                          >
                            {gender.name}
                          </option>
                        ))}
                    </Form.Select>
                    {this.validator.message(
                      "gender_id",
                      gender_id,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The gender is required.",
                        },
                      }
                    )}
                  </Form.Group>

                  {/* <div>pnumber</div> */}
                  {/* <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>NIC*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="NIC"
                        onChange={this.handleChange}
                        ref={this.lastNameRef}
                      />
                      {this.validator.message(
                        "last_name",
                        last_name,
                        "required|alpha_space|min:2|max:15",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The student last name is required.",
                          },
                        }
                      )}
                    </Form.Group> */}
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Phone Number*</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone_number"
                      defaultValue={phone_number}
                      placeholder="Phone Number"
                      onChange={this.handleChange}
                      ref={this.phoneNumberRef}
                    />
                    {this.validator.message(
                      "phone_number",
                      phone_number,
                      "required|numeric|between:9,10|phone_number",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The phone number is required.",
                        },
                      }
                    )}
                  </Form.Group>
                  {/* <div>DOB</div> */}
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Status*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="status_id"
                      value={status_id}
                      onChange={this.handleChange}
                      ref={this.statusIdRef}
                      defaultValue={""}
                    >
                      {stu_statuses?.map((status) => (
                        <option key={status.status_id} value={status.status_id}>
                          {status.name}
                        </option>
                      ))}
                    </Form.Select>
                    {this.validator.message(
                      "status_id",
                      status_id,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The status is required.",
                        },
                      }
                    )}
                  </Form.Group>
                </Row>

                {!isEdfoci && (
                  <>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>Grade*</Form.Label>

                        <Form.Select
                          aria-label="Default select example"
                          name="grade"
                          value={this.state.grade}
                          onChange={this.handleChange}
                        >
                          <option value="">Select</option>
                            {grades_with_limit.length>0 ? (grades_with_limit.map((grade)=>(
                              <option key={grade.grade_id} value={grade.grade_id} disabled={grade.limit_exceeded} style={{color:grade.limit_exceeded?'red':'black'}}>
                                {grade.limit_exceeded ? `${grade.grade} (Registration Limit exceeded)` : grade.grade}
                              </option>
                            ))
                          ) : (
                            <option value="">No grades available</option>
                          )}
                        </Form.Select>
                        {this.validator.message("grade", grade, "required", {
                          className: "text-danger",
                          messages: {
                            required: "The grade is required.",
                          },
                        })}
                      </Form.Group>
                      <Form.Group 
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                        >
                        <Form.Label>Student’s Language:</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="medium"
                            value={toInteger(this.state.medium)}
                            onChange={this.handleChange}
                          >
                            <option value="">Select</option>
                            {mediums.length>0?(mediums.map((medium)=>(
                              <option key={medium.language_id} value={medium.language_id}>
                                {medium.language}
                              </option>
                            ))):(
                              <option value="">No languages available</option>
                            )}
                          </Form.Select>
                          <span className="form-error-message">
                          {this.validator.message(
                            "medium",
                            this.state.medium,
                            "required"
                          )}
                          </span>
                        </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>School</Form.Label>
                        <Form.Control
                          className="py-2"
                          type="text"
                          name="school"
                          defaultValue={school}
                          placeholder="School"
                          onChange={this.handleChange}
                        />
                        {this.validator.message("school", school, "max:60", {
                          className: "text-danger",
                          messages: {
                            required: "The school is required.",
                          },
                        })}
                      </Form.Group>
                    </Row>
                  </>
                )}

                <Row className="my-5">
                  {/* <div>status</div> */}
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Student ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="user_code"
                      value={user_code ? user_code : ""}
                      placeholder="Student ID"
                      disabled
                    />
                    {this.validator.message(
                      "user_code",
                      user_code,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The student ID is required.",
                        },
                      }
                    )}
                  </Form.Group>
                  {/* <div>Std id pref</div> */}
                </Row>

                <Row className="pt-5">
                  <Modal.Title className="pl-4 mb-4">
                    Residential Details
                  </Modal.Title>
                </Row>
                {isAddressesLoading ? (
                  <div className="loading-container">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <div>
                    {studentAddresses.length > 0 &&
                      studentAddresses.map((type, index) => {
                        const update_country_id = `update_country_id${index}`;
                        const update_province_id = `update_province_id${index}`;
                        const update_city_id = `update_city_id${index}`;
                        const update_address_1 = `update_address_1${index}`;
                        const update_address_2 = `update_address_2${index}`;
                        const update_postalcode = `update_postalcode${index}`;
                        return type.visibility ? (
                          <div>
                            <div key={index}>
                              <Row>
                                <Col md="11">
                                  <Modal.Title className="h6">
                                    {type.name}
                                  </Modal.Title>
                                </Col>
                                <Col md="1">
                                  {index > 0 && (
                                    <Tooltip title={"Remove " + type.name}>
                                      <IconButton
                                        onClick={() =>
                                          this.toggleUpdateAddress(index)
                                        }
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Col>
                              </Row>
                              <Row className="my-5">
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Address Line 1</Form.Label>
                                  <Form.Control
                                    readOnly={!editVisibility}
                                    type="text"
                                    name={update_address_1}
                                    placeholder="Address 1"
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.address_1 || ""
                                    }
                                    ref={this.addressesRefs[index].address_1}
                                  />
                                  {/* {this.state.addressValidity[index] &&
                                      this.state.showModal &&
                                      this.validator.message(
                                        update_address_1,
                                        studentAddresses[index]?.address_1 ||
                                          "",
                                        "address|required",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required:
                                              "The Address 1 is required.",
                                            address:
                                              "The address 1 must be a valid address",
                                          },
                                        }
                                      )} */}
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput2"
                                >
                                  <Form.Label>Address Line 2</Form.Label>
                                  <Form.Control
                                    readOnly={!editVisibility}
                                    type="text"
                                    name={update_address_2}
                                    placeholder="Address 2"
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.address_2 || ""
                                    }
                                    ref={this.addressesRefs[index].address_2}
                                  />
                                  {/* {this.state.addressValidity[index] &&
                                      this.state.showModal &&
                                      this.validator.message(
                                        update_address_2,
                                        studentAddresses[index]?.address_2 ||
                                          "",
                                        "address|required",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required:
                                              "The Address 2 is required.",
                                            address:
                                              "The address 2 must be a valid address",
                                          },
                                        }
                                      )} */}
                                </Form.Group>
                              </Row>
                              <Row className="my-5">
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput2"
                                >
                                  <Form.Label>Country</Form.Label>
                                  <Form.Select
                                    disabled={!editVisibility}
                                    aria-label="Default select example"
                                    name={update_country_id}
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.country_id || ""
                                    }
                                    ref={this.addressesRefs[index].country_id}
                                  >
                                    <option value="">Select</option>
                                    {!!countries.length &&
                                      countries.map((country) => (
                                        <option
                                          value={country.country_id}
                                          key={country.country_id}
                                        >
                                          {country.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {/* {this.state.addressValidity[index] &&
                                      this.state.showModal &&
                                      this.validator.message(
                                        update_country_id,
                                        studentAddresses[index]?.country_id ||
                                          "",
                                        "required",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required:
                                              "The Country is required.",
                                          },
                                        }
                                      )} */}
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput3"
                                >
                                  <Form.Label>Province/District</Form.Label>
                                  <Form.Select
                                    disabled={!editVisibility}
                                    aria-label="Default select example"
                                    name={update_province_id}
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.province_id || ""
                                    }
                                    ref={this.addressesRefs[index].province_id}
                                  >
                                    <option value="">Select</option>
                                    {studentAddresses[index]?.provinces?.map(
                                      (province) => (
                                        <option
                                          value={province.province_id}
                                          key={province.province_id}
                                        >
                                          {province.name}
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                  {/* {this.state.addressValidity[index] &&
                                      this.state.showModal &&
                                      this.validator.message(
                                        update_province_id,
                                        studentAddresses[index]?.province_id ||
                                          "",
                                        "required",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required:
                                              "The Province is required.",
                                          },
                                        }
                                      )} */}
                                </Form.Group>
                              </Row>
                              <Row className="my-5">
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput3"
                                >
                                  <Form.Label>City</Form.Label>
                                  <Form.Select
                                    disabled={!editVisibility}
                                    aria-label="Default select example"
                                    name={update_city_id}
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.city_id || ""
                                    }
                                    ref={this.addressesRefs[index].city_id}
                                  >
                                    <option value="">Select</option>
                                    {studentAddresses[index]?.cities?.map(
                                      (city) => (
                                        <option
                                          value={city.city_id}
                                          key={city.city_id}
                                        >
                                          {city.name}
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                  {/* {this.state.addressValidity[index] &&
                                      this.state.showModal &&
                                      this.validator.message(
                                        update_city_id,
                                        studentAddresses[index]?.city_id || "",
                                        "required",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required: "The City is required.",
                                          },
                                        }
                                      )} */}
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput3"
                                >
                                  <Form.Label>Postal Code</Form.Label>
                                  <Form.Control
                                    readOnly={!editVisibility}
                                    type="text"
                                    name={update_postalcode}
                                    placeholder="Postal Code"
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.postalcode || ""
                                    }
                                    ref={this.addressesRefs[index].postalcode}
                                  />
                                  {/* {this.state.addressValidity[index] &&
                                      this.state.showModal &&
                                      this.validator.message(
                                        update_postalcode,
                                        studentAddresses[index]?.postalcode ||
                                          "",
                                        "numeric|required",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required:
                                              "The postal code is required.",
                                            numeric:
                                              "The postal code must be a number",
                                          },
                                        }
                                      )} */}
                                </Form.Group>
                              </Row>
                            </div>
                          </div>
                        ) : (
                          <></>
                        );
                      })}
                    {studentAddresses.length > 0 && (
                      <Box
                        sx={{
                          position: "relative",
                          mt: 3,
                          height: 320,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <StyledSpeedDial
                          ariaLabel="SpeedDial playground example"
                          hidden={hidden}
                          icon={<SpeedDialIcon />}
                          direction={"down"}
                          FabProps={{
                            sx: {
                              bgcolor: "#5458AF",
                              "&:hover": {
                                bgcolor: "#5458AF",
                              },
                            },
                          }}
                        >
                          {studentAddresses?.map(
                            (action, index) =>
                              !action.visibility && (
                                <SpeedDialAction
                                  key={action.student_address_type_id}
                                  icon={<AddCircleIcon />}
                                  tooltipTitle={
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      {action.name}
                                    </div>
                                  }
                                  tooltipPlacement="right"
                                  onClick={() =>
                                    this.toggleUpdateAddress(index)
                                  }
                                  tooltipOpen
                                />
                              )
                          )}
                        </StyledSpeedDial>
                      </Box>
                    )}
                  </div>
                )}
                {/* <Row>
                   <Modal.Title className="pl-4 mt-4 h6">
                   Shipping Address
                   </Modal.Title>
                   <Button
                style={{ marginLeft: '10px', backgroundColor: '#EDEEF7', padding: '1px', borderRadius: '10%', height:'20px', marginTop: '15px',border: 'none' }}
                onClick={() => {
                  // Handle button click

                }}
              >
                <AddIcon style={{ color: '#5458AF' }} />
              </Button>
                   </Row>

                   <Row>
                   <div>
                   <FormControlLabel
        control={
          <Checkbox
            name="useSameAddress"
            color="primary"
            style={{ marginLeft: '13px'}}
          />
        }
        label={
          <Typography variant="body1">
            Use same as permanent address.
            
          </Typography>
        }
      />
    </div>
                   </Row>


                   <Row>
                   <Modal.Title className="pl-4 mt-4 h6">
                   Billing Address
                   </Modal.Title>
                   <Button
                style={{ marginLeft: '10px', backgroundColor: '#EDEEF7', padding: '1px', borderRadius: '10%', height:'20px', marginTop: '15px', border: 'none'}}
                onClick={() => {
                  // Handle button click
                }}
              >
                <AddIcon style={{ color: '#5458AF' }} />
              </Button>
                  
                   </Row>


                   <Row>
                   <div>
      <FormControlLabel
        control={
          <Checkbox
            name="useSameAddress"
            color="primary"
            style={{ marginLeft: '13px'}}
          />
        }
        label={
          <Typography variant="body1">
            Use same as permanent address.
            
          </Typography>
        }
      />
    </div>
                   </Row> */}

                <Modal.Footer>
                  {editVisibility && (
                    <Button
                      className="modal-btn"
                      variant="primary"
                      style={addBtnStyle}
                      onClick={this.handleEditSubmit}
                    >
                      Save changes
                    </Button>
                  )}
                  <Button
                    className="modal-btn"
                    id="modal-close-button"
                    style={closeBtnStyle}
                    onClick={() =>
                      this.handleSweetAlert(
                        this.toggleUpdateStudentModal,
                        "showModal"
                      )
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </div>
        </Modal>
        <div>
          <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.qrmodal}
            onHide={() => this.setState({ qrmodal: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <QRgenerator data={this.state.selectedStudent} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
