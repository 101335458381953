import React, { Component, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ContentWrapper from "../../../../../components/common/ContentWrapper.js";
import Card from "../../../../../components/common/Card.js";
import Col from "react-bootstrap/Col";
import CardHeader from "../../../../../components/common/CardHeader.js";
import CardBody from "../../../../../components/common/CardBody.js";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from "../../../../../components/common/form/FormGroup.js";
import moment from "moment";
import Row from "react-bootstrap/Row";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Box
} from "@mui/material";
import MaterialCustomTablePagination from "../../../../../components/common/MaterialCustomTablePagination.js";
import debounce from "lodash.debounce";
import { history } from "../../../../../helper/history.js";
import { PaperService } from "../../../../../service/manage/paper/paper_service.js";
import {
  sweetAlertEnroll,
  sweetStatusAlert
} from "../../../../../components/common/SweetAlert.js";
import PageSpinner from "../../../../../components/common/spinner/PageSpinner.js";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { StudentService } from "../../../../../service/manage/registration/student.service.js";
import { EnrolledPaperService } from "../../../../../service/manage/paper/enrolled_paper.service.js";
import { Button } from "react-bootstrap";
import Chip from "@mui/material/Chip";
import { assessmentService } from "../../../../../service/assessment/assessmentService.js";
import { ASSESSMENT_FILTERS } from "../../../../../helper/constants.js";
import { CreditService } from "../../../../../service/manage/subscription/credit.service.js";
import TimeZoneHandler from "../../../../../handlers/TimeZoneHandler.js";
import config from "../../../../../helper/config.js";
import { customerConstants } from "../../../../../helper/constants.js";
import { GradeService } from "../../../../../service/settings/manage/grade.service.js";
import { assessSubjectService } from "../../../../../service/settings/assessment/subjectService.js";
import { assessYearService } from "../../../../../service/settings/assessment/yearService.js";
import ReactSpinner from "react-bootstrap/Spinner";
import { routes } from "../../../../../helper/routes.js";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Radio, Sheet } from '@mui/joy';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import "./Paper.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FormControlLabel from "@mui/material/FormControlLabel";
import PaperListChips from "./PaperListChips.js";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default class EdPaperList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: null,
      userId: "",
      node_mid: null,
      papers: [],
      enrolledPapers: [],
      limit: 10,
      offset: 0,
      search_term: "",
      breadcrumb2: null,
      nodeTitle: null,
      totalPapers: 0,
      currentPage: 0,
      sortColumn: "assess_name",
      sortDirection: "desc",
      selectedStudent: "",
      studentlist: [],
      isTableDataLoading: true,
      isSpinnerLoading: false,
      isDropHidden: false,
      assessment_name: "",
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      viewVisibility: false,
      create_user_id: "",
      create_user_table_id: "",
      filterValues: [],
      filters: [],
      selectedFilters: [],
      selectedGrades: null,
      selectedChapters: null,
      selectedsubjects: null,
      selectedAuthors: null,
      selectedYears: null,
      selectedCategories: null,
      selectedTerms: null,
      selectedDifficulties: null,
      selectedLanguages: null,
      allCategories: [],
      allLanguages: [],
      allSubjects: [],
      allAuthors: [],
      allYears: [],
      allDifficulties: [],
      allGrades: [],
      allTerms: [],
      allChapters: [],
      showInsertModal: false,
      credits: [],
      credit_id: "",
      selectedCredit: null,
      isCreditNotAvailable: false,
      isModified: false,
      asses_id: null,
      assess_name: null,
      grade_id: "",
      is_level_price_loading: false,
      creditsLoading: false,
      grades: [],
      subjects: [],
      years: [],
      isGradeLoading: false,
      isSubjectLoading: false,
      isYearLoading: false,
      selectedPaper: false
    };

    this.debouncedHandleSearch = debounce(this.callAfterSubmit, 2000);
  }

  componentDidMount() {

    const {
      lg_user_id,
      lg_user_table_id,
      flg_list_active,
      privilage_view,
      privilege_add,
      privilege_edit,
      nodeTitle,
      breadcrumb2,
      privilege_delete,
    } = this.props.state;

    this.getVisibilityCheck(
      privilage_view,
      privilege_add,
      privilege_delete,
      privilege_edit
    );
    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle: nodeTitle,
    });
    let userType = sessionStorage.getItem("userType");
    this.setState({ userType });

    this.getAllGrades();
    this.getAllSubjects();
    this.getAllYears();

    if (userType === "admin") {
      if (flg_list_active === 1) {
        this.setState({ isDropHidden: true });
      }
      this.fetchParentData();
      this.fetchAllStudents();
    }

    if (userType === "student") {


      if (flg_list_active === null) {

        StudentService.getCreateUserDetail(
          lg_user_id,
          lg_user_table_id,
          userType
        ).then((response) => {
          const create_user_id = response.data.user_detail[0].create_user_id;
          const create_user_table_id =
            response.data.user_detail[0].create_user_table_id;
          this.setState({
            create_user_id: create_user_id,
            create_user_table_id: create_user_table_id,
            isDropHidden: false,
          }, () => {
            this.fetchStudentData();
            this.fetchAllEnrolledChildPapers();
          });
        });
      }

    }

    if (userType === "parent") {
      if (flg_list_active === 1) {
        this.setState({ isDropHidden: true });
      }
      this.fetchParentData();
      this.fetchAllStudents();
    }
    this.fetchAllAssessmentSettings();

    // if(userType === "student"){
    //   let stu_grade = sessionStorage.getItem("studentGrade");
    //   let stu_medium = sessionStorage.getItem("studentMedium");

    //   if (stu_grade) {    
    //     this.setState({stu_grade})
    //     this.onSelectFilterMulti({
    //       filter: "Grade",
    //       grade_id: parseInt(stu_grade),
    //       name: stu_grade,
    //     });        

    //   }
    //   if (stu_medium) {
    //     this.setState({stu_medium})
    //     this.onSelectFilterMulti({
    //       filter: "Language",
    //       language_id: parseInt(stu_medium),
    //       name: stu_medium,
    //     });  

    //   }
    // }
  }

  getVisibilityCheck(
    privilage_view,
    privilege_add,
    privilege_delete,
    privilege_edit
  ) {
    if (privilege_add === 1) {
      this.setState({
        addVisibility: true,
      });
    } else if (privilege_edit === 1) {
      this.setState({
        editVisibility: true,
      });
    } else if (privilage_view === 1) {
      this.setState({
        viewVisibility: true,
      });
    } else if (privilege_delete === 1) {
      this.setState({
        deleteVisibility: true,
      });
    }
  }

  fetchStudentGrade = async () => {
    this.setState({ isTableDataLoading: true });
    let email = sessionStorage.getItem("email");
    await StudentService.getStudentbyEmail(email).then((response) => {
      sessionStorage.setItem("studentGrade", response.data.grade);
    })
  }

  fetchSelectedStudentGrade = async () => {
    this.setState({ isTableDataLoading: true });
    const { selectedStudent } = this.state;
    await StudentService.getById(parseInt(selectedStudent)).then((response) => {
      sessionStorage.setItem("selectedStudentGrade", response.data.grade);
    })
  }

  fetchStudentData = async () => {
    await this.fetchStudentGrade();
    await this.fetchAllPapersForStudent();
  }

  fetchParentData = async () => {
    await this.fetchAllPapersForParent();
  }

  fetchAllAssessmentSettings() {
    this.setState({
      isTableDataLoading: true
    });

    const fetchAssessmentMetaData = assessmentService.getAllAssessmentMetaData();

    Promise.all([
      fetchAssessmentMetaData
    ])
      .then((response) => {
        const [
          assessmentMetaDataResponse
        ] = response;

        this.setState({
          allCategories: assessmentMetaDataResponse.data.find(item => 'categories' in item).categories,
          allLanguages: assessmentMetaDataResponse.data.find(item => 'languages' in item).languages,
          allSubjects: assessmentMetaDataResponse.data.find(item => 'subjects' in item).subjects,
          allAuthors: assessmentMetaDataResponse.data.find(item => 'authors' in item).authors,
          allYears: assessmentMetaDataResponse.data.find(item => 'years' in item).years,
          allDifficulties: assessmentMetaDataResponse.data.find(item => 'difficulty_types' in item).difficulty_types,
          allGrades: assessmentMetaDataResponse.data.find(item => 'grades' in item).grades,
          allTerms: assessmentMetaDataResponse.data.find(item => 'terms' in item).terms,
          allChapters: assessmentMetaDataResponse.data.find(item => 'chapters' in item).chapters,
          isLoading: false
        },async()=>{

          const studentGrade = parseInt(sessionStorage.getItem("studentGrade"), 10);
          const studentLanguage = parseInt(sessionStorage.getItem("studentMedium"), 10);

          if(this.state.allGrades && this.state.allGrades.length>0){

            const matchingGrade = this.state.allGrades.find((grade) => grade.grade_id === studentGrade);
            
            if (matchingGrade) {
              matchingGrade.filter = "Grade";
              matchingGrade.name = matchingGrade.grade
              await this.onSelectFilterMulti(matchingGrade)
            }

          }

          if(this.state.allLanguages && this.state.allLanguages.length>0){

            const matchingLanguage = this.state.allLanguages.find((language) => language.language_id === studentLanguage);
            
            

            if (matchingLanguage) {
              matchingLanguage.filter = "Language";
              matchingLanguage.name = matchingLanguage.language

              this.onSelectFilterMulti(matchingLanguage)
            }

          }

        });
        
      })
      .catch((error) => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getAllGrades = async () => {
    this.setState({ isGradeLoading: true });
    await GradeService.getGrades()
      .then((response) => {
        if (response.success) {
          this.setState({ grades: response.data, isGradeLoading: false })
        }
        else {
          this.setState({ isGradeLoading: false });
        }

      })
  }

  findGrade = (grade_id) => {
    const { grades } = this.state
    if (grades && grades.length > 0) {

      const paperGrade = grades.find((grade) => grade.grade_id === parseInt(grade_id));

      return paperGrade ? String(paperGrade.grade) : "N/A"
    }
    else {
      return "N/A"
    }
  }

  getAllSubjects = async () => {
    this.setState({ isSubjectLoading: true });
    await assessSubjectService.getAllSubjects()
      .then((response) => {
        if (response.success) {
          this.setState({ subjects: response.data.subjects, isSubjectLoading: false })
        }
        else {
          this.setState({ isSubjectLoading: false });
        }

      })
  }

  findSubject = (subject_id) => {
    const { subjects } = this.state
    if (subjects && subjects.length > 0) {

      const paperSubject = subjects.find((subject) => subject.subject_id === parseInt(subject_id));

      return paperSubject ? String(paperSubject.subject) : "N/A"
    }
    else {
      return "N/A"
    }
  }

  getAllYears = async () => {
    this.setState({ isYearLoading: true });
    await assessYearService.getAllYears()
      .then((response) => {
        if (response.success) {
          this.setState({ years: response.data.years, isYearLoading: false })
        }
        else {
          this.setState({ isYearLoading: false });
        }

      })
  }

  findYear = (year_id) => {
    const { years } = this.state
    if (years && years.length > 0) {

      const paperYear = years.find((year) => year.year_id === parseInt(year_id));

      return paperYear ? String(paperYear.year) : "N/A"
    }
    else {
      return "N/A"
    }
  }

  getPaperCategory(category_id) {
    if (category_id !== null) {
      const category = this.state.allCategories?.find(
        (category) => category.category_id == category_id
      );
      return category?.name;
    }
    else {
      return "N/A";
    }
  }

  getPaperLanguage(language_id) {
    if (language_id !== null) {
      const language = this.state.allLanguages?.find(
        (language) => language.language_id == language_id
      );
      return language?.language;
    }
    else {
      return "N/A";
    }
  }

  getPaperSubject(subject_id) {
    if (subject_id !== null) {
      const subject = this.state.allSubjects?.find(
        (subject) => subject.subject_id == subject_id
      );
      return subject?.subject;
    }
    else {
      return "N/A";
    }
  }

  getPaperAuthor(author_id) {
    if (author_id !== null) {
      const author = this.state.allAuthors?.find(
        (author) => author.author_id == author_id
      );
      return author?.author;
    }
    else {
      return "N/A";
    }
  }

  getPaperYear(year_id) {
    if (year_id !== null) {
      const year = this.state.allYears?.find(
        (year) => year.year_id == year_id
      );
      return year?.year;
    }
    else {
      return "N/A";
    }
  }

  getPaperDifficulty(difficulty_id) {
    if (difficulty_id !== null) {
      const difficulty = this.state.allDifficulties?.find(
        (difficulty) => difficulty.difficulty_id == difficulty_id
      );
      return difficulty?.difficulty_type;
    }
    else {
      return "N/A";
    }
  }

  getPaperGrade(grade_id) {
    if (grade_id !== null) {
      const grade = this.state.allGrades?.find(
        (grade) => grade.grade_id == grade_id
      );
      return grade?.grade;
    }
    else {
      return "N/A";
    }
  }

  getPaperTerm(term_id) {
    if (term_id !== null) {
      const term = this.state.allTerms?.find(
        (term) => term.term_id == term_id
      );
      return term?.term;
    }
    else {
      return "N/A";
    }
  }

  getPaperChapter(chapter_id) {
    if (chapter_id !== null) {
      const chapter = this.state.allChapters?.find(
        (chapter) => chapter.chapter_id == chapter_id
      );
      return chapter?.chapter;
    }
    else {
      return "N/A";
    }
  }

  getCreatedAtLocal(time) {
    if (time != null) {
      let timeStamp = new Date(time.concat("Z"))
      let d = new Date(timeStamp)
      // let startDate = d.toDateString();
      // let startTime = d.toLocaleTimeString('en-SL');
      let local = moment(timeStamp).local().format('YYYY-MM-DDTHH:mm')
      return local;
    }
    else {
      return "N/A";
    }
  }

  callAfterSubmit() {
    this.setState({ isTableDataLoading: true });
    let userType = sessionStorage.getItem("userType");
    if (userType === "student") {
      this.fetchStudentData();
      // this.fetchEnrollPaperData();
    }
    else {
      this.fetchParentData();
      this.fetchEnrollPaperData();
    }
  }

  fetchAllPapersForStudent = async () => {
    this.setState({ isTableDataLoading: true });
    const {
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedDifficulties,
      selectedGrades,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects,
    } = this.state;

    let node_mid = this.props.state.mid;

    if (selectedGrades !== null) {
      await PaperService.getfilterdAllAssesmentsMultiFilter(
        limit,
        offset,
        sortColumn,
        sortDirection,
        search_term,
        selectedCategories,
        selectedAuthors,
        selectedChapters,
        selectedGrades,
        selectedDifficulties,
        selectedLanguages,
        selectedTerms,
        selectedYears,
        selectedsubjects
      )
        .then((response) => {
          if (response.success) {
            this.setState({
              papers: response.data.papers,
              totalPapers: response.data.count,
              isTableDataLoading: false,
            });
          } else {
            sweetStatusAlert(
              "Failed!",
              "Error fetching papers. Please refresh the screen!",
              "Error"
            );
          }
        })
        .catch((error) => {
          sweetStatusAlert(
            "Failed!",
            "Error fetching papers. Please refresh the screen!",
            "Eerror"
          );
        })
        .finally(() => {
          this.setState({ isTableDataLoading: false });
        });
    }
    else {
      await PaperService.getfilterdAllAssesmentsMultiFilter(
        limit,
        offset,
        sortColumn,
        sortDirection,
        search_term,
        selectedCategories,
        selectedAuthors,
        selectedChapters,
        // sessionStorage.getItem("studentGrade"),
        selectedGrades,
        selectedDifficulties,
        selectedLanguages,
        selectedTerms,
        selectedYears,
        selectedsubjects
      )
        .then((response) => {
          if (response.success) {
            this.setState({
              papers: response.data.papers,
              totalPapers: response.data.count,
              isTableDataLoading: false,
            });
          } else {
            sweetStatusAlert(
              "Failed!",
              "Error fetching papers. Please refresh the screen!",
              "Error"
            );
          }
        })
        .catch((error) => {
          sweetStatusAlert(
            "Failed!",
            "Error fetching papers. Please refresh the screen!",
            "Eerror"
          );
        })
        .finally(() => {
          this.setState({ isTableDataLoading: false });
        });
    }

  };

  fetchAllPapersForParent = async () => {
    const {
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedDifficulties,
      selectedGrades,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects,
    } = this.state;

    let node_mid = this.props.state.mid;

    if (selectedGrades !== null) {
      await PaperService.getfilterdAllAssesments(
        node_mid,
        limit,
        offset,
        sortColumn,
        sortDirection,
        search_term,
        selectedCategories,
        selectedAuthors,
        selectedChapters,
        selectedGrades,
        selectedDifficulties,
        selectedLanguages,
        selectedTerms,
        selectedYears,
        selectedsubjects
      )
        .then((response) => {
          if (response.success) {
            this.setState({
              papers: response.data.papers,
              totalPapers: response.data.count,
              isTableDataLoading: false,
            });
          } else {
            sweetStatusAlert(
              "Failed!",
              "Error fetching papers. Please refresh the screen!",
              "Error"
            );
          }
        })
        .catch((error) => {
          sweetStatusAlert(
            "Failed!",
            "Error fetching papers. Please refresh the screen!",
            "Eerror"
          );
        })
        .finally(() => {
          this.setState({ isTableDataLoading: false });
        });
    }
    else {
      await PaperService.getfilterdAllAssesments(
        node_mid,
        limit,
        offset,
        sortColumn,
        sortDirection,
        search_term,
        selectedCategories,
        selectedAuthors,
        selectedChapters,
        // sessionStorage.getItem("selectedStudentGrade"),
        selectedGrades,
        selectedDifficulties,
        selectedLanguages,
        selectedTerms,
        selectedYears,
        selectedsubjects
      )
        .then((response) => {
          if (response.success) {
            this.setState({
              papers: response.data.papers,
              totalPapers: response.data.count,
              isTableDataLoading: false,
            });
          } else {
            sweetStatusAlert(
              "Failed!",
              "Error fetching papers. Please refresh the screen!",
              "Error"
            );
          }
        })
        .catch((error) => {
          sweetStatusAlert(
            "Failed!",
            "Error fetching papers. Please refresh the screen!",
            "Eerror"
          );
        })
        .finally(() => {
          this.setState({ isTableDataLoading: false });
        });
    }

  };

  handleViewClick = (e, paper) => {
    e.preventDefault();
    this.setState({
      selectedPaper: paper,
      showDetailModal: true,
    });
  };

  toggleDetailModal = () => {
    this.setState((prevState) => ({
      showDetailModal: !prevState.showDetailModal,
    }));
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleSortChange = (sortColumn) => {
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  fetchAllStudents = async () => {
    const { mid, lg_user_id, lg_user_table_id, form_list_id } = this.props.state;

    this.setState({ isTableDataLoading: true });

    let userType = sessionStorage.getItem("userType");
    let user_id;
    let user_table_id;

    if (userType === "parent") {
      user_id = sessionStorage.getItem("parent_group_id");
      user_table_id = sessionStorage.getItem("parent_group_user_table_id");
    } else {
      user_id = lg_user_id;
      user_table_id = lg_user_table_id;
    }

    await StudentService.getAllStudentsForParent(
      mid,
      form_list_id,
      user_id,
      user_table_id,
      userType
    )
      .then((response) => {
        if (response.success) {
          this.setState({ studentlist: response.data.students });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching students. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching students. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  toggleEnrollModal = (asses_id, assess_name) => {
    const { userType } = this.state;
    const session_id = sessionStorage.getItem("manage_user_id");
    const handleEnrollment = () => {
      this.setState({ isSpinnerLoading: true }, () => {
        if (userType === "student") {
          StudentService.getreference(session_id).then((response) => {
            if (
              response.data.reference_id &&
              response.data.has_parent
            ) {
              this.handleSubmit(asses_id, assess_name);
            } else {
              this.handleSubmitStudent(asses_id, assess_name);
            }
          });
        } else {
          this.handleSubmit(asses_id, assess_name);
        }
      });
    };

    var confirmMessage = "";

    if (userType === "student") {
      confirmMessage = "Are you sure you want to enroll for this exam?";
    }
    else if (userType === "parent") {
      confirmMessage = "Do you want to enroll this exam for your child?";
    }
    else {
      confirmMessage = "Do you want to enroll this exam for the selected student?";
    }

    sweetAlertEnroll("Enroll Confirmation", confirmMessage).then((enrollConfirmed) => {
      if (enrollConfirmed) {
        this.setState({ showInsertModal: false });
        handleEnrollment();
      }
    });

  };

  handleSubmitStudent = async (assessment_id, assess_name) => {
    const { selectedStudent, userType, selectedCredit } = this.state;

    const UserId = sessionStorage.getItem("manage_user_id");

    var enrolledby = "";
    var credit_id = "";

    const attempt_no = this.state.papers.find(
      (assessment) => assessment.assessment_id === assessment_id
    );

    if (userType === "student") {
      enrolledby = "Student";
    } else if (userType === "admin") {
      enrolledby = "Admin";
    } else {
      enrolledby = "Parent";
    }

    let student = this.state.studentlist.find(
      (student) => student.user_id === parseInt(selectedStudent)
    );
    if (selectedCredit) {
      credit_id = selectedCredit.credit_id;
    }

    const enrolled_paper = {
      node_mid: !!this.props.state.mid ? this.props.state.mid : null,
      create_user_id:
        userType === "admin" || userType === "student"
          ? this.state.create_user_id
          : this.props.state.lg_user_id,
      create_user_table_id:
        userType === "parent"
          ? this.props.state.lg_user_table_id
          : this.state.create_user_table_id,
      enroll_user_id:
        userType === "student" ? UserId : parseInt(selectedStudent),
      assessment_id: assessment_id,
      assess_name: assess_name,
      paper_status: 0,
      enrolled_by: enrolledby,
      enroll_user_table_id: userType === "student" ? 2 : student.user_table_id,
      user_table_id: userType === "student" ? 2 : student.user_table_id,
      attempt_no: attempt_no.attempt_no,
      userType: userType,
      credit_id: credit_id,
    };

    EnrolledPaperService.selfenrollPaperToNode(JSON.stringify(enrolled_paper))
      .then((response) => {
        if (response.success) {
          sweetStatusAlert(
            "Success!",
            "You have successfully enrolled to past paper " +
            enrolled_paper.assess_name +
            "!",
            "success"
          );

          this.callAfterSubmit();
          this.setState({
            isSpinnerLoading: false,
            selectedCredit: null,
            isCreditNotAvailable: false,
            isModified: false,
            asses_id: null,
            assess_name: null,
            showInsertModal: false,
          });
        } else {
          sweetStatusAlert(
            "Error!",
            "Failed to enroll. You may not have enough credits. Please check your available credits!.",
            "error"
          );
          this.setState({ isSpinnerLoading: false });
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Error!",
          "An error occurred while enrolling the student. Please try again later.",
          "error"
        );
        this.setState({ isSpinnerLoading: false });
      });
  };

  handleStudentSelect = (event) => {
    if (!event.target.value) {
      this.setState({ selectedStudent: "" });
      return;
    }
    let student = this.state.studentlist.find(
      (student) => student.user_id === parseInt(event.target.value)
    );
    this.setState({ isTableDataLoading: true });
    const { flg_list_active } = this.props.state;
    if (this.state.userType === "admin") {
      if (flg_list_active === 1) {
        StudentService.getCreateUserDetail(
          student.user_id,
          student.user_table_id,
          this.state.userType
        ).then((response) => {
          const create_user_id = response.data.user_detail[0].create_user_id;
          const create_user_table_id =
            response.data.user_detail[0].create_user_table_id;
          this.setState({
            create_user_id: create_user_id,
            create_user_table_id: create_user_table_id,
          });
        });
      }
    }
    const selectedStudent = student.user_id;
    if (selectedStudent) {
      this.setState({ selectedStudent }, async () => {
        await this.fetchSelectedStudentGrade();
        await this.fetchParentData();
        await this.fetchEnrollPaperData();
      });
    }
  };

  handleSubmit = async (assessment_id, assess_name) => {
    const { selectedStudent, userType, selectedCredit } = this.state;

    const UserId = sessionStorage.getItem("manage_user_id");

    var enrolledby = "";
    var sentTo = "";
    var msg = "";
    var credit_id = "";

    const attempt_no = this.state.papers.find(
      (assessment) => assessment.assessment_id === assessment_id
    );

    if (userType === "student") {
      enrolledby = "Student";
    } else if (userType === "admin") {
      enrolledby = "Admin";
    } else {
      enrolledby = "Parent";
    }

    if (userType === "student") {
      sentTo = "your parent";
      msg = "Please wait until your parent approves";
    } else if (userType === "admin") {
      sentTo = "Parent";
      msg = "Please wait until the parent of the selected student approves";
    } else {
      sentTo = "you";
      msg = "Please check your mail inbox or spam/junk";
    }
    let student = this.state.studentlist.find(
      (student) => student.user_id === parseInt(selectedStudent)
    );
    if (selectedCredit) {
      credit_id = selectedCredit.credit_id;
    }

    const enrolled_paper = {
      node_mid: !!this.props.state.mid ? this.props.state.mid : null,
      create_user_id:
        userType === "admin" || userType === "student"
          ? this.state.create_user_id
          : sessionStorage.getItem("parent_group_id"),
      create_user_table_id:
        userType === "parent"
          ? sessionStorage.getItem("parent_group_user_table_id")
          : this.state.create_user_table_id,
      enroll_user_id:
        userType === "student" ? UserId : parseInt(selectedStudent),
      assessment_id: assessment_id,
      assess_name: assess_name,
      paper_status: 0,
      enrolled_by: enrolledby,
      enroll_user_table_id: userType === "student" ? 2 : student.user_table_id,
      user_table_id: userType === "student" ? 2 : student.user_table_id,
      attempt_no: attempt_no.attempt_no,
      userType: userType,
      credit_id: credit_id,
    };

    EnrolledPaperService.addEnrolledPaperToNode(JSON.stringify(enrolled_paper))
      .then((response) => {
        if (response.success) {
          let parentEmail = response?.data?.credit_owner_parent_email
          if (userType === "student") {
            sweetStatusAlert(
              "Success!",
              "You have enrolled to the paper successfully!",
              "success"
            );
          }
          else {
            sweetStatusAlert(
              "Success!",
              "Student has been enrolled to the paper successfully!",
              "success"
            );
          }
          this.fetchEnrollPaperData();
          this.callAfterSubmit();
          this.setState({
            isSpinnerLoading: false,
            selectedCredit: null,
            isCreditNotAvailable: false,
            isModified: false,
            asses_id: null,
            assess_name: null,
            showInsertModal: false,
          });
        } else {
          sweetStatusAlert(
            "Error!",
            "Failed to enroll. Please try again later.",
            "error"
          );
          this.setState({ isSpinnerLoading: false });
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Error!",
          "An error occurred while enrolling the student. Please try again later.",
          "error"
        );
        this.setState({ isSpinnerLoading: false });
      });
  };

  fetchEnrollPaperData = async () => {
    this.setState({ isTableDataLoading: true });
    const { selectedStudent, create_user_id, create_user_table_id } = this.state;
    const {
      mid,
      owner_restriction,
      lg_user_id,
      lg_user_table_id,
      user_list_table_id,
      user_list_restriction,
    } = this.props.state;

    // let create_user_id =
    //   owner_restriction === "True" && lg_user_id ? lg_user_id : "null";
    // let create_user_table_id =
    //   owner_restriction === "True" && lg_user_table_id
    //     ? lg_user_table_id
    //     : "null";
    let enroll_user_table_id =
      user_list_restriction === "True" && user_list_table_id
        ? user_list_table_id
        : "null";
    // let enroll_user_id = (user_list_restriction === "True" && selectedStudent) ? selectedStudent : 'null'
    let enroll_user_id =
      user_list_restriction === "True" && selectedStudent
        ? selectedStudent
        : selectedStudent;

    await EnrolledPaperService.getAllEnrolledPapersMultiFilter(
      create_user_id,
      create_user_table_id,
      lg_user_id,
      lg_user_table_id,
      this.state.userType
    )
      .then((res) => {
        this.setState({ enrolledPapers: res.data.enrolledPapers });
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  fetchAllEnrolledChildPapers = async () => {
    this.setState({ isTableDataLoading: true });
    const { lg_user_id } = this.props.state;
    this.setState({ selectedStudent: lg_user_id }, () => {
      this.fetchEnrollPaperData();
    });
    this.setState({ isTableDataLoading: false });
  };

  onFilterInput = (v, r) => {
    let categoryOptions;
    let languageOptions;
    let subjectOptions;
    let authorOptions;
    let gradeOptions;
    let yearOptions;
    let termOptions;
    let chapterOptions;
    let difficultyOptions;
    let selectedFilters = [];
    this.setState({
      filters: v,
      filterValues: [],
    });

    if (r === "removeOption") {
      this.setState(
        {
          selectedAuthors: null,
          selectedsubjects: null,
          selectedChapters: null,
          selectedTerms: null,
          selectedCategories: null,
          selectedYears: null,
          selectedLanguages: null,
          selectedDifficulties: null,
          selectedGrades: null,
        },
        () => {
          this.callAfterSubmit();
        }
      );
    }
    if (v.includes("Category")) {
      categoryOptions = this.state.allCategories?.map((val) => {
        return {
          filter: "Category",
          name: val.name,
          category_id: val.category_id,
        };
      });
      selectedFilters = [...selectedFilters, ...categoryOptions];
    }
    if (v.includes("Language")) {
      languageOptions = this.state.allLanguages?.map((val) => {
        return {
          filter: "Language",
          name: val.language,
          language_id: val.language_id,
        };
      });
      selectedFilters = [...selectedFilters, ...languageOptions];
    }
    if (v.includes("Subject")) {
      subjectOptions = this.state.allSubjects?.map((val) => {
        return {
          filter: "Subject",
          name: val.subject,
          subject_id: val.subject_id,
        };
      });
      selectedFilters = [...selectedFilters, ...subjectOptions];
    }
    if (v.includes("Author")) {
      authorOptions = this.state.allAuthors?.map((val) => {
        return {
          filter: "Author",
          name: val.author,
          author_id: val.author_id,
        };
      });
      selectedFilters = [...selectedFilters, ...authorOptions];
    }
    if (v.includes("Year")) {
      yearOptions = this.state.allYears?.map((val) => {
        return {
          filter: "Year",
          name: val.year,
          year_id: val.year_id,
        };
      });
      selectedFilters = [...selectedFilters, ...yearOptions];
    }
    if (v.includes("Difficulty")) {
      difficultyOptions = this.state.allDifficulties?.map((val) => {
        return {
          filter: "Difficulty",
          name: val.difficulty_type,
          difficulty_id: val.difficulty_id,
        };
      });
      selectedFilters = [...selectedFilters, ...difficultyOptions];
    }
    if (v.includes("Grade")) {
      gradeOptions = this.state.allGrades?.map((val) => {
        return {
          filter: "Grade",
          name: val.grade,
          grade_id: val.grade_id,
        };
      });
      selectedFilters = [...selectedFilters, ...gradeOptions];
    }
    if (v.includes("Term")) {
      termOptions = this.state.allTerms?.map((val) => {
        return {
          filter: "Term",
          name: val.term,
          term_id: val.term_id,
        };
      });
      selectedFilters = [...selectedFilters, ...termOptions];
    }
    if (v.includes("Chapter")) {
      chapterOptions = this.state.allChapters?.map((val) => {
        return {
          filter: "Chapter",
          name: val.chapter,
          chapter_id: val.chapter_id,
        };
      });
      selectedFilters = [...selectedFilters, ...chapterOptions];
    }
    this.setState({
      selectedFilters: selectedFilters,
    });
  };

  onSelectFilter = (e) => {
    let selectedGrades = [];
    let selectedChapters = [];
    let selectedsubjects = [];
    let selectedAuthors = [];
    let selectedYears = [];
    let selectedCategories = [];
    let selectedTerms = [];
    let selectedDifficulties = [];
    let selectedLanguages = [];

    e.map((val) => {
      if (val.filter === "Category") {
        selectedCategories.push(val?.category_id);
      }
      if (val.filter === "Difficulty") {
        selectedDifficulties.push(val?.difficulty_id);
      }
      if (val.filter === "Language") {
        selectedLanguages.push(val?.language_id);
      }
      if (val.filter === "Subject") {
        selectedsubjects.push(val?.subject_id);
      }
      if (val.filter === "Author") {
        selectedAuthors.push(val?.author_id);
      }
      if (val.filter === "Year") {
        selectedYears.push(val?.year_id);
      }
      if (val.filter === "Term") {
        selectedTerms.push(val?.term_id);
      }
      if (val.filter === "Chapter") {
        selectedChapters.push(val?.chapter_id);
      }
      if (val.filter === "Grade") {
        selectedGrades.push(val?.grade_id);
      }
    });

    this.setState(
      {
        filterValues: e,
        selectedAuthors: selectedAuthors.length > 0 ? selectedAuthors : null,
        selectedsubjects: selectedsubjects.length > 0 ? selectedsubjects : null,
        selectedChapters: selectedChapters.length > 0 ? selectedChapters : null,
        selectedTerms: selectedTerms.length > 0 ? selectedTerms : null,
        selectedCategories:
          selectedCategories.length > 0 ? selectedCategories : null,
        selectedYears: selectedYears.length > 0 ? selectedYears : null,
        selectedLanguages:
          selectedLanguages.length > 0 ? selectedLanguages : null,
        selectedDifficulties:
          selectedDifficulties.length > 0 ? selectedDifficulties : null,
        selectedGrades: selectedGrades.length > 0 ? selectedGrades : null,
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  toggleAddEnrollModal = (asses_id, assess_name, grade_id) => {
    const { userType } = this.state;

    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        asses_id: asses_id,
        assess_name: assess_name,
        grade_id: grade_id,
        selectedCredit: null,
        isCreditNotAvailable: false,
      }),
      () => {
        if (this.state.showInsertModal) {
          if (userType === "student") {
            this.getAllEnrollCredits();
          } else if (userType === "parent" || userType === "admin") {
            this.getAllEnrollCreditsForParent();
          }
        } else {
          this.setState({ selectedCredit: null, grade_id: "" });
        }
      }
    );
  };

  handleChangeCredit = (event) => {
    const { name, value } = event.target;
    const { credits } = this.state;

    const selectedCredit = credits.find(
      (credit) => credit.credit_id === parseInt(value)
    );

    this.setState({ selectedCredit: selectedCredit, isModified: true }, () => {
      if (this.state.selectedCredit) {
        if (this.state.selectedCredit.available_credits <= 0) {
          this.setState({ isCreditNotAvailable: true });
        } else {
          this.setState({ isCreditNotAvailable: false });
        }
      } else {
        this.setState({ selectedCredit: null });
      }
    });
  };

  getAllEnrollCredits() {
    this.setState({
      is_level_price_loading: true,
      creditsLoading: true,
    });

    let mid = 100;
    const { grade_id } = this.state;

    const UserId = sessionStorage.getItem("manage_user_id");
    CreditService.getAllCreditsForPaperEnrollment(mid, UserId, grade_id)
      .then((res) => {
        if (res.success) {
          this.setState({ credits: res.data.credits });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Credits. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          is_level_price_loading: false,
          creditsLoading: false,
        });
      });
  }

  getAllEnrollCreditsForParent() {
    this.setState({
      is_level_price_loading: true,
      creditsLoading: true,
    });

    // const { mid } = this.props.state;
    let mid = 100;
    const { grade_id } = this.state;

    const UserId = this.state.selectedStudent;
    CreditService.getAllParentCreditsForPaperEnrollment(mid, UserId, grade_id)
      .then((res) => {
        if (res.success) {
          this.setState({ credits: res.data.credits });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Credits. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          is_level_price_loading: false,
          creditsLoading: false,
        });
      });
  }

  redirectToSubscriptions() {
    const state = {
      privilege_edit: 1,
      privilege_add: 1,
      privilege_delete: 1,
      nodeTitle: 'Subscriptions',
      mid: 100,
      main_table_id: 10,
      owner_restriction: null,
      lg_user_id: sessionStorage.getItem("manage_user_id"),
      lg_user_table_id: sessionStorage.getItem("table_log_id"),
      user_list_restriction: null,
      flg_list_active: this.state.userType !== 'student' ? 1 : 0,
      form_list_id: 29,
      tab_param: 0
    };

    history.push({
      pathname: routes.manage_subscription_main,
      state: { ...state },
    });
  }

  // NEw functions

  onSelectFilterMulti = (selectedItem) => {

    const { filterValues } = this.state;

    let updatedFilterValues = [...filterValues];

    const index = updatedFilterValues.findIndex(
      (item) =>
        item.filter === selectedItem.filter &&
        item[`${selectedItem.filter.toLowerCase()}_id`] === selectedItem[`${selectedItem.filter.toLowerCase()}_id`]
    );


    if (index === -1) {

      updatedFilterValues.push(selectedItem);

    } else {

      updatedFilterValues.splice(index, 1);
    }


    this.onSelectFilterMultiProcess(updatedFilterValues);
  };

  onSelectFilterMultiProcess = (filterValues) => {

    const filterMap = {
      Category: [],
      Difficulty: [],
      Language: [],
      Subject: [],
      Author: [],
      Year: [],
      Term: [],
      Chapter: [],
      Grade: []
    };

    filterValues.forEach((val) => {
      const filterType = val.filter;
      if (filterType && filterMap.hasOwnProperty(filterType)) {
        switch (filterType) {
          case "Category":
            filterMap.Category.push(val?.category_id);
            break;
          case "Difficulty":
            filterMap.Difficulty.push(val?.difficulty_id);
            break;
          case "Language":
            filterMap.Language.push(val?.language_id);
            break;
          case "Subject":
            filterMap.Subject.push(val?.subject_id);
            break;
          case "Author":
            filterMap.Author.push(val?.author_id);
            break;
          case "Year":
            filterMap.Year.push(val?.year_id);
            break;
          case "Term":
            filterMap.Term.push(val?.term_id);
            break;
          case "Chapter":
            filterMap.Chapter.push(val?.chapter_id);
            break;
          case "Grade":
            filterMap.Grade.push(val?.grade_id);
            break;
          default:
            break;
        }
      }
    });

    this.setState(
      {
        filterValues,
        selectedAuthors: filterMap.Author.length > 0 ? filterMap.Author : null,
        selectedsubjects: filterMap.Subject.length > 0 ? filterMap.Subject : null,
        selectedChapters: filterMap.Chapter.length > 0 ? filterMap.Chapter : null,
        selectedTerms: filterMap.Term.length > 0 ? filterMap.Term : null,
        selectedCategories:
          filterMap.Category.length > 0 ? filterMap.Category : null,
        selectedYears: filterMap.Year.length > 0 ? filterMap.Year : null,
        selectedLanguages:
          filterMap.Language.length > 0 ? filterMap.Language : null,
        selectedDifficulties:
          filterMap.Difficulty.length > 0 ? filterMap.Difficulty : null,
        selectedGrades: filterMap.Grade.length > 0 ? filterMap.Grade : null
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  isParentChecked = (filterType) => {
    const filterValues = this.state.filterValues;
    const childItems = this.getChildItems(filterType);
    return (
      childItems.length > 0 &&
      childItems.every((child) =>
        filterValues.some(
          (selectedItem) =>
            selectedItem.filter === filterType &&
            selectedItem[`${filterType.toLowerCase()}_id`] ===
            child[`${filterType.toLowerCase()}_id`]
        )
      )
    );
  };

  isParentIndeterminate = (filterType) => {
    const filterValues = this.state.filterValues;
    const childItems = this.getChildItems(filterType);
    const selectedCount = childItems.filter((child) =>
      filterValues.some(
        (selectedItem) =>
          selectedItem.filter === filterType &&
          selectedItem[`${filterType.toLowerCase()}_id`] ===
          child[`${filterType.toLowerCase()}_id`]
      )
    ).length;
    return selectedCount > 0 && selectedCount < childItems.length;
  };

  handleParentCheckboxChange = (filterType, filterItems) => {
    const filterValues = [...this.state.filterValues];
    const childItems = filterItems

    const allSelected = this.isParentChecked(filterType);

    let updatedFilterValues;
    if (allSelected) {

      updatedFilterValues = filterValues.filter(
        (item) => item.filter !== filterType
      );
    } else {

      const newSelections = childItems.map((child) => ({
        filter: filterType,
        name: child.name,
        [`${filterType.toLowerCase()}_id`]: child[`${filterType.toLowerCase()}_id`],
      }));

      updatedFilterValues = [
        ...filterValues.filter((item) => item.filter !== filterType),
        ...newSelections,
      ];
    }


    this.onSelectFilterMultiProcess(updatedFilterValues);
  };

  getChildItems = (filterType) => {

    switch (filterType) {
      case "Category":
        return this.state.allCategories || [];
      case "Difficulty":
        return this.state.allDifficulties || [];
      case "Language":
        return this.state.allLanguages || [];
      case "Subject":
        return this.state.allSubjects || [];
      case "Author":
        return this.state.allAuthors || [];
      case "Year":
        return this.state.allYears || [];
      case "Grade":
        return this.state.allGrades || [];
      case "Term":
        return this.state.allTerms || [];
      case "Chapter":
        return this.state.allChapters || [];
      default:
        return [];
    }
  };

  clearAllFilters = () => {
    this.setState(
      {
        filterValues: [],
        selectedAuthors: null,
        selectedsubjects: null,
        selectedChapters: null,
        selectedTerms: null,
        selectedCategories: null,
        selectedYears: null,
        selectedLanguages: null,
        selectedDifficulties: null,
        selectedGrades: null
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  findLanguage = (language_id) => {
    const { allLanguages } = this.state
    if (allLanguages && allLanguages.length > 0) {

      const paperLanguage = allLanguages.find((language) => language.language_id === parseInt(language_id));

      return paperLanguage ? String(paperLanguage.language) : "N/A"
    }
    else {
      return "N/A"
    }
  }

  




  render() {
    const {
      papers,
      totalPapers,
      selectedStudent,
      studentlist,
      isSpinnerLoading,
      creditsLoading,
      isTableDataLoading,
      enrolledPapers,
      limit,
      currentPage,
      editVisibility,
      addVisibility,
      isDropHidden,
      userType,
      credits,
      isCreditNotAvailable,
      selectedCredit,
      offset,
      isGradeLoading,
      isSubjectLoading,
      isYearLoading
    } = this.state;
    const path = history.location.pathname;

    // const [expanded, setExpanded] = useState(false);

    // const handleAccordionChange = () => {
    //   setExpanded((prev) => !prev);
    // };

    return (
      <>
        <ContentWrapper disabled={path === "/manage-search"}>
          <Card>
            <CardHeader>
              {isDropHidden ? (
                <div className="col-md-12 d-flex align-items-center dropdown px-0">
                  <div className="dropdown mb-2">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Select
                        aria-label="Select the student"
                        name="student_id"
                        value={selectedStudent}
                        onChange={this.handleStudentSelect}
                      >
                        <option
                          value=""
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          Select Student
                        </option>
                        {studentlist.map((student) => (
                          <option key={student.user_id} value={student.user_id}>
                            {student.data_concat}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-4 px-0">
                    {(selectedStudent || !isDropHidden) && (
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleSearch}
                          isInvalid={this.state.searchError}
                          className="col-md-8"
                        />
                        {this.state.searchError && (
                          <Form.Control.Feedback type="invalid">
                            Invalid input
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    )}
                  </div>
                </div>
              ) : (
                <div className="col-md-12 d-flex align-items-center dropdown px-0">

                  {/* <Form.Group
                    controlId="exampleForm.ControlInput1"
                    className="mb-2 searchBySec"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      onChange={this.handleSearch}
                      isInvalid={this.state.searchError}
                      className="col-md-8"
                    />
                    {this.state.searchError && (
                      <Form.Control.Feedback type="invalid">
                        Invalid input
                      </Form.Control.Feedback>
                    )}
                  </Form.Group> */}

                  <div className="col-md-12 px-0 filterBySec">

                    <Accordion className="filterAccordion"
                    // expanded={expanded} onChange={() => {}}
                    >

                      <AccordionSummary className="filterAccordionSummary" aria-controls="panel1-content" id="panel1-header">
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                        >
                          <div className="filterpprs">
                            <FilterAltIcon />
                            &emsp;Filter&emsp;
                            <KeyboardArrowDownIcon
                              sx={{
                                // transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                                transition: "transform 0.3s ease",
                                cursor: "pointer", // Make it clear this is clickable
                              }}
                              onClick={(e) => {
                                // Prevent the click from affecting the Accordion
                                // e.stopPropagation(); 
                                // Manually toggle the accordion state
                                // handleAccordionChange(); 
                              }}
                            />
                          </div>
                        </Box>

                        <Typography component="h5" sx={{ color: 'text.primary',display:'flex', alignItems:'center', justifyContent:'center' }}>
                          &emsp;Paper Count : &emsp; {this.state.totalPapers? this.state.totalPapers: "0"}
                        </Typography>
                      </AccordionSummary>
                        

                      <Box className="filterCardContainer" sx={{ overflowX: "auto", whiteSpace: "nowrap", display: "flex", padding: "8px", height: '40vh' }}>

                        {ASSESSMENT_FILTERS.filter((category) => {

                          if (this.state.userType === "student") {
                            return ["Grade", "Subject", "Language"].includes(category);
                          }
                          return true;
                        }).map((category, index) => {

                          let filterItems = [];
                          if (category === "Category") {
                            filterItems = this.state.allCategories?.map((val) => ({
                              filter: "Category",
                              name: val.name,
                              category_id: val.category_id,
                            }));
                          } else if (category === "Difficulty") {
                            filterItems = this.state.allDifficulties?.map((val) => ({
                              filter: "Difficulty",
                              name: val.difficulty_type,
                              difficulty_id: val.difficulty_id,
                            }));
                          } else if (category === "Language") {
                            filterItems = this.state.allLanguages?.map((val) => ({
                              filter: "Language",
                              name: val.language,
                              language_id: val.language_id,
                            }));
                          } else if (category === "Subject") {
                            filterItems = this.state.allSubjects?.map((val) => ({
                              filter: "Subject",
                              name: val.subject,
                              subject_id: val.subject_id,
                            }));
                          } else if (category === "Author") {
                            filterItems = this.state.allAuthors?.map((val) => ({
                              filter: "Author",
                              name: val.author,
                              author_id: val.author_id,
                            }));
                          } else if (category === "Year") {
                            filterItems = this.state.allYears?.map((val) => ({
                              filter: "Year",
                              name: val.year,
                              year_id: val.year_id,
                            }));
                          } else if (category === "Grade") {
                            filterItems = this.state.allGrades?.map((val) => ({
                              filter: "Grade",
                              name: val.grade,
                              grade_id: val.grade_id,
                            }));
                          } else if (category === "Term") {
                            filterItems = this.state.allTerms?.map((val) => ({
                              filter: "Term",
                              name: val.term,
                              term_id: val.term_id,
                            }));
                          } else if (category === "Chapter") {
                            filterItems = this.state.allChapters?.map((val) => ({
                              filter: "Chapter",
                              name: val.chapter,
                              chapter_id: val.chapter_id,
                            }));
                          }

                          return (
                            <div className="filterCard" key={index} style={{ display: "inline-block", minWidth: "300px", marginRight: "16px", maxHeight: "30vh" }}>
                              <FormControlLabel
                                label={category}

                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  "& .MuiFormControlLabel-label": { fontWeight: "600" },
                                }}
                                control={
                                  <Checkbox
                                    checked={this.isParentChecked(category)}
                                    indeterminate={this.isParentIndeterminate(category)}
                                    onChange={() => this.handleParentCheckboxChange(category, filterItems)}
                                  />
                                }
                              />

                              <Box sx={{ display: "flex", flexDirection: "column", ml: 3, maxHeight: "20vh", overflowY: "auto" }}>

                                {filterItems.map((item) => {

                                  const isChecked = this.state.filterValues.some(
                                    (selectedItem) =>
                                      selectedItem.filter === item.filter &&
                                      selectedItem[`${item.filter.toLowerCase()}_id`] === item[`${item.filter.toLowerCase()}_id`]
                                  )

                                  return (
                                    <FormControlLabel
                                      key={item[`${category.toLowerCase()}_id`]}
                                      label={`${item.name}`}

                                      control={
                                        <Checkbox
                                          checked={isChecked}
                                          onChange={() => this.onSelectFilterMulti(item)}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </Box>

                            </div>

                          );

                        })}
                      </Box>

                    </Accordion>

                    {this.state.filterValues && this.state.filterValues.length > 0 && (
                      <PaperListChips
                        filterValues={this.state.filterValues}
                        chipClick={this.onSelectFilterMulti}
                        clearAll={this.clearAllFilters}
                      />
                    )}
                    {/* <FormGroup labelName="Filter By">
                      <Autocomplete
                        multiple
                        id="checkboxes-filter"
                        options={ASSESSMENT_FILTERS}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        onChange={(e, v, r) => this.onFilterInput(v, r)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        className="col-lg-8 col-md-9 p-0"
                        renderInput={(params) => (
                          <TextField {...params} label="Select Filter" />
                        )}
                      />
                    </FormGroup> */}
                  </div>
                  <div className="col-md-4 px-0">
                    {this.state.filters.length > 0 && (
                      <FormGroup labelName="">
                        <Autocomplete
                          multiple
                          id="grouped-demo"
                          className="col-lg-8 col-md-9 p-0"
                          options={this.state.selectedFilters}
                          groupBy={(option) => option.filter}
                          onChange={(e, v) => this.onSelectFilter(v)}
                          getOptionLabel={(option) => option.name}
                          sx={{ width: 300 }}
                          value={this.state.filterValues}
                          renderInput={(params) => (
                            <TextField {...params} label="Filters" />
                          )}
                        />
                      </FormGroup>
                    )}
                  </div>
                </div>
              )}
            </CardHeader>

            {isDropHidden ? (
              <CardHeader>
                <div className="col-md-6 px-0">
                  {(selectedStudent || !isDropHidden) && (

                    <FormGroup labelName="Filter By">
                      <Autocomplete
                        multiple
                        id="checkboxes-filter"
                        options={ASSESSMENT_FILTERS}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        onChange={(e, v, r) => this.onFilterInput(v, r)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        className="col-lg-8 col-md-9 p-0"
                        renderInput={(params) => (
                          <TextField {...params} label="Select Filter" />
                        )}
                      />
                    </FormGroup>

                  )}
                </div>
                {(selectedStudent || !isDropHidden) && (
                  <div className="col-md-6 px-0">
                    {this.state.filters.length > 0 && (
                      <FormGroup labelName="Filters">
                        <Autocomplete
                          multiple
                          id="grouped-demo"
                          className="col-lg-8 col-md-9 p-0"
                          options={this.state.selectedFilters}
                          groupBy={(option) => option.filter}
                          onChange={(e, v) => this.onSelectFilter(v)}
                          getOptionLabel={(option) => option.name}
                          sx={{ width: 300 }}
                          value={this.state.filterValues}
                          renderInput={(params) => (
                            <TextField {...params} label="Filters" />
                          )}
                        />
                      </FormGroup>
                    )}
                  </div>
                )}
              </CardHeader>
            ) : (
              <></>
            )}

            <CardBody>
              {isSpinnerLoading && (
                <PageSpinner id="show" text="Loading, Please wait!" />
              )}
              {(isGradeLoading || isSubjectLoading || isYearLoading) && (
                <div>
                  <div className="loading-container mb-5 mt-10">
                    <ReactSpinner
                      animation="border"
                      role="status"
                      style={{ display: "block", margin: "auto" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </ReactSpinner>
                  </div>
                </div>
              )}
              {
                isTableDataLoading ? (
                  <div>
                    <div className="loading-container mb-5 mt-10">
                      <ReactSpinner
                        animation="border"
                        role="status"
                        style={{ display: "block", margin: "auto" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </ReactSpinner>
                    </div>
                  </div>
                ) : selectedStudent || !isDropHidden ? (
                  <div
                    style={{
                      overflowY: "auto",
                      overflowX: "auto",
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSortChange("assess_name")}
                          >
                            Paper Title{" "}
                            {this.state.sortColumn === "assess_name" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? "⬇️"
                                  : "⬆️"}
                              </span>
                            )}
                          </TableCell>

                          <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSortChange("year_id")}
                          >
                            Paper Language{" "}
                            {this.state.sortColumn === "language_id" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? "⬇️"
                                  : "⬆️"}
                              </span>
                            )}
                          </TableCell>

                          <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSortChange("subject_id")}
                          >
                            Paper Subject{" "}
                            {this.state.sortColumn === "subject_id" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? "⬇️"
                                  : "⬆️"}
                              </span>
                            )}
                          </TableCell>

                          <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSortChange("grade_id")}
                          >
                            Paper Grade{" "}
                            {this.state.sortColumn === "grade_id" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? "⬇️"
                                  : "⬆️"}
                              </span>
                            )}
                          </TableCell>





                          {userType === "admin" && <TableCell className="thead">
                            More Details
                          </TableCell>}
                          {(editVisibility || addVisibility) && (
                            <TableCell className="thead">
                              Enrollment Status
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {papers.map((paper) => {
                          const enrolledExam = enrolledPapers.find((exam) => {
                            if (userType === "student") {
                              return exam.assessment_id === paper.assessment_id;
                            }
                            return (
                              exam.assessment_id === paper.assessment_id &&
                              exam.enroll_user_id ===
                              parseInt(this.state.selectedStudent)
                            );
                          });
                          return (
                            <TableRow key={paper.id}>
                              {/* <TableCell className="tData">
                                {paper.node_mid}
                              </TableCell>
                              <TableCell className="tData">
                                {paper.assessment_id}
                              </TableCell> */}
                              <TableCell className="tData">
                                {paper.assess_name}
                              </TableCell>
                              <TableCell className="tData">
                                {this.findLanguage(paper.language_id)}
                              </TableCell>
                              <TableCell className="tData">
                                {this.findSubject(paper.subject_id)}
                              </TableCell>
                              <TableCell className="tData">
                                {this.findGrade(paper.grade_id)}
                              </TableCell>
                              {userType === "admin" && <TableCell className="tData">
                                <Button
                                  onClick={(e) => this.handleViewClick(e, paper)}
                                  className="btn btn-icon btn-primary btn-sm m-1 view-assement-btn flaticon-eye-icon"
                                  title="View Paper Details"
                                />
                              </TableCell>}
                              {/* {userType === "admin" &&
                                (editVisibility || addVisibility) && (
                                  <TableCell className="stu-tData">
                                    {enrolledExam ? (
                                      enrolledExam.paper_status === 0 ? (
                                        <Chip
                                          label="Pending"
                                          color="primary"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      ) : enrolledExam.paper_status === 1? (
                                        <Chip
                                          label="Approved"
                                          color="success"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      ) : enrolledExam.paper_status === 2 ? (
                                        <Chip
                                          label="Rejected"
                                          color="error"
                                          variant="outlined"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      ) : (
                                        <Chip
                                          label="Not enroll"
                                          color="success"
                                          variant="outlined"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      )
                                    ) : (
                                      <Button
                                        className="stu-variant-class"
                                        id="modal-close-button"
                                        onClick={() =>
                                          this.toggleEnrollModal(
                                            paper.assessment_id,
                                            paper.assess_name
                                          )
                                        }
                                      >
                                        Enroll
                                      </Button>
                                    )}
                                  </TableCell>
                                )} */}
                              {(userType === "student" ||
                                userType === "parent" || userType === "admin") &&
                                (editVisibility || addVisibility) && (
                                  <TableCell className="stu-tData">
                                    {enrolledExam ? (
                                      // <Tooltip placement="top" title="Select above enrolled papers tab to see the paper">
                                        <HtmlTooltip
                                          arrow
                                          placement="top"
                                          title={
                                            // <React.Fragment>
                                            //   <Typography color="inherit">Select above <b><u>Enrolled Papers</u></b> tab to see the paper</Typography>
                                            // </React.Fragment>
                                            <React.Fragment>
                                              <Typography color="inherit">
                                                Select{" "}
                                                <span
                                                  onClick={() => this.props.handleChange(null, 1)}
                                                  style={{
                                                    color: "blue",
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <b><u>Enrolled Papers</u></b>
                                                </span>{" "}
                                                tab to see the paper
                                              </Typography>
                                            </React.Fragment>
                                          }
                                        >
                                        <Chip
                                        label="Enrolled"
                                        color="success"
                                        style={{
                                          fontFamily: "Poppins, sans-serif",
                                        }}
                                        />
                                      </HtmlTooltip>
                                      // enrolledExam.paper_status === 0 ? (
                                      //   <Chip
                                      //     label="Pending"
                                      //     color="primary"
                                      //     style={{
                                      //       fontFamily: "Poppins, sans-serif",
                                      //     }}
                                      //   />
                                      // ) : enrolledExam.paper_status === 1 ? (
                                      //   <Chip
                                      //     label="Approved"
                                      //     color="success"
                                      //     style={{
                                      //       fontFamily: "Poppins, sans-serif",
                                      //     }}
                                      //   />
                                      // ) : enrolledExam.paper_status === 2 ? (
                                      //   <Chip
                                      //     label="Rejected"
                                      //     color="error"
                                      //     variant="outlined"
                                      //     style={{
                                      //       fontFamily: "Poppins, sans-serif",
                                      //     }}
                                      //   />
                                      // ) : (
                                      //   <Chip
                                      //     label="Not enroll"
                                      //     color="success"
                                      //     variant="outlined"
                                      //     style={{
                                      //       fontFamily: "Poppins, sans-serif",
                                      //     }}
                                      //   />
                                      // )
                                    ) : (
                                      <Tooltip
                                        title="Add to your paper list"
                                        placement="right"
                                        arrow
                                        PopperProps={{
                                          sx: {
                                            '& .MuiTooltip-tooltip': {
                                              fontSize: '12px',
                                            },
                                          },
                                        }}
                                      >
                                        <Button
                                          className="stu-variant-class"
                                          id="modal-close-button"
                                          onClick={() =>
                                            this.toggleAddEnrollModal(
                                              paper.assessment_id,
                                              paper.assess_name,
                                              paper.grade_id
                                            )
                                          }
                                        >
                                          <PlaylistAddIcon />
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <MaterialCustomTablePagination
                      totalCount={parseInt(totalPapers)}
                      currentPage={currentPage}
                      limit={limit}
                      handlePageChange={this.handlePageChange}
                      handleRowsPerPageChange={this.handleRowsPerPageChange}
                      handlePageOffsetChange={this.handlePageOffsetChange}
                      rowsPerPageOptions={[5, 10, 20]}
                      offset={offset}
                    />
                  </div>
                ) : null /* Render nothing if no student is selected */
              }
            </CardBody>
          </Card>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.showInsertModal}
            onHide={() => this.setState({ showInsertModal: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select The Credit To Enroll</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Available Credit Packages*</Form.Label>
                    {creditsLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "25vh !important",
                        }}
                      >
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <div className="pt-2">
                        {credits.length > 0
                          ? credits.map((credit) => (
                            <Sheet
                              key={credit.credit_id}
                              variant="outlined"
                              sx={{
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1.5,
                                p: 2,
                                mb: 2, // Add margin-bottom to separate each item
                                transition: 'background-color 0.3s, border-color 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                  backgroundColor: 'primary.50', // Background color change on hover
                                  boxShadow: 'md',
                                  borderColor: 'primary.500',
                                },
                              }}
                            >
                              <Radio
                                id={`credit-radio-${credit.credit_id}`}
                                name="credits"
                                value={credit.credit_id}
                                label={`${credit.level_price_name} - ${credit.type_name}`}
                                checked={this.state.selectedCredit && this.state.selectedCredit.credit_id === credit.credit_id}
                                onChange={(e) => this.handleChangeCredit(e)}
                                checkedIcon={<CheckCircleRoundedIcon />}
                                slotProps={{
                                  label: ({ checked }) => ({
                                    sx: {
                                      fontWeight: 'lg',
                                      fontSize: 'md',
                                      color: checked ? 'text.primary' : 'text.secondary',
                                    },
                                  }),
                                  action: ({ checked }) => ({
                                    sx: (theme) => ({
                                      ...(checked && {
                                        '--variant-borderWidth': '2px',
                                        '&&': {
                                          borderColor: theme.vars.palette.primary[500],
                                        },
                                      }),
                                    }),
                                  }),
                                }}
                              />
                              <span>
                                Available Credits - <b>{credit.available_credits}</b>
                              </span>
                              <span>
                                Expiration Date - <b>{TimeZoneHandler.convertTime(credit.expiration_date)}</b>
                              </span>
                              <span>
                                Paid by - <b>{credit.paid_by}</b>
                              </span>
                            </Sheet>
                          ))
                          : !this.state.is_level_price_loading && (
                            <div>
                              You need credits to enroll.
                              <br />
                              <Button
                                className="modal-btn"
                                variant="primary"
                                onClick={() => this.redirectToSubscriptions()}
                              >
                                Buy Credits
                              </Button>
                            </div>
                          )}
                      </div>
                    )}

                    <div style={{ color: "red" }}>
                      {/* {this.validator.message('level', level_id, 'required')} */}
                    </div>
                  </Form.Group>
                  {this.state.isCreditNotAvailable ? (
                    <div
                      className="col-md-6"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                        color: "red",
                      }}
                    >
                      <p>No Credits Available!</p>
                      <p>
                        Please subscribe to a suitable credit package to enroll
                        for this paper.
                      </p>
                      <br></br>
                      <Button
                        className="modal-btn"
                        variant="primary"
                        onClick={() => this.redirectToSubscriptions()}
                      >
                        Subscriptions
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </Row>

                <Modal.Footer>
                  <Button
                    className="modal-btn"
                    id="modal-close-button"
                    style={closeBtnStyle}
                    onClick={() => this.setState({ showInsertModal: false })}
                  >
                    Close
                  </Button>

                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    disabled={isCreditNotAvailable || selectedCredit == null}
                    onClick={() =>
                      this.toggleEnrollModal(
                        this.state.asses_id,
                        this.state.assess_name
                      )
                    }
                  >
                    Enroll
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            show={this.state.showDetailModal}
            onHide={this.toggleDetailModal}
          >
            <Modal.Header>
              <h5 className="modal-title">{this.state.selectedPaper?.assess_name}</h5>
              <Button variant="secondary" onClick={this.toggleDetailModal}>
                Close
              </Button>
            </Modal.Header>
            {this.state.selectedPaper && (
              <Modal.Body>
                <div className="form" id="kt_form">
                  {this.state.selectedPaper?.grade_id !== null &&
                    <>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Grade :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {this.getPaperGrade(this.state.selectedPaper?.grade_id)}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {this.state.selectedPaper?.category_id !== null &&
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Category :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {this.getPaperCategory(this.state.selectedPaper?.category_id)}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {this.state.selectedPaper?.language_id !== null &&
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Language :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {this.getPaperLanguage(this.state.selectedPaper?.language_id)}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {this.state.selectedPaper?.difficulty_id !== null &&
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Difficulty :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {this.getPaperDifficulty(this.state.selectedPaper?.difficulty_id)}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {/* {this.state.selectedPaper?.grade_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Grade :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperGrade(this.state.selectedPaper?.grade_id)}
                      </div>
                    </div>
                  </div>
                </>
              } */}
                  {/* {this.state.selectedPaper?.subject_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Subject :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperSubject(this.state.selectedPaper?.subject_id)}
                      </div>
                    </div>
                  </div>
                </>
              } */}
                  {this.state.selectedPaper?.chapter_id !== null &&
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Chapter/Module/Unit :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {this.getPaperChapter(this.state.selectedPaper?.chapter_id)}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {this.state.selectedPaper?.author_id !== null &&
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Author :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {this.getPaperAuthor(this.state.selectedPaper?.author_id)}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {/* {this.state.selectedPaper?.year_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Year :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperYear(this.state.selectedPaper?.year_id)}
                      </div>
                    </div>
                  </div>
                </>
              } */}
                  {this.state.selectedPaper?.term_id !== null &&
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Term/Semester :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {this.getPaperTerm(this.state.selectedPaper?.term_id)}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {this.state.selectedPaper?.first_name !== null && this.state.selectedPaper?.last_name !== null &&
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Created By :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {this.state.selectedPaper?.first_name + " " + this.state.selectedPaper?.last_name}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {this.state.selectedPaper?.created_at !== null &&
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Created At :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {this.getCreatedAtLocal(this.state.selectedPaper?.created_at)}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </Modal.Body>
            )}
          </Modal>
        </ContentWrapper>
      </>
    );
  }
}
