import React,{useState} from 'react'
import { getSender, getSenderFull } from "../../helper/ChatLogics";
import { useEffect } from "react";
import {
    Col,
    Row,
    Form,
    Modal,
    Spinner,
  } from "react-bootstrap";
import Button from '@mui/material/Button';
import { sweetChatRenameAlert, sweetStatusAlert, sweetStudentAddAlert, sweetStudentRemoveAlert, sweetSuccessAlert } from '../common/SweetAlert';
import { ChatService } from '../../service/manage/chat/chat.service';
import io from "socket.io-client";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Skeleton, TextField, Input } from "@mui/material";
import ScrollableChat from './ScrollableChat';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import LogoutIcon from '@mui/icons-material/Logout';
import { pink,red,green } from '@mui/material/colors';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import config from "../../helper/config";
import './SingleChat.css'
import { ChatState } from "../context/ChatProvider";
import { TreeMainService } from '../../service/manage/h_tree/tree_main.service';

const ENDPOINT = config.chat_socket_ep

var socket;

function SingleChat(props) {

    const { selectedChat, setSelectedChat, user, notification, setNotification } =ChatState();
    const {showChatModal, chat,chat_details,chat_server_details,toggleChatModal,updateState} = props
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newMessage, setNewMessage] = useState("");
    const [typing, setTyping] = useState(false);
    const [istyping, setIsTyping] = useState(false);
    const [socketConnected, setSocketConnected] = useState(false);
    const [groupChatEdit,setGroupChatEdit] = useState(false)
    const [studentsNotinChat,setStudentsNotinChat] = useState([])
    const [checked, setChecked] = useState([]);
    const [addChecked, setAddChecked] = useState([]);
    const [updateLoading,setUpdateLoading] = useState(false)
    const [chatName,setChatName] = useState(null)
    const [currentUser,setCurrentUser] = useState(null)
    const [typingUser,setTypingUser] = useState('')
    
    const checkUserInChat = (chat, given) => {
      // Check groupAdmin
      if (
        chat.groupAdmin.lg_user_id === given.lg_user_id &&
        chat.groupAdmin.lg_user_table_id === given.lg_user_table_id
      ) {
        return chat.groupAdmin;
      }
    
      // Check users array
      const matchingUser = chat.users.find(
        user =>
          user.lg_user_id === given.lg_user_id &&
          user.lg_user_table_id === given.lg_user_table_id
      );
    
      return matchingUser;
    };

    const fetchMessages = async () => {
        if (!chat_server_details._id) return;
        setLoading(true);
        try {
    
          setLoading(true);
          ChatService.getMessagesForChat(chat_server_details._id)
            .then((result)=>{
                setMessages(result);
                setLoading(false);
                socket.emit("join chat", chat_server_details._id);
            })
            .catch((error)=>{
                sweetStatusAlert("Error","Error getting messages","error")
            })
         
          
        } catch (error) {
          sweetStatusAlert("Oops","Failed to load messages","error")
        }
    };

      useEffect(() => {
        socket = io(ENDPOINT);
        if(chat.lg_user_id){
          socket.emit("setup", chat);
        }
        socket.on("connected", () => setSocketConnected(true));
        socket.on("typing", (currentUser) => {
          setIsTyping(true)
          setTypingUser(currentUser)
        });
        socket.on("stop typing", () => setIsTyping(false));
      },[]);

      useEffect(() => {
        fetchMessages();
        setCurrentUser(checkUserInChat(chat_server_details,{"lg_user_id": String(chat?.lg_user_id),"lg_user_table_id":String(chat?.lg_user_table_id)}))
      },[]);

      useEffect(() => {
        socket.on("message received", (newMessageReceived) => {
          if (
            newMessageReceived.sender.lg_user_id === String(chat.lg_user_id) &&
            newMessageReceived.sender.lg_user_table_id === String(chat.lg_user_table_id)
          ) {
            return;
          } else {
            setMessages((prevMessages) => {
              const messageAlreadyExists = prevMessages.some(
                (message) => message._id === newMessageReceived._id
              );
      
              if (!messageAlreadyExists) {
                return [...prevMessages, newMessageReceived];
              } else {
                return prevMessages;
              }
            });
          }
        });
      }, []);

      const sendMessage = async (event) => {
        event.preventDefault()
        if (event.key === "Enter" || newMessage) {
          socket.emit("stop typing", chat_server_details._id);
          try {
            const message = {
                content:newMessage,
                chatId:chat_server_details._id,
                userId:currentUser
            }
            ChatService.sendMessage(message)
                .then((response)=>{
                    setNewMessage("");
                    socket.emit("new message", response);
                    setMessages([...messages, response]);
                })
                .catch((error)=>{
                    sweetStatusAlert("Error","Error while sending message","error")
                })
    
          } catch (error) {
            sweetStatusAlert("Error","Error while sending message","error")
          }
        }
      };

      const typingHandler = (e) => {
        setNewMessage(e.target.value);
    
        if (!socketConnected) return;

            if (!istyping) {
              setTyping(true);
              socket.emit("typing", chat_server_details._id,currentUser);
            }

        let lastTypingTime = new Date().getTime();
        var timerLength = 3000;
    
        setTimeout(() => {
          var timeNow = new Date().getTime();
          var timeDiff = timeNow - lastTypingTime;
    
          if (timeDiff >= timerLength && typing) {
            socket.emit("stop typing", chat_server_details._id);
            setTyping(false);
          }
        }, timerLength);
      };

      const toggleEditGroupModal = ()=>{

        if(groupChatEdit){
          setAddChecked([]);
          setChecked([])
          setStudentsNotinChat([])
        }
        getNodeStudentsDetailsForEditChat();
        setGroupChatEdit(!groupChatEdit)

      };

      const getNodeStudentsDetailsForEditChat=async()=>{
        ChatService.getMembersListOfNode(chat.mid,chat.form_list_id,chat.lg_user_id,chat.lg_user_table_id)
          .then((response)=>{
            const members = JSON.parse(response.data.members)

            const filteredData = members.map(item => {
              let lg_user_id = String(item.user_id);
              let lg_user_table_id = String(item.user_table_id);
              let name = 'Unknown';
            
              const match = item.data_concat.match(/First Name:(.*?)\/Last Name:(.*?)$/);
              if (match) {
                name = match[1]+" "+match[2];
              }
            
              return {
                lg_user_id,
                lg_user_table_id,
                name
              };
            });
            setStudentsNotinChat(findStudentsNotinChat(filteredData))
          })
          .catch((error)=>{
            sweetStatusAlert("Error","Error getting members list","error")
          })
    
      };

      const findStudentsNotinChat = (students) => {
        const itemsNotInStudents = students.filter(student => !chat_server_details.users.some(student1 => student1.lg_user_id === student.lg_user_id && student1.lg_user_table_id === student.lg_user_table_id));
        return itemsNotInStudents;
      };

      const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          if (newChecked.length < chat_server_details.users.length - 2) {
            newChecked.push(value);
          }
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
      };

      const handleAddToggle=(value)=>()=>{
        const currentAddIndex = addChecked.indexOf(value);
        const newAddChecked = [...addChecked];
    
        if (currentAddIndex === -1) {
          newAddChecked.push(value);
        } else {
          newAddChecked.splice(currentAddIndex, 1);
        }
    
        setAddChecked(newAddChecked);
      };

      const handleToggleAll = (items) => () => {
        if(checked.length===0){
          setChecked(items)
        }
        else if(items.length === checked.length){
          setChecked([])
        }
        else{
          const uncheckedItems = items.filter((item) => !checked.includes(item));
          setChecked([...checked, ...uncheckedItems]);
        }
      };

      const handleAddToggleAll = (items) => () => {
        if(addChecked.length===0){
          setAddChecked(items)
        }
        else if(items.length === addChecked.length){
          setAddChecked([])
        }
        else{
          const uncheckedItems = items.filter((item) => !addChecked.includes(item));
          setAddChecked([...addChecked, ...uncheckedItems]);
        }
      };

      const removeUsersFromChat=(chatId)=>{
        if(checked.length<=0){
          sweetStatusAlert("Warnning","No students selected to remove","info")
        }
        else{
          
          sweetStudentRemoveAlert()
            .then((response)=>{
              if(response){
                setUpdateLoading(true)
                const extractedIds = checked.map(user => user._id);
                ChatService.removeUsers(extractedIds,chatId)
                  .then((response)=>{
                    if(response.status===200){
                      setUpdateLoading(false)
                      updateState(response.data)
                      setStudentsNotinChat([...studentsNotinChat,...checked])
                      setChecked([])
                      sweetSuccessAlert("Success","Users removed from chat","success")
                    }
                  })
                  .catch((error)=>{
                    setUpdateLoading(false)
                    sweetStatusAlert("Error","Error while removing users","error")
                  })
              }
            })
        }
      };

      const addUsersToChat=(chatId)=>{
        if(addChecked.length<=0){
          sweetStatusAlert("Warnning","No students selected to add","info")
        }
        else{
          sweetStudentAddAlert()
            .then((response)=>{
              if(response){
                setUpdateLoading(true)
                ChatService.addUsers(addChecked,chatId)
                  .then((response)=>{
                    if(response.status===200){
                      setUpdateLoading(false)
                      updateState(response.data)

                        const uncheckedItems = studentsNotinChat.filter((item) => !addChecked.includes(item));
                        setStudentsNotinChat([...uncheckedItems]);
                      
                      setAddChecked([])
                      sweetSuccessAlert("Success","Users added to the chat","success")
                    }
                  })
                  .catch((error)=>{
                    setUpdateLoading(false)
                    sweetStatusAlert("Error","Error while adding users","error")
                  })
              }
            })
        }
      };

      const renameChat=(chatId)=>{

        if(!chatName){
          sweetStatusAlert("Warnning","Please change name before update","info")
        }
        else{
          sweetChatRenameAlert()
          .then((response)=>{
            if(response){
              setUpdateLoading(true)
              ChatService.renameChat(chatName,chatId)
                .then((response)=>{
                  if(response.status===200){
                    setUpdateLoading(false)
                    updateState(response.data)
                    sweetSuccessAlert("Success","Chat name changed","success")
                  }
                })
                .catch((error)=>{
                  setUpdateLoading(false)
                  sweetStatusAlert("Error","Error while changing chat name","error")
                })
            }
          })
        }
        
      };

      const toggleChat=()=>{
        if(chat.lg_user_id){
        socket.emit('unsubscribe',chat)
        }
        toggleChatModal()
      }

  return (
      <div>
            <Modal
                show={showChatModal}
                onHide={toggleChat}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Modal.Header closeButton>
                    <Modal.Title>{chat?.form_display_title?chat.form_display_title+" ":'Not available'}<Chip label={chat_server_details?.chatName} color="primary" variant="outlined"/></Modal.Title>
                    
                    {(!!chat?.privilege_add || !!chat?.privilege_edit || !!chat?.privilege_delete || chat_server_details?.groupAdmin === String(chat?.lg_user_id)) && (
                      <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', marginRight: '-50px' }}>
                        <Tooltip title="Chat details">
                          <IconButton aria-label="delete" onClick={() => toggleEditGroupModal()}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                </Modal.Header>

                <Modal.Body>
                    {loading?(
                        <Box >
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                            >
                                <Skeleton variant="text" />
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                {/* <Skeleton variant="circular" width={40} height={40} /> */}
                                <Skeleton variant="rectangular" height={118} />
                            </Typography>
                        </Box>
                    ):(
                    <Box >

                           <div className='status_bar'>
                           {chat_server_details.users?.map((user) => (
                              <Chip
                                key={user.id} // Make sure to provide a unique key for each item in the map
                                label={user.name ? user.name : 'unknown'}
                                color="info"
                                size="small"
                                avatar={<Avatar alt={user.name ? user.name : 'U'} src={user.avatar || 'U'} />}
                                style={{ margin: '2px' }} // Add margin for spacing
                              />
                            ))}
                            </div>

                        <Typography id="modal-modal-description">
                            <ScrollableChat typingUser={typingUser} messages={messages} chat={chat} istyping={istyping}/>

                            <Form mt={3} style={{ display: 'flex', alignItems: 'end' }}>
                              <Input
                                id="standard-basic"
                                placeholder="Enter Message"
                                variant="standard"
                                value={newMessage}
                                onChange={typingHandler}
                                style={{ flex: 1 }}
                              />
                              <span>
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  type='submit'
                                  sx={{ marginLeft: "5px", backgroundColor: '#C7F6FF' }}
                                  onClick={sendMessage}
                                >
                                  <SendIcon />
                                </IconButton>
                              </span>
                            </Form>
                        </Typography>
                    </Box>
                    )}
                </Modal.Body>
            </Modal>

            <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={groupChatEdit}
            onHide={() => toggleEditGroupModal()}
            >
            <Modal.Header closeButton>
              <Modal.Title>{chat?.form_display_title?chat.form_display_title+" ":'Not available '}<Chip label={chat_server_details?.chatName?chat_server_details.chatName:'chat name undefined'} color="primary" variant="outlined"/></Modal.Title>
            </Modal.Header>
              <Modal.Body>
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="8"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Group Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="chatName"
                      placeholder="Group Name"
                      defaultValue={chat_server_details?.chatName?chat_server_details.chatName:'chat name undefined'}
                      onChange={(e)=>setChatName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="exampleForm.ControlInput1"
                  >
                  <Form.Label >Update Group</Form.Label>
                    <Button
                      variant="outlined"
                      onClick={()=>renameChat(chat_server_details?._id)}
                    >
                      Update
                    </Button>
                  </Form.Group>
                  </Row>

                  <Row className="my-5">

                    <List
                      as={Col}
                      md="6"
                      subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                          <Row className="my-5">
                          <h6 className='col-md-7'><b>Group Chat Members</b></h6> 
                          {checked.length>0 &&(
                            <Tooltip title="Remove selected users" placement="top">
                              <Button onClick={()=>removeUsersFromChat(chat_server_details._id)} className='col-md-5'
                              color="error" variant="text" startIcon={<LogoutIcon sx={{ color: red[500] }} />}>
                                Remove
                              </Button>
                              </Tooltip>
                            )}
                          </Row>
                        </ListSubheader>
                      }
                        sx={{
                          width: '100%',
                          maxWidth: 360,
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 300,
                          '& ul': { padding: 0 },
                        }}>
                          
                          <ListSubheader component="div" id="nested-list-subheader">
                            <Checkbox
                              onClick={handleToggleAll(chat_server_details?.users)}
                              checked={checked.length>0}
                              disabled
                              inputProps={{
                                'aria-label': 'all items selected',
                              }}
                              style ={{
                                color: "#5458AF",
                              }}
                            />
                            {checked.length +"/"+chat_server_details?.users.length +" "+"selected"}

                            {checked.length >= chat_server_details.users.length-2?
                            <i style={{color:'red', fontSize:'10px',marginLeft:'5px'}}>Atleast 2 members need to group</i>
                            :
                            <></>
                            }
                            
                          </ListSubheader>
                              {chat_server_details.users?.map((item) => (
                                <ListItem 
                                key={item.lg_user_id}
                                secondaryAction={
                                <Checkbox
                                  edge="end"
                                  onChange={handleToggle(item)}
                                  checked={checked.indexOf(item) !== -1}
                                  inputProps={{ 'aria-labelledby': item }}
                                  disabled={checked.length >= chat_server_details.users.length-2 && checked.indexOf(item) === -1}
                                  style ={{
                                    color: "#5458AF",
                                  }}
                                />
                              }
                              disablePadding
                              >
                                <ListItemButton>
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={item.name}
                                      src={item.name}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText id={item.lg_user_id} primary={item.name?item.name:'unknown'} secondary={item?item.lg_user_id:'unknown'}/>
                                </ListItemButton>
                              </ListItem>
                              ))}
                          
                    </List>

                      <List
                      as={Col}
                      md="6"
                      subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                          <Row className="my-5">
                          <h6 className='col-md-7'><b>Members Not in Group Chat</b></h6> 
                          {addChecked?.length>0 &&(
                            <Tooltip title="Add selected users" placement="top">
                              <Button onClick={()=>addUsersToChat(chat_server_details?._id)} className='col-md-5'
                              color="success" variant="text" startIcon={<LogoutIcon sx={{ color: green[500] }} />}>
                                add
                              </Button>
                              </Tooltip>
                            )}
                          </Row>
                        </ListSubheader>
                      }
                        sx={{
                          width: '100%',
                          maxWidth: 360,
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 300,
                          '& ul': { padding: 0 },
                        }}>
                          <ListSubheader component="div" id="nested-list-subheader">
                            <Checkbox
                              onClick={handleAddToggleAll(studentsNotinChat?studentsNotinChat:null)}
                              checked={addChecked.length>0}
                              disabled={studentsNotinChat?.length === 0}
                              inputProps={{
                                'aria-label': 'all items selected',
                              }}
                              style ={{
                                color: "#5458AF",
                              }}
                            />
                            {addChecked?.length +"/"+studentsNotinChat?.length +" "+"selected"}
                            
                          </ListSubheader>
                          {studentsNotinChat?.length>0?(
                            (studentsNotinChat?.map((item) => (
                              <ListItem 
                                key={item.lg_user_id}
                                secondaryAction={
                                <Checkbox
                                  edge="end"
                                  onChange={handleAddToggle(item)}
                                  checked={addChecked.indexOf(item) !== -1}
                                  inputProps={{ 'aria-labelledby': item }}
                                  style ={{
                                    color: "#5458AF",
                                  }}
                                />
                              }
                              disablePadding
                              >
                                <ListItemButton>
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={item?item.name:'!'}
                                      src={item?item.name:''}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText id={item?item.lg_user_id:''} primary={item?item.name:'unknown'} secondary={item?item.lg_user_id:'unknown'} />
                                </ListItemButton>
                              </ListItem>
                            )))
                          ):(
                            <div>
                              <ListItemButton component="a" >
                              <ListItemIcon sx={{ fontSize: 20 }}>🔥</ListItemIcon>
                              <ListItemText
                                sx={{ my: 0 }}
                                primary="All users are in the chat"
                                primaryTypographyProps={{
                                  fontSize: 20,
                                  fontWeight: 'medium',
                                  letterSpacing: 0,
                                }}
                              />
                            </ListItemButton>
                            </div>
                          )
                          }
                          
                      </List>
                  </Row>
              </Form>

            </Modal.Body>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={updateLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
          </div>
  )
}
export default SingleChat;