export default {
    OAT_API:process.env.REACT_APP_API,
    CHAT_API:process.env.REACT_APP_CHAT_API,
    SOCKET_ENDPOINT:process.env.REACT_APP_CHAT_API_END_POINT,
    REDIRECT_URI: `${window.location.protocol}//${window.location.host}/`,
    LOGOUT_REDIRECT_URI: `${window.location.protocol}//${window.location.host}/login`,
    APP_INSIGHTS_CONNECTION_STRING:process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
    STORAGE_ACCOUNT_URI:process.env.REACT_APP_QUESTIONS_STORAGE_ACCOUNT,
    MANAGE_STORAGE_ACCOUNT_URI:process.env.REACT_APP_MANAGE_STORAGE_ACCOUNT,
    CUSTOMER_FEATURE_FLAG:process.env.REACT_APP_CUSTOMER_FEATURE_FLAG,
    LANDING_PAGE_URI:process.env.REACT_APP_LANDING_PAGE_URI
}