import React from 'react';
import { history } from '../../helper/history.js';
import { routes } from '../../helper/routes.js';
import AdminTemplate from '../../components/admin/layouts/Template.js';
import ContentWrapper from '../../components/common/AssessmentContentWrapper.js';
import Card from '../../components/common/Card.js';
import CardHeader from '../../components/common/CardHeader.js';
import CardBody from '../../components/common/CardBody.js';
import CardTitle from '../../components/common/AssessmentCardTitle.js';
import CardToolbar from '../../components/common/QuestionCardToolBar.js';
import Breadcrumb from '../../components/common/Breadcrumb.js';
import BreadcrumbItem from '../../components/common/BreadcrumbItem.js';
import Spinner from '../../components/common/Spinner.js';
import Form from '../../components/common/form/Form.js';
import Button from '../../components/common/form/Button.js';
import FormGroup from '../../components/common/form/FormGroup.js';
import Input from '../../components/common/form/Input.js';
import Select from '../../components/common/form/AssessmentSelect.js';
import Option from '../../components/common/form/Option.js';
import FileUpload from '../../components/common/form/FileUpload.js';
import { createActions } from '../../actions/question/createActions.js';
import { connect } from "react-redux";
import { questionTypeConstants } from "../../helper/constants";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { mcqActions } from "../../actions/question/mcqActions";
import { descriptiveActions } from "../../actions/question/descriptiveActions";
import CustomEditor from "../../components/common/editor/CustomEditor";
import GuidanceEditor from "../../components/common/editor/GuidanceEditor";
import { AzureStorage } from "../../helper/azureStorage";
import { submissionActions } from "../../actions/question/submissionActions";
import DataHandler from "../../handlers/DataHandler";
import { namespace } from "../../helper/namespace"
import "./QuestionsLibrary.css"
import config from '../../helper/config.js';
import {customerConstants} from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, confirmClass, cancelClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class NewQuestion extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showSummerNoteOrFileUpload: questionTypeConstants.MCQ.name,

      //**************** */
      question_author: '',

      questionCategoryMap: [],
      questionTypesMap: [],
      questionAnswerTypesMap: [],
      categoryOptions: [],
      typeOptions: [],
      answerTypeOptions: [],
      selectedCategory: '',
      selectedType: '',
      selectedAnswerType: '',
      questionDifficultyMap: [],
      difficultyOptions: [],
      selectedDifficulty: '',

      questionLanguageMap: [],
      subQuestionIds: [],
      languageOptions: [],
      selectedLanguage: '',

      selectedTitle: '',
      allGrades: [],
      allChapters: [],

      questionSourceMap: [],
      sourceOptions: [],
      selectedSource: '',

      questionSubjectMap: [],
      subjectOptions: [],
      selectedSubject: '',

      questionAuthorMap: [],
      authorOptions: [],
      selectedAuthor: '',

      questionYearMap: [],
      yearOptions: [],
      privilegeOptions: [],
      selectedPrivilege: "",
      selectedYear: '',
      selectedGrade: "",
      selectedChapter: "",
      guidance: null,

      questionTermMap: [],
      termOptions: [],
      selectedTerm: '',
      clearFile: false,
      subQuestionState: false,

      selectedTag: '',

      newCategory: null,
      contentEditorState: EditorState.createEmpty(),
      guidanceEditorState: EditorState.createEmpty(),
      choicesEditorState: [],
      selectedChoices: [],
      subQuestions: [],
      tagsList: [],
      errors: {},

      submission: '',
      questionMCQCreateState: false,
      questionDescriptiveCreateState: false,
      questionSubQuestionCreateState: false,
      questionSubmissionCreateState: false,
      uploaded: true,
      answerKey: '',
      answerContent: ''
    }

    this.ref_tagify = React.createRef();
    this.initTagify = this.initTagify.bind(this);

    this.onChangeQuestionCategory = this.onChangeQuestionCategory.bind(this);
    this.onChangeQuestionType = this.onChangeQuestionType.bind(this);
    this.onChangeQuestionAnswerType = this.onChangeQuestionAnswerType.bind(this);
    this.onNewTitleInput = this.onNewTitleInput.bind(this);

    this.onChangeQuestionSource = this.onChangeQuestionSource.bind(this);
    this.onChangeQuestionSubject = this.onChangeQuestionSubject.bind(this);
    this.onChangeQuestionAuthor = this.onChangeQuestionAuthor.bind(this);
    this.onChangeQuestionYear = this.onChangeQuestionYear.bind(this);
    this.onChangeQuestionTerm = this.onChangeQuestionTerm.bind(this);
    this.onNewTagInput = this.onNewTagInput.bind(this);

    this.addOption = this.addOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.optionOnAnimationEnd = this.optionOnAnimationEnd.bind(this);
    this.onContentEditorChange = this.onContentEditorChange.bind(this);
    this.onGuidanceEditorChange = this.onGuidanceEditorChange.bind(this);
    this.onChoiceEdit = this.onChoiceEdit.bind(this);
    this.onChoicesEditorChange = this.onChoicesEditorChange.bind(this);
    this.onCancelQuestion = this.onCancelQuestion.bind(this);
    this.onSaveQuestion = this.onSaveQuestion.bind(this);
    this.saveDescriptive = this.saveDescriptive.bind(this);
    this.getSubmissionContent = this.getSubmissionContent.bind(this);
    this.uploadSubmission = this.uploadSubmission.bind(this);
    this._editorUploadImageCallBack = this._editorUploadImageCallBack.bind(this);
    this.onChangeQuestionDifficulty = this.onChangeQuestionDifficulty.bind(this);
    this.onChangeQuestionLanguage = this.onChangeQuestionLanguage.bind(this);
    this.deleteSubmissionContent = this.deleteSubmissionContent.bind(this)
  }

  async componentDidMount() {
    if (window.KTSelect2) window.KTSelect2.init();
    if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
    if (window.KTIONRangeSlider) window.KTIONRangeSlider.init();
    if (window.KTSummernoteDemo) window.KTSummernoteDemo.init();
    if (window.KTSweetAlert2Demo) window.KTSweetAlert2Demo.init();
    if (window.$) window.$('.selectpicker').selectpicker();

    window.$('.selectPicker').selectpicker('refresh');
    if (this.state.init_tagify) {
      if (window.$) window.$('.selectpicker').selectpicker();
      this.initTagify()
    }

    await this.props.getQuestionMetaData()

    let questionCatgories = this.props.allQuestionMetaData.find(item => 'categories' in item).categories;
    if (questionCatgories.length > 0) {
      let tempCategories = [];
      questionCatgories.map((el, i) => {
        tempCategories[el.category_id] = el.name
      });

      this.setState({
        questionCategoryMap: tempCategories
      });
      this.generateCategoryOptions();
    }

    let questionTypes = this.props.allQuestionMetaData.find(item => 'types' in item).types;
    if (questionTypes.length > 0) {
      let tempQTypes = [];
      let typeOptions = []
      questionTypes.map((el, i) => {
        tempQTypes[el.type_id] = el.qtype
        typeOptions.push(<Option key={el.type_id} optionName={el.qtype} value={el.type_id} />)
      });

      this.setState({
        questionTypesMap: tempQTypes,
        typeOptions: typeOptions,
        selectedType: typeOptions[0].props.value,
      })

      if (typeOptions)
        window.$('#question-type').selectpicker('refresh');
    }

    let questionAnswerTypes = this.props.allQuestionMetaData.find(item => 'answer_types' in item).answer_types;
    if (questionAnswerTypes.length > 0) {
      let tempAnswerTypes = [];
      let answerTypeOptions = []
      questionAnswerTypes.map((el, i) => {
        tempAnswerTypes[el.answer_type_id] = el.answer_type
        answerTypeOptions.push(<Option key={el.answer_type_id} optionName={el.answer_type} value={el.answer_type_id} />)
      });

      this.setState({
        questionAnswerTypesMap: tempAnswerTypes,
        answerTypeOptions: answerTypeOptions,
        selectedAnswerType: answerTypeOptions[0].props.value,
      })

      if (answerTypeOptions)
        window.$('#question-answer-type').selectpicker('refresh');
    }

    let questionDifficulties = this.props.allQuestionMetaData.find(item => 'difficulty_types' in item).difficulty_types;
    if (questionDifficulties.length > 0) {
      let tempDifficultyTypes = [];
      let difficultyOptions = [];
      questionDifficulties.map((el, i) => {
        tempDifficultyTypes[el.difficulty_id] = el.difficulty_type
        difficultyOptions.push(<Option key={el.difficulty_id} optionName={el.difficulty_type} value={el.difficulty_id} />)
      });

      this.setState({
        questionDifficultyMap: tempDifficultyTypes,
        difficultyOptions: difficultyOptions,
        selectedDifficulty: difficultyOptions[0].props.value
      })

      if (difficultyOptions)
        window.$('#question-difficulty').selectpicker('refresh');
    }

    let questionLanguages = this.props.allQuestionMetaData.find(item => 'languages' in item).languages;
    if (questionLanguages.length > 0) {
      let tempLanguages = [];
      let languageOptions = [];
      languageOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionLanguages.map((el, i) => {
        tempLanguages[el.language_id] = el.language
        languageOptions.push(<Option key={el.language_id} optionName={el.language} value={el.language_id} />)
      });

      this.setState({
        questionLanguageMap: tempLanguages,
        languageOptions: languageOptions,
        selectedLanguage: languageOptions[0].props.value
      })

      if (languageOptions)
        window.$('#question-language').selectpicker('refresh');
    }

    let questionPrivileges = this.props.allQuestionMetaData.find(item => 'privileges' in item).privileges;
    if (questionPrivileges.length > 0) {
      let privilegeOptions = [];
      questionPrivileges.map((el, i) => {
        privilegeOptions.push(<Option key={el.privilege_id} optionName={el.privilege} value={el.privilege_id} />)
      });

      this.setState({
        privilegeOptions: privilegeOptions,
        selectedPrivilege: privilegeOptions[0].props.value
      })

      if (privilegeOptions)
        window.$('#question-privilege').selectpicker('refresh');
    }

    let questionGrades = this.props.allQuestionMetaData.find(item => 'grades' in item).grades;
    if (questionGrades.length > 0) {
      let allGrades = [];
      allGrades.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionGrades.map((el, i) => {
        allGrades.push(<Option key={el.grade_id} optionName={el.grade} value={el.grade_id} />)
      });

      this.setState({
        allGrades: allGrades,
        selectedGrade: allGrades[0].props.value
      })

      if (allGrades)
        window.$('#question-grade').selectpicker('refresh');
    }

    let questionChapters = this.props.allQuestionMetaData.find(item => 'chapters' in item).chapters;
    if (questionChapters.length > 0) {
      let allChapters = [];
      allChapters.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionChapters.map((el, i) => {
        allChapters.push(<Option key={el.chapter_id} optionName={el.chapter} value={el.chapter_id} />)
      });

      this.setState({
        allChapters: allChapters,
        selectedChapter: allChapters[0].props.value
      })

      if (allChapters)
        window.$('#question-chapter').selectpicker('refresh');
    }

    let questionSources = this.props.allQuestionMetaData.find(item => 'sources' in item).sources;
    if (questionSources.length > 0) {
      let tempSources = [];
      let sourceOptions = [];
      sourceOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionSources.map((el, i) => {
        tempSources[el.source_id] = el.source
        sourceOptions.push(<Option key={el.source_id} optionName={el.source} value={el.source_id} />)
      });
      this.setState({
        questionSourceMap: tempSources,
        sourceOptions: sourceOptions,
        selectedSource: sourceOptions[0].props.value
      })
      if (sourceOptions)
        window.$('#question-source').selectpicker('refresh');
    }

    let questionSubjects = this.props.allQuestionMetaData.find(item => 'subjects' in item).subjects;
    if (questionSubjects.length > 0) {
      let tempSubjects = [];
      let subjectOptions = [];
      subjectOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionSubjects.map((el, i) => {
        tempSubjects[el.subject_id] = el.subject
        subjectOptions.push(<Option key={el.subject_id} optionName={el.subject} value={el.subject_id} />)
      });

      this.setState({
        questionSubjectMap: tempSubjects,
        subjectOptions: subjectOptions,
        selectedSubject: subjectOptions[0].props.value
      })

      if (subjectOptions)
        window.$('#question-subject').selectpicker('refresh');
    }

    let questionAuthors = this.props.allQuestionMetaData.find(item => 'authors' in item).authors;
    if (questionAuthors.length > 0) {
      let tempAuthors = [];
      let authorOptions = [];
      authorOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionAuthors.map((el, i) => {
        tempAuthors[el.author_id] = el.author
        authorOptions.push(<Option key={el.author_id} optionName={el.author} value={el.author_id} />)
      });

      this.setState({
        questionAuthorMap: tempAuthors,
        authorOptions: authorOptions,
        selectedAuthor: authorOptions[0].props.value
      })

      if (authorOptions)
        window.$('#question-author').selectpicker('refresh');
    }

    let questionYears = this.props.allQuestionMetaData.find(item => 'years' in item).years;
    if (questionYears.length > 0) {
      let tempYears = [];
      let yearOptions = [];
      yearOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionYears.map((el, i) => {
        tempYears[el.year_id] = el.year
        yearOptions.push(<Option key={el.year_id} optionName={el.year} value={el.year_id} />)
      });

      this.setState({
        questionYearMap: tempYears,
        yearOptions: yearOptions,
        selectedYear: yearOptions[0].props.value
      })

      if (yearOptions)
        window.$('#question-year').selectpicker('refresh');
    }

    let questionTerms = this.props.allQuestionMetaData.find(item => 'terms' in item).terms;
    if (questionTerms.length > 0) {
      let tempTerms = [];
      let termOptions = [];
      termOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionTerms.map((el, i) => {
        tempTerms[el.term_id] = el.term
        termOptions.push(<Option key={el.term_id} optionName={el.term} value={el.term_id} />)
      });

      this.setState({
        questionTermMap: tempTerms,
        termOptions: termOptions,
        selectedTerm: termOptions[0].props.value
      })

      if (termOptions)
        window.$('#question-term').selectpicker('refresh');
    }

    if (this.state.showSummerNoteOrFileUpload === questionTypeConstants.MCQ.name || this.state.showSummerNoteOrFileUpload === questionTypeConstants.DESCRIPTIVE.name) {
      if (window.KTSummernoteDemo) window.KTSummernoteDemo.init();
    }
  }

  componentDidUpdate() {
    if (this.props.mcq_success && this.state.questionMCQCreateState) {
      if (!this.props.mcq_loading && this.props.mcq_error) {

        window.Swal.fire({
          title: 'Failed!',
          text: 'Failed to create question',
          icon: 'error',
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
          }
        });
      } else {
        window.Swal.fire({
          title: 'Success!',
          text: "Added new MCQ question",
          icon: 'success',
          showCancelButton: true,
          cancelButtonText: "Back to Questions",
          confirmButtonColor: addBtnColor,
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Create Another Question!'
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.setState({ questionMCQCreateState: false, selectedTitle: '', contentEditorState: EditorState.createEmpty(), guidanceEditorState: EditorState.createEmpty(), choicesEditorState: [], selectedChoices: [], guidance: "" })
            } else if (result.isDismissed) {
              history.push(routes.questions_library)
              //window.location.reload()
            }
          });
      }
      this.setState({ questionMCQCreateState: false, selectedTitle: '', contentEditorState: EditorState.createEmpty(), guidanceEditorState: EditorState.createEmpty(), choicesEditorState: [], selectedChoices: [], guidance: "" })
    }

    if (this.props.desc_success && this.state.questionDescriptiveCreateState) {
      if (!this.props.desc_loading && this.props.desc_error) {

        window.Swal.fire({
          title: 'Failed!',
          text: 'Failed to create question',
          icon: 'error',
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
          }
        });
      } else {

        window.Swal.fire({
          title: 'Success!',
          text: "Added new Descriptive question",
          icon: 'success',
          showCancelButton: true,
          cancelButtonText: "Back to Questions",
          confirmButtonColor: addBtnColor,
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Create Another Question!'
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.setState({ questionDescriptiveCreateState: false, selectedTitle: '', contentEditorState: EditorState.createEmpty(), guidanceEditorState: EditorState.createEmpty(), choicesEditorState: EditorState.createEmpty(), guidance: "" })
            } else if (result.isDismissed) {
                history.push(routes.questions_library)
                //window.location.reload()
            }
          });
      }
      this.setState({ questionDescriptiveCreateState: false, selectedTitle: '', contentEditorState: EditorState.createEmpty(), guidanceEditorState: EditorState.createEmpty(), choicesEditorState: EditorState.createEmpty(), guidance: "" })
    }

    if (this.props.desc_success && this.state.questionSubQuestionCreateState) {
      if (!this.props.desc_loading && this.props.desc_error) {

        window.Swal.fire({
          title: 'Failed!',
          text: 'Failed to create question',
          icon: 'error',
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
          }
        });
      } else {

        window.Swal.fire({
          title: 'Success!',
          text: "Added new Sub question",
          icon: 'success',
          showCancelButton: true,
          cancelButtonText: "Back to Questions",
          confirmButtonColor: addBtnColor,
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Create Another Question!'
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.setState({ questionSubQuestionCreateState: false, selectedTitle: '', contentEditorState: EditorState.createEmpty(), guidanceEditorState: EditorState.createEmpty(), choicesEditorState: [], subQuestions: [], subQuestionIds: [], guidance: "" })
            } else if (result.isDismissed) {
              history.push(routes.questions_library)
              //window.location.reload()
            }
          });
      }
      this.setState({ questionSubQuestionCreateState: false, selectedTitle: '', contentEditorState: EditorState.createEmpty(), guidanceEditorState: EditorState.createEmpty(), choicesEditorState: [], subQuestions: [], subQuestionIds: [], guidance: "" })
    }

    if (this.props.desc_success && this.state.subQuestionState) {
      let subQuestionIds = this.state.subQuestionIds
      subQuestionIds.push(this.props.question_id)
      this.setState({
        subQuestionIds: subQuestionIds,
        subQuestionState: false
      })
    }

    if (this.props.submission_success && this.state.questionSubmissionCreateState) {
      this.setState({ clearFile: true })
      if (!this.props.submission_loading && this.props.submission_error) {

        window.Swal.fire({
          title: 'Failed!',
          text: 'Failed to create question',
          icon: 'error',
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
          }
        });
      } else {

        window.Swal.fire({
          title: 'Success!',
          text: "Added new Submission question",
          icon: 'success',
          showCancelButton: true,
          cancelButtonText: "Back to Questions",
          confirmButtonColor: addBtnColor,
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Create Another Question!'
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.setState({ questionSubmissionCreateState: false, selectedTitle: '', contentEditorState: EditorState.createEmpty(), guidanceEditorState: EditorState.createEmpty(), choicesEditorState: [], clearFile: false, submission: "", guidance: "" })
            } else if (result.isDismissed) {
              history.push(routes.questions_library)
              //window.location.reload()
            }
          });
      }
      this.setState({ questionSubmissionCreateState: false, selectedTitle: '', contentEditorState: EditorState.createEmpty(), guidanceEditorState: EditorState.createEmpty(), choicesEditorState: [], guidance: "" })
    }
  }


  //for changes in tags
  handleChangeTags = (e) => {
    var list_of_Tags = [];
    list_of_Tags.push(e.target.value)
    this.state.tagsList.push(e.target.value)
    if (!this.state.errors['tagsList']) {
      let errors = Object.assign({}, this.state.errors);
      delete errors['tagsList'];
      this.setState({
        tagsList: list_of_Tags,
        errors
      });
    } else {
      this.setState({ tagsList: list_of_Tags });
    }
  }


  generateCategoryOptions() {
    let categoryOptions = []
    let questionCatgories = this.props.allQuestionMetaData.find(item => 'categories' in item).categories;
    questionCatgories.map((el, i) => {
      categoryOptions.push(<Option key={el.category_id} optionName={el.name} value={el.category_id} />)
    });

    this.setState({
      categoryOptions: categoryOptions,
      selectedCategory: categoryOptions[0].props.value
    });

    if (categoryOptions)
      window.$('#question-category').selectpicker('refresh');
  }

  onChangeQuestionCategory(e) {
    let category = e.target.value;
    this.setState({
      selectedCategory: category
    })
  }
  
  onChangeQuestionType(e) {
    let type = e.target.value;
    let name = this.state.questionTypesMap[type]
    if (name === questionTypeConstants.MCQ.name || name === questionTypeConstants.DESCRIPTIVE.name || name === questionTypeConstants.SUBMISSION.name || name === questionTypeConstants.SUBQUESTION.name) {
      this.setState({
        showSummerNoteOrFileUpload: name,
        selectedType: type
      })
    } else {
      this.setState({
        showSummerNoteOrFileUpload: null
      })
    }

    if (name === questionTypeConstants.DESCRIPTIVE.name)
      this.setState({
        choicesEditorState: EditorState.createEmpty()
      });
    else if (name === questionTypeConstants.MCQ.name || name === questionTypeConstants.SUBQUESTION.name)
      this.setState({
        choicesEditorState: []
      });
      
    if (name === questionTypeConstants.MCQ.name){
      window.location.reload();
      const { selectedAnswerType } = this.state;
      this.setState({ selectedAnswerType: selectedAnswerType });
    }
    else{
      this.setState({ selectedAnswerType: null });
    }
  }

  onChangeQuestionAnswerType(e) {
    let answer_type = e.target.value;
    this.setState({
      selectedAnswerType: answer_type,
    })
  }

  onChangeQuestionDifficulty(e) {
    let difficulty = e.target.value;
    this.setState({
      selectedDifficulty: difficulty === "Nothing selected" ? null : difficulty,
    })
  }

  onChangeQuestionPrivilege = (e) => {
    let privilege = e.target.value;
    this.setState({
      selectedPrivilege: privilege,
    })
  }

  onChangeQuestionLanguage = (e) => {
    let language = e.target.value;
    this.setState({
      selectedLanguage: language === "Nothing selected" ? null : language,
    })
  }

  onNewTitleInput(e) {
    this.setState({
      selectedTitle: e.target.value
    });
  }

  onNewGuidanceInput = (e) => {
    this.setState({
      guidance: e.target.value
    });
  }


  /************************************************************************************* */

  onChangeQuestionSource(e) {
    let source = e.target.value;
    this.setState({
      selectedSource: source === "Nothing selected" ? null : source,
    })

  }


  onChangeQuestionSubject(e) {
    let subject = e.target.value;
    this.setState({
      selectedSubject: subject === "Nothing selected" ? null : subject,
    })

  }

  onChangeQuestionAuthor(e) {
    let author = e.target.value;
    this.setState({
      selectedAuthor: author === "Nothing selected" ? null : author,
    })

  }

  onChangeQuestionYear(e) {
    let year = e.target.value;
    this.setState({
      selectedYear: year === "Nothing selected" ? null : year,
    })

  }

  onChangeQuestionGrade = (e) => {
    let grade = parseInt(e.target.value);
    this.setState({
      selectedGrade: grade === "Nothing selected" ? null : grade,
    })

  }

  onChangeQuestionChapter = (e) => {
    let chapter = e.target.value;
    this.setState({
      selectedChapter: chapter === "Nothing selected" ? null : chapter,
    })

  }

  onChangeQuestionTerm(e) {
    let term = e.target.value;
    this.setState({
      selectedTerm: term === "Nothing selected" ? null : term,
    })

  }

  onNewTagInput(e) {
    this.setState({
      selectedTag: e.target.value
    });
  }

  /*********************************************************************** */

  addOption() {
    let choiceAnswers = [...this.state.selectedChoices];
    let pre = [...this.state.choicesEditorState]
    let sub = [...this.state.subQuestions]
    pre.push(EditorState.createEmpty())
    sub.push(EditorState.createEmpty())
    choiceAnswers.push(0);

    this.setState({
      choicesEditorState: pre,
      selectedChoices: choiceAnswers,
      subQuestions: sub
    })
  }

  removeOption() {
    let pre = [...this.state.choicesEditorState]
    let choiceAnswers = [...this.state.selectedChoices];
    let sub = [...this.state.subQuestions]
    pre.pop()
    sub.pop()
    choiceAnswers.pop()
    this.setState({
      choicesEditorState: pre,
      selectedChoices: choiceAnswers,
      subQuestions: sub
    })
  }

  optionOnAnimationEnd(e) {
    const event = e.target

    function removeClass(event) {
      if (event) event.classList.remove('new-item');
      if (window.KTSummernoteDemo) window.KTSummernoteDemo.init();
    }

    setTimeout(() => removeClass(event), 500)
  }

  onContentEditorChange(editorState) {
    this.setState({
      contentEditorState: editorState
    });
  }

  onGuidanceEditorChange(guidanceEditorState) {
    this.setState({
      guidanceEditorState: guidanceEditorState
    });
  }

  onSubQUestionEditorChange = (editorState, value) => {
    let newEditorStates = this.state.subQuestions;
    newEditorStates[value] = editorState
    this.setState({
      subQuestions: newEditorStates
    });
  }

  onChoiceEdit(e, i) {
    let selectedChoices = this.state.selectedChoices;
    selectedChoices[i] = parseInt(e.target.value)

    this.setState({
      selectedChoices: selectedChoices
    });

  }

  // onChoiceChecked(e, i) {
  //     let selectedChoices = this.state.selectedChoices;
  //     selectedChoices[i] = !this.state.selectedChoices[i]

  //     this.setState({
  //         selectedChoices: selectedChoices
  //     });
  // }

  onChoicesEditorChange(editorState, value) {
    let newEditorStates = this.state.choicesEditorState;

    let typeName = this.state.questionTypesMap[this.state.selectedType];
    if (typeName === questionTypeConstants.MCQ.name || typeName === questionTypeConstants.SUBQUESTION.name) {
      newEditorStates[value] = editorState
    } else if (typeName === questionTypeConstants.DESCRIPTIVE.name) {
      newEditorStates = editorState
    }

    this.setState({
      choicesEditorState: newEditorStates
    });
  }

  onCancelQuestion(e) {
    e.preventDefault();
    window.Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: addBtnColor,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Cancel it!'
    }).then((result) => {
      if (result.isConfirmed) {
        history.push(routes.questions_library);
        //window.location.reload();
      }
    })
  }

  async onSaveQuestion(e) {

    e.preventDefault()
    let typeName = this.state.questionTypesMap[this.state.selectedType];
    let questionObject = {
      questionKey: `${Date.now()}`,
      title: this.state.selectedTitle,
      categoryId: this.state.selectedCategory,
      qType: this.state.selectedType,
      answer_type_id: this.state.selectedAnswerType,
      createdBy: DataHandler.getFromSession("user_id"),
      chapter_id: this.state.selectedChapter,
      grade_id: parseInt(this.state.selectedGrade),
      guidence: [convertToRaw(this.state.guidanceEditorState.getCurrentContent())],
      questionContent: [convertToRaw(this.state.contentEditorState.getCurrentContent())],
      difficulty: this.state.selectedDifficulty,
      language: this.state.selectedLanguage,
      subject: this.state.selectedSubject,
      source: this.state.selectedSource,
      author: this.state.selectedAuthor,
      year: this.state.selectedYear,
      privilege_id: this.state.selectedPrivilege,
      term: this.state.selectedTerm,
      tags: this.state.selectedTag

    }

    var e = 0;
    var qcount = Object.keys(questionObject.questionContent[0].blocks).length;
    for (var i = 0; i < qcount; i++) {
      if (!questionObject.questionContent[0].blocks[i].text.trim() && !questionObject.questionContent[0].entityMap[i]) { e = e + 1; }
    }

    if (!questionObject.title.trim()) {
      this.validate();
      window.Swal.fire({
        title: 'Failed!',
        text: namespace.QUESTION_OR_TITLE_NOT_GIVEN,
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    } else if (e == qcount) {
      window.Swal.fire({
        title: 'Failed!',
        text: namespace.QUESTION_OR_TITLE_NOT_GIVEN,
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    } else if (questionObject.questionContent[0].blocks[0].text == " " && questionObject.questionContent[0].blocks[1].text == "" && questionObject.questionContent[0].entityMap[0]) {
      window.Swal.fire({
        title: 'Failed!',
        text: namespace.QUESTION_OR_TITLE_NOT_GIVEN,
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
    else {
      if (typeName === questionTypeConstants.MCQ.name) {
        await this.saveMCQ(questionObject);
      } else if (typeName === questionTypeConstants.DESCRIPTIVE.name) {
        await this.saveDescriptive(questionObject);
      } else if (typeName === questionTypeConstants.SUBMISSION.name) {
        await this.saveSubmission(questionObject);
      } else if (typeName == questionTypeConstants.SUBQUESTION.name) {
        await this.saveSubQuestion(questionObject)
      }
    }
  }

  async saveMCQ(questionObject) {
    let choiceState;
    for (choiceState of this.state.choicesEditorState) {
      questionObject.questionContent.push(convertToRaw(choiceState.getCurrentContent()));
    }
    var value = this.state.selectedChoices.reduce(function (r, a) {
      return a > 0 ? r + a : r;
    }, 0);
    const answer = this.state.selectedChoices.toString();

    let question = {
      title: questionObject.title,
      qType: questionObject.qType,
      answer_type_id: questionObject.answer_type_id,
      categoryId: questionObject.categoryId,
      questionKey: questionObject.questionKey,
      questionContent: questionObject.questionContent,
      createdBy: questionObject.createdBy,
      answer: answer,
      difficulty_id: questionObject.difficulty,
      language_id: questionObject.language,
      source_id: questionObject.source,
      subject_id: questionObject.subject,
      chapter_id: questionObject.chapter_id,
      grade_id: questionObject.grade_id,
      guidence: questionObject.guidence,
      author_id: questionObject.author,
      year_id: questionObject.year,
      privilege_id: questionObject.privilege_id,
      term_id: questionObject.term,
      tags: questionObject.tags
    }
    var err = 0;
    for (var i = 1; i <= this.state.selectedChoices.length; i++) {
      var qcountmcq = Object.keys(questionObject.questionContent[i].blocks).length;
      var err2 = 0;
      for (var x = 0; x < qcountmcq; x++) {
        if (!questionObject.questionContent[i].blocks[x].text.trim() && !questionObject.questionContent[i].entityMap[x]) {
          err2 = err2 + 1;
        }
      }
      if (err2 == qcountmcq) {
        err = err + 1;
      }
      else if (questionObject.questionContent[i].blocks[0].text == " " && questionObject.questionContent[i].entityMap[0]) {
        err = err + 1;
      }
    }
    if (err != 0) {
      window.Swal.fire({
        title: 'Failed!',
        text: namespace.ANSWER_NOT_COMPLETED,
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
    else if (this.state.selectedChoices.length == 0) {
      window.Swal.fire({
        title: 'Failed!',
        text: 'Please select at least one option',
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    } else if (value != 100) {
      window.Swal.fire({
        title: 'Failed!',
        text: 'Mark allocations should sum up to 100%',
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
    else {
      await this.props.createMCQ(question);
      this.setState({ questionMCQCreateState: true })
    }
  }

  async saveDescriptive(questionObject) {
    const answerKey = `${Date.now()}`;
    const answerContent = [convertToRaw(this.state.choicesEditorState.getCurrentContent())];

    let question = {
      title: questionObject.title,
      qType: questionObject.qType,
      answer_type_id: questionObject.answer_type_id,
      categoryId: questionObject.categoryId,
      questionKey: questionObject.questionKey,
      questionContent: questionObject.questionContent,
      createdBy: questionObject.createdBy,
      answerKey: answerKey,
      answerContent: answerContent,
      difficulty_id: questionObject.difficulty,
      language_id: questionObject.language,
      chapter_id: questionObject.chapter_id,
      grade_id: questionObject.grade_id,
      guidence: questionObject.guidence,
      source_id: questionObject.source,
      subject_id: questionObject.subject,
      privilege_id: questionObject.privilege_id,
      author_id: questionObject.author,
      year_id: questionObject.year,
      term_id: questionObject.term,
      tags: questionObject.tags
    }

    var e2 = 0;
    var q2count = Object.keys(question.answerContent[0].blocks).length;
    for (var i = 0; i < q2count; i++) {
      if (!question.answerContent[0].blocks[i].text.trim() && !question.answerContent[0].entityMap[i]) {
        e2 = e2 + 1;
      }
    }

    if (e2 == q2count) {
      window.Swal.fire({
        title: 'Failed!',
        text: namespace.ANSWER_NOT_GIVEN,
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    } else if (question.answerContent[0].blocks[0].text == " " && question.answerContent[0].blocks[1].text == "" && question.answerContent[0].entityMap[0]) {
      window.Swal.fire({
        title: 'Failed!',
        text: namespace.ANSWER_NOT_GIVEN,
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
    else if (question.answerContent[0].blocks[0].text == " " && question.answerContent[0].blocks[1].text == "" && question.answerContent[0].entityMap[0]) {
      window.Swal.fire({
        title: 'Failed!',
        text: namespace.ANSWER_NOT_GIVEN,
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
    else {
      await this.props.createDescriptive(question);
      this.setState({ questionDescriptiveCreateState: true })
    }

  }

  async saveMainQuestion(questionObject) {
    const answerKey = `${Date.now()}`;
    let question = {
      title: questionObject.title,
      qType: questionObject.qType,
      answer_type_id: questionObject.answer_type_id,
      categoryId: questionObject.categoryId,
      questionKey: questionObject.questionKey,
      questionContent: questionObject.questionContent,
      createdBy: questionObject.createdBy,
      answerKey: answerKey,
      answerContent: "0",
      difficulty_id: questionObject.difficulty,
      language_id: questionObject.language,
      chapter_id: questionObject.chapter_id,
      sub_questions: this.state.subQuestionIds.toString(),
      is_subquestion: null,
      grade_id: questionObject.grade_id,
      guidence: questionObject.guidence,
      source_id: questionObject.source,
      subject_id: questionObject.subject,
      privilege_id: questionObject.privilege_id,
      author_id: questionObject.author,
      year_id: questionObject.year,
      term_id: questionObject.term,
      tags: questionObject.tags
    }
    await this.props.createMain(question);
    this.setState({ questionSubQuestionCreateState: true })


  }
  async saveSubQuestion(questionObject) {
    let subquestions = this.state.subQuestions
    for (let i = 0; i <= subquestions.length - 1; i++) {
      const answerKey = `${Date.now()}`;
      const questionKey = `${Date.now()}`;
      const answerContent = [convertToRaw(this.state.choicesEditorState[i].getCurrentContent())];
      const questionContent = [convertToRaw(this.state.subQuestions[i].getCurrentContent())]
      let question = {
        title: questionObject.title,
        qType: questionObject.qType,
        answer_type_id: questionObject.answer_type_id,
        categoryId: questionObject.categoryId,
        questionKey: questionKey,
        questionContent: questionContent,
        createdBy: questionObject.createdBy,
        answerKey: answerKey,
        answerContent: answerContent,
        difficulty_id: questionObject.difficulty,
        language_id: questionObject.language,
        chapter_id: questionObject.chapter_id,
        grade_id: questionObject.grade_id,
        guidence: questionObject.guidence,
        sub_questions: null,
        is_subquestion: "true",
        source_id: questionObject.source,
        subject_id: questionObject.subject,
        privilege_id: questionObject.privilege_id,
        author_id: questionObject.author,
        year_id: questionObject.year,
        term_id: questionObject.term,
        tags: questionObject.tags
      }

      var e2 = 0;
      var q2count = Object.keys(question.answerContent[0].blocks).length;
      for (var j = 0; j < q2count; j++) {
        if (!question.answerContent[0].blocks[j].text.trim() && !question.answerContent[0].entityMap[j]) {
          e2 = e2 + 1;
        }
      }

      if (e2 == q2count) {
        window.Swal.fire({
          title: 'Failed!',
          text: namespace.ANSWER_NOT_GIVEN,
          icon: 'error',
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
          }
        });
      } else if (question.answerContent[0].blocks[0].text == " " && question.answerContent[0].blocks[1].text == "" && question.answerContent[0].entityMap[0]) {
        window.Swal.fire({
          title: 'Failed!',
          text: namespace.ANSWER_NOT_GIVEN,
          icon: 'error',
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
          }
        });
      }
      else if (question.answerContent[0].blocks[0].text == " " && question.answerContent[0].blocks[1].text == "" && question.answerContent[0].entityMap[0]) {
        window.Swal.fire({
          title: 'Failed!',
          text: namespace.ANSWER_NOT_GIVEN,
          icon: 'error',
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
          }
        });
      }
      else {
        await this.props.createSubQuestion(question);
        this.setState({ subQuestionState: true })
      }
      if (i === subquestions.length - 1) {
        this.setState({ subQuestionState: false })
        await this.saveMainQuestion(questionObject)
      }
    }

  }
  async saveSubmission(questionObject) {
    if (this.state.submission != null) {
      try {
        if (this.state.answerContent != '' && this.state.answerKey != '') {
          let answerContent = this.state.answerContent
          const answerKey = this.state.answerKey;
          let question = {
            title: questionObject.title,
            qType: questionObject.qType,
            answer_type_id: questionObject.answer_type_id,
            categoryId: questionObject.categoryId,
            questionKey: questionObject.questionKey,
            questionContent: questionObject.questionContent,
            createdBy: questionObject.createdBy,
            answerKey: answerKey,
            answerContent: answerContent,
            language_id: questionObject.language,
            difficulty_id: questionObject.difficulty,
            chapter_id: questionObject.chapter_id,
            grade_id: questionObject.grade_id,
            guidence: questionObject.guidence,
            privilege_id: questionObject.privilege_id,
            source_id: questionObject.source,
            subject_id: questionObject.subject,
            author_id: questionObject.author,
            year_id: questionObject.year,
            term_id: questionObject.term,
            tags: questionObject.tags
          }
          await this.props.createSubmission(question);
          this.setState({ questionSubmissionCreateState: true })
          const editorState = EditorState.push(this.state.contentEditorState, ContentState.createFromText(''));
          const guidanceEditorState = EditorState.push(this.state.guidanceEditorState, ContentState.createFromText(''));
        } else {
          let answerContent = await this.uploadSubmission(this.state.submission);
          const answerKey = this.state.answerKey;
          let question = {
            title: questionObject.title,
            qType: questionObject.qType,
            answer_type_id: questionObject.answer_type_id,
            categoryId: questionObject.categoryId,
            questionKey: questionObject.questionKey,
            questionContent: questionObject.questionContent,
            createdBy: questionObject.createdBy,
            answerKey: answerKey,
            answerContent: answerContent,
            difficulty_id: questionObject.difficulty,
            language_id: questionObject.language,
            chapter_id: questionObject.chapter_id,
            grade_id: questionObject.grade_id,
            guidence: questionObject.guidence,
            source_id: questionObject.source,
            subject_id: questionObject.subject,
            privilege_id: questionObject.privilege_id,
            author_id: questionObject.author,
            year_id: questionObject.year,
            term_id: questionObject.term,
            tags: questionObject.tags
          }
          await this.props.createSubmission(question);
          this.setState({ questionSubmissionCreateState: true })
          const editorState = EditorState.push(this.state.contentEditorState, ContentState.createFromText(''));
          const guidanceEditorState = EditorState.push(this.state.guidanceEditorState, ContentState.createFromText(''));
        }
      } catch (e) {
        throw { 'error': 'Saving answer attachment failed' };
      }
    }
  }

  async getSubmissionContent(file) {
    let files = [...this.state.submission]
    files.push(file)

    this.setState({
      submission: files
    });
    this.setState({
      answerContent: await this.uploadSubmission(this.state.submission)
    })

  }

  async deleteSubmissionContent(file) {
    if (!this.state.clearFile) {

      let files = [...this.state.submission]

      let fileIndex = files.indexOf(file)
      files.splice(fileIndex, 1);
      const user_id = DataHandler.getFromSession("user_id")
      // const uniqueContainer = "".concat(user_id, "-", this.state.answerKey)
      const uniqueContainer= config.storageBlobConfigs.containerName
      await AzureStorage.deleteblob(uniqueContainer, file.name.replace(/['"]+/g, ''))
      this.setState({
        submission: files
      })
      let submissionName = []
      for (var i = 0; i < this.state.submission.length; i++) {
        var name = this.state.submission.name;
        submissionName.push(name)
      }
      this.setState({
        answerContent: submissionName
      })
    }
  }

  async uploadSubmission(file) {
    let fileReader;
    this.setState({
      uploaded: false
    })
    const user_id = DataHandler.getFromSession("user_id")
    this.setState({
      answerKey: `${Date.now()}`
    })
    //const uniqueContainer = "".concat(user_id, "-sa-", this.state.answerKey)
    const uniqueContainer= config.storageBlobConfigs.containerName
    let submissionName = []
    var self = this;
    for (var i = 0; i < file.length; i++) {
      (function (file) {
        var name = file.name;
        submissionName.push(name)
        var reader = new FileReader();
        var text;
        reader.onload = async function (e) {
          text = e.target.result;
          await AzureStorage.uploadBlob(uniqueContainer, name, text).then(() => {
            window.Swal.fire({
              title: 'Success!',
              text: namespace.FILE_UPLOAD_SUCCESS,
              icon: 'success',
              customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                      modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
              }
            })
            self.setState({
              uploaded: true
            })
          })
        }
        reader.readAsArrayBuffer(file);
      })(file[i])
    }
    return submissionName;
  }

  _editorUploadImageCallBack(file) {
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    }
    uploadedImages.push(imageObject);
    this.setState({ uploadedImages: uploadedImages })

    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
      }
    );
  }

  validate() {
    const FORM = document.getElementById("create-question")
    const FORM_VALIDATION = window.FormValidation;
    const submitButton = FORM.querySelector('[type="submit"]');
    let form = FORM_VALIDATION.formValidation(FORM, {
      fields: {
        title: {
          validators: {
            notEmpty: {
              message: 'Title is required'
            }
          }
        }
      },
      plugins: {
        trigger: new FORM_VALIDATION.plugins.Trigger,
        bootstrap: new FORM_VALIDATION.plugins.Bootstrap({
          eleValidClass: ""
        }),
        fieldStatus: new FORM_VALIDATION.plugins.FieldStatus({
          onStatusChanged: function (areFieldsValid) {
            areFieldsValid
              ? submitButton.removeAttribute('disabled')
              : submitButton.setAttribute('disabled', 'disabled');
          }
        }),
      }
    })
    return form.validate()
  }


  initTagify() {
    const input = this.ref_tagify.current;
    if (input) {
      this.ref_tagify.current.tagify = new window.Tagify(input, { addTagOnBlur: false });
      this.ref_tagify.current.tagify.DOM.input.classList.add('form-control');
      this.ref_tagify.current.tagify.DOM.input.setAttribute('placeholder', 'enter tag...');
      this.ref_tagify.current.tagify.DOM.scope.parentNode.insertBefore(this.ref_tagify.current.tagify.DOM.input, this.ref_tagify.current.tagify.DOM.scope);
      this.ref_tagify.current.tagify.on('add', (e) => {
        this.funcAddTag(e)
      });
      this.ref_tagify.current.tagify.on('remove', (e) => {
        this.funcAddTag(e)
      });
    }
    this.setState({ init_tagify: false })
  }
  render() {

    let options = []
    // this.state.questionCategoryMap.map((el, i) => (
    //     options.push(<Option value={el.optionName} optionName={el.value}/>)
    // ), this)
    return (
    <AdminTemplate>
      <ContentWrapper>
        {this.props.mcq_loading ||
          this.props.desc_loading ||
          this.props.submission_loading ? (
          <Spinner id="show" text="Please wait.." />
        ) : (
          <div></div>
        )}
        <Form id="create-question">
          {/* begin::Card */}
          <Card>
            <CardHeader>
              <CardTitle cardLabel="Create Question">
                <Breadcrumb>
                  <BreadcrumbItem
                    to={routes.questions_library}
                    breadcrumbItemName="Questions Library"
                  />
                  <BreadcrumbItem
                    to={routes.new_question}
                    active="active"
                    breadcrumbItemName="Create a new question"
                  />
                </Breadcrumb>
              </CardTitle>
              <CardToolbar>
                <Button
                  className="btn btn-primary mr-2"
                  btnName="Save Changes"
                  title={!this.state.uploaded && "File upload Onprogress"}
                  type="button"
                  disabled={!this.state.uploaded}
                  onClick={this.onSaveQuestion}
                />
                <Button
                  className="btn btn-primary"
                  btnName="Cancel"
                  onClick={this.onCancelQuestion}
                  type="button"
                />
              </CardToolbar>
            </CardHeader>

            <CardBody>
              {/* <div className="row">
                    <div className="col-md-6 d-flex align-items-center">
                        <div className="row pl-3">
                            <div className="mr-3 question-btn">Q1</div>
                            <div className="mr-3 question-btn">Q2</div>
                            <div className="mr-3 question-btn">Q3</div>
                            <div className="mr-3 question-btn">Q4</div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <Link className="btn w-auto btn-clean d-inline-flex align-items-center create-btn">
                        <div className="symbol symbol-30 symbol-circle symbol-primary">
                            <span className="symbol-label font-size-h6 text-center">
                            <i className="flaticon2-plus text-white pr-0"></i>
                            </span>
                        </div>
                        <span className="btn btn-link-primary font-weight-bold font-size-base ml-4 font-size-h6">
                            Add Question
                        </span>
                        </Link>
                    </div>
                  </div> */}

              <div className="row">
                <h4 className="text-dark font-weight-bold mt-5 mb-5 col-lg-12">
                  Select a topic
                </h4>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  {/**Question Category**/}
                  <FormGroup
                    labelName="Category"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    {this.state.categoryOptions && (
                      <Select
                        selectId="question-category"
                        id="question-category"
                        selectWrap="col-lg-9"
                        className="form-control selectpicker"
                        dataLiveSearch="true"
                        dataSize="5"
                        defaultValue={this.state.categoryOptions[0]}
                        multiple={false}
                        spinner={
                          this.props.loading &&
                          this.state.categoryOptions.length <= 0
                        }
                        disabled={
                          this.props.loading &&
                          this.state.categoryOptions.length <= 0
                        }
                        children={this.state.categoryOptions}
                        onChange={this.onChangeQuestionCategory}
                      />
                    )}
                  </FormGroup>

                  {/**Question Type**/}
                  <FormGroup
                    labelName="Type"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    <Select
                      selectWrap="col-lg-9"
                      selectId="question-type"
                      id="question-type"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      defaultValue={this.state.typeOptions[0]}
                      multiple={false}
                      spinner={
                        this.props.loading &&
                        this.state.typeOptions.length <= 0
                      }
                      disabled={
                        this.props.loading &&
                        this.state.typeOptions.length <= 0
                      }
                      children={this.state.typeOptions}
                      onChange={this.onChangeQuestionType}
                    ></Select>
                  </FormGroup>

                  {/**Question Answer Type**/}
                  {this.state.showSummerNoteOrFileUpload === questionTypeConstants.MCQ.name && 
                    <FormGroup
                      labelName="Answer Type"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <Select
                        selectWrap="col-lg-9"
                        selectId="question-answer-type"
                        id="question-answer-type"
                        className="form-control selectpicker"
                        dataLiveSearch="true"
                        dataSize="5"
                        defaultValue={this.state.answerTypeOptions[0]}
                        multiple={false}
                        spinner={
                          this.props.loading &&
                          this.state.answerTypeOptions.length <= 0
                        }
                        disabled={
                          this.props.loading &&
                          this.state.answerTypeOptions.length <= 0
                        }
                        children={this.state.answerTypeOptions}
                        onChange={this.onChangeQuestionAnswerType}
                      ></Select>
                    </FormGroup>
                  }

                  {/**Question Difficulty**/}
                  <FormGroup
                    labelName="Difficulty"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    <Select
                      selectWrap="col-lg-9"
                      selectId="question-difficulty"
                      id="question-difficulty"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      defaultValue={this.state.difficultyOptions[0]}
                      multiple={false}
                      spinner={
                        this.props.loading &&
                        this.state.difficultyOptions.length <= 0
                      }
                      disabled={
                        this.props.loading &&
                        this.state.difficultyOptions.length <= 0
                      }
                      children={this.state.difficultyOptions}
                      onChange={this.onChangeQuestionDifficulty}
                    ></Select>
                  </FormGroup>

                  {/**Question Subject**/}
                  <FormGroup
                    labelName="Subject"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    <Select
                      selectWrap="col-lg-9"
                      selectId="question-subject"
                      id="question-subject"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      defaultValue={this.state.subjectOptions[0]}
                      multiple={false}
                      spinner={
                        this.props.loading &&
                        this.state.subjectOptions.length <= 0
                      }
                      disabled={
                        this.props.loading &&
                        this.state.subjectOptions.length <= 0
                      }
                      children={this.state.subjectOptions}
                      onChange={this.onChangeQuestionSubject}
                    ></Select>
                  </FormGroup>

                  {/**Question Medium/Language**/}
                  <FormGroup
                    labelName="Language"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    <Select
                      selectWrap="col-lg-9"
                      selectId="question-language"
                      id="question-language"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      defaultValue={this.state.languageOptions[0]}
                      multiple={false}
                      spinner={
                        this.props.loading &&
                        this.state.languageOptions.length <= 0
                      }
                      disabled={
                        this.props.loading &&
                        this.state.languageOptions.length <= 0
                      }
                      children={this.state.languageOptions}
                      onChange={this.onChangeQuestionLanguage}
                    ></Select>
                  </FormGroup>
                  <FormGroup
                    labelName="Privilege"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    <Select
                      selectWrap="col-lg-9"
                      selectId="question-privilege"
                      id="question-privilege"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      defaultValue={this.state.privilegeOptions[0]}
                      multiple={false}
                      spinner={
                        this.props.loading &&
                        this.state.privilegeOptions.length <= 0
                      }
                      disabled={
                        !DataHandler.getFromSession('isSYST')
                      }
                      children={this.state.privilegeOptions}
                      onChange={this.onChangeQuestionPrivilege}
                    >
                    </Select>
                  </FormGroup>

                  {/**Question Grade**/}
                  <FormGroup
                    labelName="Grade"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    <Select
                      selectWrap="col-lg-9"
                      selectId="question-grade"
                      id="question-grade"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      defaultValue={this.state.allGrades[0]}
                      multiple={false}
                      spinner={
                        this.props.loading &&
                        this.state.allGrades.length <= 0
                      }
                      disabled={
                        this.props.loading &&
                        this.state.allGrades.length <= 0
                      }
                      children={this.state.allGrades}
                      onChange={this.onChangeQuestionGrade}
                    ></Select>
                  </FormGroup>

                  {/**Question ChapterModule/Unit**/}
                  <FormGroup
                    labelName="Chapter/Module/Unit"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    <Select
                      selectWrap="col-lg-9"
                      selectId="question-chapter"
                      id="question-chapter"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      defaultValue={this.state.allChapters[0]}
                      multiple={false}
                      spinner={
                        this.props.loading &&
                        this.state.allChapters.length <= 0
                      }
                      disabled={
                        this.props.loading &&
                        this.state.allChapters.length <= 0
                      }
                      children={this.state.allChapters}
                      onChange={this.onChangeQuestionChapter}
                    ></Select>
                  </FormGroup>

                  <div className="output" id="kt_form">
                    {/**Question Source**/}
                    <FormGroup
                      labelName="Source"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <Select
                        selectWrap="col-lg-9"
                        selectId="question-source"
                        id="question-source"
                        className="form-control selectpicker"
                        dataLiveSearch="false"
                        dataSize="5"
                        defaultValue={this.state.sourceOptions[0]}
                        multiple={false}
                        spinner={
                          this.props.loading &&
                          this.state.sourceOptions.length <= 0
                        }
                        disabled={
                          this.props.loading &&
                          this.state.sourceOptions.length <= 0
                        }
                        children={this.state.sourceOptions}
                        onChange={this.onChangeQuestionSource}
                      ></Select>
                    </FormGroup>

                    {/**Question Author**/}
                    <FormGroup
                      labelName="Author"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <Select
                        selectWrap="col-lg-9"
                        selectId="question-author"
                        id="question-author"
                        className="form-control selectpicker"
                        dataLiveSearch="true"
                        dataSize="5"
                        defaultValue={this.state.authorOptions[0]}
                        multiple={false}
                        spinner={
                          this.props.loading &&
                          this.state.authorOptions.length <= 0
                        }
                        disabled={
                          this.props.loading &&
                          this.state.authorOptions.length <= 0
                        }
                        children={this.state.authorOptions}
                        onChange={this.onChangeQuestionAuthor}
                      ></Select>
                    </FormGroup>

                    {/**Question Year**/}
                    <FormGroup
                      labelName="Year"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <Select
                        selectWrap="col-lg-9"
                        selectId="question-year"
                        id="question-year"
                        className="form-control selectpicker"
                        dataLiveSearch="true"
                        dataSize="5"
                        defaultValue={this.state.yearOptions[0]}
                        multiple={false}
                        spinner={
                          this.props.loading &&
                          this.state.yearOptions.length <= 0
                        }
                        disabled={
                          this.props.loading &&
                          this.state.yearOptions.length <= 0
                        }
                        children={this.state.yearOptions}
                        onChange={this.onChangeQuestionYear}
                      ></Select>
                    </FormGroup>

                    {/**Question Term/Semester**/}
                    <FormGroup
                      labelName="Term/Semester"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <Select
                        selectWrap="col-lg-9"
                        selectId="question-term"
                        id="question-term"
                        className="form-control selectpicker"
                        dataLiveSearch="true"
                        dataSize="5"
                        defaultValue={this.state.termOptions[0]}
                        multiple={false}
                        spinner={
                          this.props.loading &&
                          this.state.termOptions.length <= 0
                        }
                        disabled={
                          this.props.loading &&
                          this.state.termOptions.length <= 0
                        }
                        children={this.state.termOptions}
                        onChange={this.onChangeQuestionTerm}
                      ></Select>
                    </FormGroup>

                    {/**Question Tags**/}
                    <FormGroup
                      labelName="Tags"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <Input
                        type="text"
                        inputWrap="col-lg-9"
                        className="form-control"
                        id="id-tags"
                        name="tags"
                        value={this.state.selectedTag}
                        onChange={this.onNewTagInput}
                        maxLength={120}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 question-right-container">
                  {/**
                           
                                <FormGroup labelName="Tags" className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                <div className="dropdown bootstrap-select form-control">
                                    <Input type="text" inputWrap="col-lg-8" className="form-control" id="kt_tagify_3"
                                          name="title"
                                          value={this.state.tagsList}
                                          ref={this.ref_tagify}
                                          
                                          onChange={this. handleChangeTags}
                                          class='form-control tagify tagify--outside'
                                          placeholder='Add keywords here...' />
                                          <span className="d-block text-muted pt-2 font-size-sm">
                                                            Press enter key to tag the typed or keywords
                                                        </span>
                                          </div>
                                </FormGroup>


                                 */}

                  {/**Question Title**/}
                  <FormGroup
                    labelName="Title"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    <Input
                      type="text"
                      inputWrap="col-lg-9"
                      className="form-control"
                      id="id-title"
                      name="title"
                      value={this.state.selectedTitle}
                      onChange={this.onNewTitleInput}
                      maxLength={120}
                    />
                  </FormGroup>

                  {/**Question Guidance**/}
                  <FormGroup
                    labelName="Marking Guidance"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    {/* <Input
                      type="text"
                      inputWrap="col-lg-9"
                      className="form-control"
                      id="id-guidance"
                      name="guidance"
                      value={this.state.guidance}
                      onChange={this.onNewGuidanceInput}
                    /> */}
                    <div className="col-lg-12">
                      <div className="RichEditor-root-createquestion">
                        <GuidanceEditor
                          value={this.state.guidanceEditorState}
                          editorState={this.state.guidanceEditorState}
                          onEditorStateChange={this.onGuidanceEditorChange}
                        />
                      </div>
                    </div>
                  </FormGroup>

                  {/**Question Content**/}
                  <FormGroup
                    labelName={this.state.showSummerNoteOrFileUpload ===
                      questionTypeConstants.SUBQUESTION.name ? "Main Question" : "Content"}
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    <div className="col-lg-12">
                      <div className="RichEditor-root-createquestion">
                        <CustomEditor
                          value={this.state.contentEditorState}
                          editorState={this.state.contentEditorState}
                          onEditorStateChange={this.onContentEditorChange}
                        />
                      </div>
                    </div>
                  </FormGroup>

                  {/**Question Answer**/}
                  {this.state.showSummerNoteOrFileUpload ===
                    questionTypeConstants.MCQ.name && (
                      <>
                        <div id="mcq" className="colors">
                          <div className="row ml-1">
                            <h4 className="text-dark font-weight-bold mt-5 mb-5 col-lg-12">
                              Enter the options
                              <span className="form-text text-muted font-size-sm">
                                Enter the percentage of marks to be given when
                                the choice is selected
                              </span>
                            </h4>
                          </div>

                          <div className="option-wrap">
                            <ul className="p-0 row">
                              {this.state.choicesEditorState.length > 0 &&
                                this.state.choicesEditorState.map(
                                  (editorState, i) => (
                                    <li
                                      className="new-item new-item-custom col-md-12 px-0"
                                      onAnimationEnd={
                                        this.optionOnAnimationEnd
                                      }
                                    >
                                      <FormGroup
                                        key={i}
                                        labelName="Choice"
                                        className="col-lg-12"
                                      >
                                        <div className="col-lg-12">
                                          <Input
                                            type="number"
                                            className="col-md-4 Choice-mark"
                                            name="marks"
                                            value={
                                              this.state.selectedChoices[i]
                                            }
                                            // onChange={this.onChoiceEdit}
                                            onChange={(e) =>
                                              this.onChoiceEdit(e, i)
                                            }
                                          />
                                          <div className="RichEditor-root-createquestion">
                                            <CustomEditor
                                              editorState={
                                                this.state.choicesEditorState[
                                                i
                                                ]
                                              }
                                              onEditorStateChange={(e) =>
                                                this.onChoicesEditorChange(
                                                  e,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </FormGroup>
                                    </li>
                                  )
                                )}
                            </ul>

                            <Button
                              type="button"
                              onClick={this.addOption}
                              className="btn btn-primary btn-sm font-weight-bold mr-2 add"
                              btnName="Add options"
                              btnIconStyle="flaticon2-plus icon-sm mr-2"
                            />
                            <Button
                              type="button"
                              onClick={this.removeOption}
                              className="btn btn-danger btn-sm font-weight-bold mr-2 remove"
                              btnName="Remove options"
                              btnIconStyle="flaticon2-plus icon-sm mr-2"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  {this.state.showSummerNoteOrFileUpload ===
                    questionTypeConstants.DESCRIPTIVE.name && (
                      <div id="descriptive" className="colors">
                        <FormGroup
                          labelName="Model answer"
                          className="col-lg-12"
                        >
                          <div className="col-lg-12">
                            <div className="RichEditor-root-createquestion">
                              <CustomEditor
                                editorState={this.state.choicesEditorState}
                                onEditorStateChange={(e) =>
                                  this.onChoicesEditorChange(e, 0)
                                }
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    )}
                  {this.state.showSummerNoteOrFileUpload ===
                    questionTypeConstants.SUBMISSION.name && (
                      <div id="file-upload" className="colors">
                        <FormGroup
                          labelName="Model answer"
                          className="col-lg-12"
                        >
                          <FileUpload
                            className="col-lg-12"
                            callBack={this.getSubmissionContent}
                            deleteback={this.deleteSubmissionContent}
                            enable={true}
                            clear={this.state.clearFile}
                          />
                        </FormGroup>
                      </div>
                    )}
                  {this.state.showSummerNoteOrFileUpload ===
                    questionTypeConstants.SUBQUESTION.name && (
                      <>
                        <div id="mcq" className="colors">

                          <div className="option-wrap">
                            <ul className="p-0 row">
                              {this.state.choicesEditorState.length > 0 &&
                                this.state.choicesEditorState.map(
                                  (editorState, i) => (
                                    <li
                                      className="new-item new-item-custom col-md-12"
                                      onAnimationEnd={
                                        this.optionOnAnimationEnd
                                      }
                                    >
                                      <FormGroup
                                        labelName="Question"
                                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                      >
                                        <div className="col-lg-12">
                                          <div className="RichEditor-root-createquestion">
                                            <CustomEditor
                                              editorState={
                                                this.state.subQuestions[
                                                i
                                                ]
                                              }
                                              onEditorStateChange={(e) =>
                                                this.onSubQUestionEditorChange(
                                                  e,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </FormGroup>
                                      <FormGroup
                                        key={i}
                                        labelName="Model Answer"
                                        className="col-lg-12"
                                      >
                                        <div className="col-lg-12">

                                          <div className="RichEditor-root-createquestion">
                                            <CustomEditor
                                              editorState={
                                                this.state.choicesEditorState[
                                                i
                                                ]
                                              }
                                              onEditorStateChange={(e) =>
                                                this.onChoicesEditorChange(
                                                  e,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </FormGroup>
                                    </li>
                                  )
                                )}
                            </ul>

                            <Button
                              type="button"
                              onClick={this.addOption}
                              className="btn btn-primary btn-sm font-weight-bold mr-2 add"
                              btnName="Add Sub Question"
                              btnIconStyle="flaticon2-plus icon-sm mr-2"
                            />
                            {this.state.choicesEditorState.length > 0 && (
                              <Button
                                type="button"
                                onClick={this.removeOption}
                                className="btn btn-danger btn-sm font-weight-bold mr-2 remove"
                                btnName="Remove Sub Question"
                                btnIconStyle="flaticon2-plus icon-sm mr-2"
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}

                </div>
              </div>
            </CardBody>
          </Card>
          {/* end::Card */}
        </Form>
      </ContentWrapper>
    </AdminTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.questionsCreateReducer.loading,
    error: state.questionsCreateReducer.error,
    success: state.questionsCreateReducer.success,

    mcq_loading: state.mcqReducer.loading,
    mcq_error: state.mcqReducer.error,
    mcq_success: state.mcqReducer.success,

    desc_loading: state.descriptiveReducer.loading,
    desc_error: state.descriptiveReducer.error,
    desc_success: state.descriptiveReducer.success,
    
    question_id: state.descriptiveReducer.question_id,
    allQuestionMetaData: state.questionsCreateReducer.allQuestionMetaData,

    submission_loading: state.submissionQuestionReducer.loading,
    submission_success: state.submissionQuestionReducer.success,
    submission_error: state.submissionQuestionReducer.error
  };
}

const mapActionsToProps = {
  getQuestionMetaData: createActions.getQuestionMetaData,
  refreshPage: createActions.refreshPage,
  createCategory: createActions.createCategory,
  selectCategory: createActions.selectCategory,
  selectType: createActions.selectType,
  createMCQ: mcqActions.createQuestion,
  createDescriptive: descriptiveActions.createQuestion,
  createMain: descriptiveActions.createMainQuestion,
  createSubQuestion: descriptiveActions.createSubQuestion,
  createSubmission: submissionActions.createQuestion
}

export default connect(mapStateToProps, mapActionsToProps)(NewQuestion);

