import React from "react";
import ContentWrapper from "../../components/common/ContentWrapper.js";
import Card from "../../components/common/Card.js";
import CardBody from "../../components/common/CardBody.js";
import moment from "moment";
import { history } from "../../helper/history.js";
import TabList from "../../components/common/tabs/TabList.js";
import NavItem from "../../components/common/tabs/NavItem.js";
import TabContent from "../../components/common/tabs/TabContent.js";
import TabPane from "../../components/common/tabs/TabPane.js";
import Spinner from "../../components/Spinner3.js";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ReactSelect from "react-select";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Typography
} from "@mui/material";
import { Button as ViewButton } from 'react-bootstrap';
import MaterialCustomTablePagination from "../../components/common/MaterialCustomTablePagination.js";
import debounce from "lodash.debounce";
import {
  listAssessments,
  listChildAssessments,
  listNodeAssessments,
  getNodeEnrolledPapers,
  getNodeEnrolledChildPapers,
  getNodeEnrolledPapersForUser,
  openAssessment,
  getReport,
  getEnrolledPapers
} from "../../actions/candidate/examActions.js";
import {
  refreshAssessmentStartPage,
  validateCandidate,
  startTimer,
  loadQuestions,
  refreshForExamView,
  getCandidateAnswers,
  getAllCandidateAnswerContents,
  clearCandidateAnswers,
  finishTest,
  loadAssessmentQuestions,
  onSetMarks,
  submitAnswers
} from "../../actions/candidate/testActions.js";
import {
  saveMarks,
  finalizeGrade,
  finalizeAutoGrade
} from "../../actions/candidate/gradeActions.js";
import { connect } from "react-redux";
import { assessmentFilters, assessmentStatus, questionTypeConstants } from "../../helper/constants.js";
import { UserManagementService } from "../../service/user_management/user_management.service.js";
import { namespace } from "../../helper/namespace.js";
import "../../components/admin/css/manage-table-title.css";
import Form from "react-bootstrap/Form";
import { sweetStatusAlert } from "../../components/common/SweetAlert.js";
import { AssessExamService } from "../../service/assessment/assessExamService.js";
import { parseInt } from "lodash";
import { AssessmentService } from "../../service/candidate/assessmentService.js";
import { libraryService } from "../../service/assessment/libraryService.js";
import PaperGradeReport from "./PaperGradeReport.js";
import EnrolledPaperDetailsNew from "./EnrolledPaperDetailsNew.js";
import PaperQuestions from "./PaperQuestions.js";
import { sweetAlertTryagain } from "../../components/common/SweetAlert.js";
import { ASSESSMENT_FILTERS } from "../../helper/constants.js";
import { assessmentService } from "../../service/assessment/assessmentService.js";
import { Checkbox } from "@mui/material";
import config from "../../helper/config.js";
import { customerConstants } from "../../helper/constants.js";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { MarksheetService } from "../../service/manage/mark_sheet/marksheet.service.js";
import { StudentService } from "../../service/manage/registration/student.service.js";
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  ContentState
} from "draft-js";
import Modal from "react-bootstrap/Modal";
import { unitConstants } from "../../helper/constants.js";
import { store } from "../../helper/redux_store";
import { candidateService } from "../../service/settings/candidate/candidateService.js";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
    Box
  } from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControlLabel from "@mui/material/FormControlLabel";
import PaperListChips from "../manage/paper/edsider/FindPaperTabs/PaperListChips.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, startBtnStyle, cancelClass, confirmClass, isEdFoci;
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "Unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    };
    closeBtnStyle = {
      color: "var(--maincolor)",
      backgroundColor: "#edeef7",
      borderColor: "transparent",
      textTransform: "Unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    };
    isEdFoci = false;
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
      boxShadow: "none",
    };
    isEdFoci = false;
    break;
  default:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "Unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    };
    startBtnStyle = {
      color: "#fff",
      backgroundColor: "#32B965",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "Unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    };
    closeBtnStyle = {
      color: "var(--maincolor)",
      backgroundColor: "#edeef7",
      borderColor: "transparent",
      textTransform: "Unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    };
    isEdFoci = true;
    break
}


class EdEnrolledPaperList extends React.Component {
  intervalID;
  constructor(props) {
    super(props);

    this.controller = props.controller;

    this.state = {
      userType: null,
      start_exam: false,
      resume_exam: false,
      show_report: false,
      isEligibleToStart: false,
      node_assessments: [],
      selectedChildStuId: "",
      childUserId: "0",
      isNavHidden: false,
      shouldShowSelect: false,
      limit: 100,
      offset: 0,
      search_term: "",
      currentPage: 0,
      totalEnrolledPapers: 0,
      studentlist: [],
      isTableDataLoading: false,
      isStudentsLoading: false,
      sort_column: "enrolled_time",
      sort_direction: "DESC",
      breadcrumb2: null,
      nodeTitle: null,
      isLoginUserParent: false,
      shouldShowEnrolledBy: false,
      renderPage: true,
      added_by: "",
      lastAttemp: [],
      nextAttempt: "",
      tryAgainButtonHide: false,
      filterValues: [],
      filters: [],
      selectedFilters: [],
      selectedGrades: null,
      selectedChapters: null,
      selectedsubjects: null,
      selectedAuthors: null,
      selectedYears: null,
      selectedCategories: null,
      selectedTerms: null,
      selectedDifficulties: null,
      selectedLanguages: null,
      allCategories: [],
      allLanguages: [],
      allSubjects: [],
      allAuthors: [],
      allYears: [],
      allDifficulties: [],
      allGrades: [],
      allTerms: [],
      allChapters: [],
      allUnits: [],
      selectedCandidateStatus: null,
      studentSearchTerm: "",
      selectedStudent: null,
      isAllMCQs: true,
      examLoading: false,
      selectedPaper: false,
      autoGradeLoading: false,
      clickdisableLoader:false,
    };

    this.onView = this.onView.bind(this);
    this.onViewReport = this.onViewReport.bind(this);
    this.onViewResume = this.onViewResume.bind(this);
    this.onClickView = this.onClickView.bind(this)
    this.onViewBoth = this.onViewBoth.bind(this);
    this.validateIsEligibleToStart = this.validateIsEligibleToStart.bind(this);
    this.listenerForOnProgressAssessments =
      this.listenerForOnProgressAssessments.bind(this);
    this.handleAddNewAssessment = this.handleAddNewAssessment.bind(this);
    this.autosaveTestState = this.autosaveTestState.bind(this);
    this.saveTestState = this.saveTestState.bind(this);
    this.debouncedHandleStudentSearch = debounce(this.fetchAllStudents, 1000);
    this.checkingForAllMcqs = this.checkingForAllMcqs.bind(this);
    this.autoGradeMCQ = this.autoGradeMCQ.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.start_exam !== this.state.start_exam &&
      !this.state.start_exam
    ) {
      let userType = sessionStorage.getItem("userType");
      this.fetching(userType);
    }

    if (
      (prevState.start_exam === true && this.state.start_exam === false) ||
      (prevState.show_report === true && this.state.show_report === false)
    ) {
      if (this.props.nodeProps) {
        this.props.nodeProps.setIsHideForExam(true)
      }
    }

    if (
      (prevState.start_exam === false && this.state.start_exam === true) ||
      (prevState.show_report === false && this.state.show_report === true)
    ) {
      if (this.props.nodeProps) {
        this.props.nodeProps.setIsHideForExam()
      }
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
    clearInterval(this.intervalID);
  }

  async componentDidMount() {
    await AssessExamService.getExamCount().then((res) => {
      const limit = parseInt(res.enrolled_exam_list_count);
    });
    let userType = sessionStorage.getItem("userType");
    this.setState({ userType });

    if (window.KTSelect2) window.KTSelect2.init();
    if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();

    const {
      mid,
      pid,
      lg_user_id,
      lg_user_table_id,
      flg_list_active,
      breadcrumb2,
      nodeTitle,
      form_display_title,
      form_list_id,
    } = this.props.state;

    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle: nodeTitle,
    });

    sessionStorage.setItem("enrolled_paper_list_mid", mid);
    sessionStorage.setItem("pid", pid);
    sessionStorage.setItem("lg_user_id", lg_user_id);
    sessionStorage.setItem("lg_user_table_id", lg_user_table_id);
    sessionStorage.setItem("flg_list_active", flg_list_active);
    sessionStorage.setItem("form_display_title", form_display_title);
    sessionStorage.setItem("form_list_id", form_list_id);

    const defaultCandidateStatus = {
        filter: "Paper Status",
        name: "All",
        value: "null",
      };

    this.setState((prevState) => ({
    filterValues: [...prevState.filterValues, defaultCandidateStatus],
    selectedCandidateStatus: defaultCandidateStatus.value,
    }));

    if (userType === "student") {

        // const studentGrade = parseInt(sessionStorage.getItem("studentGrade"), 10);
        // const studentLanguage = parseInt(sessionStorage.getItem("studentMedium"), 10);
      
        // this.setState(
        //   { selectedGrades: stuGrade, selectedLanguages: stuLanguage },
        //   () => {

        //     if(this.state.selectedGrades && this.state.selectedLanguages){
        //       
              
        //       this.fetchAllEnrolledPapers();
        //     }
      
        //   }
        // );
    }
      

    if (userType === "parent") {
      if (parseInt(flg_list_active) === 1) {
        this.setState({ isLoginUserParent: true });
        this.setState({ shouldShowSelect: true });
        this.setState({ shouldShowEnrolledBy: true });
        this.setState({ renderPage: false });
      }
      this.fetchAllStudents();
    }

    if (userType === "admin") {
      if (parseInt(flg_list_active) === 1) {
        this.setState({ isLoginUserParent: true });
        this.setState({ shouldShowSelect: true });
        this.setState({ added_by: "All" });
        this.setState({ shouldShowEnrolledBy: true });
        this.setState({ renderPage: false });
      }
      this.fetchAllStudents();
    }

    this.listenerForOnProgressAssessments(this.state.node_assessments);
    this.controller(false);
    this.setState({ start_exam: false });
    this.setState({ resume_exam: false });

    this.intervalID = setInterval(
      this.autosaveTestState,
      config.examConfigs.autoSubmitInterval * 60 * 1000
    );

    this.onDatatableActionEvent();
    this.fetchAllAssessmentSettings();

    const savedState = sessionStorage.getItem(namespace.EXAMSTATE);
    if (savedState) {
      const examState = JSON.parse(savedState);
      await this.props?.submitAnswers({...examState})
    }
  }

  async autosaveTestState() {
    const savedState = sessionStorage.getItem(namespace.EXAMSTATE);
    if (savedState) {
      const examState = JSON.parse(savedState);
      await this.props?.submitAnswers({...examState})
    }
  }

  fetchAllAssessmentSettings() {
    this.setState({
      isLoading: true,
      isTableDataLoading:true
    });
    const fetchAssessmentMetaData = assessmentService.getAllAssessmentMetaData();

    Promise.all([
      fetchAssessmentMetaData
    ])
      .then((response) => {
        const [
          assessmentMetaDataResponse
        ] = response;

        this.setState({
          allCategories: assessmentMetaDataResponse.data.find(item => 'categories' in item).categories,
          allLanguages: assessmentMetaDataResponse.data.find(item => 'languages' in item).languages,
          allSubjects: assessmentMetaDataResponse.data.find(item => 'subjects' in item).subjects,
          allAuthors: assessmentMetaDataResponse.data.find(item => 'authors' in item).authors,
          allYears: assessmentMetaDataResponse.data.find(item => 'years' in item).years,
          allDifficulties: assessmentMetaDataResponse.data.find(item => 'difficulty_types' in item).difficulty_types,
          allGrades: assessmentMetaDataResponse.data.find(item => 'grades' in item).grades,
          allTerms: assessmentMetaDataResponse.data.find(item => 'terms' in item).terms,
          allChapters: assessmentMetaDataResponse.data.find(item => 'chapters' in item).chapters,
          allUnits: assessmentMetaDataResponse.data.find(item => 'units' in item).units,
          isLoading: false,
          isTableDataLoading:false
        },async()=>{

          const studentGrade = parseInt(sessionStorage.getItem("studentGrade"), 10);
          const studentLanguage = parseInt(sessionStorage.getItem("studentMedium"), 10);
          

          if(this.state.allGrades && this.state.allGrades.length>0 && this.state.allLanguages && this.state.allLanguages.length>0){

            const matchingGrade = this.state.allGrades.find((grade) => grade.grade_id === studentGrade);

            const matchingLanguage = this.state.allLanguages.find((language) => language.language_id === studentLanguage);
            

            const filterMap = {
              Category: [],
              Difficulty: [],
              Language: [],
              Subject: [],
              Author: [],
              Year: [],
              Term: [],
              Chapter: [],
              Grade: []
            };

            filterMap.Grade.push(matchingGrade.grade_id);
            filterMap.Language.push(matchingLanguage.language_id);

            const defaultSelectedGrade = {
              filter: "Grade",
              name: matchingGrade.grade,
              grade_id: matchingGrade.grade_id,
            };

            const defaultSelectedLanguage = {
              filter: "Language",
              name: matchingLanguage.language,
              language_id: matchingLanguage.language_id,
            };
      
            this.setState((prevState) => ({
            filterValues: [...prevState.filterValues, defaultSelectedGrade,defaultSelectedLanguage],
            }));

            this.setState({
              selectedLanguages:filterMap.Language.length > 0 ? filterMap.Language : null,
              selectedGrades: filterMap.Grade.length > 0 ? filterMap.Grade : null,
            },async()=>{
              this.fetchAllEnrolledPapers()
            })

          }

          // if(this.state.allGrades && this.state.allGrades.length>0 ){

          //   const matchingGrade = this.state.allGrades.find((grade) => grade.grade_id === studentGrade);
            
          //   if (matchingGrade) {
          //     matchingGrade.filter = "Grade";
          //     matchingGrade.name = matchingGrade.grade
          //     await this.onSelectFilterMulti(matchingGrade)
          //   }

          // }

          // if(this.state.allLanguages && this.state.allLanguages.length>0){

          //   const matchingLanguage = this.state.allLanguages.find((language) => language.language_id === studentLanguage);
            
            

          //   if (matchingLanguage) {
          //     matchingLanguage.filter = "Language";
          //     matchingLanguage.name = matchingLanguage.language

          //     await this.onSelectFilterMulti(matchingLanguage)
          //   }

          // }

        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isTableDataLoading:false
        });
      });
  }

  fetching = async (userType) => {
    if (userType === "student") {
      
      await this.fetchAllEnrolledPapers();
    }
    if (userType === "parent") {
      await this.fetchAllStudents();
      await this.fetchAllEnrolledChildPapers();
    }
    if (userType === "admin") {
      await this.fetchAllStudents();
      await this.fetchAllEnrolledNodeExamsForUser();
    }
    this.listenerForOnProgressAssessments(this.state.node_assessments);
    this.onDatatableActionEvent();
  };

  fetchAllEnrolledPapers = async () => {
    const {
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      selectedCandidateStatus,
    } = this.state;
    const { mid } = this.props.state;
    let login_user_id = sessionStorage.getItem("user_id");
    this.setState({ isTableDataLoading: true });
    await this.props.getEnrolledPapers(
      login_user_id,
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      selectedCandidateStatus
    );

    this.setState(() => {
      let tryAgainButtonHide = false;
      let assess = this.props?.node_enrolled_papers.map((item) => {
        if (item.candidate_status === "Finished") {
          tryAgainButtonHide = true;
        }
        return { ...item, tryAgainButtonHide };
      });
      const totalEnrolledPapers = this.props?.total_count;
      return { node_assessments: assess, totalEnrolledPapers };
    });
    this.setState({ isTableDataLoading: false });
  };

  fetchAllEnrolledChildPapers = async () => {
    const {
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      childUserId,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      selectedCandidateStatus,
    } = this.state;
    const { mid } = this.props.state;
    // let login_user_id = sessionStorage.getItem("user_id");
    this.setState({ isTableDataLoading: true });
    await this.props.getNodeEnrolledChildPapers(
      sessionStorage.getItem("parent_group_id"),
      childUserId,
      mid,
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      selectedCandidateStatus
    );
    if (this.props.status === 200) {
      this.setState(
        {
          node_assessments: this.props?.node_enrolled_child_papers,
          totalEnrolledPapers: this.props?.total_count,
        },
        () => { }
      );
    }
    this.setState({ isTableDataLoading: false });
  };

  fetchAllEnrolledNodeExamsForUser = async () => {
    const {
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      childUserId,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      added_by,
      selectedCandidateStatus,
    } = this.state;
    const { mid } = this.props.state;
    this.setState({ isTableDataLoading: true });
    await this.props.getNodeEnrolledPapersForUser(
      childUserId,
      mid,
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      added_by,
      selectedCandidateStatus
    );
    if (this.props.status === 200) {
      this.setState({
        node_assessments: this.props?.node_enrolled_papers_for_user,
        totalEnrolledPapers: this.props?.total_count,
      });
    }
    this.setState({ isTableDataLoading: false });
  };

  fetchAllStudents = async () => {
    if (isEdFoci) {
      await this.fetchAllEdFociStudents();
    }
    else {
      await this.fetchAllEdSiderCMCStudents();
    }
  }

  fetchAllEdSiderCMCStudents = async () => {
    const { mid, lg_user_id, lg_user_table_id, form_list_id } = this.props.state;

    this.setState({ isStudentsLoading: true });

    let userType = sessionStorage.getItem("userType");

    let user_id;
    let user_table_id;

    if (userType === "parent") {
      user_id = sessionStorage.getItem("parent_group_id");
      user_table_id = sessionStorage.getItem("parent_group_user_table_id");
    } else {
      user_id = lg_user_id;
      user_table_id = lg_user_table_id;
    }

    await StudentService.getAllStudentsForParent(
      mid,
      form_list_id,
      user_id,
      user_table_id,
      userType
    )
      .then((response) => {
        if (response.success) {
          this.setState({ studentlist: response.data.students });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching students. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching students. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isStudentsLoading: false });
      });
  };

  fetchAllEdFociStudents = async () => {
    const { mid, lg_user_id, lg_user_table_id, form_list_id } = this.props.state;

    this.setState({ isStudentsLoading: true });

    await MarksheetService.getAllEnrolledStudents(
      mid,
      form_list_id,
      lg_user_id,
      lg_user_table_id,
      5,
      0,
      "user_code",
      "desc",
      this.state.studentSearchTerm
    )
      .then((response) => {
        if (response.success) {
          this.setState({ studentlist: response.data.users });
          this.setState({ isStudentsLoading: false });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching students. Please refresh the screen!",
            "error"
          );
          this.setState({ isStudentsLoading: false });
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching students. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleChildSelect = async () => {

    let student = this.state.studentlist.find(
      (student) => student.user_id === parseInt(this.state.selectedChildStuId)
    );
    this.setState({ isTableDataLoading: true });
    this.setState({ renderPage: true });
    const selectedChild = this.state.selectedChildStuId;
    const selectedChildStuId = selectedChild?.toString();
    this.setState({ selectedChildStuId: selectedChildStuId });
    await UserManagementService.getStuId(student.user_id, student.user_table_id)
      .then((umRes) => {
        const childUserId = umRes.data?.user_id.toString();
        sessionStorage.setItem("selected_child_user_id", childUserId);
        this.setState({ childUserId: childUserId });
      })
      .finally(() => {
        if (this.state.userType === "admin") {
          this.fetchAllEnrolledNodeExamsForUser();
        }
        if (this.state.userType === "parent") {
          this.fetchAllEnrolledChildPapers();
        }
      });
    this.setState({ isTableDataLoading: false });
  };

  getPaperCategory(category_id) {
    if (category_id !== null) {
        const category = this.state.allCategories?.find(
            (category) => category.category_id == category_id
        );
        return category?.name;
    }
    else{
        return "N/A";
    }
  }

  getPaperLanguage(language_id) {
    if (language_id !== null) {
        const language = this.state.allLanguages?.find(
            (language) => language.language_id == language_id
        );
        return language?.language;
    }
    else{
        return "N/A";
    }
  }

  getPaperSubject(subject_id) {
    if (subject_id !== null) {
        const subject = this.state.allSubjects?.find(
            (subject) => subject.subject_id == subject_id
        );
        return subject?.subject;
    }
    else{
        return "N/A";
    }
  }

  getPaperAuthor(author_id) {
    if (author_id !== null) {
        const author = this.state.allAuthors?.find(
            (author) => author.author_id == author_id
        );
        return author?.author;
    }
    else{
        return "N/A";
    }
  }

  getPaperYear(year_id) {
    if (year_id !== null) {
        const year = this.state.allYears?.find(
            (year) => year.year_id == year_id
        );
        return year?.year;
    }
    else{
        return "N/A";
    }
  }

  getPaperDifficulty(difficulty_id) {
    if (difficulty_id !== null) {
        const difficulty = this.state.allDifficulties?.find(
            (difficulty) => difficulty.difficulty_id == difficulty_id
        );
        return difficulty?.difficulty_type;
    }
    else{
        return "N/A";
    }
  }

  getPaperGrade(grade_id) {
    if (grade_id !== null) {
        const grade = this.state.allGrades?.find(
            (grade) => grade.grade_id == grade_id
        );
        return grade?.grade;
    }
    else{
        return "N/A";
    }
  }

  getPaperTerm(term_id) {
    if (term_id !== null) {
        const term = this.state.allTerms?.find(
            (term) => term.term_id == term_id
        );
        return term?.term;
    }
    else{
        return "N/A";
    }
  }

  getCreatedAtLocal(time) {
    if(time != null){
        let timeStamp = new Date(time.concat("Z"))
        let d = new Date(timeStamp)
        // let startDate = d.toDateString();
        // let startTime = d.toLocaleTimeString('en-SL');
        let  local = moment(timeStamp).local().format('YYYY-MM-DDTHH:mm')
        return local;
    }
    else{
        return "N/A";
    }
  }

  getPaperChapter(chapter_id) {
    if (chapter_id !== null) {
        const chapter = this.state.allChapters?.find(
            (chapter) => chapter.chapter_id == chapter_id
        );
        return chapter?.chapter;
    }
    else{
        return "N/A";
    }
  }

  getScheduleUnit(unit_id, value) {
    if(value !== null){
      if (unit_id !== null) {
          const unit = this.state.allUnits?.find(
              (unit) => unit.unit_id == unit_id
          );
          return unit?.unit;
      }
      else{
          return "N/A";
      }
    }
    else{
      return "";
    }
  }

  convertScheduleInSelectedUnit(value, unit) {
    if(value !== null){
      let valueInSelectedUnit;
      let selectedUnit = this.getScheduleUnit(unit, value);

      switch (selectedUnit) {
        case unitConstants.MONTHS.name:
          valueInSelectedUnit = value / 43200;
          break;
        case unitConstants.HOURS.name:
          valueInSelectedUnit = value / 60;
          break;
        case unitConstants.DAYS.name:
          valueInSelectedUnit = value / 1440;
          break;
        case unitConstants.MINUTES.name:
        default:
          valueInSelectedUnit = value;
      }
      
      return valueInSelectedUnit;
    }
    else{
      return 'Unlimited';
    }
  }
  
  getPaperStartTimeLocal(time) {
    if(time != null){
        let timeStamp = new Date(time.concat("Z"))
        let d = new Date(timeStamp)
        // let startDate = d.toDateString();
        // let startTime = d.toLocaleTimeString('en-SL');
        let  local = moment(timeStamp).local().format('YYYY-MM-DDTHH:mm')
        return local;
    }
    else{
        return "N/A";
    }
  }

  getColor(state) {
    switch (state) {
      case "Assigned":
        return "label label-lg label-light-primary label-inline";
      case "Onprogress":
        return "label label-lg label-light-warning label-inline";
      case "Finished":
        return "label label-lg label-success label-inline";
      default:
        return "label label-lg label-light-info label-inline";
    }
  }

  getPaperStatus(state) {
    switch (state) {
      case "Assigned":
        return "Not Started";
      case "Finished":
        return "Completed";
      case "Onprogress":
        return "Started";
      default:
        return state;
    }
  }

  toggleDetailModal = () => {
    this.setState((prevState) => ({
      showDetailModal: !prevState.showDetailModal,
    }));
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const { lg_user_table_id } = this.props.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        if (this.state.userType === "admin") {
          this.fetchAllEnrolledNodeExamsForUser();
        }
        if (this.state.userType === "student") {
          this.fetchAllEnrolledPapers();
        }
        if (this.state.userType === "parent") {
          this.fetchAllEnrolledChildPapers();
        }
      }
    );
  };

  handleStudentSearch = (event) => {
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;
    if (event === "" || regex.test(event)) {
      let num = event
      this.setState(
        {
          studentSearchTerm: num,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleStudentSearch();
        }
      );
    } else {
      this.setState({ studentSearchTerm: event, searchError: true });
    }
  };

//   handleCandidateStatusChange = (candidateStatus) => {
//     this.setState(
//       {
//         selectedCandidateStatus: candidateStatus,
//         offset: 0,
//         isTableDataLoading: true,
//       },
//       () => {
//         if (this.state.userType === "admin") {
//           this.fetchAllEnrolledNodeExamsForUser();
//         }
//         if (this.state.userType === "student") {
//           this.fetchAllEnrolledPapers();
//         }
//         if (this.state.userType === "parent") {
//           this.fetchAllEnrolledChildPapers();
//         }
//       }
//     );
//   };

  handleCandidateStatusChange = (candidateStatus) => {
    const filterValues = [...this.state.filterValues];
    
    const updatedFilterValues = filterValues.filter(
      (item) => item.filter !== "Paper Status"
    );
  
    let displayName = "";
    switch (candidateStatus) {
        case "null":
        displayName = "All";
        break;
        case "Upcoming":
        displayName = "Not Started";
        break;
        case "Onprogress":
        displayName = "Started";
        break;
        case "Finished":
        displayName = "Completed";
        break;
        default:
        displayName = "All";
    }

    if (candidateStatus !== undefined) {
        updatedFilterValues.push({
        filter: "Paper Status",
        name: displayName, 
        exam_type: candidateStatus,
        });
    }
  
    this.setState(
      {
        selectedCandidateStatus: candidateStatus,
        filterValues: updatedFilterValues, 
        offset: 0,
        isTableDataLoading: true,
      },
      () => {
        
        if (this.state.userType === "admin") {
          this.fetchAllEnrolledNodeExamsForUser();
        }
        if (this.state.userType === "student") {
          this.fetchAllEnrolledPapers();
        }
        if (this.state.userType === "parent") {
          this.fetchAllEnrolledChildPapers();
        }
      }
    );
  };
  

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        if (this.state.userType === "admin") {
          this.fetchAllEnrolledNodeExamsForUser();
        }
        if (this.state.userType === "student") {
          this.fetchAllEnrolledPapers();
        }
        if (this.state.userType === "parent") {
          this.fetchAllEnrolledChildPapers();
        }
      }
    );
  };

  //handle the data fetching as per the data per page limit gets changes
  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const { lg_user_table_id } = this.props.state;
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        if (this.state.userType === "admin") {
          this.fetchAllEnrolledNodeExamsForUser();
        }
        if (this.state.userType === "student") {
          this.fetchAllEnrolledPapers();
        }
        if (this.state.userType === "parent") {
          this.fetchAllEnrolledChildPapers();
        }
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
    const { lg_user_table_id } = this.props.state;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          // this.debouncedHandleSearch();
          if (this.state.userType === "admin") {
            this.fetchAllEnrolledNodeExamsForUser();
          }
          if (this.state.userType === "student") {
            this.fetchAllEnrolledPapers();
          }
          if (this.state.userType === "parent") {
            this.fetchAllEnrolledChildPapers();
          }
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleSortChange = (sort_column) => {
    // If the same column is clicked, toggle the sort direction
    const { sort_column: currentSortColumn, sort_direction } = this.state;
    const { lg_user_table_id } = this.props.state;
    const newSortDirection =
      currentSortColumn === sort_column && sort_direction === "ASC"
        ? "DESC"
        : "ASC";

    this.setState(
      {
        sort_column,
        sort_direction: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        if (this.state.userType === "admin") {
          this.fetchAllEnrolledNodeExamsForUser();
        }
        if (this.state.userType === "student") {
          this.fetchAllEnrolledPapers();
        }
        if (this.state.userType === "parent") {
          this.fetchAllEnrolledChildPapers();
        }
      }
    );
  };

  listenerForOnProgressAssessments(assessments) {
    let filteredAssessments = [];
    assessments.map((assessment) => {
      if(!assessment.is_set_time_limit){
        if (moment(assessment.start_time.concat("+00:00")).local().valueOf() < moment.now() || assessment.candidate_status === "Onprogress") {
          filteredAssessments.push(assessment);
        }
      }
      else{
        let timeWithDuration = moment(assessment.start_time.concat("+00:00"))
          .local()
          .add(assessment.exam_time_interval, "minutes")
          .valueOf();
        if (
          ((moment(assessment.start_time.concat("+00:00")).local().valueOf() <
            moment.now() &&
            timeWithDuration > moment.now()) && assessment.candidate_status === "Assigned") ||
          assessment.candidate_status === "Onprogress") 
        {
          filteredAssessments.push(assessment);
        }
      }
      });
    //Making the "ALL" tab active if one or more than onprogress assessments exist
    if (filteredAssessments.length > 0) {
      this.setState({
        navItemAll: "nav-link active",
        tabPaneAll: "tab-pane fade show active",
        navItemOnProgress: "nav-link",
        tabPaneOnProgress: "tab-pane fade",
        navItemCompleted: "nav-link",
        tabPaneCompleted: "tab-pane fade",
      });
    } else {
      this.setState({
        navItemAll: "nav-link active",
        tabPaneAll: "tab-pane fade show active",
        navItemOnProgress: "nav-link",
        tabPaneOnProgress: "tab-pane fade",
        navItemCompleted: "nav-link",
        tabPaneCompleted: "tab-pane fade",
      });
    }
  }

  async onView(el) {
    await this.props.openAssessment(el);

    window.Swal.fire({
      title: this.props.assessment.terms_content
        ? namespace.VALIDATION_START
        : namespace.START_MESSAGE,
      text: this.props.assessment.terms_content
        ? namespace.WARNING_MESSAGE
        : "",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: namespace.YES,
      denyButtonText: namespace.NO,
      customClass: {
        confirmButton: `btn font-weight-bold ${confirmClass}`,
        cancelButton: `btn font-weight-bold ${cancelClass}`,
      },
      onOpen: (modalElement) => {
        if (customer === customerConstants.EXO_CUSTOMER2) {
          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
        }
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.setState({ examLoading: true });
        if (!this.props.assessment.terms_content) {
          await this.props.validateCandidate(
            this.props.assessment.candidate_id
          );
          if (this.props.text === "candidate validated successfully") {
            await this.props.startTimer({
              candidate_id: this.props.assessment.candidate_id,
              assessment_id: this.props.assessment.assessment_id,
              attempt_no: this.props.assessment.attempt_no,
            });
            await this.props.loadQuestions(this.props.assessment.assessment_id);
            await this.props.getCandidateAnswers(this.props.assessment);
            await this.props.getAllCandidateAnswerContents({
              answers: this.props.candidateAnswers,
            });
            this.props.clearCandidateAnswers();
            this.controller(true);
            this.setState({ start_exam: true });
            this.setState({ examLoading: false });
          } else {
            window.Swal.fire({
              title: "Can not start paper!",
              text: this.props.validation_error.error,
              icon: "warning",
              customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
              }
            });
          }
        } else {
          this.controller(true);
          this.setState({ start_exam: true });
          this.setState({ examLoading: false });
        }
      }
    });
  }

  async onViewResume(el) {
    await this.props.openAssessment(el);
    window.Swal.fire({
      title: this.props.assessment.terms_content
        ? namespace.VALIDATION_START
        : namespace.RESUME_MESSAGE,
      text: this.props.assessment.terms_content
        ? namespace.WARNING_MESSAGE
        : "",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: namespace.YES,
      denyButtonText: namespace.NO,
      customClass: {
        confirmButton: `btn font-weight-bold ${confirmClass}`,
        cancelButton: `btn font-weight-bold ${cancelClass}`,
      },
      onOpen: (modalElement) => {
        if (customer === customerConstants.EXO_CUSTOMER2) {
          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
        }
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.setState({ examLoading: true });
        if (!this.props.assessment.terms_content) {
          await this.props.validateCandidate(
            this.props.assessment.candidate_id
          );
          if (this.props.text === "candidate validated successfully") {
            await this.props.startTimer({
              candidate_id: this.props.assessment.candidate_id,
              assessment_id: this.props.assessment.assessment_id,
              attempt_no: this.props.assessment.attempt_no,
            });
            await this.props.loadQuestions(this.props.assessment.assessment_id);
            await this.props.getCandidateAnswers(this.props.assessment);
            await this.props.getAllCandidateAnswerContents({
              answers: this.props.candidateAnswers,
            });
            this.controller(true);
            this.setState({ start_exam: true });
            this.setState({ examLoading: false });
          } else {

            if (this.props.validation_error.error && this.props.validation_error.error === "Time's up! You have exceeded the allocated paper duration. Please contact the Administrator!") {
              this.setState({ examLoading: false });
              this.setState({ autoGradeLoading: true });
              await this.props.loadQuestions(this.props.assessment.assessment_id);
              // await this.props.loadAssessmentQuestions(this.props.assessment.assessment_id);
              await this.props.getCandidateAnswers(this.props.assessment);
              await this.props.getAllCandidateAnswerContents({
                answers: this.props.candidateAnswers,
              });
              await this.saveTestState();
              await this.getAllCandidateGradeScales();
              await this.checkingForAllMcqs();
              if (this.state.isAllMCQs) {
                await this.autoGradeMCQ();
              }
              this.setState({ autoGradeLoading: false });
              let candidate_id = this.props.assessment.candidate_id;
              let is_all_mcq = this.state.isAllMCQs;
              this.props.finishTest({ candidate_id, is_all_mcq }).then(() => {
                clearInterval(this.intervalID);
                this.setState({ finishState: true }, () => {
                  if (!this.props.submission_uploading && this.props.submission_error) {
                    window.Swal.fire({
                      title: "Exam Finishing Failed",
                      text: this.props.error?.error,
                      icon: "error",
                      customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                      },
                      onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                      }
                    });
                    this.setState({ finishState: false, timeUp: false, submit: false, renderGradeReport: null });
                    this.fetchAllEnrolledPapers();
                  } else if (
                    !this.props.finish_loading && this.props.finish_success
                  ) {
                    this.setState({ examLoading: false });
                    localStorage.removeItem(this.props.candidate_id);
                    window.Swal.fire({
                      title: "Time's up!",
                      text: " Time's up! You have exceeded the allocated paper duration, Exam Finished",
                      icon: "info",
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                      },
                      onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                      }
                    });
                    this.fetchAllEnrolledPapers();
                  }
                });
              });
            }
            else {
              this.setState({ examLoading: false });
              window.Swal.fire({
                title: "Can not resume paper!",
                text: this.props.validation_error.error,
                icon: "warning",
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              });
            }
          }
        } else {
          this.controller(true);
          this.setState({ start_exam: true });
          this.setState({ examLoading: false });
        }
      }
    });

    this.setState({ resume_exam: true });
    this.setState({ examLoading: false });
  }

  // checking whether all questions are MCQS or not
  async checkingForAllMcqs() {
    var question_list = this.props.questions,
      i;
    for (i = 0; i < this.props?.questions.length; i++) {
      if (question_list[i]["question_type"] !== questionTypeConstants.MCQ.name) {
        this.setState({ isAllMCQs: false });
      }
    }
    return;
  }

  getAllCandidateGradeScales = async() => {
    this.setState({ isTableDataLoading: true });
    await candidateService.getAllGradeScales()
        .then((response)=>{
            if(response.success){
                this.setState({ 
                    candidateGradeScales: response.data.candidate_grade_scales,
                    isTableDataLoading: false 
                });
            }
            else{
                this.setState({ isTableDataLoading: false });
            }
            
        })
        .catch(()=>{
          this.setState({ isTableDataLoading: false });
        })
  }

  getRemark(percentage) {
    for (const scale of this.state.candidateGradeScales) {
        if (percentage >= scale.min_marks && percentage <= scale.max_marks) {
            return scale.remarks;
        }
    }
    return "Invalid grade percentage"; 
  }

  getGrade(percentage) {
    for (const scale of this.state.candidateGradeScales) {
          if (percentage >= scale.min_marks && percentage <= scale.max_marks) {
              return scale.grade;
          }
      }
    return "Invalid grade percentage"; 
  }

  calculateTotalAllocatedMarks() {
    var question_list = this.state.assessmentQuestions, i, sum = 0 ;
    for (i = 0; i < this.state?.assessmentQuestions.length; i++) {
      sum = sum + question_list[i]["mark_allocation"]
    }
    return sum;
  }

  calculateTotalReceivedMarks() {
    var i, total = 0;
    for (i = 0; i < this.state?.assessmentQuestions?.length; i++) {
      var candidate_answers = store.getState()["testReducer"]["candidateAnswerMap"];
      if (candidate_answers[this.state?.assessmentQuestions[i].question_id]["mark_obtained"]) {
        total = total + candidate_answers[this.state?.assessmentQuestions[i].question_id]["mark_obtained"];
      }
    }
    return total;
  }

  async autoGradeMCQ() {
    var i;
    for (i = 0; i < this.props?.assessmentQuestions.length; i++) {
      if (this.props.assessmentQuestions[i]?.question_type == questionTypeConstants.MCQ.name) {
        var candidate_answer =
          this.props?.answerMap[
          this.props.assessmentQuestions[i]?.question_id
          ];
        if (candidate_answer) {
          candidate_answer = JSON.parse(candidate_answer);
        }
        var res = this.props.answerContents[i]?.toString().split(",");
        let numOfCandidateAnswers = candidate_answer?.length;
        let numOfAnswers = 0;
        for (var m in res) {
          if (res[m] != "Nan" && res[m] != 0) {
            numOfAnswers = numOfAnswers + 1;
          }
        }
        if (numOfCandidateAnswers <= numOfAnswers) {
          var marks = 0, j, ans_index;
          for (j in candidate_answer) {
            ans_index = candidate_answer[j];
            marks = marks + (res[ans_index] *
              this.props.assessmentQuestions[i]?.mark_allocation) /
              100;
          }
          const assessor_id = "root";
          var id = this.props.questions[i]?.question_id;
          await this.props?.onSetMarks({ id, marks, assessor_id });
        }
        else {
          var marks = 0;
          const assessor_id = "root";
          var id = this.props?.questions[i]?.question_id;
          await this.props?.onSetMarks({ id, marks, assessor_id });
        }
      }
    }

    var total = 0;
    for (i = 0; i < this.props?.assessmentQuestions?.length; i++) {
      var candidate_answer =
        this.props?.answerMap[
        this.props?.assessmentQuestions[i]?.question_id
        ];
      if (candidate_answer?.mark_obtained) {
        total = total + candidate_answer?.mark_obtained;
      }
    }

    const totalMarksAllocation = this.calculateTotalAllocatedMarks();
    const finalGrade = this.calculateTotalReceivedMarks();
    const percentage = (finalGrade / totalMarksAllocation) * 100;
    const remark = this.getRemark(percentage)
    const grade = this.getGrade(percentage)

    let contentState = convertFromRaw({
      blocks: [
          {
              key: 'initial',
              text: remark,
              type: 'unstyled',
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {}
          }
      ],
      entityMap: {}
    });
    let remarksState = EditorState.createWithContent(contentState);
    this.setState({ remarksEditorState: remarksState})

    const assessment_id = this.props?.assessment?.assessment_id;
    const user_role_id = "CASS" + this.props?.assessment?.created_by;
    const questions = this.props?.candidateAnswers;
    const candidate_id = this.props?.candidateAssessment.candidate_id;
    const attempt_no = this.props?.candidateAssessment.attempt_no;
    sessionStorage.setItem("candidate_id", candidate_id);
    sessionStorage.setItem("attempt_no", attempt_no);
    const final_grade = parseFloat(finalGrade);
    const total_mark_allocation = parseFloat(totalMarksAllocation);
    const assessor_remarks = convertToRaw(
      this.state.remarksEditorState.getCurrentContent()
    );
    window.Swal.showLoading();

    await this.props?.saveMarks({
      assessment_id,
      questions,
      user_role_id,
      assessor_remarks,
      candidate_id,
      attempt_no
    });

    await this.props?.finalizeAutoGrade({
      candidate_id,
      final_grade,
      user_role_id,
      attempt_no,
      total_mark_allocation,
      grade
    });

    await this.props.getReport({ candidate_id, attempt_no });
    window.Swal.close();

    if (!this.props.gradeLoading && this.props.gradeError) {
      window.Swal.fire({
        title: "Failed!",
        text: "Failed to autograde!",
        icon: "error",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
    // else {
    //   window.Swal.fire("Success!", "Autograded successfully!", "success");
    // }

  }

  async saveTestState() {
    let candidate_id = this.props?.candidate_id;
    let assessment_id = this.props?.assessment.assessment_id;
    let attempt_no = this.props?.candidateAssessment?.attempt_no;
    let sub_questions = this.state?.subQuestions

    // let attempt_no = 
    let question_id, contentState, answer_content;
    let answers = {},
      i;
    for (i = 0; i < this.state?.questions?.length; i++) {
      question_id = this.state.questions[i]?.question_id;
      answers[question_id] = [];
      if (this.state.questions[i]?.question_type === questionTypeConstants?.MCQ?.name) {
        if (this.state?.answers[question_id]) {
          answers[question_id] = this.state?.answers[question_id];
        } else {
          answers[question_id] = [];
        }
      }
      else if (
        this.state?.questions[i].question_type ===
        questionTypeConstants?.DESCRIPTIVE.name
      ) {
        if (this.state.questions[i].answer) {
          contentState = this.state?.questions[i]?.answer?.getCurrentContent();
          answer_content = convertToRaw(contentState);
          answers[question_id] = answer_content;
        } else {
          let editorContent = [];
          let block = {
            blocks: [
              {
                key: _randomString(5, "#a"),
                text: "",
                type: "unstyled",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ],
            entityMap: {},
          };
          function _randomString(length, chars) {
            let mask = "";
            if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
            if (chars.indexOf("#") > -1) mask += "0123456789";
            let result = "";
            for (let i = length; i > 0; --i)
              result += mask[Math.floor(Math.random() * mask.length)];
            return result;
          }

          editorContent.push(block);
          answer_content = editorContent[0];
          answers[question_id] = answer_content;
        }
      } else if (
        this.state.questions[i].question_type ===
        questionTypeConstants?.SUBQUESTION.name && this.state?.questions[i]?.is_subquestion !== "true"
      ) {

        let editorContent = [];
        let block = {
          blocks: [
            {
              key: _randomString(5, "#a"),
              text: "",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
          ],
          entityMap: {},
        };
        function _randomString(length, chars) {
          let mask = "";
          if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
          if (chars.indexOf("#") > -1) mask += "0123456789";
          let result = "";
          for (let i = length; i > 0; --i)
            result += mask[Math.floor(Math.random() * mask.length)];
          return result;
        }

        editorContent.push(block);
        answer_content = editorContent[0];
        answers[question_id] = answer_content;

      }
      else if (
        this.state.questions[i].question_type ===
        questionTypeConstants?.SUBMISSION.name
      ) {
        // blobs are uploaded/deleted when adding/removing - No action needed for submission
        if (this.state.answers[question_id]) {
          answers[question_id] = this.state?.answers[question_id];
        } else {
          answers[question_id] = [];
        }
      }
    }

    if (sub_questions && Object.keys(sub_questions).length > 0) {
      for (let val in sub_questions) {
        if (sub_questions[val]?.answer) {
          let answer_content = sub_questions[val]?.answer.getCurrentContent();
          let sub_answer = convertToRaw(answer_content)
          answers[sub_questions[val]?.question_id] = sub_answer
        } else {
          let editorContent = [];
          let block = {
            blocks: [
              {
                key: _randomString(5, "#a"),
                text: "",
                type: "unstyled",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ],
            entityMap: {},
          };
          function _randomString(length, chars) {
            let mask = "";
            if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
            if (chars.indexOf("#") > -1) mask += "0123456789";
            let result = "";
            for (let i = length; i > 0; --i)
              result += mask[Math.floor(Math.random() * mask.length)];
            return result;
          }

          editorContent.push(block);
          let answer_content = editorContent[0];
          answers[sub_questions[val]?.question_id] = answer_content;
        }
      }
    }

    await this.props?.submitAnswers({ candidate_id, assessment_id, answers, attempt_no });
    await this.props?.getCandidateAnswers(this.props?.exam);
    await this.props?.getAllCandidateAnswerContents({ answers: this.props?.candidateAnswers });
    //await this.autoGradeMCQ();
  }

  async onViewReport(el) {
    this.setState({clickdisableLoader: true})
    await this.props.getReport(el);
    this.setState({ show_report: true });
  }
  async offViewReport() {
    this.setState({clickdisableLoader: false})
    this.setState({ show_report: false });
  }
  async offStartExam() {
    this.controller(false);
    this.setState({ start_exam: false });
  }

  async onClickTryAgain(el) {
    await this.handleAddNewAssessment(el.assessment_id);
  }

  async onClickView(el) {
    this.setState({
      selectedPaper: el,  
      showDetailModal: true,  
    });
  }

  onViewBoth(el) {
    if (el.candidate_status === "Onprogress") {
      this.onView(el);
    } else if (el.candidate_status === "Assigned") {
      this.onView(el);
    } else {
      this.onViewReport(el);
    }
  }

  assessmentsFilter(assessments, filter) {
    let filteredAssessments = [];
    switch (filter) {
      // case assessmentFilters.UP_COMING:
      //   assessments.map((assessment) => {
      //     if (
      //       moment(assessment.start_time.concat("+00:00")).local().valueOf() >=
      //       moment.now() && assessment.candidate_status === "Assigned"
      //     ) {
      //       filteredAssessments.push(assessment);
      //     }
      //   });
      //   break;
      // case assessmentFilters.ON_PROGRESS:
      //   assessments.map((assessment) => {
      //     if(!assessment.is_set_time_limit){
      //       if ((moment(assessment.start_time.concat("+00:00")).local().valueOf() < moment.now() && assessment.candidate_status === "Assigned") || assessment.candidate_status === "Onprogress") {
      //         filteredAssessments.push(assessment);
      //       }
      //     }
      //     else{
      //       let timeWithDuration = moment(assessment.start_time.concat("+00:00"))
      //         .local()
      //         .add(assessment.exam_time_interval, "minutes")
      //         .add(assessment.buffer_time, "minutes")
      //         .valueOf();

      //       if (
      //         (moment(assessment.start_time.concat("+00:00")).local().valueOf() <
      //         moment.now() &&
      //         timeWithDuration > moment.now() && assessment.candidate_status === "Assigned") || 
      //         assessment.candidate_status === "Onprogress"
      //       ) {
      //         filteredAssessments.push(assessment);
      //       }
      //     }
      //   });
      //   break;
      case assessmentFilters.EXPIRED:
        assessments.map((assessment) => {
          if(assessment.is_set_time_limit){
            let timeWithDuration = moment(assessment.start_time.concat("+00:00"))
              .local()
              .add(assessment.exam_time_interval, "minutes")
              .add(assessment.buffer_time, "minutes")
              .valueOf();
            if (
              timeWithDuration < moment.now() &&
              assessment.candidate_status !== "Finished"
            ) {
              filteredAssessments.push(assessment);
            }
          }
          else{
            filteredAssessments = filteredAssessments;
          }
        });
        break;
      case assessmentFilters.UP_COMING:
        assessments.map((assessment) => {
          if (moment(assessment.start_time.concat("+00:00")).local().valueOf() <= moment.now() && assessment.candidate_status === "Assigned") {
            filteredAssessments.push(assessment);
          }
        });
          break;
      case assessmentFilters.ON_PROGRESS:
        assessments.map((assessment) => {
          if (assessment.candidate_status === "Onprogress") {
            filteredAssessments.push(assessment);
          }
        });
        break;
      case assessmentFilters.COMPLETED:
        assessments.map((assessment) => {
          if (assessment.candidate_status === assessmentStatus.FINISHED) {
            filteredAssessments.push(assessment);
          }
        });
        break;
      case assessmentFilters.ALL:
        filteredAssessments = assessments;
        break;
    }

    return filteredAssessments;
  }

  validateIsEligibleToStart(startTime, bufferTime) {
    let examStartWithBuffer = moment(startTime.concat("+00:00"))
      .local()
      .add(bufferTime, "minutes")
      .toDate();
    if (examStartWithBuffer >= Date.now()) {
      this.setState({
        isEligibleToStart: true,
      });
    }
  }

  // this function bind jquery event listener to view-detail btn on datatable
  // datatable plugin is a jquery plgin, so it is duplicting action element and whatever event we have
  // bind via react component will be ignored.
  // to fix this bug, we are finding the array index on click event and retrive data. thereafter passing to relevent function
  // triggering the modal to be shown.
  onDatatableActionEvent() {
    window.$(document).on("click", ".on-start-assement-btn", (e) => {
      const _target = e.target;

      const idx = _target.getAttribute("dataindex");
      const value = _target.getAttribute("value");
      switch (value) {
        case "all":
          this.onView(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.ALL
            )[idx]
          );
          break;
        case "up_coming":
          this.onView(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.UP_COMING
            )[idx]
          );
          break;
        case "expired":
          this.onView(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.EXPIRED
            )[idx]
          );
          break;
      }
    });

    window.$(document).on("click", ".on-resume-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      const value = _target.getAttribute("value");
      switch (value) {
        case "all":
          this.onViewResume(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.ALL
            )[idx]
          );
          break;
        case "on_progress":
          this.onViewResume(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.ON_PROGRESS
            )[idx]
          );
          break;
        case "expired":
          this.onViewResume(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.EXPIRED
            )[idx]
          );
          break;
      }
    });

    window.$(document).on("click", ".on-report-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      const value = _target.getAttribute("value");
      switch (value) {
        case "all":
          this.onViewReport(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.ALL
            )[idx]
          );
          break;
        case "on_progress":
          this.onViewReport(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.ON_PROGRESS
            )[idx]
          );
          break;
        case "completed":
          this.onViewReport(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.COMPLETED
            )[idx]
          );
          break;
        case "expired":
          this.onViewReport(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.EXPIRED
            )[idx]
          );
          break;
      }
    });

    window.$(document).on("click", ".on-try-again-btn", (e) => {
      e.preventDefault();
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      const value = _target.getAttribute("value");
      switch (value) {
        case "all":
          sweetAlertTryagain(
            "Re-Try Confirmation",
            "Are you sure you want to try again for this exam?"
          ).then((enrollConfirmed) => {
            if (enrollConfirmed) {
              this.setState({ isTableDataLoading: true }, () => {
                this.onClickTryAgain(
                  this.assessmentsFilter(
                    this.state.node_assessments,
                    assessmentFilters.ALL
                  )[idx]
                );
              });
            }
          });

          break;
      }
    });

    window.$(document).on("click", ".on-view-assement-btn", (e) => {
      e.preventDefault();
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      const value = _target.getAttribute("value");
      switch (value) {
        case "all":
          this.onClickView(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.ALL
            )[idx]
          );
          break;
        case "on_progress":
          this.onClickView(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.ON_PROGRESS
            )[idx]
          );
          break;
        case "completed":
          this.onClickView(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.COMPLETED
            )[idx]
          );
          break;
        case "expired":
          this.onClickView(
            this.assessmentsFilter(
              this.state.node_assessments,
              assessmentFilters.EXPIRED
            )[idx]
          );
          break;
      }
    });  
  }

  handleAddNewAssessment = async (assessment_id) => {
    try {
      const lg_user_id = sessionStorage.getItem("user_id");

      const lastAttempt = await AssessmentService.getAllLastAttemp(
        lg_user_id,
        assessment_id
      );

      // await libraryService.getSchedule(
      //   assessment_id
      // ).then((res) =>{
      //     this.setState({newAttempt : res.data.assessmentSchedule})
      // });

      // const {newAttempt} = this.state;

      if (lastAttempt.data.attempt_no < lastAttempt.data.no_of_attempts) {
        if (lastAttempt.success) {
          const attempt_no = lastAttempt.data.attempt_no + 1;
          const current_time = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

          const newAttempts = [
            {
              added_by: lastAttempt.data.added_by,
              assessment_id: lastAttempt.data.assessment_id,
              attempt_no: attempt_no,
              candidate_id: lastAttempt.data.candidate_id,
              candidate_status: "Assigned",
              no_of_attempts: lastAttempt.data.no_of_attempts,
              node_mid: lastAttempt.data.node_mid,
              parent_user_id: lastAttempt.data.parent_user_id,
              user_id: lastAttempt.data.user_id,
              student_id: lastAttempt.data.student_id,
              start_time: current_time,
              exam_time_interval: lastAttempt.data.exam_time_interval,
              buffer_time: lastAttempt.data.buffer_time,
              exam_time_interval_unit_id: lastAttempt.data.exam_time_interval_unit_id,
              buffer_time_unit_id: lastAttempt.data.buffer_time_unit_id,
              is_set_time_limit: lastAttempt.data.is_set_time_limit
            },
          ];

          const response = await AssessmentService.AddCandidate(
            JSON.stringify(newAttempts)
          );

          this.setState({
            isTableDataLoading: false,
          });

          if (response.success) {
            sweetStatusAlert("Success!", "New Attempt Added", "success");
            this.fetchAllEnrolledPapers();
            this.setState({ tryAgainButtonHide: true });
          } else {
            sweetStatusAlert("Failed!", response.message, "error");
          }
        }
      } else {
        sweetStatusAlert(
          "Failed!",
          "All the attempts are over for this exam",
          "error"
        );
        this.setState({
          isTableDataLoading: false,
        });
      }
    } catch (error) {
      // Handle errors appropriately
      console.error("Error in handleAddNewAssessment:", error);
    }
  };

  onFilterInput = (v, r) => {
    let categoryOptions;
    let languageOptions;
    let subjectOptions;
    let authorOptions;
    let gradeOptions;
    let yearOptions;
    let termOptions;
    let chapterOptions;
    let difficultyOptions;
    let selectedFilters = [];
    this.setState({
      filters: v,
      filterValues: [],
    });

    if (r === "removeOption") {
      this.setState(
        {
          selectedAuthors: null,
          selectedsubjects: null,
          selectedChapters: null,
          selectedTerms: null,
          selectedCategories: null,
          selectedYears: null,
          selectedLanguages: null,
          selectedDifficulties: null,
          selectedGrades: null,
        },
        () => {
          if (this.state.userType === "admin") {
            this.fetchAllEnrolledNodeExamsForUser();
          }
          if (this.state.userType === "student") {
            this.fetchAllEnrolledPapers();
          }
          if (this.state.userType === "parent") {
            this.fetchAllEnrolledChildPapers();
          }
        }
      );
    }
    if (v.includes("Category")) {
      categoryOptions = this.state.allCategories?.map((val) => {
        return {
          filter: "Category",
          name: val.name,
          category_id: val.category_id,
        };
      });
      selectedFilters = [...selectedFilters, ...categoryOptions];
    }
    if (v.includes("Language")) {
      languageOptions = this.state.allLanguages?.map((val) => {
        return {
          filter: "Language",
          name: val.language,
          language_id: val.language_id,
        };
      });
      selectedFilters = [...selectedFilters, ...languageOptions];
    }
    if (v.includes("Subject")) {
      subjectOptions = this.state.allSubjects?.map((val) => {
        return {
          filter: "Subject",
          name: val.subject,
          subject_id: val.subject_id,
        };
      });
      selectedFilters = [...selectedFilters, ...subjectOptions];
    }
    if (v.includes("Author")) {
      authorOptions = this.state.allAuthors?.map((val) => {
        return {
          filter: "Author",
          name: val.author,
          author_id: val.author_id,
        };
      });
      selectedFilters = [...selectedFilters, ...authorOptions];
    }
    if (v.includes("Year")) {
      yearOptions = this.state.allYears?.map((val) => {
        return {
          filter: "Year",
          name: val.year,
          year_id: val.year_id,
        };
      });
      selectedFilters = [...selectedFilters, ...yearOptions];
    }
    if (v.includes("Difficulty")) {
      difficultyOptions = this.state.allDifficulties?.map((val) => {
        return {
          filter: "Difficulty",
          name: val.difficulty_type,
          difficulty_id: val.difficulty_id,
        };
      });
      selectedFilters = [...selectedFilters, ...difficultyOptions];
    }
    if (v.includes("Grade")) {
      gradeOptions = this.state.allGrades?.map((val) => {
        return {
          filter: "Grade",
          name: val.grade,
          grade_id: val.grade_id,
        };
      });
      selectedFilters = [...selectedFilters, ...gradeOptions];
    }
    if (v.includes("Term")) {
      termOptions = this.state.allTerms?.map((val) => {
        return {
          filter: "Term",
          name: val.term,
          term_id: val.term_id,
        };
      });
      selectedFilters = [...selectedFilters, ...termOptions];
    }
    if (v.includes("Chapter")) {
      chapterOptions = this.state.allChapters?.map((val) => {
        return {
          filter: "Chapter",
          name: val.chapter,
          chapter_id: val.chapter_id,
        };
      });
      selectedFilters = [...selectedFilters, ...chapterOptions];
    }
    this.setState({
      selectedFilters: selectedFilters,
    });
  };

  onSelectFilter = (e) => {
    let selectedGrades = [];
    let selectedChapters = [];
    let selectedsubjects = [];
    let selectedAuthors = [];
    let selectedYears = [];
    let selectedCategories = [];
    let selectedTerms = [];
    let selectedDifficulties = [];
    let selectedLanguages = [];

    e.map((val) => {
      if (val.filter === "Category") {
        selectedCategories.push(val?.category_id);
      }
      if (val.filter === "Difficulty") {
        selectedDifficulties.push(val?.difficulty_id);
      }
      if (val.filter === "Language") {
        selectedLanguages.push(val?.language_id);
      }
      if (val.filter === "Subject") {
        selectedsubjects.push(val?.subject_id);
      }
      if (val.filter === "Author") {
        selectedAuthors.push(val?.author_id);
      }
      if (val.filter === "Year") {
        selectedYears.push(val?.year_id);
      }
      if (val.filter === "Term") {
        selectedTerms.push(val?.term_id);
      }
      if (val.filter === "Chapter") {
        selectedChapters.push(val?.chapter_id);
      }
      if (val.filter === "Grade") {
        selectedGrades.push(val?.grade_id);
      }
    });

    this.setState(
      {
        filterValues: e,
        selectedAuthors: selectedAuthors.length > 0 ? selectedAuthors : null,
        selectedsubjects: selectedsubjects.length > 0 ? selectedsubjects : null,
        selectedChapters: selectedChapters.length > 0 ? selectedChapters : null,
        selectedTerms: selectedTerms.length > 0 ? selectedTerms : null,
        selectedCategories:
          selectedCategories.length > 0 ? selectedCategories : null,
        selectedYears: selectedYears.length > 0 ? selectedYears : null,
        selectedLanguages:
          selectedLanguages.length > 0 ? selectedLanguages : null,
        selectedDifficulties:
          selectedDifficulties.length > 0 ? selectedDifficulties : null,
        selectedGrades: selectedGrades.length > 0 ? selectedGrades : null,
      },
      () => {
        if (this.state.userType === "admin") {
          this.fetchAllEnrolledNodeExamsForUser();
        }
        if (this.state.userType === "student") {
          this.fetchAllEnrolledPapers();
        }
        if (this.state.userType === "parent") {
          this.fetchAllEnrolledChildPapers();
        }
      }
    );
  };


// NEw functions

  onSelectFilterMulti = (selectedItem) => {    

    const { filterValues } = this.state;

    let updatedFilterValues = [...filterValues];

    const index = updatedFilterValues.findIndex(
      (item) =>
        item.filter === selectedItem.filter &&
        item[`${selectedItem.filter.toLowerCase()}_id`] === selectedItem[`${selectedItem.filter.toLowerCase()}_id`]
    );


    if (index === -1) {

      updatedFilterValues.push(selectedItem);

    } else {

      updatedFilterValues.splice(index, 1);
    }


    this.onSelectFilterMultiProcess(updatedFilterValues);
  };

  onSelectFilterMultiProcess = (filterValues) => {

    const filterMap = {
      Category: [],
      Difficulty: [],
      Language: [],
      Subject: [],
      Author: [],
      Year: [],
      Term: [],
      Chapter: [],
      Grade: []
    };

    filterValues.forEach((val) => {
      const filterType = val.filter;
      if (filterType && filterMap.hasOwnProperty(filterType)) {
        switch (filterType) {
          case "Category":
            filterMap.Category.push(val?.category_id);
            break;
          case "Difficulty":
            filterMap.Difficulty.push(val?.difficulty_id);
            break;
          case "Language":
            filterMap.Language.push(val?.language_id);
            break;
          case "Subject":
            filterMap.Subject.push(val?.subject_id);
            break;
          case "Author":
            filterMap.Author.push(val?.author_id);
            break;
          case "Year":
            filterMap.Year.push(val?.year_id);
            break;
          case "Term":
            filterMap.Term.push(val?.term_id);
            break;
          case "Chapter":
            filterMap.Chapter.push(val?.chapter_id);
            break;
          case "Grade":
            filterMap.Grade.push(val?.grade_id);
            break;
          default:
            break;
        }
      }
    });

    this.setState(
      {
        filterValues,
        selectedAuthors: filterMap.Author.length > 0 ? filterMap.Author : null,
        selectedsubjects: filterMap.Subject.length > 0 ? filterMap.Subject : null,
        selectedChapters: filterMap.Chapter.length > 0 ? filterMap.Chapter : null,
        selectedTerms: filterMap.Term.length > 0 ? filterMap.Term : null,
        selectedCategories:
          filterMap.Category.length > 0 ? filterMap.Category : null,
        selectedYears: filterMap.Year.length > 0 ? filterMap.Year : null,
        selectedLanguages:
          filterMap.Language.length > 0 ? filterMap.Language : null,
        selectedDifficulties:
          filterMap.Difficulty.length > 0 ? filterMap.Difficulty : null,
        selectedGrades: filterMap.Grade.length > 0 ? filterMap.Grade : null
      },
      () => {
        this.fetchAllEnrolledPapers();
      }
    );
  };

  isParentChecked = (filterType) => {
    const filterValues = this.state.filterValues;
    const childItems = this.getChildItems(filterType);
    return (
      childItems.length > 0 &&
      childItems.every((child) =>
        filterValues.some(
          (selectedItem) =>
            selectedItem.filter === filterType &&
            selectedItem[`${filterType.toLowerCase()}_id`] ===
            child[`${filterType.toLowerCase()}_id`]
        )
      )
    );
  };

  isParentIndeterminate = (filterType) => {
    const filterValues = this.state.filterValues;
    const childItems = this.getChildItems(filterType);
    const selectedCount = childItems.filter((child) =>
      filterValues.some(
        (selectedItem) =>
          selectedItem.filter === filterType &&
          selectedItem[`${filterType.toLowerCase()}_id`] ===
          child[`${filterType.toLowerCase()}_id`]
      )
    ).length;
    return selectedCount > 0 && selectedCount < childItems.length;
  };

  handleParentCheckboxChange = (filterType, filterItems) => {
    const filterValues = [...this.state.filterValues];
    const childItems = filterItems

    const allSelected = this.isParentChecked(filterType);
    

    let updatedFilterValues;
    if (allSelected) {

      updatedFilterValues = filterValues.filter(
        (item) => item.filter !== filterType
      );
    } else {

      const newSelections = childItems.map((child) => ({
        filter: filterType,
        name: child.name,
        [`${filterType.toLowerCase()}_id`]: child[`${filterType.toLowerCase()}_id`],
      }));
      updatedFilterValues = [
        ...filterValues.filter((item) => item.filter !== filterType),
        ...newSelections,
      ];
    }


    this.onSelectFilterMultiProcess(updatedFilterValues);
  };

  getChildItems = (filterType) => {

    switch (filterType) {
      case "Category":
        return this.state.allCategories || [];
      case "Difficulty":
        return this.state.allDifficulties || [];
      case "Language":
        return this.state.allLanguages || [];
      case "Subject":
        return this.state.allSubjects || [];
      case "Author":
        return this.state.allAuthors || [];
      case "Year":
        return this.state.allYears || [];
      case "Grade":
        return this.state.allGrades || [];
      case "Term":
        return this.state.allTerms || [];
      case "Chapter":
        return this.state.allChapters || [];
      default:
        return [];
    }
  };

  clearAllFilters = () => {
    this.setState(
        (prevState) => ({
            filterValues: prevState.filterValues
                .filter((filter) => filter.filter === "Paper Status")
                .map((filter) => ({
                    ...filter,
                    name: "All", 
                    exam_type: "null",
                })),
            selectedAuthors: null,
            selectedsubjects: null,
            selectedChapters: null,
            selectedTerms: null,
            selectedCategories: null,
            selectedYears: null,
            selectedLanguages: null,
            selectedDifficulties: null,
            selectedGrades: null,
            selectedCandidateStatus: "null", 
        }),
        () => {
            this.fetchAllEnrolledPapers();
        }
    );
  };


  findLanguage = (language_id) => {
    const { allLanguages } = this.state
    if (allLanguages && allLanguages.length > 0) {

      const paperLanguage = allLanguages.find((language) => language.language_id === parseInt(language_id));

      return paperLanguage ? String(paperLanguage.language) : "N/A"
    }
    else {
      return "N/A"
    }
  }


  render() {

    const {
      limit,
      totalEnrolledPapers,
      currentPage,
      selectedChildStuId,
      isTableDataLoading,
      isLoginUserParent,
      sort_column,
      sort_direction,
      node_assessments,
      shouldShowEnrolledBy,
      nodeTitle,
      breadcrumb2,
      userType,
      offset,
      allUnits
    } = this.state;

    const path = history.location.pathname;

    const { lg_user_table_id, flg_list_active } = this.props.state;

    if (this.state.start_exam) {
      if (this.props.assessment.terms_content) {
        return (
          <EnrolledPaperDetailsNew
            state={this.props.state}
            offStartExam={() => this.offStartExam()}
            offViewReport={() => this.offViewReport()}
            allUnits={this.state.allUnits}
          />
        ); //<Redirect to="/paper-details" />;
      } else {
        return (
          <PaperQuestions
            state={this.props.state}
            offStartExam={() => this.offStartExam()}
            offViewReport={() => this.offViewReport()}
            allUnits={this.state.allUnits}
          />
        ); //<Redirect to="/view-question-paper" />;
      }
    }

    if (this.state.show_report) {
      return <PaperGradeReport offViewReport={() => this.offViewReport()} assessment={this.props.assessment} allUnits={this.state.allUnits}/>; //<Redirect to="/paper-grade-report" />;
    }

    function AssessmentTable({
      showingAssessmentsList,
      onView,
      handlePaperIDSortChange,
      handlePaperTitleSortChange,
      handlePaperDurationSortChange,
      handleEnrolledBySortChange,
    }) {
      let assessmentMap = {};

      showingAssessmentsList.forEach((item) => {
        const { assessment_id, attempt_no } = item;

        if (
          !assessmentMap[assessment_id] ||
          attempt_no > assessmentMap[assessment_id].attempt_no
        ) {
          assessmentMap[assessment_id] = item;
        }
      });

      function getOrdinal(n) {
        const s = ["th", "st", "nd", "rd"];
        const v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
      };

      function getScheduleUnit(unit_id, value) {
        if(value !== null){
          if (unit_id !== null) {
              const unit = allUnits?.find(
                (unit) => unit.unit_id == unit_id
              );
              return unit?.unit;
          }
          else{
              return "N/A";
          }
        }
        else{
          return "";
        }
      }

      function convertScheduleInSelectedUnit(value, unit) {
        if(value !== null){
          let valueInSelectedUnit;
          let selectedUnit = getScheduleUnit(unit, value);
      
          switch (selectedUnit) {
            case unitConstants.MONTHS.name:
              valueInSelectedUnit = value / 43200;
              break;
            case unitConstants.HOURS.name:
              valueInSelectedUnit = value / 60;
              break;
            case unitConstants.DAYS.name:
              valueInSelectedUnit = value / 1440;
              break;
            case unitConstants.MINUTES.name:
            default:
              valueInSelectedUnit = value;
          }
          
          return valueInSelectedUnit;
        }
        else{
          return 'Unlimited';
        }
      }    
      
      showingAssessmentsList.forEach((item) => {
        const { assessment_id, attempt_no } = item;

        const maxAttemptItem = assessmentMap[assessment_id];

        item.tryAgainButtonHide =
          maxAttemptItem && attempt_no === maxAttemptItem.attempt_no;
      });

      const groupedAssessments = showingAssessmentsList.reduce((groups, assessment, index) => {
        const { assessment_id } = assessment;
        if (!groups[assessment_id]) {
          groups[assessment_id] = [];
        }
        assessment['realIndex'] = index
        groups[assessment_id].push(assessment);
        return groups;
      }, {});

      
      if(Object.keys(groupedAssessments).length == 0){
        return  (
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="thead">Paper Title</TableCell>
                  <TableCell className="thead">Paper Time Duration</TableCell>
                  <TableCell className="thead">Enrolled By</TableCell>
                  <TableCell className="thead">Total Attempts</TableCell>
                  <TableCell className="thead">Current Attempt</TableCell>
                  {userType === "admin" && <TableCell className="thead">More Details</TableCell>}
                  <TableCell className="thead">Actions</TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div className="text-center mb-5 my-10">
              <label>No papers are available!</label>
            </div>
          </div>
        )
      }

      if (
        !selectedChildStuId &&
        userType === "parent" &&
        parseInt(flg_list_active) === 1
      ) {
        return null;
      }

      if (
        isTableDataLoading &&
        userType === "parent" &&
        parseInt(flg_list_active) === 1
      ) {
        return null;
      }

      if (node_assessments.length === 0) {
        return null;
      }

      return (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell 
                    className="thead"
                    style={{ cursor: "pointer" }}
                    onClick={handlePaperIDSortChange}
                  >
                    Paper ID{" "}
                                {sort_column === "assessment_id" && (
                                  <span>
                                    {sort_direction === "ASC"
                                      ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                      : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                                  </span>
                                )}
                  </TableCell> */}

                <TableCell
                  className="thead"
                  style={{ cursor: "pointer" }}
                  onClick={handlePaperTitleSortChange}
                >
                  Paper Title{" "}
                  {sort_column === "title" && (
                    <span>{sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                  )}
                </TableCell>
                <TableCell
                  className="thead"
                  style={{ cursor: "pointer" }}
                  onClick={handlePaperDurationSortChange}
                >
                  Paper Time Duration{" "}
                  {sort_column === "exam_time_interval" && (
                    <span>{sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                  )}
                </TableCell>
                {shouldShowEnrolledBy && (
                  <TableCell
                    className="thead"
                    style={{ cursor: "pointer" }}
                    onClick={handleEnrolledBySortChange}
                  >
                    Enrolled By{" "}
                    {sort_column === "added_by" && (
                      <span>{sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                    )}
                  </TableCell>
                )}
                {customer !== customerConstants.EXO_CUSTOMER2 && (
                  <>
                    <TableCell className="thead">Total Attempts</TableCell>
                    <TableCell className="thead">Current Attempt</TableCell>
                  </>
                )}
                {userType === "admin" && <TableCell className="thead">More Details</TableCell>}
                <TableCell className="thead">Actions</TableCell>

                {/* <TableCell className="" */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(groupedAssessments)?.map((group, index) => (
                <React.Fragment key={index}>
                  {group?.map((assessment, i) => (
                    <TableRow key={assessment.candidate_id}>
                      <TableCell>{assessment.title}</TableCell>
                      <TableCell>{convertScheduleInSelectedUnit(assessment?.exam_time_interval, assessment?.exam_time_interval_unit_id) + ' ' + getScheduleUnit(assessment?.exam_time_interval_unit_id, assessment?.exam_time_interval)} </TableCell>
                      {shouldShowEnrolledBy && (
                        <TableCell>{assessment.added_by}</TableCell>
                      )}
                      {customer !== customerConstants.EXO_CUSTOMER2 && (
                        <>
                          <TableCell>{assessment.no_of_attempts}</TableCell>
                          <TableCell>{assessment.attempt_no + ' out of ' + assessment.no_of_attempts}</TableCell>
                        </>
                      )}
                      {userType === "admin" && <TableCell> 
                        <Button
                          className="btn btn-icon btn-primary btn-sm m-1 on-view-assement-btn flaticon-eye-icon"
                          title="View More Paper Details"
                          dataIndex={assessment.realIndex}
                          value={onView}
                          style={closeBtnStyle}
                        />
                      </TableCell>}
                      <TableCell>
                        <div className="row">
                          {assessment.is_set_time_limit ? 
                            (!isLoginUserParent && new Date(assessment.start_time.concat("+00:00")) <= Date.now() &&
                              moment(assessment.start_time.concat("+00:00")).local().add(assessment.buffer_time, "minutes").toDate() >= Date.now() &&
                              assessment.candidate_status === assessmentStatus.ASSIGNED && (
                                <Button
                                  className="btn btn-primary btn-lg font-weight-bold on-start-assement-btn"
                                  dataIndex={assessment.realIndex}
                                  value={onView}
                                  style={startBtnStyle}
                                >
                                  Start Exam
                                </Button>
                            )) : 
                            (!isLoginUserParent && new Date(assessment.start_time.concat("+00:00")) <= Date.now() && assessment.candidate_status === assessmentStatus.ASSIGNED && (
                                <Button
                                  className="btn btn-primary btn-lg font-weight-bold on-start-assement-btn"
                                  dataIndex={assessment.realIndex}
                                  value={onView}
                                  style={startBtnStyle}
                                >
                                  Start Exam
                                </Button>
                            ))                
                          }
                          {assessment.is_set_time_limit ? 
                            (!isLoginUserParent && new Date(assessment.start_time.concat("+00:00")) < Date.now() &&
                              moment(assessment.start_time.concat("+00:00")).local().add(assessment.buffer_time, "minutes").toDate() < Date.now() &&
                              assessment.candidate_status === assessmentStatus.ASSIGNED && (
                                <span className="stu-variant-class label label-lg label-danger label-inline">
                                  Expired
                                </span>
                            )) : (
                              <div></div>
                            )
                          }
                          {assessment.is_set_time_limit ? 
                            (isLoginUserParent && new Date(assessment.start_time.concat("+00:00")) <= Date.now() &&
                              moment(assessment.start_time.concat("+00:00")).local().add(assessment.buffer_time, "minutes").toDate() >= Date.now() &&
                              assessment.candidate_status === assessmentStatus.ASSIGNED && (
                                <span className="stu-variant-class label label-lg label-light label-inline">
                                  Not Started
                                </span>
                            )) : (isLoginUserParent && assessment.candidate_status === assessmentStatus.ASSIGNED && (
                                <span className="stu-variant-class label label-lg label-light label-inline">
                                  Not Started
                                </span>
                            ))
                          }
                          {assessment.is_set_time_limit ? 
                            (isLoginUserParent && new Date(assessment.start_time.concat("+00:00")) < Date.now() &&
                              moment(assessment.start_time.concat("+00:00")).local().add(assessment.buffer_time, "minutes").toDate() < Date.now() &&
                              assessment.candidate_status === assessmentStatus.ASSIGNED && (
                                <span className="stu-variant-class label label-lg label-light label-inline">
                                  Expired
                                </span>
                            )) : (
                              <div></div>
                            )
                          }
                          {/* {new Date(assessment.start_time.concat("+00:00")) >= Date.now() &&
                            assessment.candidate_status === assessmentStatus.ASSIGNED  && (
                            <span className="stu-variant-class label label-lg label-light-warning label-inline">
                              Upcoming
                            </span>
                          )} */}
                          {!isLoginUserParent && new Date(assessment.start_time.concat("+00:00")) <= Date.now() &&
                            assessment.candidate_status === assessmentStatus.ON_PROGRESS && (
                              <Button
                                className="btn btn-primary btn-lg font-weight-bold on-resume-assement-btn"
                                dataIndex={assessment.realIndex}
                                value={onView}
                                style={closeBtnStyle}
                              >
                                Resume Exam
                              </Button>
                          )}
                          {isLoginUserParent && new Date(assessment.start_time.concat("+00:00")) <= Date.now() &&
                            assessment.candidate_status === assessmentStatus.ON_PROGRESS && (
                              <span className="stu-variant-class label label-lg label-light-success label-inline">
                                Started
                              </span>
                          )}
                          {assessment.candidate_status === assessmentStatus.FINISHED && (
                            <Button
                              className="btn btn-primary btn-lg font-weight-bold on-report-assement-btn mr-2 mt-1"
                              dataIndex={assessment.realIndex}
                              value={onView}
                              style={addBtnStyle}
                            >
                              Grade Report
                            </Button>
                          )}
                          <div className="">
                            {assessment.is_set_time_limit ? 
                              (!isLoginUserParent && (assessment.attempt_no < assessment.no_of_attempts) &&
                                new Date(assessment.start_time.concat("+00:00")) <= Date.now() &&
                                moment(assessment.start_time.concat("+00:00")).local().add(assessment.buffer_time, "minutes").toDate() >= Date.now() &&
                                assessment.candidate_status === assessmentStatus.FINISHED &&
                                customer !== customerConstants.EXO_CUSTOMER2 &&
                                assessment.tryAgainButtonHide ? (
                                <Button
                                  className="btn btn-primary btn-lg font-weight-bold on-try-again-btn mt-1"
                                  dataIndex={assessment.realIndex}
                                  value={onView}
                                  style={closeBtnStyle}
                                >
                                  {/* Try {getOrdinal(assessment.attempt_no + 1)} Attempt */}
                                  Try Again
                                </Button>
                              ) : (
                                <div></div>
                              )) : 
                              (!isLoginUserParent && (assessment.attempt_no < assessment.no_of_attempts) &&
                                new Date(assessment.start_time.concat("+00:00")) <= Date.now() && 
                                (assessment.attempt_no === assessmentMap[assessment.assessment_id].attempt_no) &&
                                assessment.candidate_status === assessmentStatus.FINISHED &&
                                customer !== customerConstants.EXO_CUSTOMER2 &&
                                assessment.tryAgainButtonHide ? (
                                <Button
                                  className="btn btn-primary btn-lg font-weight-bold on-try-again-btn mt-1"
                                  dataIndex={assessment.realIndex}
                                  value={onView}
                                  style={closeBtnStyle}
                                >
                                  {/* Try {getOrdinal(assessment.attempt_no + 1)} Attempt */}
                                  Try Again
                                </Button>
                              ) : (
                                <div></div>
                              ))
                            }
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={5} style={{ backgroundColor: "#f0f0f0" }}></TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      );
    }

    if(this.state.clickdisableLoader){
      return <Spinner id="show" text="Please wait..." />
    }

    return (
      <div style={{ height: "90vh", overflow: "auto" }}>
        <ContentWrapper disabled={path === "/manage-search"}>
          {/* begin::Card */}
          {(this.props.loading ||
            this.state.isTableDataLoading ||
            this.props.report_loading ||
            this.props.validationLoading ||
            this.props.finish_loading
          ) && (
              <Spinner id="show" text="Please wait..." />
            )}
          {this.state.examLoading && <Spinner id="show" text="Loading questions paper..." />}
          {this.state.autoGradeLoading && <Spinner id="show" text="Please wait..." />}
          <Card>
            <div>
              {/* <CardTitle
                  cardLabel={`${this.props.state.form_display_title}`}
                /> */}
              {/* <h3 style={{ color: 'gray' }}>{(breadcrumb2 || nodeTitle) ? `(${breadcrumb2 && breadcrumb2}${nodeTitle && nodeTitle})` : undefined}</h3>
               */}
            </div>

            <div className="row mt-10">
              <div className="col-md-6 mb-2 d-flex">
                {this.state.shouldShowSelect ? (
                  <>
                    {/* {this.state.selectedChildStuId && ( */}
                    <div className="pr-3 mb-2" style={{ width: "200px" }}>
                      <ReactSelect
                        isClearable={true}
                        placeholder="Select Student"
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        options={this.state.studentlist.map((student) => ({
                          value: student.user_id,
                          label: `${student.data_concat}`,
                        }))}
                        value={this.state.selectedStudent}
                        isLoading={this.state.isStudentsLoading}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption?.value;
                          this.setState(
                            {
                              selectedChildStuId: selectedValue,
                              selectedStudent: selectedOption,

                            }, () => {
                              if (this.state.selectedStudent && this.state.selectedChildStuId) {
                                this.handleChildSelect()
                              } else {

                                this.setState({
                                  childUserId: '0',
                                  node_assessments: [],
                                  totalEnrolledPapers: 0,
                                });
                              }
                            }
                          );
                        }}
                        onInputChange={(inputValue) => {
                          if (inputValue) {
                            this.handleStudentSearch(inputValue);
                          }
                        }}
                      />
                    </div>
                    {/* <div className="pr-3  mb-2">
                      <Form.Select
                        aria-label="Select Student"
                        name="student_id"
                        value={parseInt(this.state.selectedChildStuId)}
                        onChange={this.handleChildSelect}
                        style={{
                          color: "#A2A5B5",
                        }}
                        className="select-student-dropdown"
                      >
                        <option
                          value=""
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            color: "#A2A5B5",
                          }}
                        >
                          Select Student
                        </option>
                        {this.state.studentlist?.map((student) => (
                          <option key={student.user_id} value={student.user_id}>
                            {student.data_concat}
                          </option>
                        ))}
                      </Form.Select>
                    </div> */}
                    {/* )} */}
                    <div className="mb-2">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search Paper"
                          onChange={this.handleSearch}
                        // style={{ height: "48px" }}
                        //className="col-md-12 col-12"
                        />
                      </Form.Group>
                    </div>
                  </>
                ) : (

                //   <>
                //         <div className="mb-2">
                //             <Form.Group
                //                 controlId="exampleForm.ControlInput1"
                //                 className="mb-2"
                //             >
                //                 <Form.Control
                //                 type="text"
                //                 placeholder="Search Paper"
                //                 onChange={this.handleSearch}
                //                 // style={{ height: "48px" }}
                //                 //className="col-md-12 col-12"
                //                 />
                //             </Form.Group>
                //         </div>
                //     </>

                    <Accordion className="filterAccordion"
                    // expanded={expanded} onChange={() => {}}
                    >

                    <AccordionSummary className="filterAccordionSummary" aria-controls="panel1-content" id="panel1-header">
                        <Box
                        sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                        >
                        <div className="filterpprs">
                            <FilterAltIcon />
                            &emsp;Filter&emsp;
                            <KeyboardArrowDownIcon
                            sx={{
                                // transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                                transition: "transform 0.3s ease",
                                cursor: "pointer", // Make it clear this is clickable
                            }}
                            onClick={(e) => {
                                // Prevent the click from affecting the Accordion
                                // e.stopPropagation(); 
                                // Manually toggle the accordion state
                                // handleAccordionChange(); 
                            }}
                            />
                        </div>
                        </Box>
                        <Typography component="h5" sx={{ color: 'text.primary',display:'flex', alignItems:'center', justifyContent:'center' }}>
                          &emsp;Total Attempts : &emsp; {this.state.totalEnrolledPapers? this.state.totalEnrolledPapers: "0"}
                        </Typography>
                    </AccordionSummary>

                    <Box className="filterCardContainer" sx={{ overflowX: "auto", whiteSpace: "nowrap", display: "flex", padding: "8px", height: '40vh' }}>

                    <div className="filterCard" style={{ display: "inline-block", minWidth: "300px", marginRight: "16px", maxHeight: "30vh" }}>

                        <FormControl sx={{width:'100%'}}>
                            <FormLabel id="demo-radio-buttons-group-label">Paper Status</FormLabel>
                            <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={this.state.selectedCandidateStatus}
                                    onChange={(e)=>this.handleCandidateStatusChange(e.target.value)}
                                >

                                <Box sx={{ display: "flex", flexDirection: "column", ml: 3, maxHeight: "20vh", overflowY: "auto" }}>
                                    {[
                                        {"type":"All","value":"null"}, 
                                        {"type":"Not Started","value":"Upcoming"}, 
                                        {"type":"Started","value":"Onprogress"}, 
                                        {"type":"Completed","value":"Finished"}

                                    ].map((type) => (

                                        <FormControlLabel
                                            key={type.type}
                                            label={type.type}
                                            value={type.value}
                                            control={
                                                <Radio/>
                                            }
                                        />
                                    ))}
                                </Box>
                            </RadioGroup>
                        </FormControl>
                    </div>

                        {ASSESSMENT_FILTERS.filter((category) => {

                        if (this.state.userType === "student") {
                            return ["Grade", "Subject", "Language"].includes(category);
                        }
                        return true;
                        }).map((category, index) => {

                        let filterItems = [];
                        if (category === "Category") {
                            filterItems = this.state.allCategories?.map((val) => ({
                            filter: "Category",
                            name: val.name,
                            category_id: val.category_id,
                            }));
                        } else if (category === "Difficulty") {
                            filterItems = this.state.allDifficulties?.map((val) => ({
                            filter: "Difficulty",
                            name: val.difficulty_type,
                            difficulty_id: val.difficulty_id,
                            }));
                        } else if (category === "Language") {
                            filterItems = this.state.allLanguages?.map((val) => ({
                            filter: "Language",
                            name: val.language,
                            language_id: val.language_id,
                            }));
                        } else if (category === "Subject") {
                            filterItems = this.state.allSubjects?.map((val) => ({
                            filter: "Subject",
                            name: val.subject,
                            subject_id: val.subject_id,
                            }));
                        } else if (category === "Author") {
                            filterItems = this.state.allAuthors?.map((val) => ({
                            filter: "Author",
                            name: val.author,
                            author_id: val.author_id,
                            }));
                        } else if (category === "Year") {
                            filterItems = this.state.allYears?.map((val) => ({
                            filter: "Year",
                            name: val.year,
                            year_id: val.year_id,
                            }));
                        } else if (category === "Grade") {
                            filterItems = this.state.allGrades?.map((val) => ({
                            filter: "Grade",
                            name: val.grade,
                            grade_id: val.grade_id,
                            }));
                        } else if (category === "Term") {
                            filterItems = this.state.allTerms?.map((val) => ({
                            filter: "Term",
                            name: val.term,
                            term_id: val.term_id,
                            }));
                        } else if (category === "Chapter") {
                            filterItems = this.state.allChapters?.map((val) => ({
                            filter: "Chapter",
                            name: val.chapter,
                            chapter_id: val.chapter_id,
                            }));
                        }

                        return (
                            <div className="filterCard" key={index} style={{ display: "inline-block", minWidth: "300px", marginRight: "16px", maxHeight: "30vh" }}>
                            <FormControlLabel
                                label={category}

                                sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                "& .MuiFormControlLabel-label": { fontWeight: "600" },
                                }}
                                control={
                                <Checkbox
                                    checked={this.isParentChecked(category)}
                                    indeterminate={this.isParentIndeterminate(category)}
                                    onChange={() => this.handleParentCheckboxChange(category, filterItems)}
                                />
                                }
                            />

                            <Box sx={{ display: "flex", flexDirection: "column", ml: 3, maxHeight: "20vh", overflowY: "auto" }}>

                                {filterItems.map((item) => {

                                const isChecked = this.state.filterValues.some((selectedItem) =>
                                    selectedItem.filter === item.filter &&
                                    selectedItem[`${item.filter.toLowerCase()}_id`] === item[`${item.filter.toLowerCase()}_id`]
                                );

                                return (
                                    <FormControlLabel
                                    key={item[`${category.toLowerCase()}_id`]}
                                    label={`${item.name}`}

                                    control={
                                        <Checkbox
                                        checked={isChecked}
                                        onChange={() => this.onSelectFilterMulti(item)}
                                        />
                                    }
                                    />
                                )
                                })}
                            </Box>

                            </div>

                        );

                        })}




                    </Box>

                    </Accordion>

                    

                )}
                 
              </div>

              

             

              {/* <div className="col-md-2 mb-2">
                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search Paper"
                    onChange={this.handleSearch}
                    style={{ height: "50px" }}
                    //className="col-md-12 col-12"
                  />
                </Form.Group>
              </div> */}

              {/* <div className="col-md-6 d-flex justify-content-end">
                <TabList>
                  <NavItem
                    className={
                      this.state.navItemAll === "nav-link active"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="all-tab"
                    href="#all"
                    dataToggle="tab"
                    style={{ fontSize: "6px" }}
                    tabName="All"
                    isHidden={this.state.isNavHidden}
                    onClick={() => this.handleCandidateStatusChange(null)}
                  />
                  <NavItem
                    className="nav-link"
                    id="scheduled-tab"
                    href="#scheduled"
                    style={{ fontSize: "6px" }}
                    dataToggle="tab"
                    // tabName="Upcoming"
                    tabName="Not Started"
                    onClick={() => this.handleCandidateStatusChange("Upcoming")}
                  />
                  <NavItem
                    className={
                      this.state.navItemOnProgress === "nav-link active"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="on-progress-tab"
                    href="#on-progress"
                    dataToggle="tab"
                    style={{ fontSize: "6px" }}
                    // tabName="On Progress"
                    tabName="Started"
                    isHidden={this.state.isNavHidden}
                    onClick={() =>
                      this.handleCandidateStatusChange("Onprogress")
                    }
                  />
                  <NavItem
                    className={
                      this.state.navItemCompleted === "nav-link active"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="completed-tab"
                    href="#completed"
                    style={{ fontSize: "6px" }}
                    dataToggle="tab"
                    tabName="Completed"
                    onClick={() => this.handleCandidateStatusChange("Finished")}
                  />
                </TabList>
              </div> */}

              {selectedChildStuId ? (
                <div className="col-md-12">
                  <div className="row d-flex">
                    <Autocomplete
                      multiple
                      size="small"
                      id="checkboxes-filter"
                      options={ASSESSMENT_FILTERS}
                      disableCloseOnSelect
                      getOptionLabel={(option) => (
                        <li style={{ fontFamily: 'Poppins, sans-serif', listStyle: 'none' }}>{option}</li>
                      )}
                      onChange={(e, v, r) => this.onFilterInput(v, r)}
                      sx={{
                        width: 300,
                        fontFamily: "Poppins, sans-serif"
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ fontFamily: 'Poppins' }}>
                          <Checkbox
                            style={{ marginRight: 8, color: "#5458AF" }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      className="col-lg-6 col-md-6 col-12 px-0 py-2"
                      renderInput={(params) => (
                        <TextField {...params} label="Select Filter" className="col-md-12" />
                      )}
                    />

                    {this.state.filters.length > 0 && (
                      <Autocomplete
                        multiple
                        size="small"
                        id="grouped-demo"
                        className="col-lg-6 col-md-6 col-12 px-0 py-2"
                        options={this.state.selectedFilters}
                        groupBy={(option) => option.filter}
                        onChange={(e, v) => this.onSelectFilter(v)}
                        getOptionLabel={(option) => (
                          <li style={{ fontFamily: 'Poppins, sans-serif', listStyle: 'none' }}>{option.name}</li>
                        )}
                        sx={{
                          width: 300,
                          fontFamily: "Poppins, sans-serif"
                        }}
                        value={this.state.filterValues}
                        renderInput={(params) => (
                          <TextField {...params} label="Filters" className="col-md-12" />
                        )}
                      />
                    )}
                  </div>
                </div>
              ) : (

                !selectedChildStuId &&
                userType === "student" && (
                  <div className="col-md-12">
                    <div className="row d-flex">
                      {/* <Autocomplete
                        multiple
                        size="small"
                        id="checkboxes-filter"
                        options={ASSESSMENT_FILTERS}
                        disableCloseOnSelect
                        getOptionLabel={(option) => (
                          <li style={{ fontFamily: 'Poppins, sans-serif', listStyle: 'none' }}>{option}</li>
                        )}
                        onChange={(e, v, r) => this.onFilterInput(v, r)}
                        sx={{
                          width: 300,
                          fontFamily: "Poppins, sans-serif"
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} style={{ fontFamily: 'Poppins' }}>
                            <Checkbox
                              style={{ marginRight: 8, color: "#5458AF" }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        className="col-lg-6 col-md-6 col-12 px-1 py-2"
                        renderInput={(params) => (
                          <TextField {...params} label="Select Filter" className="col-md-12" />
                        )}
                      /> */}
                      {this.state.filters.length > 0 && (
                        <></>
                        // <Autocomplete
                        //   multiple
                        //   size="small"
                        //   id="grouped-demo"
                        //   className="col-lg-6 col-md-6 col-12 px-1 py-2"
                        //   options={this.state.selectedFilters}
                        //   groupBy={(option) => option.filter}
                        //   onChange={(e, v) => this.onSelectFilter(v)}
                        //   getOptionLabel={(option) => (
                        //     <li style={{ fontFamily: 'Poppins, sans-serif', listStyle: 'none' }}>{option.name}</li>
                        //   )}
                        //   sx={{
                        //     width: 300,
                        //     fontFamily: "Poppins, sans-serif"
                        //   }}
                        //   value={this.state.filterValues}
                        //   renderInput={(params) => (
                        //     <TextField {...params} label="Filters4" className="col-md-12" />
                        //   )}
                        // />
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
            <CardBody>
            {this.state.filterValues && this.state.filterValues.length > 0 && 
                        <PaperListChips
                        filterValues={this.state.filterValues}
                        chipClick={this.onSelectFilterMulti}
                        clearAll={this.clearAllFilters}
                        />
              }
              {this.state.node_assessments?.length > 0 && (
                <div>
                  <TabContent>
                    <TabPane
                      className={this.state.tabPaneAll}
                      id="all"
                      ariaLabelledby="all-tab"
                    >
                      {this.state.node_assessments.length > 0 && (
                        <AssessmentTable
                          showingAssessmentsList={this.assessmentsFilter(
                            this.state.node_assessments,
                            assessmentFilters.ALL
                          )}
                          onView={"all"}
                          handlePaperIDSortChange={() =>
                            this.handleSortChange("assessment_id")
                          } // Pass the function as a prop
                          handlePaperTitleSortChange={() =>
                            this.handleSortChange("title")
                          }
                          handlePaperDurationSortChange={() =>
                            this.handleSortChange("exam_time_interval")
                          }
                          handleEnrolledBySortChange={() =>
                            this.handleSortChange("added_by")
                          }
                        />
                      )}
                    </TabPane>

                    <TabPane
                      className="tab-pane fade"
                      id="scheduled"
                      ariaLabelledby="scheduled-tab"
                    >
                      {this.state.node_assessments.length > 0 && (
                        <AssessmentTable
                          showingAssessmentsList={this.assessmentsFilter(
                            this.state.node_assessments,
                            assessmentFilters.UP_COMING
                          )}
                          onView={"up_coming"}
                          handlePaperIDSortChange={() =>
                            this.handleSortChange("assessment_id")
                          } // Pass the function as a prop
                          handlePaperTitleSortChange={() =>
                            this.handleSortChange("title")
                          }
                          handlePaperDurationSortChange={() =>
                            this.handleSortChange("exam_time_interval")
                          }
                          handleEnrolledBySortChange={() =>
                            this.handleSortChange("added_by")
                          }
                        />
                      )}
                    </TabPane>

                    <TabPane
                      className={this.state.tabPaneOnProgress}
                      id="on-progress"
                      ariaLabelledby="on-progress-tab"
                    >
                      {this.state.node_assessments.length > 0 && (
                        <AssessmentTable
                          showingAssessmentsList={this.assessmentsFilter(
                            this.state.node_assessments,
                            assessmentFilters.ON_PROGRESS
                          )}
                          onView={"on_progress"}
                          handlePaperIDSortChange={() =>
                            this.handleSortChange("assessment_id")
                          } // Pass the function as a prop
                          handlePaperTitleSortChange={() =>
                            this.handleSortChange("title")
                          }
                          handlePaperDurationSortChange={() =>
                            this.handleSortChange("exam_time_interval")
                          }
                          handleEnrolledBySortChange={() =>
                            this.handleSortChange("added_by")
                          }
                        />
                      )}
                    </TabPane>

                    <TabPane
                      className={this.state.tabPaneCompleted}
                      id="completed"
                      ariaLabelledby="completed-tab"
                    >
                      {this.state.node_assessments.length > 0 && (
                        <AssessmentTable
                          showingAssessmentsList={this.assessmentsFilter(
                            this.state.node_assessments,
                            assessmentFilters.COMPLETED
                          )}
                          onView={"completed"}
                          handlePaperIDSortChange={() =>
                            this.handleSortChange("assessment_id")
                          } // Pass the function as a prop
                          handlePaperTitleSortChange={() =>
                            this.handleSortChange("title")
                          }
                          handlePaperDurationSortChange={() =>
                            this.handleSortChange("exam_time_interval")
                          }
                          handleEnrolledBySortChange={() =>
                            this.handleSortChange("added_by")
                          }
                        />
                      )}
                    </TabPane>
                  </TabContent>
                  <div className="d-flex justify-content-end">
                    <MaterialCustomTablePagination
                      totalCount={totalEnrolledPapers}
                      currentPage={currentPage}
                      limit={limit}
                      handlePageChange={this.handlePageChange}
                      handleRowsPerPageChange={this.handleRowsPerPageChange}
                      handlePageOffsetChange={this.handlePageOffsetChange}
                      rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
                      offset={offset}
                    />
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
          {/* end::Card */}
        </ContentWrapper>
        <Modal 
            size="lg"
            show={this.state.showDetailModal}
            onHide={this.toggleDetailModal}
          >
          <Modal.Header>
            <h5 className="modal-title">{this.state.selectedPaper?.title}</h5>
            <ViewButton variant="secondary" onClick={this.toggleDetailModal}>
              Close
            </ViewButton>
          </Modal.Header>
          {this.state.selectedPaper && (
            <Modal.Body>
              <div className="form" id="kt_form">
              {/* {this.state.selectedPaper?.start_time !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Start Time :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperStartTimeLocal(this.state.selectedPaper?.start_time)}
                      </div>
                    </div>
                  </div>
                </>
              }  */}
              {this.state.selectedPaper?.attempt_start_time !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Attempt Start Time :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperStartTimeLocal(this.state.selectedPaper?.attempt_start_time)}
                      </div>
                    </div>
                  </div>
                </>
              }     
              {this.state.selectedPaper?.attempt_end_time !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Attempt End Time :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperStartTimeLocal(this.state.selectedPaper?.attempt_end_time)}
                      </div>
                    </div>
                  </div>
                </>
              }    
              {/* {this.state.selectedPaper?.exam_time_interval !== null && this.state.selectedPaper?.exam_time_interval_unit_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Time Duration :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.convertScheduleInSelectedUnit(this.state.selectedPaper?.exam_time_interval, this.state.selectedPaper?.exam_time_interval_unit_id) + ' ' + this.getScheduleUnit(this.state.selectedPaper?.exam_time_interval_unit_id, this.state.selectedPaper?.exam_time_interval)}
                      </div>
                    </div>
                  </div>
                </>
              }  */}
              {
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Access Buffer Duration :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.convertScheduleInSelectedUnit(this.state.selectedPaper?.buffer_time, this.state.selectedPaper?.buffer_time_unit_id) + ' ' + this.getScheduleUnit(this.state.selectedPaper?.buffer_time_unit_id, this.state.selectedPaper?.buffer_time)}
                      </div>
                    </div>
                  </div>
                </>
              } 
              {
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Status :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        <div className={this.getColor(this.state.selectedPaper.candidate_status)}>
                          {this.getPaperStatus(this.state.selectedPaper.candidate_status)}
                        </div>
                      </div>
                  </div>
                  </div>
                </>
              } 
              {this.state.selectedPaper?.category_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Category :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperCategory(this.state.selectedPaper?.category_id)}
                      </div>
                    </div>
                  </div>
                </>
              }     
              {this.state.selectedPaper?.language_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Language :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperLanguage(this.state.selectedPaper?.language_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.difficulty_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Difficulty :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperDifficulty(this.state.selectedPaper?.difficulty_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.grade_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Grade :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperGrade(this.state.selectedPaper?.grade_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.subject_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Subject :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperSubject(this.state.selectedPaper?.subject_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.chapter_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Chapter/Module/Unit :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperChapter(this.state.selectedPaper?.chapter_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.author_id !== null &&
                <>
                  <div className="separator separator-dashed my-5"></div> 
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Author :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperAuthor(this.state.selectedPaper?.author_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.year_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Year :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperYear(this.state.selectedPaper?.year_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.term_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Term/Semester :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperTerm(this.state.selectedPaper?.term_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.first_name !== null && this.state.selectedPaper?.last_name !== null &&
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Created By :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.state.selectedPaper?.first_name + " " + this.state.selectedPaper?.last_name}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.created_at !== null &&
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Created At :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getCreatedAtLocal(this.state.selectedPaper?.created_at)}
                      </div>
                    </div>
                  </div>
                </>
              }
              </div>
            </Modal.Body>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToActions = {
  listAssessments: listAssessments,
  listChildAssessments: listChildAssessments,
  listNodeAssessments: listNodeAssessments,
  getNodeEnrolledPapers: getNodeEnrolledPapers,
  getNodeEnrolledChildPapers: getNodeEnrolledChildPapers,
  getNodeEnrolledPapersForUser: getNodeEnrolledPapersForUser,
  openAssessment: openAssessment,
  getReport: getReport,
  refreshAssessmentStartPage: refreshAssessmentStartPage,
  validateCandidate: validateCandidate,
  loadQuestions: loadQuestions,
  refreshForExamView: refreshForExamView,
  startTimer: startTimer,
  getCandidateAnswers: getCandidateAnswers,
  getAllCandidateAnswerContents: getAllCandidateAnswerContents,
  clearCandidateAnswers: clearCandidateAnswers,
  finishTest: finishTest,
  loadAssessmentQuestions: loadAssessmentQuestions,
  onSetMarks: onSetMarks,
  saveMarks: saveMarks,
  finalizeGrade: finalizeGrade,
  finalizeAutoGrade: finalizeAutoGrade,
  submitAnswers: submitAnswers,
  getEnrolledPapers: getEnrolledPapers,
};

function mapStateToProps(state) {
  return {
    assessments: state.examReducer.assessment_list,
    child_assessments: state.examReducer.child_assessment_list,
    node_assessments: state.examReducer.node_assessment_list,
    node_enrolled_papers: state.examReducer.enrolled_node_exams_list,
    node_enrolled_papers_for_user:
      state.examReducer.enrolled_node_exams_list_for_user,
    node_enrolled_child_papers:
      state.examReducer.enrolled_child_node_exams_list,
    assessment: state.examReducer.assessment,
    candidateAssessment: state.examReducer.assessment,
    loading: state.examReducer.loading,
    report_loading: state.examReducer.report_loading,
    error: state.examReducer.error,
    status: state.examReducer.status,
    questions: state.testReducer.questions,
    text: state.validationReducer.text,
    validation_error: state.validationReducer.error,
    validationLoading: state.validationReducer.loading,
    total_count: state.examReducer.totalEnrolledPapers,

    loading: state.validationReducer.loading,
    error: state.validationReducer.error,
    success: state.validationReducer.success,
    candidate_id: state.validationReducer.candidate_id,
    loading_test: state.testReducer.loading,
    candidateAnswers: state.testReducer.candidateAnswers,

    submission_uploading: state.submissionReducer.loading,
    finish_loading: state.submissionReducer.finish_loading,
    submission_error: state.submissionReducer.error,
    submission_success: state.submissionReducer.success,
    finish_success: state.submissionReducer.finish_success,
    finish_error: state.submissionReducer.finish_error,

    assessmentQuestions: state.testReducer.assessmentQuestions,

    loading: state.testReducer.loading,
    answerMap: state.testReducer.candidateAnswerContentMap,
    candidateAnswerContents: state.testReducer.candidateAnswerContents,
    answerContents: state.testReducer.answerContents,
    candidate: state.testReducer.candidate,

    loading: state.gradeReducer.loading,
    gradeError: state.gradeReducer.error,
    gradeLoading: state.gradeReducer.loading,

    report: state.examReducer.report,
  };
}

export default connect(mapStateToProps, mapStateToActions)(EdEnrolledPaperList);
