import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../../components/admin/css/common.css";
import {sweetStatusAlert} from "../../../components/common/SweetAlert.js";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import moment from "moment";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Autocomplete,
  TextField
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import debounce from "lodash.debounce";
import SimpleReactValidator from "simple-react-validator";
import { candidateService } from "../../../service/settings/candidate/candidateService.js";
import { libraryService } from "../../../service/assessment/libraryService.js";
import { assessmentService } from "../../../service/assessment/assessmentService";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import config from '../../../helper/config.js';
import {customerConstants} from "../../../helper/constants.js";
import { PAPER_SETTINGS_FILTERS } from "../../../helper/constants.js";
import ModalBody from "../../../components/common/modal/ModalBody";
import ModalHeader from "../../../components/common/modal/ModalHeader";
import ViewForm from "../../../components/common/form/Form";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, privilegeEditEnabled;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    privilegeEditEnabled = false;
    break;
  case customerConstants.EXO_CUSTOMER2:
    privilegeEditEnabled = false;
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    privilegeEditEnabled = true;
    break;
}

export default class extends Component {
  constructor(props) {
    super(props);
    this.createValidationsInstance();
    this.state = {
      papers: [],
      limit: 300, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      sort_column: "created_at",
      sort_direction: "DESC",
      selectedCategories: null,
      selectedAuthors: null,
      selectedChapters: null,
      selectedGrades: null,
      selectedDifficulties: null,
      selectedLanguages: null,
      selectedTerms: null,
      selectedYears: null,
      selectedsubjects: null,
      selectedStatuses: null,
      selectedTeachers: null,
      teachers: [],
      checked: false,
      totalCount: 300,
      isTableDataLoading: true,
      currentPage: 0,
      isLoading: false,
      allCategories: [],
      allLanguages: [],
      allSubjects: [],
      allAuthors: [],
      allYears: [],
      allDifficulties: [],
      allGrades: [],
      allTerms: [],
      allChapters: [],
      allPrivileges: [],
      selectSearchTerm: "",
      selectedPapers: [],
      selectedPaper: "",
      selectAll: false,
      filterValues: [],
      filters: [],
      selectedFilters: [],
      editCategoryId: null,
      editGradeId: null,
      editLanguageId: null,
      editSubjectId: null,
      editChapterId: null,
      editDifficultyId: null,
      editAuthorId: null,
      editYearId: null,
      editTermId: null,
      editPrivilegeId: null,
      editStatusId: null,
      editStatusName: null,
      isAllReady: false
    };

    // Debounced version of handleSearch function
    this.debouncedHandleSearch = debounce(this.fetchAllPapers, 3000);
  }

  // Reset the form fields to initail values on update, insert, edit modal close and add modal close
  resetFormFields = () => {
    this.setState({
      editCategoryId: null,
      editGradeId: null,
      editLanguageId: null,
      editSubjectId: null,
      editChapterId: null,
      editDifficultyId: null,
      editAuthorId: null,
      editYearId: null,
      editTermId: null,
      editPrivilegeId: null,
      editStatusId: null,
      editStatusName: null,
      isAllReady: false
    });
  };

  componentDidMount() {
    this.fetchAllAssessmentMetaData();
    this.fetchAllPapers();
  }

  // Creating validator instance
  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        customMin: {
          message: "The :attribute value must be greater than :min.",
          rule: (val, params) => {
            return parseInt(val, 10) > params[0];
          },
          messageReplace: (message, params) => message.replace(':min', params[0]),
          required: true
        }
      }
    });
  }

  fetchAllAssessmentMetaData  = async () => {
    this.setState({
      isTableDataLoading: true,
    });

    const fetchAssessmentMetaData =
      await assessmentService.getAllAssessmentMetaData();

    await Promise.all([fetchAssessmentMetaData])
      .then((response) => {
        const [assessmentMetaDataResponse] = response;

        this.setState({
          allCategories: assessmentMetaDataResponse.data.find(
            (item) => "categories" in item
          ).categories,
          allLanguages: assessmentMetaDataResponse.data.find(
            (item) => "languages" in item
          ).languages,
          allSubjects: assessmentMetaDataResponse.data.find(
            (item) => "subjects" in item
          ).subjects,
          allAuthors: assessmentMetaDataResponse.data.find(
            (item) => "authors" in item
          ).authors,
          allYears: assessmentMetaDataResponse.data.find(
            (item) => "years" in item
          ).years,
          allDifficulties: assessmentMetaDataResponse.data.find(
            (item) => "difficulty_types" in item
          ).difficulty_types,
          allGrades: assessmentMetaDataResponse.data.find(
            (item) => "grades" in item
          ).grades,
          allTerms: assessmentMetaDataResponse.data.find(
            (item) => "terms" in item
          ).terms,
          allChapters: assessmentMetaDataResponse.data.find(
            (item) => "chapters" in item
          ).chapters,
          allPrivileges: assessmentMetaDataResponse.data.find(
            (item) => "privileges" in item
          ).privileges,
          allAssessmentStatuses: assessmentMetaDataResponse.data.find(
            (item) => "statuses" in item
          ).statuses,
          isTableDataLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getPaperCategory(category_id) {
    if (category_id !== null) {
        const category = this.state.allCategories?.find(
            (category) => category.category_id == category_id
        );
        return category?.name;
    }
    else{
        return "N/A";
    }
  }

  getPaperLanguage(language_id) {
    if (language_id !== null) {
        const language = this.state.allLanguages?.find(
            (language) => language.language_id == language_id
        );
        return language?.language;
    }
    else{
        return "N/A";
    }
  }

  getPaperSubject(subject_id) {
    if (subject_id !== null) {
        const subject = this.state.allSubjects?.find(
            (subject) => subject.subject_id == subject_id
        );
        return subject?.subject;
    }
    else{
        return "N/A";
    }
  }

  getPaperAuthor(author_id) {
    if (author_id !== null) {
        const author = this.state.allAuthors?.find(
            (author) => author.author_id == author_id
        );
        return author?.author;
    }
    else{
        return "N/A";
    }
  }

  getPaperYear(year_id) {
    if (year_id !== null) {
        const year = this.state.allYears?.find(
            (year) => year.year_id == year_id
        );
        return year?.year;
    }
    else{
        return "N/A";
    }
  }

  getPaperDifficulty(difficulty_id) {
    if (difficulty_id !== null) {
        const difficulty = this.state.allDifficulties?.find(
            (difficulty) => difficulty.difficulty_id == difficulty_id
        );
        return difficulty?.difficulty_type;
    }
    else{
        return "N/A";
    }
  }

  getPaperGrade(grade_id) {
    if (grade_id !== null) {
        const grade = this.state.allGrades?.find(
            (grade) => grade.grade_id == grade_id
        );
        return grade?.grade;
    }
    else{
        return "N/A";
    }
  }

  getPaperTerm(term_id) {
    if (term_id !== null) {
        const term = this.state.allTerms?.find(
            (term) => term.term_id == term_id
        );
        return term?.term;
    }
    else{
        return "N/A";
    }
  }

  getCreatedAtLocal(time) {
    if(time != null){
        let timeStamp = new Date(time.concat("Z"))
        let d = new Date(timeStamp)
        // let startDate = d.toDateString();
        // let startTime = d.toLocaleTimeString('en-SL');
        let  local = moment(timeStamp).local().format('YYYY-MM-DDTHH:mm')
        return local;
    }
    else{
        return "N/A";
    }
  }

  getPaperChapter(chapter_id) {
    if (chapter_id !== null) {
        const chapter = this.state.allChapters?.find(
            (chapter) => chapter.chapter_id == chapter_id
        );
        return chapter?.chapter;
    }
    else{
        return "N/A";
    }
  }

  getPaperStatus(status_id) {
    if (status_id !== null) {
        const status = this.state.allAssessmentStatuses?.find(
            (status) => status.status_id == status_id
        );
        return status?.status;
    }
    else{
        return "N/A";
    }
  }

  fetchAllPapers = async () => {
    const { 
      limit, 
      offset, 
      sort_column, 
      sort_direction, 
      search_term, 
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedGrades,
      selectedDifficulties,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects,
      selectedStatuses,
      selectedTeachers
    } = this.state;
    this.setState({ isTableDataLoading: true })
    await libraryService.getAllPapers(
        limit,
        offset,
        sort_column,
        sort_direction,
        search_term,
        selectedCategories,
        selectedAuthors,
        selectedChapters,
        selectedGrades,
        selectedDifficulties,
        selectedLanguages,
        selectedTerms,
        selectedYears,
        selectedsubjects,
        selectedStatuses,
        selectedTeachers
    ).then((response) => {
        if (response.success) {
          this.setState({
            papers: response.data.papers,
            totalCount: parseInt(response.data.total_count),
            teachers: response.data.teachers
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching all papers. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        sweetStatusAlert(
          "Failed!",
          "Error fetching all papers. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false }); // Set isTableDataLoading to false after data is fetched
      });
  };

  handleSortChange = (sort_column) => {
    // If the same column is clicked, toggle the sort direction
    const { sort_column: currentSortColumn, sort_direction } = this.state;
    const newSortDirection =
      currentSortColumn === sort_column && sort_direction === "ASC"
        ? "DESC"
        : "ASC";

    this.setState(
      {
        sort_column,
        sort_direction: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
       this.fetchAllPapers();
      }
    );
  };

  // Handle the data fetching as per the offset(page no) changes
  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllPapers();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.fetchAllPapers();
      }
    );
  };

  // handle the data fetching as per the data per page limit gets changess
  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllPapers();
      }
    );
  };

  // handle search text as per the regex validation and search_term , and fetch data as per the debounce
  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  onFilterInput = (v, r) => {
    let categoryOptions;
    let languageOptions;
    let subjectOptions;
    let authorOptions;
    let gradeOptions;
    let yearOptions;
    let termOptions;
    let chapterOptions;
    let assessmentStatusOptions;
    let difficultyOptions;
    let paperCreatedTeacherOptions;
    let selectedFilters = [];
    this.setState({
      filters: v,
      filterValues: [],
    });

    if (r === "removeOption") {
      this.setState(
        {
          selectedAuthors: null,
          selectedsubjects: null,
          selectedChapters: null,
          selectedTerms: null,
          selectedCategories: null,
          selectedYears: null,
          selectedLanguages: null,
          selectedDifficulties: null,
          selectedGrades: null,
          selectedStatuses: null,
          selectedTeachers: null
        },
        () => {
          this.fetchAllPapers();
        }
      );
    }
    if (v.includes("Category")) {
      categoryOptions = this.state.allCategories?.map((val) => {
        return {
          filter: "Category",
          name: val.name,
          category_id: val.category_id,
        };
      });
      selectedFilters = [...selectedFilters, ...categoryOptions];
    }
    if (v.includes("Language")) {
      languageOptions = this.state.allLanguages?.map((val) => {
        return {
          filter: "Language",
          name: val.language,
          language_id: val.language_id,
        };
      });
      selectedFilters = [...selectedFilters, ...languageOptions];
    }
    if (v.includes("Subject")) {
      subjectOptions = this.state.allSubjects?.map((val) => {
        return {
          filter: "Subject",
          name: val.subject,
          subject_id: val.subject_id,
        };
      });
      selectedFilters = [...selectedFilters, ...subjectOptions];
    }
    if (v.includes("Author")) {
      authorOptions = this.state.allAuthors?.map((val) => {
        return {
          filter: "Author",
          name: val.author,
          author_id: val.author_id,
        };
      });
      selectedFilters = [...selectedFilters, ...authorOptions];
    }
    if (v.includes("Year")) {
      yearOptions = this.state.allYears?.map((val) => {
        return {
          filter: "Year",
          name: val.year,
          year_id: val.year_id,
        };
      });
      selectedFilters = [...selectedFilters, ...yearOptions];
    }
    if (v.includes("Difficulty")) {
      difficultyOptions = this.state.allDifficulties?.map((val) => {
        return {
          filter: "Difficulty",
          name: val.difficulty_type,
          difficulty_id: val.difficulty_id,
        };
      });
      selectedFilters = [...selectedFilters, ...difficultyOptions];
    }
    if (v.includes("Grade")) {
      gradeOptions = this.state.allGrades?.map((val) => {
        return {
          filter: "Grade",
          name: val.grade,
          grade_id: val.grade_id,
        };
      });
      selectedFilters = [...selectedFilters, ...gradeOptions];
    }
    if (v.includes("Term")) {
      termOptions = this.state.allTerms?.map((val) => {
        return {
          filter: "Term",
          name: val.term,
          term_id: val.term_id,
        };
      });
      selectedFilters = [...selectedFilters, ...termOptions];
    }
    if (v.includes("Chapter")) {
      chapterOptions = this.state.allChapters?.map((val) => {
        return {
          filter: "Chapter",
          name: val.chapter,
          chapter_id: val.chapter_id,
        };
      });
      selectedFilters = [...selectedFilters, ...chapterOptions];
    }
    if (v.includes("Status")) {
      assessmentStatusOptions = this.state.allAssessmentStatuses?.filter(status => status.status_id !== 4).filter(status => status.status_id !== 5).map((val) => {
        return {
          filter: "Status",
          name: val.status,
          status_id: val.status_id
        };
      });
      selectedFilters = [...selectedFilters, ...assessmentStatusOptions];
    }
    if (v.includes("Created By")) {
      let paperCreatedTeachers = this.state.teachers;
      paperCreatedTeacherOptions = paperCreatedTeachers?.map((val) => {
          return {
              filter: "Created By",
              name: val.first_name + ' ' + val.last_name,
              teacher_user_id: parseInt(val.user_id)
          }
      })
      selectedFilters = [...selectedFilters, ...paperCreatedTeacherOptions]
    }
    this.setState({
      selectedFilters: selectedFilters,
    });
  };

  onSelectFilter = (e) => {
    let selectedGrades = [];
    let selectedChapters = [];
    let selectedsubjects = [];
    let selectedAuthors = [];
    let selectedYears = [];
    let selectedCategories = [];
    let selectedTerms = [];
    let selectedDifficulties = [];
    let selectedLanguages = [];
    let selectedStatuses = [];
    let selectedTeachers = [];

    e.map((val) => {
      if (val.filter === "Category") {
        selectedCategories.push(val?.category_id);
      }
      if (val.filter === "Difficulty") {
        selectedDifficulties.push(val?.difficulty_id);
      }
      if (val.filter === "Language") {
        selectedLanguages.push(val?.language_id);
      }
      if (val.filter === "Subject") {
        selectedsubjects.push(val?.subject_id);
      }
      if (val.filter === "Author") {
        selectedAuthors.push(val?.author_id);
      }
      if (val.filter === "Year") {
        selectedYears.push(val?.year_id);
      }
      if (val.filter === "Term") {
        selectedTerms.push(val?.term_id);
      }
      if (val.filter === "Chapter") {
        selectedChapters.push(val?.chapter_id);
      }
      if (val.filter === "Grade") {
        selectedGrades.push(val?.grade_id);
      }
      if (val.filter === "Status") {
        selectedStatuses.push(val?.status_id);
      }
      if (val.filter === "Created By") {
        selectedTeachers.push(val?.teacher_user_id);
      }
    });

    this.setState(
      {
        filterValues: e,
        selectedAuthors: selectedAuthors.length > 0 ? selectedAuthors : null,
        selectedsubjects: selectedsubjects.length > 0 ? selectedsubjects : null,
        selectedChapters: selectedChapters.length > 0 ? selectedChapters : null,
        selectedTerms: selectedTerms.length > 0 ? selectedTerms : null,
        selectedCategories:
          selectedCategories.length > 0 ? selectedCategories : null,
        selectedYears: selectedYears.length > 0 ? selectedYears : null,
        selectedLanguages:
          selectedLanguages.length > 0 ? selectedLanguages : null,
        selectedDifficulties:
          selectedDifficulties.length > 0 ? selectedDifficulties : null,
        selectedGrades: selectedGrades.length > 0 ? selectedGrades : null,
        selectedStatuses: selectedStatuses.length > 0 ? selectedStatuses : null,
        selectedTeachers: selectedTeachers.length > 0 ? selectedTeachers : null
      },
      () => {
        this.fetchAllPapers();
      }
    );
  };

  handleBulkClose = () => {
    this.setState({
      showUpdateBulkDetailModal: false
    });
    this.validator.hideMessages();
    this.validator.purgeFields();
    this.resetFormFields();
  }

  toggleUpdateBulkPaperModal = () => {
    this.setState((prevState) => ({
      showUpdateBulkDetailModal: !prevState.showUpdateBulkDetailModal,
    }));
  };

  handleViewClick = (paperItem) => {
    this.setState({
      selectedPaper: paperItem,  
      showDetailModal: true, 
    });
  };
  
  toggleDetailModal = () => {
    this.setState((prevState) => ({
      showDetailModal: !prevState.showDetailModal,
    }));
  };

  handleChangeCategory = (e) => {
    e.preventDefault();
    const editCategoryId = e.target.value;
    this.setState({ editCategoryId });
  };

  handleChangeGrade = (e) => {
    e.preventDefault();
    const editGradeId = e.target.value;
    this.setState({ editGradeId });
  };

  handleChangeLanguage = (e) => {
    e.preventDefault();
    const editLanguageId = e.target.value;
    this.setState({ editLanguageId });
  };

  handleChangeChapter = (e) => {
    e.preventDefault();
    const editChapterId = e.target.value;
    this.setState({ editChapterId });
  };

  handleChangeSubject = (e) => {
    e.preventDefault();
    const editSubjectId = e.target.value;
    this.setState({ editSubjectId });
  };

  handleChangeDifficulty = (e) => {
    e.preventDefault();
    const editDifficultyId = e.target.value;
    this.setState({ editDifficultyId });
  };

  handleChangeAuthor = (e) => {
    e.preventDefault();
    const editAuthorId = e.target.value;
    this.setState({ editAuthorId });
  };

  handleChangeYear = (e) => {
    e.preventDefault();
    const editYearId = e.target.value;
    this.setState({ editYearId });
  };

  handleChangeTerm = (e) => {
    e.preventDefault();
    const editTermId = e.target.value;
    this.setState({ editTermId });
  };

  handleChangePrivilege = (e) => {
    e.preventDefault();
    const editPrivilegeId = e.target.value;
    this.setState({ editPrivilegeId });
  };

  handleChangeStatus = (e) => {
    e.preventDefault();
    const editStatusId = e.target.value;
    const editStatusName = this.getPaperStatus(parseInt(e.target.value, 10));
    this.setState({ editStatusId });
    this.setState({ editStatusName });
  };

  handleUpdateBulkSubmit = async (event) => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });

      const paperItem = {
        paper_list: this.state.selectedPapers,
        category_id: this.state.editCategoryId,
        grade_id: this.state.editGradeId,
        language_id: this.state.editLanguageId,
        chapter_id: this.state.editChapterId,
        subject_id: this.state.editSubjectId,
        difficulty_id: this.state.editDifficultyId,
        author_id: this.state.editAuthorId,
        year_id: this.state.editYearId,
        term_id: this.state.editTermId,
        privilege_id: this.state.editPrivilegeId,
        status_id: this.state.editStatusId,
        assessment_state: this.state.editStatusName
      };

      await candidateService.updateAllPapers(paperItem)
        .then((response) => {
          this.setState({ isLoading: false, selectedPapers: [], selectAll: false });
          if (response.success) {
            this.setState({ showUpdateBulkDetailModal: false });
            sweetStatusAlert(
              "Success!",
              "Selected papers updated successfully!",
              "success"
            );
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
            this.fetchAllPapers();
          } else {
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert(
            "Failed !",
            "Unable to update the selected papers!",
            "error"
          );
        });
    }
  };

  getColor(state) {
    switch (state) {
      case "Initial":
        return "label label-lg label-light-primary label-inline";
      case "Ready":
        return "label label-lg label-light-warning label-inline";
      case "Review":
        return "label label-lg label-light-default label-inline";
      case "Published":
        return "label label-lg label-success label-inline";
      case "Retired":
        return "label label-lg label-light-danger label-inline";
      default:
        return "label label-lg label-light-info label-inline";
    }
  }
  
  handleSelect = (assessment_id) => {
    this.setState((prevState) => {
      const { selectedPapers} = prevState;
      if (selectedPapers.includes(assessment_id)) {
        return {
          selectedPapers: selectedPapers.filter((id) => (id !== assessment_id)),
        };
      } else {
        return {
          selectedPapers: [...selectedPapers, assessment_id],
        };
      }
    });
    const isAll = this.state.selectedPapers.every(paper => paper.status_id === 2)
    if(isAll){
      this.setState({ isAllReady : true });
    }
  };

  handleSelectAll = () => {
    const { selectAll, papers } = this.state;
    const isAll = papers.every(paper => paper.status_id === 2)
    if(isAll){
      this.setState({ isAllReady : true });
    }
    if (selectAll) {
      this.setState({ selectedPapers: [], selectAll: false });
    } else {
      const allIds = papers.map((item) => item.assessment_id);
      this.setState({ selectedPapers: allIds, selectAll: true });
    }
  };

  handleSelectMyPapers = (e) => {
    const isChecked = e.target.checked;
    this.setState(
      {
        checked: isChecked,  
        selectedTeachers: isChecked ? sessionStorage.getItem("user_id") : null  
      },
      () => {
        this.fetchAllPapers();  
      }
    );
  };

  //Rendering the necessary components based on the logic
  render() {
    const {
      limit,
      papers,
      totalCount,
      isTableDataLoading,
      currentPage,
      isLoading,
      selectedPapers,
      selectAll,
      isAllReady
    } = this.state;
    
    this.validator.purgeFields();

    return (
      <>
        <ContentWrapper>
          <div className="row justify-content-between align-items-center py-5 mb-3">
            <div className="col-md-6 col-6">
              <h3 className="text-nowrap">Manage All Paper Details</h3>
            </div>
          </div>
          <div>
          <div className="pr-3 mb-2" style={{ display: "flex", gap: "10px" }}>
            <div className="col-md-9 col-12 px-0">
              <div className="row w-100">
                <Autocomplete
                  multiple
                  size="small"
                  id="checkboxes-filter"
                  options={PAPER_SETTINGS_FILTERS}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (
                    <li
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        listStyle: "none",
                      }}
                    >
                      {option}
                    </li>
                  )}
                  onChange={(e, v, r) => this.onFilterInput(v, r)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ fontFamily: "Poppins" }}>
                      <Checkbox
                        style={{ marginRight: 8, color: "#5458AF" }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  className="col-lg-6 col-md-6 col-12 px-0 py-2"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Paper"
                      className="col-md-12"
                    />
                  )}
                />
                {this.state.filters.length > 0 && (
                  <Autocomplete
                    multiple
                    size="small"
                    id="grouped-demo"
                    className="col-lg-6 col-md-6 col-12 px-md-1 px-sm-0 py-2"
                    options={this.state.selectedFilters}
                    groupBy={(option) => option.filter}
                    onChange={(e, v) => this.onSelectFilter(v)}
                    getOptionLabel={(option) => (
                      <li
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          listStyle: "none",
                        }}
                      >
                        {option.name}
                      </li>
                    )}
                    sx={{
                      width: 300,
                      fontFamily: "Poppins, sans-serif",
                    }}
                    value={this.state.filterValues}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Filters"
                        className="col-md-12"
                      />
                    )}
                  />
                )}
              </div>
            </div>
            {this.state.selectedPapers.length > 0 && 
              <Button
                onClick={() => this.toggleUpdateBulkPaperModal()}
                className="btn btn-primary btn-sm m-2" 
                title="Select an/all papers to update selected papers"
                disabled={this.state.selectedPapers.length === 0 ? true : false} 
              >
                Update All Selected Papers
              </Button>
            }
            </div>
            <div className="row">
              {/* <div style={{ display: 'inline-flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                  type="checkbox"
                  id="selectMyPapers"
                  onChange={this.handleSelectMyPapers}
                  checked={this.state.checked}
                  style={{ marginRight: '8px' }}  
                />
                <label htmlFor="selectMyPapers" style={{ margin: 0, cursor: 'pointer' }}>
                  My Papers
                </label>
              </div> */}
              <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                <Form.Group controlId="exampleForm.ControlInput1" style={{ width: "300px" }}>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    style={{ width: "100%" }}
                    className="col-md-12 col-12"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>
            </div>
            {isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            {isTableDataLoading ? (
              <div
                className="loading-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "75vw",
                  height: "90vh",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : papers.length > 0 ? (
            <div className="row">
              <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                            checked={selectAll}
                            onChange={this.handleSelectAll}
                            color="primary"
                        />
                        <span>Select All</span>
                        </div>
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("title")}
                      >
                        Paper Title{" "}
                        {this.state.sort_column === "tile" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("grade_id")}
                      >
                        Paper Grade{" "}
                        {this.state.sort_column === "grade_id" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("assessment_state")}
                      >
                        Paper Status{" "}
                        {this.state.sort_column === "assessment_state" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                      </TableCell>
                      <TableCell className="thead">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {papers.map((paperItem) => (
                      <TableRow key={paperItem.assessment_id}>
                        <TableCell>
                            <Checkbox
                              checked={selectedPapers.includes(paperItem.assessment_id)}
                              onChange={() => this.handleSelect(paperItem.assessment_id)}
                              color="primary"
                            />
                        </TableCell>
                        <TableCell className="tData">
                          {paperItem.title} 
                        </TableCell>
                         <TableCell className="tData">
                          {this.getPaperGrade(paperItem.grade_id)}
                        </TableCell>
                        <TableCell className="tData">
                          <div className={this.getColor(paperItem.assessment_state)}>
                            {paperItem.assessment_state}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="btn-group">
                            <Button
                              onClick={() => this.handleViewClick(paperItem)}
                              className="btn btn-icon btn-primary btn-sm m-1 view-assement-btn flaticon-eye-icon"
                              title="View More Details"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <MaterialCustomTablePagination
                  totalCount = {totalCount}
                  currentPage = {currentPage}
                  limit = {limit}
                  handlePageChange = {this.handlePageChange}
                  handleRowsPerPageChange = {this.handleRowsPerPageChange}
                  handlePageOffsetChange = {this.handlePageOffsetChange}
                  offset = {this.state.offset}
                  rowsPerPageOptions={[5, 10, 20, 25, 50, 75, 100, 150, 200, 250, 300]}
                />
              </div>
            </div>
            ) : (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="thead">Paper Title</TableCell>
                      <TableCell className="thead">Paper Grade</TableCell>
                      <TableCell className="thead">Paper State</TableCell>
                      <TableCell className="thead">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <div className="text-center mb-5 my-10">
                  <label>No papers are available!</label>
                </div>
              </div>
            )}
          </div>
        </ContentWrapper>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showUpdateBulkDetailModal}
          onHide={this.toggleUpdateBulkPaperModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Details for All Selected Papers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                <Form.Label>Update Category</Form.Label>
                <Form.Select
                    id="edit-paper-category"
                    value={this.state.editCategoryId}
                    onChange={this.handleChangeCategory}
                    className="form-control"
                    >     
                    <option value={null}>Nothing Selected</option>
                    {this.state.allCategories.map((option, index) => (
                        <option key={index} value={option.category_id}>
                          {option.name}
                        </option>
                    ))}
                </Form.Select>
                <Form.Label>Update Grade</Form.Label>
                <Form.Select
                    id="edit-paper-grade"
                    value={this.state.editGradeId}
                    onChange={this.handleChangeGrade}
                    className="form-control"
                    >     
                    <option value={null}>Nothing Selected</option>
                    {this.state.allGrades.map((option, index) => (
                        <option key={index} value={option.grade_id}>
                          {option.grade}
                        </option>
                    ))}
                </Form.Select>
                <Form.Label>Update Language</Form.Label>
                <Form.Select
                    id="edit-paper-language"
                    value={this.state.editLanguageId}
                    onChange={this.handleChangeLanguage}
                    className="form-control"
                    >     
                    <option value={null}>Nothing Selected</option>
                    {this.state.allLanguages.map((option, index) => (
                        <option key={index} value={option.language_id}>
                          {option.language}
                        </option>
                    ))}
                </Form.Select>
                <Form.Label>Update Chapter/Module/Unit</Form.Label>
                <Form.Select
                    id="edit-paper-chapter"
                    value={this.state.editChapterId}
                    onChange={this.handleChangeChapter}
                    className="form-control"
                    >
                    <option value={null}>Nothing Selected</option>
                    {this.state.allChapters.map((option, index) => (
                        <option key={index} value={option.chapter_id}>
                          {option.chapter}
                        </option>
                    ))}
                </Form.Select>
                <Form.Label>Update Subject</Form.Label>
                <Form.Select
                    id="edit-paper-subject"
                    value={this.state.editSubjectId}
                    onChange={this.handleChangeSubject}
                    className="form-control"
                    >
                    <option value={null}>Nothing Selected</option>
                    {this.state.allSubjects.map((option, index) => (
                        <option key={index} value={option.subject_id}>
                          {option.subject}
                        </option>
                    ))}
                </Form.Select>
                <Form.Label>Update Difficulty</Form.Label>
                <Form.Select
                    id="edit-paper-difficulty"
                    value={this.state.editDifficultyId}
                    onChange={this.handleChangeDifficulty}
                    className="form-control"
                    >
                    <option value={null}>Nothing Selected</option>
                    {this.state.allDifficulties.map((option, index) => (
                        <option key={index} value={option.difficulty_id}>
                          {option.difficulty_type}
                        </option>
                    ))}
                </Form.Select>
                <Form.Label>Update Author</Form.Label>
                <Form.Select
                    id="edit-paper-author"
                    value={this.state.editAuthorId}
                    onChange={this.handleChangeAuthor}
                    className="form-control"
                    >
                    <option value={null}>Nothing Selected</option>
                    {this.state.allAuthors.map((option, index) => (
                        <option key={index} value={option.author_id}>
                          {option.author}
                        </option>
                    ))}
                </Form.Select>
                <Form.Label>Update Year</Form.Label>
                <Form.Select
                    id="edit-paper-year"
                    value={this.state.editYearId}
                    onChange={this.handleChangeYear}
                    className="form-control"
                    >
                    <option value={null}>Nothing Selected</option>
                    {this.state.allYears.map((option, index) => (
                        <option key={index} value={option.year_id}>
                          {option.year}
                        </option>
                    ))}
                </Form.Select>
                <Form.Label>Update Term/Semester</Form.Label>
                <Form.Select
                    id="edit-paper-term"
                    value={this.state.editTermId}
                    onChange={this.handleChangeTerm}
                    className="form-control"
                    >
                    <option value={null}>Nothing Selected</option>
                    {this.state.allTerms.map((option, index) => (
                        <option key={index} value={option.term_id}>
                          {option.term}
                        </option>
                    ))}
                </Form.Select>
                {privilegeEditEnabled && (
                  <>
                  <Form.Label>Update Privilege</Form.Label>
                  <Form.Select
                      id="edit-paper-privilege"
                      value={this.state.editPrivilegeId}
                      onChange={this.handleChangePrivilege}
                      className="form-control"
                      >
                      <option value={null}>Nothing Selected</option>
                      {this.state.allPrivileges.map((option, index) => (
                          <option key={index} value={option.privilege_id}>
                            {option.privilege}
                          </option>
                      ))}
                  </Form.Select>
                </>
                )}
                {/* {isAllReady && (
                  <>
                  <Form.Label>Update Status</Form.Label>
                  <Form.Select
                      id="edit-paper-status"
                      value={this.state.editStatusId}
                      onChange={this.handleChangeStatus}
                      className="form-control"
                      >
                      <option value={null}>Nothing Selected</option>
                      {this.state.allAssessmentStatuses.map((option, index) => (
                          <option key={index} value={option.status_id}>
                            {option.status}
                          </option>
                      ))}
                  </Form.Select>
                </>
                )} */}
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="modal-btn"
              variant="primary"
              style={addBtnStyle}
              onClick={this.handleUpdateBulkSubmit}
            >
              Save changes
            </Button>
            <Button
              className="modal-btn"
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={this.handleBulkClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          centered
          show={this.state.showDetailModal}
          onHide={this.toggleDetailModal}
        >
          <ModalHeader>
            <h5 className="modal-title">{this.state.selectedPaper?.title}</h5>
            <Button variant="secondary" onClick={this.toggleDetailModal}>
              Close
            </Button>
          </ModalHeader>
          {this.state.selectedPaper && (
          <ModalBody>
            <ViewForm className="form" id="kt_form">
              {this.state.selectedPaper?.category_id !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Category :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperCategory(this.state.selectedPaper?.category_id)}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div>
                </>
              }     
              {this.state.selectedPaper?.language_id !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Language :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperLanguage(this.state.selectedPaper?.language_id)}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div>
                </>
              }
              {this.state.selectedPaper?.difficulty_id !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Difficulty :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperDifficulty(this.state.selectedPaper?.difficulty_id)}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div>
                </>
              }
              {this.state.selectedPaper?.subject_id !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Subject :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperSubject(this.state.selectedPaper?.subject_id)}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div>
                </>
              }
              {this.state.selectedPaper?.chapter_id !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Chapter/Module/Unit :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperChapter(this.state.selectedPaper?.chapter_id)}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div> 
                </>
              }
              {this.state.selectedPaper?.author_id !== null &&
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Author :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperAuthor(this.state.selectedPaper?.author_id)}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div>
                </>
              }
              {this.state.selectedPaper?.year_id !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Year :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperYear(this.state.selectedPaper?.year_id)}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div>
                </>
              }
              {this.state.selectedPaper?.term_id !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Term/Semester :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperTerm(this.state.selectedPaper?.term_id)}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div>
                </>
              }
              {this.state.selectedPaper?.first_name !== null && this.state.selectedPaper?.last_name !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Created By :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.state.selectedPaper?.first_name + " " + this.state.selectedPaper?.last_name}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div>
                </>
              }
              {this.state.selectedPaper?.created_at !== null && 
                <>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Created At :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getCreatedAtLocal(this.state.selectedPaper?.created_at)}
                      </div>
                    </div>
                  </div>
                </>
              }
            </ViewForm>
          </ModalBody>
          )}
        </Modal>
      </>
    );
  }
}
