    import React, { Component } from "react";
    import Col from "react-bootstrap/Col";
    import Form from "react-bootstrap/Form";
    import Row from "react-bootstrap/Row";
    import Modal from "react-bootstrap/Modal";
    import Button from "react-bootstrap/Button";
    import sweetAlert, {
    sweetStatusAlert,
    sweetConfirmAlert
    } from "../../../components/common/SweetAlert";
    import { IdTypeService } from "../../../service/settings/manage/id_type.service.js";
    import Spinner from "react-bootstrap/Spinner";
    import "../../../components/admin/css/common.css";
    import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell
    } from "@mui/material";
    import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
    import debounce from "lodash.debounce";
    import SimpleReactValidator from "simple-react-validator";
    import PageSpinner from "../../../components/common/spinner/PageSpinner";
    import ContentWrapper from "../../../components/common/ContentWrapper";
    import config from '../../../helper/config';
    import {customerConstants} from "../../../helper/constants.js";

    const getCustomerFeatureFlag = () => {
    return config.customerConfigs.featureFlag;
    };

    let addBtnStyle, closeBtnStyle;
    const customer = getCustomerFeatureFlag();
    switch (customer) {
    case customerConstants.EXO_CUSTOMER1:
        break;
    case customerConstants.EXO_CUSTOMER2:
        closeBtnStyle = {
        color: "var(--maincolor-cmc)",
        backgroundColor: "#E5F5FF",
        borderColor: "transparent",
        };
        addBtnStyle = {
        color: "#fff",
        backgroundColor: "var(--maincolor-cmc)",
        borderColor: "transparent",
        };
        break;
    default:
        break;
    }

    export default class ManageIdType extends Component {
    constructor(props) {
        super(props);
        this.createValidationsInstance();
        this.state = {
        showModal: false,
        IDTypes: [],
        name: "",
        description: "",
        gender_id: "",
        modalType: false,
        search_val: "",
        isLoading: true,
        isSpinnerLoading: false,
        isModified: false,
        limit: 10, // Set the desired limit
        offset: 0, // Set the desired offset
        search_term: "",
        totalIdTypes: 10,
        currentPage: 0,
        editVisibility: true,
        addVisibility: true,
        deleteVisibility: true,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEditSubmit = this.handleEditSubmit.bind(this);

        // Debounced version of handleSearch function
        this.debouncedHandleSearch = debounce(this.fetchIDTypeData, 2000);
    }

    resetFormFields = () => {
        this.setState({
        name: "",
        description: "",
        });
    };

    componentDidMount() {
        this.fetchIDTypeData();
    }

    createValidationsInstance() {
        this.validator = new SimpleReactValidator();
    }

    fetchIDTypeData() {
        const { limit, offset, search_term } = this.state;
        IdTypeService.getAll(limit, offset, search_term)
        .then((response) => {
            if (response.success) {
            this.setState({
                IDTypes: response.data.idTypes,
                totalIdTypes: parseInt(response.data.count),
            });
            } else {
            sweetStatusAlert(
                "Failed!",
                "Error fetching Id Types. Please refresh the screen!",
                "error"
            );
            }
        })
        .catch((error) => {
            // Handle any error that occurred during the API call
            sweetStatusAlert(
            "Failed!",
            "Error fetching Id Types. Please refresh the screen!",
            "error"
            );
        })
        .finally(() => {
            this.setState({ isLoading: false, isSpinnerLoading: false });
        });
    }
    
    handlePageChange = (event, newPage) => {
        const { limit } = this.state;
        const newOffset = newPage * limit;
        this.setState(
        {
            offset: newOffset,
            isLoading: true,
            currentPage: newPage,
        },
        () => {
            this.fetchIDTypeData();
        }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        this.setState(
        {
            offset: newOffset,
            isLoading: true,
            currentPage: newPage
        },
        () => {
            this.fetchIDTypeData();
        }
        );
    };

    handleRowsPerPageChange = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        const newPage = Math.floor(this.state.offset / newLimit);
        this.setState(
        {
            limit: newLimit,
            offset: newLimit * newPage,
            isTableDataLoading: true,
            currentPage: newPage,
        },
        () => {
            this.fetchIDTypeData();
        }
        );
    };

    handleSearch = (event) => {
        const search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

        if (search_term === "" || regex.test(search_term)) {
        this.setState(
            {
            search_term,
            isLoading: true,
            offset: 0,
            currentPage: 0,
            searchError: false,
            },
            () => {
            this.debouncedHandleSearch();
            }
        );
        } else {
        this.setState({ search_term, searchError: true });
        }
    };

    toggleIdTypeModal = (modalType) => {
        this.modalType = modalType;
        this.setState((prevState) => ({
        showModal: !prevState.showModal,
        }));
    };

    toggleUpdateIdTypeModal = (name, description, gender_id, modalType) => {
        this.setState((prevState) => ({
        showModal: !prevState.showModal,
        name: name,
        description: description,
        gender_id: gender_id,
        modalType: modalType,
        }));
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(() => ({ [name]: value, isModified: true }));
    };

    handleSweetAlert = (modalType, modalVisibility) => {
        if (this.state.isModified) {
        sweetAlert().then((response) => {
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
            this.modalType = false;
            this.setState(() => ({
            [modalVisibility]: response,
            isModified: false,
            }));
        });
        } else {
        this.validator.hideMessages();
        this.validator.purgeFields();
        this.resetFormFields();
        if (modalType) this.toggleIdTypeModal(false);
        else this.toggleUpdateIdTypeModal();
        }
    };

    //Handle Edit Submit
    handleEditSubmit = (event) => {
        event.preventDefault();

        if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        } else {
        this.setState({ isSpinnerLoading: true });
        const IDTypeItem = {
            idType: this.state.name,
            description: this.state.description,
        };
        const IdTypeID = this.state.gender_id;

        IdTypeService.update(IDTypeItem, IdTypeID)
            .then((response) => {
            if (response.success) {
                this.setState({ isSpinnerLoading: false });
                this.toggleIdTypeModal();
                sweetStatusAlert(
                "Success!",
                "Id Type information updated successfully..!",
                "success"
                );
                this.validator.hideMessages();
                this.validator.purgeFields();
                this.resetFormFields();
                this.fetchIDTypeData();
            } else {
                this.setState({ isSpinnerLoading: false });
                sweetStatusAlert("Failed!", response.message, "error");
            }
            })
            .catch((error) => {
            this.setState({ isSpinnerLoading: false });
            sweetStatusAlert(
                "Failed!",
                "Unable to update the Id Type information",
                "error"
            );
            });
        }
    };

    //add new Id Type
    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        } else {
        this.setState({ isSpinnerLoading: true });
        const { name, description } = this.state;
        const newIdType = { idType: name, description: description };

        IdTypeService.add(newIdType)
            .then((response) => {
            if (response.success) {
                sweetStatusAlert("Success!", response.message, "success");
                this.validator.hideMessages();
                this.validator.purgeFields();
                this.resetFormFields();
                this.toggleIdTypeModal(false);
                this.fetchIDTypeData();
            } else {
                this.setState({ isSpinnerLoading: false });
                sweetStatusAlert("Failed!", response.message, "error");
            }
            })
            .catch((err) => {
            this.setState({ isSpinnerLoading: false });
            sweetStatusAlert("Failed!", "Unable to create new Id Type", "error");
            });
        }
    };

    handleDeleteIdType = (id) => {
        sweetConfirmAlert().then(() => {
        this.setState({ isSpinnerLoading: true });
        IdTypeService.deleteIdType(id)
            .then((response) => {
            this.setState({ isSpinnerLoading: false });
            if (response.success) {
                sweetStatusAlert("Success!", response.message, "success");
                this.fetchIDTypeData();
            } else {
                sweetStatusAlert("Oops!", "This Id Type is in use. You cannot delete it.", "info");
            }
            })
            .catch((error) => {
            this.setState({ isSpinnerLoading: false });
            sweetStatusAlert("Oops!", "Error while deleting the Id Type", "info");
            });
        });
    };

    render() {
        const {
        limit,
        IDTypes,
        totalIdTypes,
        isLoading,
        currentPage,
        isSpinnerLoading,
        editVisibility,
        addVisibility,
        deleteVisibility,
        } = this.state;
        return (
        <>
            <ContentWrapper>
            <div className="row justify-content-between align-items-center py-5 mb-3">
                <div className="col-md-6 col-6">
                <h3 className="text-nowrap">Manage ID Type</h3>
                </div>
                <div>
                {addVisibility && (
                    <Button
                    className="insert-btn"
                    variant="primary"
                    onClick={() => this.toggleIdTypeModal(true)}
                    >
                    Add ID Type
                    </Button>
                )}
                </div>
            </div>
            <div>
                <Form.Group controlId="exampleForm.ControlInput1" className="mb-2">
                <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    // style={{ width: "18%" }}
                    className="col-md-3 col-12"
                />
                {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                    Invalid input
                    </Form.Control.Feedback>
                )}
                </Form.Group>
                {isSpinnerLoading && (
                <PageSpinner id="show" text="Loading, Please wait!" />
                )}
                {isLoading ? (
                <div
                    className="loading-container"
                    style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "75vw",
                    height: "90vh",
                    }}
                >
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
                ) : IDTypes.length > 0 ? (
                <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
                    <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell className="thead">No</TableCell>
                        <TableCell className="thead">ID Type</TableCell>
                        <TableCell className="thead">
                            ID Type Description
                        </TableCell>
                        {(editVisibility || deleteVisibility) && (
                            <TableCell className="thead">Actions</TableCell>
                        )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {IDTypes.map((IDTypeItem) => (
                        <TableRow key={IDTypeItem.idType_id}>
                            <TableCell className="tData">
                            {IDTypeItem.idType_id}
                            </TableCell>
                            <TableCell className="tData">
                            {IDTypeItem.idType}
                            </TableCell>
                            <TableCell className="tData">
                            {IDTypeItem.description}
                            </TableCell>
                            {(editVisibility || deleteVisibility) && (
                            <TableCell>
                                <div className="btn-group">
                                {editVisibility && (
                                    <Button
                                    onClick={() =>
                                        this.toggleUpdateIdTypeModal(
                                        IDTypeItem.idType,
                                        IDTypeItem.description,
                                        IDTypeItem.idType_id,
                                        false
                                        )
                                    }
                                    className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                    title="Edit Details"
                                    />
                                )}
                                {deleteVisibility && (
                                    <Button
                                    onClick={() =>
                                        this.handleDeleteIdType(
                                        IDTypeItem.idType_id
                                        )
                                    }
                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                                    title="Delete"
                                    />
                                )}
                                </div>
                            </TableCell>
                            )}
                        </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                    <MaterialCustomTablePagination
                    totalCount = {totalIdTypes}
                    currentPage = {currentPage}
                    limit = {limit}
                    handlePageChange = {this.handlePageChange}
                    handleRowsPerPageChange = {this.handleRowsPerPageChange}
                    handlePageOffsetChange = {this.handlePageOffsetChange}
                    offset = {this.state.offset}
                    rowsPerPageOptions={[5, 10, 20]}
                    />
                </div>
                ) : (
                <div
                    className="text-center mb-5 mt-10"
                    style={{ height: "90vh" }}
                >
                    <label>No ID Types Available!</label>
                </div>
                )}
            </div>
            </ContentWrapper>

            {/* idType add and update model */}
            <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.showModal}
            onHide={() => this.handleSweetAlert(this.modalType, "showModal")}
            >
            {this.modalType === true ? (
                <div>
                <Modal.Header closeButton>
                    <Modal.Title>Create New ID Type </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Row className="my-5">
                        <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                        >
                        <Form.Label>ID Type *</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="ID Type"
                            value={this.state.name}
                            onChange={this.handleChange}
                        />
                        {this.validator.message(
                            "name",
                            this.state.name,
                            "required|alpha_space|max:50",
                            {
                            className: "text-danger",
                            messages: {
                                max: "The Id Type should not be greater than 50 characters.",
                                alpha_space:
                                "The Id Type must have valid characters.",
                                required: "The Id Type field is required.",
                            },
                            }
                        )}
                        </Form.Group>
                        <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                        >
                        <Form.Label>ID Type Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            placeholder="Description"
                            value={this.state.description}
                            onChange={this.handleChange}
                        />
                        {this.validator.message(
                            "description",
                            this.state.description,
                            "alpha_space|max:100",
                            {
                            className: "text-danger",
                            messages: {
                                max: "The Id Type description should not be greater than 100 characters.",
                                alpha_space:
                                "The Id Type description must have valid characters.",
                            },
                            }
                        )}
                        </Form.Group>
                    </Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.handleSubmit}
                    >
                    Save changes
                    </Button>
                    <Button
                    id="modal-close-button"
                    style={closeBtnStyle}
                    onClick={() =>
                        this.handleSweetAlert(this.modalType, "showModal")
                    }
                    >
                    Close
                    </Button>
                </Modal.Footer>
                </div>
            ) : (
                <div>
                <Modal.Header closeButton>
                    <Modal.Title>Update ID Type Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Row className="my-5">
                        <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                        >
                        <Form.Label>ID Type *</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="ID Type"
                            value={this.state.name ? this.state.name : ""}
                            onChange={this.handleChange}
                        />
                        {this.validator.message(
                            "name",
                            this.state.name,
                            "required|alpha_space|max:50",
                            {
                            className: "text-danger",
                            messages: {
                                max: "The ID Type should not be greater than 50 characters.",
                                alpha_space:
                                "The ID Type must have valid characters.",
                                required: "The ID Type is required.",
                            },
                            }
                        )}
                        </Form.Group>
                        <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                        >
                        <Form.Label>ID Type Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            placeholder="Description"
                            value={
                            this.state.description ? this.state.description : ""
                            }
                            onChange={this.handleChange}
                        />
                        {this.validator.message(
                            "description",
                            this.state.description,
                            "alpha_space|max:100",
                            {
                            className: "text-danger",
                            messages: {
                                max: "The ID Type description should not be greater than 100 characters.",
                                alpha_space:
                                "The ID Type description must have valid characters.",
                            },
                            }
                        )}
                        </Form.Group>
                    </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.handleEditSubmit}
                    >
                    Save changes
                    </Button>
                    <Button
                    className="modal-btn"
                    id="modal-close-button"
                    style={closeBtnStyle}
                    onClick={() =>
                        this.handleSweetAlert(this.modalType, "showModal")
                    }
                    >
                    Close
                    </Button>
                </Modal.Footer>
                </div>
            )}
            </Modal>
        </>
        );
    }
    }
