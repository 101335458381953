import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const AssessExamService = {
  getEnrolledNodeExams,
  getChildEnrolledNodeExams,
  getEnrolledNodeExamsForUser,
  getExamCount,
  getEnrolledNodeExamsWithoutNode
};

async function getEnrolledNodeExams(
  user_id,
  node_mid,
  limit,
  offset,
  sort_column,
  sort_direction,
  search_term,
  selectedGrades,
  selectedChapters,
  selectedsubjects,
  selectedAuthors,
  selectedYears,
  selectedCategories,
  selectedTerms,
  selectedDifficulties,
  selectedLanguages,
  selecCandStatus
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return fetch(
    `${config.oat_api_url}/candidate/exams/getEnrolledNodeExamsForStudentUser/${user_id}/${node_mid}?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selecCandStatus=${selecCandStatus}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getEnrolledNodeExamsWithoutNode(
  user_id,
  limit,
  offset,
  sort_column,
  sort_direction,
  search_term,
  selectedGrades,
  selectedChapters,
  selectedsubjects,
  selectedAuthors,
  selectedYears,
  selectedCategories,
  selectedTerms,
  selectedDifficulties,
  selectedLanguages,
  selecCandStatus
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return fetch(
    `${config.oat_api_url}/candidate/exams/getEnrolledExamsForStudentUser/${user_id}?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selecCandStatus=${selecCandStatus}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getChildEnrolledNodeExams(
  parent_user_id,
  child_user_id,
  node_mid,
  limit,
  offset,
  sort_column,
  sort_direction,
  search_term,
  selectedGrades,
  selectedChapters,
  selectedsubjects,
  selectedAuthors,
  selectedYears,
  selectedCategories,
  selectedTerms,
  selectedDifficulties,
  selectedLanguages, 
  selecCandStatus
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return fetch(
    `${config.oat_api_url}/candidate/exams/getEnrolledNodeExamsForParentUser/${parent_user_id}/${child_user_id}/${node_mid}?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selecCandStatus=${selecCandStatus}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getEnrolledNodeExamsForUser(
  user_id,
  node_mid,
  limit,
  offset,
  sort_column,
  sort_direction,
  search_term,
  selectedGrades,
  selectedChapters,
  selectedsubjects,
  selectedAuthors,
  selectedYears,
  selectedCategories,
  selectedTerms,
  selectedDifficulties,
  selectedLanguages,
  added_by,
  selecCandStatus
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return fetch(
    `${config.oat_api_url}/candidate/exams/getEnrolledNodeExamsForAdminUser/${user_id}/${node_mid}?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&added_by=${added_by}&selecCandStatus=${selecCandStatus}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getExamCount() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return fetch(
    `${config.oat_api_url}/candidate/exams/getAllExamsCount`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

