import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import ContentWrapper from "../../../../components/common/ContentWrapper.js";
import Card from "../../../../components/common/Card.js";
import Col from "react-bootstrap/Col";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardBody from "../../../../components/common/CardBody.js";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from "../../../../components/common/form/FormGroup.js";
import Row from "react-bootstrap/Row";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox
} from "@mui/material";
import MaterialCustomTablePagination from "../../../../components/common/MaterialCustomTablePagination.js";
import debounce from "lodash.debounce";
import { history } from "../../../../helper/history.js";
import { PaperService } from "../../../../service/manage/paper/paper_service.js";
import {
  sweetAlertEnroll,
  sweetStatusAlert
} from "../../../../components/common/SweetAlert.js";
import PageSpinner from "../../../../components/common/spinner/PageSpinner.js";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { StudentService } from "../../../../service/manage/registration/student.service.js";
import { EnrolledPaperService } from "../../../../service/manage/paper/enrolled_paper.service.js";
import { Button } from "react-bootstrap";
import Chip from "@mui/material/Chip";
import { assessmentService } from "../../../../service/assessment/assessmentService.js";
import { ASSESSMENT_FILTERS } from "../../../../helper/constants.js";
import { CreditService } from "../../../../service/manage/subscription/credit.service.js";
import TimeZoneHandler from "../../../../handlers/TimeZoneHandler.js";
import config from "../../../../helper/config.js";
import { customerConstants } from "../../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class PaperList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: null,
      userId: "",
      node_mid: null,
      papers: [],
      enrolledPapers: [],
      limit: 10,
      offset: 0,
      search_term: "",
      breadcrumb2: null,
      nodeTitle: null,
      totalPapers: 25,
      currentPage: 0,
      sortColumn: "assess_name",
      sortDirection: "desc",
      selectedStudent: "",
      studentlist: [],
      isTableDataLoading: true,
      isSpinnerLoading: false,
      isDropHidden: false,
      assessment_name: "",
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      viewVisibility: false,
      create_user_id: "",
      create_user_table_id: "",
      filterValues: [],
      filters: [],
      selectedFilters: [],
      selectedGrades: null,
      selectedChapters: null,
      selectedsubjects: null,
      selectedAuthors: null,
      selectedYears: null,
      selectedCategories: null,
      selectedTerms: null,
      selectedDifficulties: null,
      selectedLanguages: null,
      allCategories: [],
      allLanguages: [],
      allSubjects: [],
      allAuthors: [],
      allYears: [],
      allDifficulties: [],
      allGrades: [],
      allTerms: [],
      allChapters: [],
      showInsertModal: false,
      credits: [],
      credit_id: "",
      selectedCredit: null,
      isCreditNotAvailable: false,
      isModified: false,
      asses_id: null,
      assess_name: null,
      grade_id: "",
      is_level_price_loading: false,
      creditsLoading: false,
      selectedPaper: false
    };

    this.debouncedHandleSearch = debounce(this.callAfterSubmit, 2000);
  }

  componentDidMount() {
    const {
      lg_user_id,
      lg_user_table_id,
      flg_list_active,
      privilage_view,
      privilege_add,
      privilege_edit,
      nodeTitle,
      breadcrumb2,
      privilege_delete,
    } = this.props.state;

    this.getVisibilityCheck(
      privilage_view,
      privilege_add,
      privilege_delete,
      privilege_edit
    );
    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle: nodeTitle,
    });
    let userType = sessionStorage.getItem("userType");
    this.setState({ userType });

    if (userType === "admin") {
      if (flg_list_active === 1) {
        this.setState({ isDropHidden: true });
      }
      this.fetchAllpapers();
      this.fetchallstudent();
    }

    if (userType === "student") {
      if (flg_list_active === null) {
        StudentService.getCreateUserDetail(
          lg_user_id,
          lg_user_table_id,
          userType
        ).then((response) => {
          const create_user_id = response.data.user_detail[0].create_user_id;
          const create_user_table_id =
            response.data.user_detail[0].create_user_table_id;
          this.setState({
            create_user_id: create_user_id,
            create_user_table_id: create_user_table_id,
            isDropHidden: false,
          });
        });
      }
      this.fetchAllpapers();
      this.fetchAllEnrolledChildPapers();
    }

    if (userType === "parent") {
      if (flg_list_active === 1) {
        this.setState({ isDropHidden: true });
      }
      this.fetchAllpapers();
      this.fetchallstudent();
    }
    this.fetchAllAssessmentSettings();
  }

  getVisibilityCheck(
    privilage_view,
    privilege_add,
    privilege_delete,
    privilege_edit
  ) {
    if (privilege_add === 1) {
      this.setState({
        addVisibility: true,
      });
    } else if (privilege_edit === 1) {
      this.setState({
        editVisibility: true,
      });
    } else if (privilage_view === 1) {
      this.setState({
        viewVisibility: true,
      });
    } else if (privilege_delete === 1) {
      this.setState({
        deleteVisibility: true,
      });
    }
  }

  fetchAllAssessmentSettings() {
    this.setState({
      isLoading: true
    });
  
    const fetchAssessmentMetaData = assessmentService.getAllAssessmentMetaData();

    Promise.all([
      fetchAssessmentMetaData
    ])
      .then((response) => {
        const [
          assessmentMetaDataResponse
        ] = response;

        this.setState({
          allCategories: assessmentMetaDataResponse.data.find(item => 'categories' in item).categories,
          allLanguages: assessmentMetaDataResponse.data.find(item => 'languages' in item).languages,
          allSubjects: assessmentMetaDataResponse.data.find(item => 'subjects' in item).subjects,
          allAuthors: assessmentMetaDataResponse.data.find(item => 'authors' in item).authors,
          allYears: assessmentMetaDataResponse.data.find(item => 'years' in item).years,
          allDifficulties: assessmentMetaDataResponse.data.find(item => 'difficulty_types' in item).difficulty_types,
          allGrades: assessmentMetaDataResponse.data.find(item => 'grades' in item).grades,
          allTerms: assessmentMetaDataResponse.data.find(item => 'terms' in item).terms,
          allChapters: assessmentMetaDataResponse.data.find(item => 'chapters' in item).chapters,
          isLoading: false
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  callAfterSubmit() {
    this.fetchAllpapers();
    this.fetchEnrollPaperData();
    // this.fetchAllAssessmentSettings();
  }

  fetchAllpapers = () => {
    this.setState({ isTableDataLoading: true });
    const {
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedDifficulties,
      selectedGrades,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects,
    } = this.state;
    let node_mid = this.props.state.mid;

    PaperService.getfilterdAllAssesments(
      node_mid,
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedGrades,
      selectedDifficulties,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects
    )
      .then((response) => {
        if (response.success) {
          this.setState({
            papers: response.data.papers,
            totalPapers: response.data.count,
            isTableDataLoading: false,
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching papers. Please refresh the screen!",
            "Error"
          );
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching papers. Please refresh the screen!",
          "Eerror"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  handleViewClick = (e, paper) => {
    e.preventDefault();
    this.setState({
      selectedPaper: paper,  
      showDetailModal: true,  
    });
  };

  toggleDetailModal = () => {
    this.setState((prevState) => ({
      showDetailModal: !prevState.showDetailModal,
    }));
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleSortChange = (sortColumn) => {
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.callAfterSubmit();
      }
    );
  };

  fetchallstudent = () => {
    const { mid, lg_user_id, lg_user_table_id, form_list_id } = this.props.state;

    this.setState({ isTableDataLoading: true });

    let userType = sessionStorage.getItem("userType");
    let user_id;
    let user_table_id;

    if (userType === "parent") {
      user_id = sessionStorage.getItem("parent_group_id");
      user_table_id = sessionStorage.getItem("parent_group_user_table_id");
    } else {
      user_id = lg_user_id;
      user_table_id = lg_user_table_id;
    }
    
    StudentService.getAllStudentsForParent(
      mid,
      form_list_id,
      user_id,
      user_table_id,
      userType
    )
      .then((response) => {
        if (response.success) {
          this.setState({ studentlist: response.data.students });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching students. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching students. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  toggleEnrollModal = (asses_id, assess_name) => {
    const { userType } = this.state;
    const session_id = sessionStorage.getItem("manage_user_id");
    const handleEnrollment = () => {
      this.setState({ isSpinnerLoading: true }, () => {
        if (userType === "student") {
          StudentService.getreference(session_id).then((response) => {
            if (
              response.data.reference_id &&
              response.data.has_parent
            ) {
              this.handleSubmit(asses_id, assess_name);
            } else {
              this.handleSubmitStudent(asses_id, assess_name);
            }
          });
        } else {
          this.handleSubmit(asses_id, assess_name);
        }
      });
    };


  const confirmMessage = userType === "student" ?
    "Are you sure you want to enroll in this exam?" :
    "Do you want to enroll your child?";

  sweetAlertEnroll("Enroll Confirmation", confirmMessage).then((enrollConfirmed) => {
    if (enrollConfirmed) {
      this.setState({ showInsertModal: false });
      handleEnrollment();
    }
  });
 
  };

  handleSubmitStudent = async (assessment_id, assess_name) => {
    const { selectedStudent, userType, selectedCredit } = this.state;

    const UserId = sessionStorage.getItem("manage_user_id");

    var enrolledby = "";
    var credit_id = "";

    const attempt_no = this.state.papers.find(
      (assessment) => assessment.assessment_id === assessment_id
    );

    if (userType === "student") {
      enrolledby = "Student";
    } else if (userType === "admin") {
      enrolledby = "Admin";
    } else {
      enrolledby = "Parent";
    }

    let student = this.state.studentlist.find(
      (student) => student.user_id === parseInt(selectedStudent)
    );
    if (selectedCredit) {
      credit_id = selectedCredit.credit_id;
    }

    const enrolled_paper = {
      node_mid: !!this.props.state.mid ? this.props.state.mid : null,
      create_user_id:
        userType === "admin" || userType === "student"
          ? this.state.create_user_id
          : this.props.state.lg_user_id,
      create_user_table_id:
        userType === "parent"
          ? this.props.state.lg_user_table_id
          : this.state.create_user_table_id,
      enroll_user_id:
        userType === "student" ? UserId : parseInt(selectedStudent),
      assessment_id: assessment_id,
      assess_name: assess_name,
      paper_status: 0,
      enrolled_by: enrolledby,
      enroll_user_table_id: userType === "student" ? 2 : student.user_table_id,
      user_table_id: userType === "student" ? 2 : student.user_table_id,
      attempt_no: attempt_no.attempt_no,
      userType: userType,
      credit_id: credit_id,
    };

    EnrolledPaperService.selfenrollPaperToNode(JSON.stringify(enrolled_paper))
      .then((response) => {
        if (response.success) {
          sweetStatusAlert(
            "Success!",
            "You have successfully enrolled to past paper " +
              enrolled_paper.assess_name +
              "!",
            "success"
          );

          this.callAfterSubmit();
          this.setState({
            isSpinnerLoading: false,
            selectedCredit: null,
            isCreditNotAvailable: false,
            isModified: false,
            asses_id: null,
            assess_name: null,
            showInsertModal: false,
          });
        } else {
          sweetStatusAlert(
            "Error!",
            "Failed to enroll. You may not have enough credits. Please check your available credits!.",
            "error"
          );
          this.setState({ isSpinnerLoading: false });
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Error!",
          "An error occurred while enrolling the student. Please try again later.",
          "error"
        );
        this.setState({ isSpinnerLoading: false });
      });
  };
  handleStudentSelect = (event) => {
    if (!event.target.value) {
      this.setState({ selectedStudent: "" });
      return;
    }
    let student = this.state.studentlist.find(
      (student) => student.user_id === parseInt(event.target.value)
    );
    this.setState({ isTableDataLoading: true });
    const { flg_list_active } = this.props.state;
    if (this.state.userType === "admin") {
      if (flg_list_active === 1) {
        StudentService.getCreateUserDetail(
          student.user_id,
          student.user_table_id,
          this.state.userType
        ).then((response) => {
          const create_user_id = response.data.user_detail[0].create_user_id;
          const create_user_table_id =
            response.data.user_detail[0].create_user_table_id;
          this.setState({
            create_user_id: create_user_id,
            create_user_table_id: create_user_table_id,
          });
        });
      }
    }
    const selectedStudent = student.user_id;
    if (selectedStudent) {
      this.setState({ selectedStudent }, () => {
        this.fetchEnrollPaperData();
      });
    }
  };

  handleSubmit = async (assessment_id, assess_name) => {
    const { selectedStudent, userType, selectedCredit } = this.state;
   
    const UserId = sessionStorage.getItem("manage_user_id");

    var enrolledby = "";
    var sentTo = "";
    var msg = "";
    var credit_id = "";

    const attempt_no = this.state.papers.find(
      (assessment) => assessment.assessment_id === assessment_id
    );

    if (userType === "student") {
      enrolledby = "Student";
    } else if (userType === "admin") {
      enrolledby = "Admin";
    } else {
      enrolledby = "Parent";
    }

    if (userType === "student") {
      sentTo = "your parent";
      msg = "Please wait until your parent approves";
    } else if (userType === "admin") {
      sentTo = "Parent";
      msg = "Please wait until the parent approves";
    } else {
      sentTo = "you";
      msg = "Please check your mail inbox or spam/junk";
    }
    let student = this.state.studentlist.find(
      (student) => student.user_id === parseInt(selectedStudent)
    );
    if (selectedCredit) {
      credit_id = selectedCredit.credit_id;
    }

    const enrolled_paper = {
      node_mid: !!this.props.state.mid ? this.props.state.mid : null,
      create_user_id:
        userType === "admin" || userType === "student"
          ? this.state.create_user_id
          : this.props.state.lg_user_id,
      create_user_table_id:
        userType === "parent"
          ? this.props.state.lg_user_table_id
          : this.state.create_user_table_id,
      enroll_user_id:
        userType === "student" ? UserId : parseInt(selectedStudent),
      assessment_id: assessment_id,
      assess_name: assess_name,
      paper_status: 0,
      enrolled_by: enrolledby,
      enroll_user_table_id: userType === "student" ? 2 : student.user_table_id,
      user_table_id: userType === "student" ? 2 : student.user_table_id,
      attempt_no: attempt_no.attempt_no,
      userType: userType,
      credit_id: credit_id,
    };

    EnrolledPaperService.addEnrolledPaperToNode(JSON.stringify(enrolled_paper))
      .then((response) => {
        if (response.success) {
          let parentEmail = response?.data?.credit_owner_parent_email
          sweetStatusAlert(
            "Success!",
            "Enrollment invitation mail sent to " + sentTo + " " + parentEmail + ". " + msg + "!",
            "success"
          );

          this.callAfterSubmit();
          this.setState({
            isSpinnerLoading: false,
            selectedCredit: null,
            isCreditNotAvailable: false,
            isModified: false,
            asses_id: null,
            assess_name: null,
            showInsertModal: false,
          });
        } else {
          sweetStatusAlert(
            "Error!",
            "Failed to enroll. Please try again later.",
            "error"
          );
          this.setState({ isSpinnerLoading: false });
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Error!",
          "An error occurred while enrolling the student. Please try again later.",
          "error"
        );
        this.setState({ isSpinnerLoading: false });
      });
  };

  fetchEnrollPaperData = () => {
    const { selectedStudent } = this.state;
    const {
      mid,
      owner_restriction,
      lg_user_id,
      lg_user_table_id,
      user_list_table_id,
      user_list_restriction,
    } = this.props.state;

    let create_user_id =
      owner_restriction === "True" && lg_user_id ? lg_user_id : "null";
    let create_user_table_id =
      owner_restriction === "True" && lg_user_table_id
        ? lg_user_table_id
        : "null";
    let enroll_user_table_id =
      user_list_restriction === "True" && user_list_table_id
        ? user_list_table_id
        : "null";
    // let enroll_user_id = (user_list_restriction === "True" && selectedStudent) ? selectedStudent : 'null'
    let enroll_user_id =
      user_list_restriction === "True" && selectedStudent
        ? selectedStudent
        : selectedStudent;

    EnrolledPaperService.getAllEnrolledPapers(
      mid,
      create_user_id,
      create_user_table_id,
      enroll_user_id,
      enroll_user_table_id,
      this.state.userType
    )
      .then((res) => {
        this.setState({ enrolledPapers: res.data.enrolledPapers });
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  fetchAllEnrolledChildPapers = () => {
    this.setState({ isTableDataLoading: true });
    const { lg_user_id } = this.props.state;
    this.setState({ selectedStudent: lg_user_id }, () => {
      this.fetchEnrollPaperData();
    });
  };

  onFilterInput = (v, r) => {
    let categoryOptions;
    let languageOptions;
    let subjectOptions;
    let authorOptions;
    let gradeOptions;
    let yearOptions;
    let termOptions;
    let chapterOptions;
    let difficultyOptions;
    let selectedFilters = [];
    this.setState({
      filters: v,
      filterValues: [],
    });

    if (r === "removeOption") {
      this.setState(
        {
          selectedAuthors: null,
          selectedsubjects: null,
          selectedChapters: null,
          selectedTerms: null,
          selectedCategories: null,
          selectedYears: null,
          selectedLanguages: null,
          selectedDifficulties: null,
          selectedGrades: null,
        },
        () => {
          this.fetchAllpapers();
        }
      );
    }
    if (v.includes("Category")) {
      categoryOptions = this.state.allCategories?.map((val) => {
        return {
          filter: "Category",
          name: val.name,
          category_id: val.category_id,
        };
      });
      selectedFilters = [...selectedFilters, ...categoryOptions];
    }
    if (v.includes("Language")) {
      languageOptions = this.state.allLanguages?.map((val) => {
        return {
          filter: "Language",
          name: val.language,
          language_id: val.language_id,
        };
      });
      selectedFilters = [...selectedFilters, ...languageOptions];
    }
    if (v.includes("Subject")) {
      subjectOptions = this.state.allSubjects?.map((val) => {
        return {
          filter: "Subject",
          name: val.subject,
          subject_id: val.subject_id,
        };
      });
      selectedFilters = [...selectedFilters, ...subjectOptions];
    }
    if (v.includes("Author")) {
      authorOptions = this.state.allAuthors?.map((val) => {
        return {
          filter: "Author",
          name: val.author,
          author_id: val.author_id,
        };
      });
      selectedFilters = [...selectedFilters, ...authorOptions];
    }
    if (v.includes("Year")) {
      yearOptions = this.state.allYears?.map((val) => {
        return {
          filter: "Year",
          name: val.year,
          year_id: val.year_id,
        };
      });
      selectedFilters = [...selectedFilters, ...yearOptions];
    }
    if (v.includes("Difficulty")) {
      difficultyOptions = this.state.allDifficulties?.map((val) => {
        return {
          filter: "Difficulty",
          name: val.difficulty_type,
          difficulty_id: val.difficulty_id,
        };
      });
      selectedFilters = [...selectedFilters, ...difficultyOptions];
    }
    if (v.includes("Grade")) {
      gradeOptions = this.state.allGrades?.map((val) => {
        return {
          filter: "Grade",
          name: val.grade,
          grade_id: val.grade_id,
        };
      });
      selectedFilters = [...selectedFilters, ...gradeOptions];
    }
    if (v.includes("Term")) {
      termOptions = this.state.allTerms?.map((val) => {
        return {
          filter: "Term",
          name: val.term,
          term_id: val.term_id,
        };
      });
      selectedFilters = [...selectedFilters, ...termOptions];
    }
    if (v.includes("Chapter")) {
      chapterOptions = this.state.allChapters?.map((val) => {
        return {
          filter: "Chapter",
          name: val.chapter,
          chapter_id: val.chapter_id,
        };
      });
      selectedFilters = [...selectedFilters, ...chapterOptions];
    }
    this.setState({
      selectedFilters: selectedFilters,
    });
  };

  onSelectFilter = (e) => {
    let selectedGrades = [];
    let selectedChapters = [];
    let selectedsubjects = [];
    let selectedAuthors = [];
    let selectedYears = [];
    let selectedCategories = [];
    let selectedTerms = [];
    let selectedDifficulties = [];
    let selectedLanguages = [];

    e.map((val) => {
      if (val.filter === "Category") {
        selectedCategories.push(val?.category_id);
      }
      if (val.filter === "Difficulty") {
        selectedDifficulties.push(val?.difficulty_id);
      }
      if (val.filter === "Language") {
        selectedLanguages.push(val?.language_id);
      }
      if (val.filter === "Subject") {
        selectedsubjects.push(val?.subject_id);
      }
      if (val.filter === "Author") {
        selectedAuthors.push(val?.author_id);
      }
      if (val.filter === "Year") {
        selectedYears.push(val?.year_id);
      }
      if (val.filter === "Term") {
        selectedTerms.push(val?.term_id);
      }
      if (val.filter === "Chapter") {
        selectedChapters.push(val?.chapter_id);
      }
      if (val.filter === "Grade") {
        selectedGrades.push(val?.grade_id);
      }
    });

    this.setState(
      {
        filterValues: e,
        selectedAuthors: selectedAuthors.length > 0 ? selectedAuthors : null,
        selectedsubjects: selectedsubjects.length > 0 ? selectedsubjects : null,
        selectedChapters: selectedChapters.length > 0 ? selectedChapters : null,
        selectedTerms: selectedTerms.length > 0 ? selectedTerms : null,
        selectedCategories:
          selectedCategories.length > 0 ? selectedCategories : null,
        selectedYears: selectedYears.length > 0 ? selectedYears : null,
        selectedLanguages:
          selectedLanguages.length > 0 ? selectedLanguages : null,
        selectedDifficulties:
          selectedDifficulties.length > 0 ? selectedDifficulties : null,
        selectedGrades: selectedGrades.length > 0 ? selectedGrades : null,
      },
      () => {
        this.fetchAllpapers();
      }
    );
  };

  toggleAddEnrollModal = (asses_id, assess_name, grade_id) => {
    const { userType } = this.state;

    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        asses_id: asses_id,
        assess_name: assess_name,
        grade_id: grade_id,
        selectedCredit: null,
        isCreditNotAvailable: false,
      }),
      () => {
        if (this.state.showInsertModal) {
          if (userType === "student") {
            this.getAllEnrollCredits();
          } else if (userType === "parent") {
            this.getAllEnrollCreditsForParent();
          }
        } else {
          this.setState({ selectedCredit: null, grade_id: "" });
        }
      }
    );
  };

  handleChangeCredit = (event) => {
    const { name, value } = event.target;
    const { credits } = this.state;

    const selectedCredit = credits.find(
      (credit) => credit.credit_id === parseInt(value)
    );

    this.setState({ selectedCredit: selectedCredit, isModified: true }, () => {
      if (this.state.selectedCredit) {
        if (this.state.selectedCredit.available_credits <= 0) {
          this.setState({ isCreditNotAvailable: true });
        } else {
          this.setState({ isCreditNotAvailable: false });
        }
      } else {
        this.setState({ selectedCredit: null });
      }
    });
  };

  getAllEnrollCredits() {
    this.setState({
      is_level_price_loading: true,
      creditsLoading: true,
    });

    let mid = 100;
    const { grade_id } = this.state;

    const UserId = sessionStorage.getItem("manage_user_id");
    CreditService.getAllCreditsForPaperEnrollment(mid, UserId, grade_id)
      .then((res) => {
        if (res.success) {
          this.setState({ credits: res.data.credits });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Credits. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          is_level_price_loading: false,
          creditsLoading: false,
        });
      });
  }

  getAllEnrollCreditsForParent() {
    this.setState({
      is_level_price_loading: true,
      creditsLoading: true,
    });

    // const { mid } = this.props.state;
    let mid = 100;
    const { grade_id } = this.state;

    const UserId = this.state.selectedStudent;
    CreditService.getAllParentCreditsForPaperEnrollment(mid, UserId, grade_id)
      .then((res) => {
        if (res.success) {
          this.setState({ credits: res.data.credits });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Credits. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          is_level_price_loading: false,
          creditsLoading: false,
        });
      });
  }

  redirectToSubscriptions() {
    const state = {
      privilege_edit: 1,
      privilege_add: 1,
      privilege_delete: 1,
      nodeTitle: "Subscriptions",
      mid: 100,
      main_table_id: 10,
      owner_restriction: null,
      lg_user_id: sessionStorage.getItem("manage_user_id"),
      lg_user_table_id: sessionStorage.getItem("table_log_id"),
      user_list_restriction: null,
    };

    history.push({
      pathname: "/subscriptions",
      state: { ...state },
    });
  }

  render() {
    const {
      papers,
      totalPapers,
      selectedStudent,
      studentlist,
      isSpinnerLoading,
      creditsLoading,
      isTableDataLoading,
      enrolledPapers,
      limit,
      currentPage,
      editVisibility,
      addVisibility,
      isDropHidden,
      userType,
      credits,
      isCreditNotAvailable,
      selectedCredit,
      offset
    } = this.state;
    const path = history.location.pathname;

    return (
      <>
        <ContentWrapper disabled={path === "/manage-search"}>
          <Card>
            <CardHeader>
              {isDropHidden ? (
                <div className="col-md-12 d-flex align-items-center dropdown px-0">
                  <div className="dropdown mb-2">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Select
                        aria-label="Select the student"
                        name="student_id"
                        value={selectedStudent}
                        onChange={this.handleStudentSelect}
                      >
                        <option
                          value=""
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          Select Student
                        </option>
                        {studentlist.map((student) => (
                          <option key={student.user_id} value={student.user_id}>
                            {student.data_concat}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-4 px-0">
                    {(selectedStudent || !isDropHidden) && (
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleSearch}
                          isInvalid={this.state.searchError}
                          className="col-md-8"
                        />
                        {this.state.searchError && (
                          <Form.Control.Feedback type="invalid">
                            Invalid input
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    )}
                  </div>
                </div>
              ) : (
                <div className="col-md-12 d-flex align-items-center dropdown px-0">
                  <Form.Group
                    controlId="exampleForm.ControlInput1"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      onChange={this.handleSearch}
                      isInvalid={this.state.searchError}
                      className="col-md-8"
                    />
                    {this.state.searchError && (
                      <Form.Control.Feedback type="invalid">
                        Invalid input
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <div className="col-md-4 px-0">
                    <FormGroup labelName="Filter By">
                      <Autocomplete
                        multiple
                        id="checkboxes-filter"
                        options={ASSESSMENT_FILTERS}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        onChange={(e, v, r) => this.onFilterInput(v, r)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        className="col-lg-8 col-md-9 p-0"
                        renderInput={(params) => (
                          <TextField {...params} label="Select Filter" />
                        )}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-4 px-0">
                    {this.state.filters.length > 0 && (
                      <FormGroup labelName="">
                        <Autocomplete
                          multiple
                          id="grouped-demo"
                          className="col-lg-8 col-md-9 p-0"
                          options={this.state.selectedFilters}
                          groupBy={(option) => option.filter}
                          onChange={(e, v) => this.onSelectFilter(v)}
                          getOptionLabel={(option) => option.name}
                          sx={{ width: 300 }}
                          value={this.state.filterValues}
                          renderInput={(params) => (
                            <TextField {...params} label="Filters" />
                          )}
                        />
                      </FormGroup>
                    )}
                  </div>
                </div>
              )}
            </CardHeader>
            {isDropHidden ? (
              <CardHeader>
                <div className="col-md-6 px-0">
                  {(selectedStudent || !isDropHidden) && (
                    <FormGroup labelName="Filter By">
                      <Autocomplete
                        multiple
                        id="checkboxes-filter"
                        options={ASSESSMENT_FILTERS}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        onChange={(e, v, r) => this.onFilterInput(v, r)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        className="col-lg-8 col-md-9 p-0"
                        renderInput={(params) => (
                          <TextField {...params} label="Select Filter" />
                        )}
                      />
                    </FormGroup>
                  )}
                </div>
                {(selectedStudent || !isDropHidden) && (
                  <div className="col-md-6 px-0">
                    {this.state.filters.length > 0 && (
                      <FormGroup labelName="Filters11">
                        <Autocomplete
                          multiple
                          id="grouped-demo"
                          className="col-lg-8 col-md-9 p-0"
                          options={this.state.selectedFilters}
                          groupBy={(option) => option.filter}
                          onChange={(e, v) => this.onSelectFilter(v)}
                          getOptionLabel={(option) => option.name}
                          sx={{ width: 300 }}
                          value={this.state.filterValues}
                          renderInput={(params) => (
                            <TextField {...params} label="Filters12" />
                          )}
                        />
                      </FormGroup>
                    )}
                  </div>
                )}
              </CardHeader>
            ) : (
              <></>
            )}

            <CardBody>
              {isSpinnerLoading && (
                <PageSpinner id="show" text="Loading, Please wait!" />
              )}
              {
                isTableDataLoading ? (
                  <PageSpinner id="show" text="Loading, Please wait!" />
                ) : selectedStudent || !isDropHidden ? (
                  <div
                    style={{
                      overflowY: "auto",
                      overflowX: "auto",
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSortChange("assess_name")}
                          >
                            Paper Title{" "}
                            {this.state.sortColumn === "assess_name" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? "⬇️"
                                  : "⬆️"}
                              </span>
                            )}
                          </TableCell>
                          {userType === "admin" && <TableCell className="thead">
                            More Details
                          </TableCell>}
                          {(editVisibility || addVisibility) && (
                            <TableCell className="thead">
                              Enrollment Status
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {papers.map((paper) => {
                          const enrolledExam = enrolledPapers.find((exam) => {
                            if (userType === "student") {
                              return exam.assessment_id === paper.assessment_id;
                            }
                            return (
                              exam.assessment_id === paper.assessment_id &&
                              exam.enroll_user_id ===
                                parseInt(this.state.selectedStudent)
                            );
                          });
                          return (
                            <TableRow key={paper.id}>
                              {/* <TableCell className="tData">
                                {paper.node_mid}
                              </TableCell>
                              <TableCell className="tData">
                                {paper.assessment_id}
                              </TableCell> */}
                              <TableCell className="tData">
                                {paper.assess_name}
                              </TableCell>
                              {userType === "admin" && <TableCell className="tData">
                                <Button
                                  onClick={(e) => this.handleViewClick(e, paper)}
                                  className="btn btn-icon btn-primary btn-sm m-1 view-assement-btn flaticon-eye-icon"
                                  title="View Paper Details"
                                />
                              </TableCell>}
                              {userType === "admin" &&
                                (editVisibility || addVisibility) && (
                                  <TableCell className="stu-tData">
                                    {enrolledExam ? (
                                      enrolledExam.paper_status === 0 ? (
                                        <Chip
                                          label="Pending"
                                          color="primary"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      ) : enrolledExam.paper_status === 1? (
                                        <Chip
                                          label="Approved"
                                          color="success"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      ) : enrolledExam.paper_status === 2 ? (
                                        <Chip
                                          label="Rejected"
                                          color="error"
                                          variant="outlined"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      ) : (
                                        <Chip
                                          label="Not enroll"
                                          color="success"
                                          variant="outlined"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      )
                                    ) : (
                                      <Button
                                        className="stu-variant-class"
                                        id="modal-close-button"
                                        onClick={() =>
                                          this.toggleEnrollModal(
                                            paper.assessment_id,
                                            paper.assess_name
                                          )
                                        }
                                      >
                                        Enroll
                                      </Button>
                                    )}
                                  </TableCell>
                                )}
                              {(userType === "student" ||
                                userType === "parent") &&
                                (editVisibility || addVisibility) && (
                                  <TableCell className="stu-tData">
                                    {enrolledExam ? (
                                      enrolledExam.paper_status === 0 ? (
                                        <Chip
                                          label="Pending"
                                          color="primary"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      ) : enrolledExam.paper_status === 1 ? (
                                        <Chip
                                          label="Approved"
                                          color="success"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      ) : enrolledExam.paper_status === 2 ? (
                                        <Chip
                                          label="Rejected"
                                          color="error"
                                          variant="outlined"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      ) : (
                                        <Chip
                                          label="Not enroll"
                                          color="success"
                                          variant="outlined"
                                          style={{
                                            fontFamily: "Poppins, sans-serif",
                                          }}
                                        />
                                      )
                                    ) : (
                                      <Button
                                        className="stu-variant-class"
                                        id="modal-close-button"
                                        onClick={() =>
                                          this.toggleAddEnrollModal(
                                            paper.assessment_id,
                                            paper.assess_name,
                                            paper.grade_id
                                          )
                                        }
                                      >
                                        Enroll
                                      </Button>
                                    )}
                                  </TableCell>
                                )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <MaterialCustomTablePagination
                      totalCount = {parseInt(totalPapers)}
                      currentPage = {currentPage}
                      limit = {limit}
                      handlePageChange = {this.handlePageChange}
                      handleRowsPerPageChange = {this.handleRowsPerPageChange}
                      handlePageOffsetChange = {this.handlePageOffsetChange}
                      rowsPerPageOptions={[5, 10, 20]}
                      offset = {offset}
                    />
                  </div>
                ) : null /* Render nothing if no student is selected */
              }
            </CardBody>
          </Card>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.showInsertModal}
            onHide={() => this.setState({ showInsertModal: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select The Credit To Enroll</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Available Credit Packages*</Form.Label>
                    {creditsLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "25vh !important",
                        }}
                      >
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <div className="pt-2">
                        {credits.length > 0
                          ? credits.map((credit) => (
                              <div
                                className="border-bottom border-top py-4"
                                key={credit.credit_id}
                              >
                                <Form.Check
                                  key={credit.credit_id}
                                  type="radio"
                                  id={`credit-radio-${credit.credit_id}`}
                                  name="credits"
                                  label={`${credit.level_price_name} - ${credit.type_name}`}
                                  value={credit.credit_id}
                                  onChange={(e) => this.handleChangeCredit(e)}
                                  checked={
                                    this.state.selectedCredit &&
                                    this.state.selectedCredit.credit_id ===
                                      credit.credit_id
                                  }
                                  style={{ marginBottom: "10px" }}
                                />
                                <span>
                                  Available Credits -{" "}
                                  <b>{credit.available_credits}</b>
                                </span>
                                <br />
                                <span>
                                  Expiration Date -{" "}
                                  <b>
                                    {TimeZoneHandler.convertTime(
                                      credit.expiration_date
                                    )}
                                  </b>
                                </span>
                              </div>
                            ))
                          : !this.state.is_level_price_loading && (
                              <div>No credit levels available</div>
                            )}
                      </div>
                    )}

                    <div style={{ color: "red" }}>
                      {/* {this.validator.message('level', level_id, 'required')} */}
                    </div>
                  </Form.Group>
                  {this.state.isCreditNotAvailable ? (
                    <div
                      className="col-md-6"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                        color: "red",
                      }}
                    >
                      <p>No Credits Available!</p>
                      <p>
                        Please subscribe to a suitable credit package to enroll
                        for this paper.
                      </p>
                      <br></br>
                      <Button
                        className="modal-btn"
                        variant="primary"
                        onClick={() => this.redirectToSubscriptions()}
                      >
                        Subscriptions
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </Row>

                <Modal.Footer>
                  <Button
                    className="modal-btn"
                    id="modal-close-button"
                    style={closeBtnStyle}
                    onClick={() => this.setState({ showInsertModal: false })}
                  >
                    Close
                  </Button>

                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    disabled={isCreditNotAvailable || selectedCredit == null}
                    onClick={() =>
                      this.toggleEnrollModal(
                        this.state.asses_id,
                        this.state.assess_name
                      )
                    }
                  >
                    Enroll
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal 
            size="lg"
            show={this.state.showDetailModal}
            onHide={this.toggleDetailModal}
          >
          <Modal.Header>
            <h5 className="modal-title">{this.state.selectedPaper?.assess_name}</h5>
            <Button variant="secondary" onClick={this.toggleDetailModal}>
              Close
            </Button>
          </Modal.Header>
          {this.state.selectedPaper && (
            <Modal.Body>
              <div className="form" id="kt_form">
                {this.state.selectedPaper?.grade_id !== null && 
                  <>
                    <div className="row">
                      <h6 className="font-weight-bolder mb-3 col-md-4">
                        Grade :
                      </h6>
                      <div className="text-dark-50 line-height-lg col-md-5">
                        <div className="inline-flex full-width align-center">
                          {this.getPaperGrade(this.state.selectedPaper?.grade_id)}
                        </div>
                      </div>
                    </div>
                  </>
                }
                {this.state.selectedPaper?.category_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Category :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperCategory(this.state.selectedPaper?.category_id)}
                      </div>
                    </div>
                  </div>
                </>
              }     
              {this.state.selectedPaper?.language_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Language :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperLanguage(this.state.selectedPaper?.language_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.difficulty_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Difficulty :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperDifficulty(this.state.selectedPaper?.difficulty_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.subject_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Subject :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperSubject(this.state.selectedPaper?.subject_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.chapter_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Chapter/Module/Unit :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperChapter(this.state.selectedPaper?.chapter_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.author_id !== null &&
                <>
                  <div className="separator separator-dashed my-5"></div> 
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Author :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperAuthor(this.state.selectedPaper?.author_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.year_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Year :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperYear(this.state.selectedPaper?.year_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedPaper?.term_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Term/Semester :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperTerm(this.state.selectedPaper?.term_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              </div>
            </Modal.Body>
          )}
        </Modal>
        </ContentWrapper>
      </>
    );
  }
}
