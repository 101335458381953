import appUrls from "./appUrls";

export default {
    oat_api_url: appUrls.OAT_API,
    chat_api_url: appUrls.CHAT_API,
    chat_socket_ep: appUrls.SOCKET_ENDPOINT,
    landing_page_url: appUrls.LANDING_PAGE_URI,
    azure_ad_config: {
        msalConfig: {
            auth: {
                clientId: process.env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
                authority: process.env.REACT_APP_B2C_SI_AUTHORITY, // Choose SI as your default authority.
                knownAuthorities: [process.env.REACT_APP_B2C_AUTHORITY_DOMAIN], // Mark your B2C tenant's domain as trusted.
                redirectUri: process.env.REACT_APP_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
                postLogoutRedirectUri: appUrls.LANDING_PAGE_URI, // Indicates the page to navigate after logout.
                navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
            },
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: true
            }
        },
        login_scopes: [
            process.env.REACT_APP_API_SCP
        ],
        api_scopes: {
            OAT: [process.env.REACT_APP_API_SCP]
        },
        apis: {
            OAT: {
                name: 'OAT',
                api: process.env.REACT_APP_API_CLIENT_ID
            }
        }
    },
    examConfigs: {
        autoSubmitInterval: parseInt(process.env.REACT_APP_EXAM_AUTOSUBMIT_INTERVAL_MINUTES, 10) //mins
    },
    fileUploadConfigs: {
        maxFileSize: parseInt(process.env.REACT_APP_FILE_UPLOAD_MAX_FILE_SIZE_KB, 10), //KB
        maxFileCount: parseInt(process.env.REACT_APP_FILE_UPLOAD_MAX_FILE_COUNT, 10)
    },
    customerConfigs: {
        featureFlag: process.env.REACT_APP_CUSTOMER_FEATURE_FLAG
    },
    storageBlobConfigs:{
        containerName: process.env.REACT_APP_QUESTION_STORAGE_BLOB_CONTAINER_NAME,
        maxDbStorageSize: parseInt(process.env.REACT_APP_QUESTION_MAX_DB_STORAGE_SIZE_GB, 10) //GB 
    }
}
