import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "../../../../components/Spinner2.js";
import "./RegistrationForm.css";
import SimpleReactValidator from "simple-react-validator";
import { sweetConfirmAlert, sweetStatusAlert, sweetSuccessAlert } from "../../../../components/common/SweetAlert.js";
import { Col, Row } from 'react-bootstrap';
import NavBar from "../../../../components/edsider/components/NavBar.js";
import { GradeService } from '../../../../service/settings/manage/grade.service.js';
import config from '../../../../helper/config.js';
import {customerConstants} from "../../../../helper/constants.js";
import { routes } from '../../../../helper/routes.js';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Modal } from "react-bootstrap";
import { RequestService } from '../../../../service/manage/registration/request_form.service.js';
import { toInteger } from 'lodash';
import { assessmentService } from '../../../../service/assessment/assessmentService.js';
import { history } from '../../../../helper/history.js';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};
class RequestFrom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name:"",
            grade: "",
            medium:'',
            child_email:"",
            school:"",
            parent_fname:"",
            parent_lname:"",
            parent_number:"",
            parent_email: "",
            isFormLoading: false,
            grades:[],
            mediums:[],
            registration_type:null,
            fullMode:false,
            relation_type:"Parent",
            parent_group_id_of_student:null,
            parent_group_id_of_parent:null,
            student_age_below:false,
            openDialog:false,
            isLoading:false,
            parent_not_editability:false,
            student_not_editability:false,
            entry_id_parent:null,
            entry_id_student:null,
            openStudDialog:false,
            claim_student:null,
            claim_parent:null,
            parent_list_of_student:null,
            group_info_of_parent:null,
            parent_email_availability:false,
            student_email_availability: false,
          };

        this.checkEmailValidator = new SimpleReactValidator()

        this.validator = new SimpleReactValidator({
            validators: {
                phone_number: {
                  message: "The parent contact number must be a valid phone number",
                  rule: (val, params, validator) => {
                    const isTenDigitsStartingWithZero =
                      val.length === 10 && val.startsWith("0");
                    const isNineDigits = val.length === 9 && !val.startsWith("0");
                    return isTenDigitsStartingWithZero || isNineDigits;
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: true,
                },
                child_first_name: {
                  message: "The Student first name must have valid characters",
                  rule: (val, params, validator) => {
                    return (
                      validator.helpers.testRegex(val, /^[a-zA-Z-\s.]+$/i) &&
                      params.indexOf(val) === -1
                    );
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: false,
                },
                child_last_name: {
                  message: "The Student last name must have valid characters",
                  rule: (val, params, validator) => {
                    return (
                      validator.helpers.testRegex(val, /^[a-zA-Z-\s.]+$/i) &&
                      params.indexOf(val) === -1
                    );
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: false,
                },
                school: {
                  message: "The :attribute must have valid characters",
                  rule: (val, params, validator) => {
                    return (
                      validator.helpers.testRegex(val, /^[a-zA-Z-\s./]*\d{0,5}[a-zA-Z-\s./]*$/i) &&
                      params.indexOf(val) === -1
                    );
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: false,
                },
                parent_first_name: {
                  message: "The parent first name must have valid characters",
                  rule: (val, params, validator) => {
                    return (
                      validator.helpers.testRegex(val, /^[a-zA-Z-\s.]+$/i) &&
                      params.indexOf(val) === -1
                    );
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: false,
                },
                parent_last_name: {
                  message: "The parent last name must have valid characters",
                  rule: (val, params, validator) => {
                    return (
                      validator.helpers.testRegex(val, /^[a-zA-Z-\s.]+$/i) &&
                      params.indexOf(val) === -1
                    );
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: false,
                },
              },
        });

    }

    componentDidMount() {
      window.scrollTo(0, 0);
      const datas = this.props.location.state ? this.props.location.state : JSON.parse(sessionStorage.getItem('invitationState'))?JSON.parse(sessionStorage.getItem('invitationState')):null;
      const info = this.props.location.info ? this.props.location.info : null;
      const claim_student = this.props.location.claim_student?JSON.parse(this.props.location.claim_student):null 
      const claim_parent = this.props.location.claim_parent?this.props.location.claim_parent:null 

      
      this.setState({claim_student:claim_student,claim_parent:claim_parent})
      
      this.getAllGrades()
      this.getMedium();

      if(claim_student && claim_student.data){
        const formatted_phone_number = "0" + claim_student.data.parent.phone_number?claim_student.data.parent.phone_number.toString():'';
        this.setState({
          parent_fname: claim_student.data.parent.first_name?claim_student.data.parent.first_name:'',
          parent_lname: claim_student.data.parent.last_name?claim_student.data.parent.last_name:'',
          parent_number: formatted_phone_number,
          parent_email: claim_student.data.parent.email?claim_student.data.parent.email:'',
          registration_type: "parent",
          fullMode: true,
          entry_id_parent : claim_student.data.parent.user_id?claim_student.data.parent.user_id:'',
          parent_not_editability: false,
          relation_type: claim_student.data.parent.relation_type?claim_student.data.parent.relation_type:'Parent',
          parent_group_id_of_parent: claim_student.data.parent.parent_group_id_of_parent?claim_student.data.parent.parent_group_id_of_parent:null,
        },()=>{
          this.setState({openStudDialog:true})
        });

      }
      else if(claim_parent && claim_parent.data){
        this.setState({
          first_name: claim_parent.data.student.first_name?claim_parent.data.student.first_name:'',
          last_name: claim_parent.data.student.last_name?claim_parent.data.student.last_name:'',
          grade: claim_parent.data.student.grade?claim_parent.data.student.grade:'',
          medium: claim_parent.data.student.medium?claim_parent.data.student.medium:'',
          child_email: claim_parent.data.student.email?claim_parent.data.student.email:'',
          school: claim_parent.data.student.school?claim_parent.data.student.school:'',
          registration_type: "student",
          fullMode: true,
          entry_id_student : claim_parent.data.student.stu_id?claim_parent.data.student.stu_id:'',
          student_not_editability: false,
          relation_type: claim_parent.data.student.relation_type?claim_parent.data.student.relation_type:'Parent',
          parent_group_id_of_student: claim_parent.data.student.parent_group_id_of_student?claim_parent.data.student.parent_group_id_of_student:null,
        },()=>{
          this.setState({openDialog:true})
        });
      }
      else{
        if (datas && datas.data) {
          this.setState({
            first_name: datas.data.first_name?datas.data.first_name:'',
            last_name: datas.data.last_name?datas.data.last_name:'',
            grade: datas.data.grade?datas.data.grade:'',
            medium: datas.data.medium?datas.data.medium:'',
            child_email: datas.data.child_email?datas.data.child_email:'',
            school: datas.data.school?datas.data.school:'',
            parent_fname: datas.data.parent_fname?datas.data.parent_fname:'',
            parent_lname: datas.data.parent_lname?datas.data.parent_lname:'',
            parent_number: datas.data.phone_number?datas.data.phone_number:'',
            parent_email: datas.data.parent_email?datas.data.parent_email:'',
            registration_type: datas.data.registration_type?datas.data.registration_type:null,
            fullMode: datas.data.fullMode?datas.data.fullMode:false,
            student_age_below: datas.data.student_age_below?datas.data.student_age_below:false,
            entry_id_parent : datas.data.entry_id_parent?datas.data.entry_id_parent:null,
            entry_id_student : datas.data.entry_id_student?datas.data.entry_id_student:null,
            parent_not_editability: datas.data.parent_not_editability?datas.data.parent_not_editability:false,
            student_not_editability: datas.data.student_not_editability?datas.data.student_not_editability:false,
            relation_type: datas.data.relation_type?datas.data.relation_type:"Parent",
            parent_group_id_of_student: datas.data.parent_group_id_of_student?datas.data.parent_group_id_of_student:null,
            parent_group_id_of_parent: datas.data.parent_group_id_of_parent?datas.data.parent_group_id_of_parent:null,
            parent_email_availability: datas.data.parent_email_availability?datas.data.parent_email_availability:false,
            student_email_availability: datas.data.student_email_availability?datas.data.student_email_availability:false,
          });
        }
        else if(info && info.data){
          if(info.data.registration_type === "parent"){
            this.setState({parent_email:info.data.email, registration_type:info.data.registration_type})
          }
          else if(info.data.registration_type === "student"){
            this.setState({child_email:info.data.email, registration_type:info.data.registration_type})
          }
        }
      }
    }

    componentWillUnmount() {

      history.listen((location,action) => {
        if(action === "POP" && location.pathname != "/register"){
          sessionStorage.removeItem('invitationState')
        }
      })
      
    }

    resetForm=()=>{
        this.setState({
            name: "",
            grade: "",
            child_email:"",
            school:"",
            parent_name:"",
            parent_number:"",
            parent_email: "",
        });
    }

    validateForm = (data) => {
        for (let key in data) {
          if (!data[key]) {
            return false;
          }
        }
        return true;
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        const{registration_type,student_age_below,fullMode }= this.state;

        if(registration_type === "student" && !student_age_below && !fullMode){
          if(
            !this.validator.fieldValid('first_name') || 
            !this.validator.fieldValid('last_name') ||
            !this.validator.fieldValid('grade') ||
            !this.validator.fieldValid('medium') ||
            !this.validator.fieldValid('child_email') ||
            !this.validator.fieldValid('school')
            ){
              this.validator.showMessageFor('first_name')
              this.validator.showMessageFor('last_name')
              this.validator.showMessageFor('grade')
              this.validator.showMessageFor('child_email')
              this.validator.showMessageFor('school')
              this.validator.showMessageFor('medium')
              this.forceUpdate();
            }
            else{
              this.setState({ isFormLoading: true });


              const data = {
                  first_name: this.state.first_name,
                  last_name:this.state.last_name,
                  grade: this.state.grade,
                  medium: this.state.medium,
                  child_email: this.state.child_email,
                  school: this.state.school,
                  registration_type:this.state.registration_type,
                };

                if(this.validateForm(data)){

                    data["invitation_status"] = 0;
                    data["parent_email"] =null;
                    data["reg_mode"] ="student_only";
                    data["student_age_below"] = this.state.student_age_below;
                    data["fullMode"] = this.state.fullMode;
                    data["entry_id_student"] = this.state.entry_id_student;

                    const sessionData = {
                      "data":data
                    }

                    sessionStorage.setItem('invitationState', JSON.stringify(sessionData));

                    this.props.history.push({
                      pathname: routes.terms_and_conditions_view_from_registration_form,
                      state: {
                              "success":true,
                              "data":data
                            },
                      });
                  
                }
            this.setState({ isFormLoading: false });
            }

        }
        else if(registration_type === "parent" && !fullMode){
          if(
            !this.validator.fieldValid('parent_fname') ||
            !this.validator.fieldValid('parent_lname') ||
            !this.validator.fieldValid('parent_number') ||
            !this.validator.fieldValid('parent_email')
          ){
            this.validator.showMessageFor('parent_fname')
              this.validator.showMessageFor('parent_lname')
              this.validator.showMessageFor('parent_number')
              this.validator.showMessageFor('parent_email')
            this.forceUpdate();
          }
          else{
            this.setState({ isFormLoading: true });


              const data = {
                parent_fname: this.state.parent_fname,
                parent_lname: this.state.parent_lname,
                phone_number: this.state.parent_number,
                parent_email: this.state.parent_email,
                registration_type:this.state.registration_type,
                };

                if(this.validateForm(data)){

                    data["invitation_status"] = 0;
                    data["child_email"] =null;
                    data["reg_mode"] ="parent_only";
                    data["student_age_below"] = this.state.student_age_below;
                    data["fullMode"] = this.state.fullMode;
                    data["entry_id_parent"] = this.state.entry_id_parent;
                    data["entry_id_student"] = this.state.entry_id_student;

                    const sessionData = {
                      "data":data
                    }

                    sessionStorage.setItem('invitationState', JSON.stringify(data));

                    this.props.history.push({
                      pathname: routes.terms_and_conditions_view_from_registration_form,
                      state: {
                              "success":true,
                              "data":data
                            },
                      });
                  
                }
            this.setState({ isFormLoading: false });
            }
          
        }
        else{
          if (!this.validator.allValid()) {
              this.validator.showMessages();
              this.forceUpdate();
          }
          else{
              this.setState({ isFormLoading: true });


              const data = {
                  first_name: this.state.first_name,
                  last_name:this.state.last_name,
                  grade:this.state.grade,
                  medium: this.state.medium,
                  child_email: this.state.child_email,
                  school: this.state.school,
                  parent_fname: this.state.parent_fname,
                  parent_lname: this.state.parent_lname,
                  phone_number: this.state.parent_number,
                  parent_email: this.state.parent_email,
                  registration_type:this.state.registration_type,
                };

              if(this.validateForm(data)){

                if(data.parent_email!==data.child_email){

                  data["invitation_status"] = 0;
                  data["reg_mode"] ="full";
                  data["student_age_below"] = this.state.student_age_below;
                  data["fullMode"] = this.state.fullMode;
                  data["entry_id_parent"] = this.state.entry_id_parent;
                  data["entry_id_student"] = this.state.entry_id_student;
                  data["relation_type"] = this.state.relation_type;
                  data["parent_group_id_of_student"] = this.state.parent_group_id_of_student;
                  data["parent_group_id_of_parent"] = this.state.parent_group_id_of_parent;
                  data["parent_email_availability"] = this.state.parent_email_availability;
                  data["student_email_availability"] = this.state.student_email_availability;
                  
                  if(this.props.location.claim_student){
                    const reqResponse = await RequestService.submitRequest(
                      JSON.stringify(data)
                    );

                    if (reqResponse.success) {
                      sweetSuccessAlert(
                        "Success",
                        "Request sent successfully. Please check mail inbox or spam/junk",
                        "success"
                      );
                    }
                    else{
                      sweetStatusAlert("Failed!", reqResponse.message, "error");
                    }
                    setTimeout(() => {
                      this.handleGoBack();
                    }, 3000);
                  }
                  else if(this.props.location.claim_parent){
                    const reqResponse = await RequestService.submitRequest(
                      JSON.stringify(data)
                    );

                    if (reqResponse.success) {
                      sweetSuccessAlert(
                        "Success",
                        "Request sent successfully. Please check mail inbox or spam/junk",
                        "success"
                      );
                    }
                    else{
                      sweetStatusAlert("Failed!", reqResponse.message, "error");
                    }
                    setTimeout(() => {
                      this.handleGoBack();
                    }, 3000);
                  }
                  else{
                    const sessionData = {
                      "data":data
                    }

                    sessionStorage.setItem('invitationState', JSON.stringify(sessionData));

                    this.props.history.push({
                      pathname: routes.terms_and_conditions_view_from_registration_form,
                      state: {
                              "success":true,
                              "data":data
                            },
                      });
                  }

                }
                else{
                  sweetStatusAlert("Error","Parent and Student emails should not be the same email","error")
                }
              }
              this.setState({ isFormLoading: false });
          }
        }
    };

    getAllGrades=()=>{
      GradeService.getGradesWithLimit()
          .then((response)=>{
              if(response.success){
                  this.setState({grades:response.data})
              }
              
          })
    };

    getMedium=()=>{
      assessmentService.getAssessmentLanguage()
          .then((response)=>{
              if(response.success){
                  this.setState({mediums:response.data.languages})
              }
              
          })
    };

    handleGoBack = ()=>{

      if(this.props.location.claim_parent){
        sessionStorage.removeItem('invitationState')
        this.props.history.push({
          pathname: '/my-requests',
          state: this.props.location.claim_parent.owner_restriction,
        });
      }
      else{
        sessionStorage.removeItem('invitationState')
        this.props.history.push({
          pathname: '/register-student',
        });
      }
    }

    handleCloseDialog = () => {
      if(this.props.location.claim_student){
        if(!this.state.child_email){
          sweetStatusAlert("Warning","Please enter student email","info")
        }
        else{
          this.setState({openStudDialog:false})
        }
      }
      else if(this.props.location.claim_parent){
        if(!this.state.parent_email){
          sweetStatusAlert("Warning","Please enter parent email","info")
        }
        else{
          this.setState({openDialog:false})
        }
      }
      else{
        this.setState({openDialog: false,student_age_below:false,openStudDialog:false });
      }
      
    };

    maskValue = (value, condition) => {
      if (condition && value && value.length > 1) {
        const maskedName = value.charAt(0) + '*'.repeat(value.length - 2) + value.slice(-1);
        return maskedName;
      } else {
        return value;
      }
    };
    
    renderChildForm(){

      const { isLoading,grades,registration_type,student_age_below,student_not_editability,mediums, student_email_availability } = this.state;

      

      return(
        <>
        <Form.Group
              controlId="name"
              className="form-group"
            >
              <Row>
              <Form.Label><u>Students's Details : </u></Form.Label>
              </Row>
              <Row>
              <Col className="req-form-name mr-2">
              <Form.Label>Student's First Name:</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={this.state.claim_student || student_not_editability? this.maskValue(this.state.first_name,this.state.student_not_editability): ((this.state.registration_type === "parent" && student_email_availability === false) ? (this.maskValue(this.state.first_name, true)): this.state.first_name)}
                onChange={this.handleChange}
                disabled={this.state.student_not_editability || this.state.claim_parent || ((this.state.registration_type === "parent" && student_email_availability === false) && this.maskValue(this.state.first_name, true))}
              />
              <span className="form-error-message">
              {this.validator.message(
                  "first_name",
                  this.state.first_name,
                  "required|child_first_name|min:2|max:100",
                  {
                    messages: {
                      required: "The Student first name is required.",
                      min:  "The Student first name should have minimum 2 characters.",
                      max:  "The Student first name should have maximum 128 characters."
                    },
                  }
                )}
              </span>
              </Col>
              <Col className="req-form-name ml-2">
              <Form.Label>Student's Last Name:</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={this.state.claim_student || student_not_editability? this.maskValue(this.state.last_name,this.state.student_not_editability): ((this.state.registration_type === "parent" && student_email_availability === false) ? (this.maskValue(this.state.last_name, true)): this.state.last_name)}
                onChange={this.handleChange}
                disabled={this.state.student_not_editability || this.state.claim_parent || ((this.state.registration_type === "parent" && student_email_availability === false) && (this.maskValue(this.state.last_name, true)))}
              />
              <span className="form-error-message">
              {this.validator.message(
                  "last_name",
                  this.state.last_name,
                  "required|child_last_name|min:2|max:100",
                  {
                    messages: {
                      required: "The Student last name is required.",
                      min:  "The Student last name should have minimum 2 characters.",
                      max:  "The Student last name should have maximum 128 characters."
                    },
                  }
                )}
              </span>
              </Col>
              </Row>
            </Form.Group>
            
            <Form.Group controlId="child_email" className="form-group">
              <Form.Label>Student's Email:</Form.Label>
              <Form.Control
                type="email"
                name="child_email"
                value={this.state.child_email}
                onChange={this.handleChange}
                disabled
              />
              <span className="form-error-message">
              {this.validator.message(
                  "child_email",
                  this.state.child_email,
                  "required|email"
                )}
              </span>
            </Form.Group>
            
            <Form.Group controlId="grade" className="form-group">
            <Form.Label>Student’s Grade:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="grade"
                value={toInteger(this.state.grade)}
                onChange={this.handleChange}
                disabled={this.state.student_not_editability || this.state.claim_parent}
              >
                <option value="">Select</option>
                {grades.length>0?(grades.map((grade)=>(
                  <option key={grade.grade_id} value={grade.grade_id} disabled={grade.limit_exceeded} style={{color:grade.limit_exceeded?'red':'black'}}>
                    {grade.limit_exceeded ? `${grade.grade} (Registration Limit exceeded)` : grade.grade}
                  </option>
                ))):(
                  <option value="">No grades available</option>
                )}
              </Form.Select>
              <span className="form-error-message">
              {this.validator.message(
                "grade",
                this.state.grade,
                "required"
              )}
              </span>
            </Form.Group>

            <Form.Group controlId="grade" className="form-group">
            <Form.Label>Student’s Language:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="medium"
                value={toInteger(this.state.medium)}
                onChange={this.handleChange}
                disabled={this.state.student_not_editability || this.state.claim_parent}
              >
                <option value="">Select</option>
                {mediums.length>0?(mediums.map((medium)=>(
                  <option key={medium.language_id} value={medium.language_id}>
                    {medium.language}
                  </option>
                ))):(
                  <option value="">No languages available</option>
                )}
              </Form.Select>
              <span className="form-error-message">
              {this.validator.message(
                "medium",
                this.state.medium,
                "required"
              )}
              </span>
            </Form.Group>
            
            <Form.Group controlId="School" className="form-group">
              <Form.Label>School:</Form.Label>
              <Form.Control
                type="text"
                name="school"
                value={this.state.claim_student || student_not_editability? this.maskValue(this.state.school,this.state.student_not_editability): ((this.state.registration_type === "parent" && student_email_availability === false) ? (this.maskValue(this.state.school, true)): this.state.school)}
                onChange={this.handleChange}
                disabled={this.state.student_not_editability || this.state.claim_parent || ((this.state.registration_type === "parent" && student_email_availability === false) && (this.maskValue(this.state.school, true)))}
              />
              <span className="form-error-message">
              {this.validator.message(
                  "school",
                  this.state.school,
                  "required|school|max:60"
                )}
              </span>

              <FormControl sx={{display:registration_type === "student" && !this.props.location.claim_parent?'block':'none',mt:'10px'}}>
                <FormLabel id="demo-controlled-radio-buttons-group">Is your age below 13 years ?</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="student_age_below"
                  value={student_age_below}
                  onChange={this.switchRegModes}
                >
                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

            <span className="form-error-message">
              {this.validator.message(
                  "student_age_below",
                  student_age_below,
                  "boolean"
                )}
              </span>
            </Form.Group>
        </>
      )
    };

    renderParentForm(){

      const { isLoading,grades,registration_type, parent_not_editability, parent_email_availability } = this.state;

      return(
        <>
          <Form.Group controlId="parent_name" className="form-group">
            <Row>
              <Form.Label><u>Parent's Details :</u></Form.Label>
            </Row>
            <Row>
              <Col className="req-form-name mr-2">
              <Form.Label>Parent's First Name:</Form.Label>
              <Form.Control
                type="text"
                name="parent_fname"
                value={this.state.claim_parent || parent_not_editability? this.maskValue(this.state.parent_fname,this.state.parent_not_editability): ((this.state.registration_type === "student" && parent_email_availability === false) ? (this.maskValue(this.state.parent_fname, true)): this.state.parent_fname)}
                onChange={this.handleChange}
                disabled={this.state.parent_not_editability || this.state.claim_student || ((this.state.registration_type === "student" && parent_email_availability === false) && (this.maskValue(this.state.parent_fname, true)))}
              />
              <span className="form-error-message">
              {this.validator.message(
                  "parent_fname",
                  this.state.parent_fname,
                  "required|parent_first_name|min:2|max:100",
                  {
                    messages: {
                      required: "The parent first name is required.",
                      min:  "The parent first name should have minimum 2 characters.",
                      max:  "The parent first name should have maximum 100 characters."
                    },
                  }
                )}
              </span>
              </Col>
              <Col className="req-form-name ml-2">
              <Form.Label>Parent's Last Name:</Form.Label>
              <Form.Control
                type="text"
                name="parent_lname"
                value={this.state.claim_parent || parent_not_editability? this.maskValue(this.state.parent_lname,this.state.parent_not_editability):((this.state.registration_type === "student" && parent_email_availability === false) ? (this.maskValue(this.state.parent_lname, true)): this.state.parent_lname)}
                onChange={this.handleChange}
                disabled={this.state.parent_not_editability || this.state.claim_student || ((this.state.registration_type === "student" && parent_email_availability === false ) && (this.maskValue(this.state.parent_lname, true)))}
              />
              <span className="form-error-message">
              {this.validator.message(
                  "parent_lname",
                  this.state.parent_lname,
                  "required|parent_last_name|min:2|max:100",
                  {
                    messages: {
                      required: "The parent last name is required.",
                      min:  "The parent last name should have minimum 2 characters.",
                      max:  "The parent last name should have maximum 100 characters."
                    },
                  }
                )}
              </span>
              </Col>
            </Row>
          </Form.Group>
          
          <Form.Group controlId="parent_email" className="form-group">
            <Form.Label>Parent's Email:</Form.Label>
            <Form.Control
              type="email"
              name="parent_email"
              value={this.state.parent_email}
              onChange={this.handleChange}
              disabled
            />
            <span className="form-error-message">
            {this.validator.message(
                "parent_email",
                this.state.parent_email,
                "required|email"
              )}
            </span>
          </Form.Group>
          <Form.Group controlId="parent_number" className="form-group">
            <Form.Label>Parent's Contact Number:</Form.Label>
            <Form.Control
              type="text"
              name="parent_number"
              value={this.state.claim_parent || parent_not_editability? this.maskValue(this.state.parent_number,this.state.parent_not_editability):((this.state.registration_type === "student" && parent_email_availability === false) ? (this.maskValue(this.state.parent_number, true)): this.state.parent_number)}
              onChange={this.handleChange}
              disabled={this.state.parent_not_editability || this.state.claim_student || ((this.state.registration_type === "student" && parent_email_availability === false) && (this.maskValue(this.state.parent_number, true)))}
            />
            <span className="form-error-message">
            {this.validator.message(
                "parent_number",
                this.state.parent_number,
                "required|numeric|between:9,10|phone_number",
                {
                  messages: {
                    required: "The parent contact number is required.",
                    numeric:  "The parent contact number must be a number.",
                    between:  "The parent contact number must be between 9 and 10 characters"
                  },
                }
              )}
            </span>
          </Form.Group>
        </>
      )
    };

    checkParentEmail = () => {
      const {parent_email} =this.state;
        if (!this.validator.fieldValid('parent_email')) {
          this.validator.showMessageFor('parent_email');
          this.forceUpdate();
          return
        }
        this.setState({ isLoading: true });
        RequestService.checkParentEmail(parent_email)
          .then((response)=>{
            
            if(response.data){
              
              if(response.data.code === 1){
                if(!this.props.location.claim_parent){
                  this.setState({openDialog: false });
                }
                this.setState({ isLoading: false ,parent_email: "" });
                sweetStatusAlert("Email Exist",
                  `${typeof response.data.message === "string"?
                  response.data.message+", Please use different email":"Email already exist in system, Please use different email"}`,
                  "info"
                )
                if(this.state.student_age_below){
                  this.setState({student_age_below:false})
                }
              }
              else if(response.data.code === 2 && response.data.parent){  
                  const {
                    first_name,
                    last_name,
                    phone_number,
                    user_id,
                    } = response.data.parent                    

                  if (response.data.is_Parent_group){

                    if(response.data.group_student_limit_exceeded){
                      sweetStatusAlert("Warning",
                        `${typeof response.data.message === "string"?
                            response.data.message : "Number of students for this parent is exceeded!!!"}`,
                        "info"
                      )
                      
                      this.setState({openDialog: false });

                      if(this.state.student_age_below){
                        this.setState({student_age_below:false})
                      }
                    }
                    else{
                      if(this.props.location.claim_parent){
                      
                        if(toInteger(this.state.claim_parent?.data?.student?.create_user_table_id) === 28){
  
                          if(toInteger(this.state.claim_parent?.data?.student?.create_user_id) === response.data?.parent_group?.user_id){
                            
                            if(!this.props.location.claim_parent){
                              this.setState({openDialog: false });
                            }
                            this.setState({ isLoading: false ,parent_email: "" });
                            sweetStatusAlert("Warning",
                              `Parent Email is Already Registered as Your 
                              ${typeof response.data?.parent_group?.relation_type === "string"?response.data.parent_group.relation_type:"Parent"}`,
                              "info"
                            )
                            if(this.state.student_age_below){
                              this.setState({student_age_below:false})
                            }
  
                          }
                          else if(toInteger(this.state.claim_parent?.data?.student?.create_user_id) != response.data?.parent_group?.user_id){
                            if(!this.props.location.claim_parent){
                                this.setState({openDialog: false });
                              }
                              this.setState({ isLoading: false ,parent_email: "" });
                              sweetStatusAlert("Warning",
                                "Parent Email Is Already Registered On Another Group, Please Use Different Email",
                                "info"
                              )
                              if(this.state.student_age_below){
                                this.setState({student_age_below:false})
                              }
                          }
                        }
                        else{
                          this.setState({group_info_of_parent: response.data.parent_group, parent_group_id_of_parent:toInteger(response.data.parent_group.user_id)})
                          const formatted_phone_number = "0" + phone_number.toString();
  
                          this.setState({entry_id_parent:user_id, parent_fname:first_name,parent_lname:last_name,parent_number:formatted_phone_number,parent_not_editability:true})
                          this.setState({ isLoading: false ,openDialog: false, fullMode:true });
                        }
                      }
                      else{
                        this.setState({group_info_of_parent: response.data.parent_group, parent_group_id_of_parent:toInteger(response.data.parent_group.user_id)})
                        const formatted_phone_number = "0" + phone_number.toString();
  
                        this.setState({entry_id_parent:user_id, parent_fname:first_name,parent_lname:last_name,parent_number:formatted_phone_number,parent_not_editability:true})
                        this.setState({ isLoading: false ,openDialog: false, fullMode:true });
                      }
                    }

                  }
                  else if(toInteger(this.state.claim_parent?.data?.student?.create_user_table_id) === 28){
                    this.setState({parent_group_id_of_student:toInteger(this.state.claim_parent?.data?.student?.create_user_id)})
                    const formatted_phone_number = "0" + phone_number.toString();

                    this.setState({entry_id_parent:user_id, parent_fname:first_name,parent_lname:last_name,parent_number:formatted_phone_number,parent_not_editability:true})
                    this.setState({ isLoading: false ,openDialog: false, fullMode:true });
                  }
                  else{
                    const formatted_phone_number = "0" + phone_number.toString();

                    this.setState({entry_id_parent:user_id, parent_fname:first_name,parent_lname:last_name,parent_number:formatted_phone_number,parent_not_editability:true})
                    this.setState({ isLoading: false ,openDialog: false, fullMode:true });
                  }
              }
              else{
                if(toInteger(this.state.claim_parent?.data?.student?.create_user_table_id) === 28){
                  this.setState({parent_group_id_of_student:toInteger(this.state.claim_parent?.data?.student?.create_user_id)})
                  this.setState({ isLoading: false ,openDialog: false, fullMode:true });
                }
                this.setState({ isLoading: false ,openDialog: false, fullMode:true, parent_email_availability:true });
              }
            }
          })
    };

    checkStudentEmail = () =>{
      const {child_email} =this.state;

      
        if (!this.validator.fieldValid('child_email')) {
          this.validator.showMessageFor('child_email');
          this.forceUpdate();
          return
        }

        this.setState({ isLoading: true });
  
        RequestService.checkStudentEmail(child_email)
          .then((response)=>{
            if(response.data){
              if(response.data.code === 1){
                if(!this.props.location.claim_student){
                  this.setState({openStudDialog: false });
                }
                this.setState({isLoading:false,child_email:""})
                sweetStatusAlert("Email Exist",`${typeof response.data.message === "string"?response.data.message+", Please use different email":"Email already exist in system, Please use different email"}`,"info")
                if(this.state.student_age_below){
                  this.setState({student_age_below:false})
                }
              }
              else if(response.data.code === 2 && response.data.student){  

                  const {
                    first_name,
                    last_name,
                    grade,
                    medium,
                    stu_id,
                    school,
                    create_user_table_id,
                    create_user_id
                    } = response.data.student

                  if (response.data.is_Parent_group){

                    if(response.data.group_student_limit_exceeded){
                      sweetStatusAlert("Warning",
                        `${typeof response.data.message === "string"?
                            response.data.message : "Number of parents for this student is exceeded!!!"}`,
                        "info"
                      )
                        this.setState({openDialog: false });

                      if(this.state.student_age_below){
                        this.setState({student_age_below:false})
                      }
                    }
                    else{
                      if(this.props.location.claim_student){

                        if(this.state.claim_student.data.parent_group){
                          
                          if(this.state.claim_student.data.parent_group.user_id === response.data?.parent_group[0]?.user_id){
                            
                            if(!this.props.location.claim_student){
                              this.setState({openStudDialog: false });
                            }
                            this.setState({ isLoading: false ,child_email: "" });
                            sweetStatusAlert("Warning",
                              "Student Email is Already Registered as Your Student",
                              "info"
                            )
                            if(this.state.student_age_below){
                              this.setState({student_age_below:false})
                            }
  
                          }
                          else if(this.state.claim_student.data.parent_group.user_id != response.data?.parent_group[0]?.user_id){
                            if(!this.props.location.claim_student){
                                this.setState({openStudDialog: false });
                              }
                              this.setState({ isLoading: false ,child_email: "" });
                              sweetStatusAlert("Warning",
                                "Student Email Is Already Registered On Another Group, Please Use Different Email",
                                "info"
                              )
                              if(this.state.student_age_below){
                                this.setState({student_age_below:false})
                              }
                          }
                          
                        }
                        else{
                          this.setState({parent_list_of_student: response.data.parent_group, parent_group_id_of_student:toInteger(response.data.parent_group[0]?.user_id)})
                          this.setState({entry_id_student:stu_id, first_name:first_name,last_name:last_name,student_not_editability:true,school:school,grade:grade,medium:medium})
                          this.setState({ isLoading: false ,openStudDialog: false, fullMode:true });
                        }
                      }
                      else{
                        
                          this.setState({parent_list_of_student: response.data.parent_group, parent_group_id_of_student:toInteger(response.data.parent_group[0]?.user_id)})
                          this.setState({entry_id_student:stu_id, first_name:first_name,last_name:last_name,student_not_editability:true,school:school,grade:grade,medium:medium})
                          this.setState({ isLoading: false ,openStudDialog: false, fullMode:true });
                      }
                    }

                  }
                  else if(this.state.claim_student && this.state.claim_student.data.parent_group){
                    this.setState({group_info_of_parent: this.state.claim_student.data.parent_group, parent_group_id_of_parent:this.state.claim_student.data.parent_group.user_id})
                    this.setState({entry_id_student:stu_id, first_name:first_name,last_name:last_name,student_not_editability:true,school:school,grade:grade,medium:medium})
                    this.setState({ isLoading: false ,openStudDialog: false, fullMode:true });
                  }
                  else{
                    this.setState({entry_id_student:stu_id, first_name:first_name,last_name:last_name,student_not_editability:true,school:school,grade:grade,medium:medium})
                    this.setState({ isLoading: false ,openStudDialog: false, fullMode:true });
                  }
              }
              else{
                if(this.state.claim_student && this.state.claim_student.data.parent_group){
                  this.setState({group_info_of_parent: this.state.claim_student.data.parent_group, parent_group_id_of_parent:this.state.claim_student.data.parent_group.user_id})
                  this.setState({ isLoading: false ,openStudDialog: false, fullMode:true });
                }
                this.setState({ isLoading: false ,openStudDialog: false, fullMode:true, student_email_availability:true });
              }
            }
          })
    }

    switchRegModes=(event)=>{

      const{isLoading,fullMode,registration_type}=this.state;

      const {name,value}  =event.target;

      if(fullMode){
        this.setState((prevState) => ({
          student_age_below: !prevState.student_age_below,
          fullMode:false
        }));
      }
      else{
        if(registration_type==="parent"){
          this.setState({child_email:"", openStudDialog:true})
        }
        else if(registration_type==="student"){

          if(name && value && name === "student_age_below"){
            this.setState((prevState) => ({
              parent_email:"",
              student_age_below: !prevState.student_age_below,
              
            }),()=>{
              if(this.state.student_age_below && !this.state.openDialog){
                this.setState({openDialog:true})
              }
            });
          }
          else{
            this.setState({parent_email:"", openDialog:true})
          }
        }
      }
    }
  
    checkParentModal(){

      const{isLoading,fullMode,registration_type}=this.state;

      return(
            <Modal
              show={this.state.openDialog}
              onHide={this.handleCloseDialog}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Enter parent email</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <p className="informingMsg">Only Gmail and Microsoft email IDs are currently accepted.</p>
                    <Form.Control
                      type="email"
                      name='parent_email'
                      value={this.state.parent_email}
                      placeholder="Enter email"
                      onChange={this.handleChange}
                    />
                    <span className="form-error-message">
                      {this.validator.message(
                          'parent_email',
                           this.state.parent_email,
                          "required|email"
                        )}
                    </span>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.props.location.claim_parent?this.handleGoBack:this.handleCloseDialog}>
                  {this.props.location.claim_parent?'Go back':'Cancel'}
                </Button>
                <Button
                  variant="primary"
                  onClick={this.checkParentEmail}
                  style={{ backgroundColor: "#5458AF" }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div style={{ display: "inline-flex", alignItems: "center",minWidth:'50px' }}>
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </Button>

              </Modal.Footer>
            </Modal>
      )
    }

    checkStudentModal(){

      const{isLoading,fullMode,registration_type}=this.state;

      return(
            <Modal
              show={this.state.openStudDialog}
              onHide={this.handleCloseDialog}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Enter student email</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <p className="informingMsg">Only Gmail and Microsoft email IDs are currently accepted</p>
                    <Form.Control
                      type="email"
                      name='child_email'
                      value={this.state.child_email}
                      placeholder="Enter email"
                      onChange={this.handleChange}
                    />
                    <span className="form-error-message">
                      {this.validator.message(
                          'child_email',
                           this.state.child_email,
                          "required|email"
                        )}
                    </span>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>

                <Button variant="secondary" onClick={this.props.location.claim_student?this.handleGoBack:this.handleCloseDialog}>
                  {this.props.location.claim_student?'Go back':'Cancel'}
                </Button>
                <Button
                  variant="primary"
                  onClick={this.checkStudentEmail}
                  style={{ backgroundColor: "#5458AF" }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div style={{ display: "inline-flex", alignItems: "center",minWidth:'50px' }}>
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </Button>

              </Modal.Footer>
            </Modal>
      )
    }

    render() {
        const { isLoading,grades,registration_type,fullMode,student_age_below,isFormLoading,relation_type } = this.state;
        this.validator.purgeFields();
        const currentYear = new Date().getFullYear()
        const customer = getCustomerFeatureFlag();
        let footerText, copyRightText, cardTitle, bgImageClassName;
        switch (customer) {
          case customerConstants.EXO_CUSTOMER1:
            copyRightText = "© 2024 EdSider";
            footerText = "All Right Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd";
            cardTitle = "Request Form";
            bgImageClassName = "col-md-12 login-edsider-background";
            break;
          case customerConstants.EXO_CUSTOMER2:
            copyRightText = "© 2024"
            footerText = "All Rights Reserved. Powered by EdSider (Pvt) Ltd. Supported by Infinity Innovators (Pvt) Ltd";
            cardTitle = "Registration Form";
            bgImageClassName = "col-md-12 login-cmc-background";
            break;
          default:
            copyRightText = "© 2024 EdFoci"
            footerText = "All Rights Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
            cardTitle = "Registration Form";
            bgImageClassName = "col-md-12 login-edsider-background";
            break;
        }

        return (
            <>
        <NavBar className="navigation-bg" />
        <div className={bgImageClassName}>
          <div className="row">
            <div className="row py-5">
              <div className="col-lg-12 col-xs-12">
                <div className="card login-card col-lg-6 col-md-8 col-sm-12  mt-3">
                  <h1 className="login-card-title mt-5 mb-4">{cardTitle}</h1>
                  {(customer === customerConstants.EXO_CUSTOMER1) && (<>
                    <p className="request-form-para">
                      "Welcome to our specialized online platform, EdSider, 
                      exclusively tailored to support your Grade 5 scholarship 
                      exam preparation. As a trusted resource for model papers, 
                      we're thrilled to provide a unique opportunity, available 
                      only to a select group of individuals – individuals like you, 
                      who are committed to excelling in their exams.
                    </p>
                    <p className="request-form-para">
                      Our platform isn't just about practice; it's about strategic 
                      preparation. By engaging with our application form, you are 
                      applied for the selection procedure to gain access to a valuable 
                      collection of papers that empowers you to  approach the exams 
                      with confidence. Join our community of dedicated learners and 
                      unlock a path to success that's designed specifically for you." 
                    </p>
                  </>)}
                  {(customer === customerConstants.EXO_CUSTOMER2) && (<>
                    <p className="request-form-para">
                      Embark on your journey to mathematical excellence with
                      Chithambara Maths Challenge! Register today and unlock a
                      world of learning. With just a few simple steps, you can
                      create your account, ensuring a personalized and secure
                      experience. Dive into our resources, practice diligently,
                      and get ready to shine in the competition.
                    </p>
                    <p>
                      To register, you must have a Microsoft or Google email
                      account. Let's conquer math, together!
                    </p>
                  </>)}
                  <div className="card-body login-card-body">
                    {isFormLoading ? (
                      <Spinner />
                    ) : (
                      <Form onSubmit={this.handleSubmit}>

                          {!fullMode && registration_type === "student" && (

                            <div>
                              {this.renderChildForm()}
                              
                              <FormControlLabel disabled={this.props.location.claim_parent} control={
                                <Switch
                                  checked={fullMode}
                                  onChange={this.switchRegModes}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label="Do you need to add parent details as well?" 
                              />
                              </div>
                            )}
                          {!fullMode && registration_type === "parent" && (
                            <div>
                              {this.renderParentForm()}

                              <FormControlLabel disabled={this.props.location.claim_student} control={
                                <Switch
                                  checked={fullMode}
                                  onChange={this.switchRegModes}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              } 
                              label="Do you need to add student details as well?" 
                              />
                            </div>
                          )}

                          {fullMode && (
                            <>
                            {registration_type === "parent" && (
                            
                              <div>
                              {this.renderParentForm()}
                              <div>
                              <FormControlLabel disabled={this.props.location.claim_student} control={
                                <Switch
                                  checked={fullMode}
                                  onChange={()=>
                                    sweetConfirmAlert()
                                      .then(()=>{
                                        this.setState({fullMode:false})
                                      })
                                  }
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              } 
                              label={`Add ${registration_type === "parent"?"student":"parent"} details`}

                              />
                            </div>
                            <div>
                              <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">Relation Type</FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="relation_type"
                                  value={relation_type}
                                  onChange={this.handleChange}
                                >
                                  <FormControlLabel value="Parent" control={<Radio />} label="Parent" />
                                  <FormControlLabel value="Guardian" control={<Radio />} label="Guardian" />
                                  
                                </RadioGroup>
                              </FormControl>
                              </div>
                              {this.renderChildForm() }

                            </div>

                            )}

                            {registration_type === "student" && (
                            <div>

                              {this.renderChildForm()}
                              <div>
                              <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">Relation Type</FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="relation_type"
                                  value={relation_type}
                                  onChange={this.handleChange}
                                >
                                  <FormControlLabel value="Parent" control={<Radio />} label="Parent" />
                                  <FormControlLabel value="Guardian" control={<Radio />} label="Guardian" />
                                  
                                </RadioGroup>
                              </FormControl>
                              </div>
                              {this.renderParentForm()}
                              <div>
                              <FormControlLabel disabled={this.props.location.claim_parent || this.props.location.claim_student} control={
                                <Switch
                                  checked={!fullMode}
                                  onChange={()=>
                                    sweetConfirmAlert()
                                      .then(()=>{
                                        this.setState({fullMode:false})
                                      })
                                  }
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label={`Do you need to discard ${registration_type === "parent"?"student":"parent"} details?`}

                              sx={{display:student_age_below?'none':'block'}}
                              />
                              </div>
                            </div>
                            )}
                            </>
                          )}
                  
                        <div className="d-flex justify-content-center">
                          {this.props.location.claim_student || this.props.location.claim_parent ?(
                            <Button variant="secondary" onClick={this.handleGoBack} style={{marginRight:'10px'}}>
                              Go back
                            </Button>
                          ):(
                            <div>
                            </div>
                          )
                          }
                          <Button variant="primary" type="submit" style={{ backgroundColor: "#5458AF" }}>
                            Submit
                          </Button>
                        </div>
                      </Form>
                    )}
                    {this.checkParentModal()}
                    {this.checkStudentModal()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between footer-container">
          <div className="col-md-2 col-sm-3 copyright-items">
            {copyRightText}
          </div>
          <div className="col-md-6 col-sm-3 copyright-items d-flex justify-content-center">
            {footerText}
          </div>
          <div className="col-md-4 col-sm-3 d-flex justify-content-end policy-content">
          <span className="text-dark-75">
                <a
                  href={routes.terms_and_conditions_view}
                  target="_blank"
                  className="text-dark-75 text-hover-primary"
                >
                  Privacy Policy and Terms & Conditions
                </a>
              </span>
          </div>
        </div>
      </>
        );
    }
}


export default RequestFrom;