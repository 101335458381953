    import config from "../../../helper/config";
    import APIHandler from "../../../handlers/APIHandler";

    export const IdTypeService = {
    getAll,
    getAllIdTypes,
    add,
    update,
    deleteIdType,
    };

    async function getAll(limit, offset, search_term) {
    const configOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/idTypes/getAll/${limit}/${offset}?search_term=${search_term}`,
        configOptions
    );
    return APIHandler.handleResponse(response);
    }

    async function getAllIdTypes() {
    const configOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/idTypes/getAllIdTypes`,
        configOptions
    );
    return APIHandler.handleResponse(response);
    }

    async function add(newIdType) {
    const configOptions = {
        method: "POST",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(newIdType),
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/idTypes/add`,
        configOptions
    );
    return APIHandler.handleResponse(response);
    }

    async function update(IdTypeItem, IdTypeId) {
    const configOptions = {
        method: "PUT",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(IdTypeItem),
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/idTypes/update/${IdTypeId}`,
        configOptions
    );
    return APIHandler.handleResponse(response);
    }

    async function deleteIdType(id) {
    const configOptions = {
        method: "DELETE",
        headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
        },
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/idTypes/delete/${id}`,
        configOptions
    );
    return APIHandler.handleResponse(response);
    }
