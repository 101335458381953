import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import Button from '@mui/material/Button';
import '../Paper.css';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));


export default function FilterChips({ groupedFilters, filters, filterKeyIds, tableLoading, handleChildCheckboxChange, clearAllFilters }) {

  
  const [chipData, setChipData] = React.useState([]);

  const handleDelete = (chipToDelete) => () => {
    handleChildCheckboxChange(chipToDelete.filterKey,chipToDelete)
    // setChipData((chips) => chips.filter((chip) => chip.display_order !== chipToDelete.display_order));
  };

  React.useEffect(() => {
    const newChipData = [];

    
    Object.keys(filters).forEach((filterKey) => {
      
      const filterValues = filters[filterKey];
      const filterKeyId = filterKeyIds[0][filterKey]
      
      const groupedFilter = groupedFilters.find((group) => group.some((item) => item.list_id === filterKeyId));

      if (groupedFilter) {
        
        groupedFilter.forEach((item) => {
          if (filterValues && filterValues.includes(item.display_order)) {
            newChipData.push({
              display_order: item.display_order,
              label: item.list_text,
              filterKey:filterKey,
              filterKeyId:filterKeyId
            });
          }
        });
      }
    });

    setChipData(newChipData);
  }, [filters, groupedFilters]);

  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
        alignItems: 'center',
      }}
      component="ul"
    >
      {chipData.map((data) => {
        let icon;

        // if (data.label === 'React') {
        //   icon = <TagFacesIcon />;
        // }

        return (
          <ListItem key={data.display_order}>
            <Chip
              icon={icon}
              label={data.label}
              onDelete={handleDelete(data)}
              color='info'
              disabled={tableLoading}
            />
          </ListItem>
        );
      })}
      &emsp;
      {
        chipData && chipData.length > 0 && <Button variant="outlined" className="ClearbuttonFilter" onClick={clearAllFilters}>Clear All</Button>
      }
    </Paper>
  );
}
