import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { routes } from "../../helper/routes";
import { history } from "../../helper/history";
import _ from "lodash";
import SelfGradeModal from "../../components/common/modal/SelfGradeModal";
import ModalHeader from "../../components/common/modal/ModalHeader";
import ModalBody from "../../components/common/modal/ModalBody";
import { questionTypeConstants } from "../../helper/constants";
import { AzureStorage } from "../../helper/azureStorage";
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";
import ContentWrapper from "../../components/common/AssessmentContentWrapper";
import AdminTemplate from '../../components/admin/layouts/Template.js';
import Spinner from "../../components/common/Spinner";
import Card from "../../components/common/Card";
import CardHeader from "../../components/common/CardHeader";
import CardBody from "../../components/common/CardBody";
import CardTitle from "../../components/common/AssessmentCardTitle";
import CardToolbar from '../../components/common/QuestionCardToolBar.js';
import Breadcrumb from "../../components/common/Breadcrumb";
import BreadcrumbItem from "../../components/common/BreadcrumbItem";
import Button from "../../components/common/form/Button";
import SearchInputWrap from "../../components/common/table/SearchInputWrap";
import SearchInput from "../../components/common/table/SearchInput";
import SearchFormGroup from "../../components/common/table/SearchFormGroup";
import Select from "../../components/common/form/AssessmentSelect";
import Option from "../../components/common/form/Option";
import CheckBox from "../../components/common/form/CheckBox";
import CheckBoxInline from "../../components/common/form/CheckBoxInline";
import LinkButton from "../../components/common/form/LinkButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFilter from "../../components/common/table/TableFilter";
import FinalizeTab from "../../components/common/tabs/FinalizeTab";
import Marks from "../../components/common/tabs/Marks";
import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ViewEditor from "../../components/common/editor/ViewEditor";
import { questionActions } from "../../actions/assessment/questionActions";
import { assessmentActions } from "../../actions/assessment/assessmentActions";
import DataHandler from "../../handlers/DataHandler";
import { messageTypes } from "../../reducers/types";
import { staffUserActions } from "../../actions/user_management/staffUserActions.js";
import { manageUserActions } from "../../actions/user_management/manageUserActions.js";
import { libraryActions } from "../../actions/question/libraryActions";
import { createActions as questionCreateActions } from "../../actions/question/createActions";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { customerConstants } from "../../helper/constants.js";
import ReactSpinner from "react-bootstrap/Spinner";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    // cancelClass = "cmc-btn-default"
    // confirmClass = "cmc-btn-primary"
  default:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class Finalize extends React.Component {
  constructor(props) {
    super(props);

    this.ref_tagify = React.createRef();
    this.state = {
      assessment_title: "",
      auto_shuffle: false,
      assessment_questions: [],
      questions_contents_by_type: {},
      print_questions: [],
      to_delete: [],
      total: "",
      errors: {},
      mark_allocation: {},
      position_list: {}, //state for getting positions
      changes: false,
      get_content: true,
      add_question: false,
      searchValue: "",
      selectedCategory: 0,
      selectedType: 0,
      selectedDifficulty: 0,
      allMarks: "",
      chkbox: false,
      deletion: false,
      filteredQuestions: [],
      selectedQuestions: [],
      question_added: false,
      teacherDetailsMap: {},
      disabled: false,
      loadingSetup: false,
      attachment: '',
      isViewModalLoading: false,
      states: '',
      studentView: false
    };

    this.onSave = this.onSave.bind(this);
    this.finalizeAssessment = this.finalizeAssessment.bind(this);
    this.makeEditableAssessment = this.makeEditableAssessment.bind(this);
    this.handleChangeQuestionDifficulty = this.handleChangeQuestionDifficulty.bind(this);
    this.handleChangeQuestionType = this.handleChangeQuestionType.bind(this);
    this.handleChangeQuestionCategory = this.handleChangeQuestionCategory.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.orderQuestions = this.orderQuestions.bind(this)
    this.handleSelectQuestion = this.handleSelectQuestion.bind(this);
    this.onViewFinalizeQuestion = this.onViewFinalizeQuestion.bind(this);
    //this.handleDeleteSelectedQuestions = this.handleDeleteSelectedQuestions.bind(this);
  }

  async componentDidMount() {
    if (window.KTSelect2) window.KTSelect2.init();
    if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
    if (window.KTIONRangeSlider) window.KTIONRangeSlider.init();
    if (window.KTApp.initComponents) window.KTApp.initComponents();
    if (window.$) window.$(".selectpicker").selectpicker();
    if (typeof window.KTLayoutStickyCard !== "undefined") {
      window.KTLayoutStickyCard.update();
    } else {
      if (window.KTLayoutStickyCard)
        window.KTLayoutStickyCard.init("kt_page_sticky_card");
    }

    this.setState({ loadingSetup: true });
    await this.props.getQuestionMetaData();
    await this.props.getStaffUsers();
    if (_.isEqual({}, this.props.staffUserMap)) {
      this.props.createStaffUserMap();
    }
    this.setState({ loadingSetup: false });

    if (this.props.allStaffUsers.length > 0) {
      this.setState({ loadingSetup: true })
      var staffuser, teacher;
      await this.props.getAllTeachers();
      for (staffuser of this.props.allStaffUsers) {
        for (teacher of this.props.allTeachers)
          if (staffuser.staff_id === teacher.user_id && staffuser.role_code === 'ASSR') {
            let map = this.state.teacherDetailsMap;
            let teacherName = teacher.first_name.concat(" ", teacher.last_name)
            map[staffuser.staff_id] = teacherName
            this.setState({ teacherDetailsMap: map })
          }
      }
      this.setState({ loadingSetup: false })
    }

    this.orderQuestions();
    await this.props.getAllQuestions();
    await this.props.getAllLibraryQuestions();
    await this.props.getQuestionCategories();
    await this.props.getQuestionTypes();
    await this.props.getQuestionDifficulty();
    await this.props.getAllQuestionContentsByType({
      questions: this.props.assessmentQuestions,
    });

    await this.props.assessment;
    this.setState({ assessment_title: this.props.assessment.title });
    window.onpopstate = function (event) {
      event.preventDefault();
      history.push(routes.view_assessment);
      window.location.reload();
    };

    let disabled
    let ques = await this.props.assessmentQuestions.filter(val => val?.sub_questions === null)
    for (let k = 0; k <= ques.length - 1; k++) {
      if (ques[k]?.mark_allocation === 0) {
        disabled = true
      }
    }

    this.setState({ assessment_questions: this.props.assessmentQuestions, disabled: disabled });

    this.onDatatableActionEvent();
  }

  async componentDidUpdate() {
    if (
      this.props.assessmentQuestions &&
      this.props.assessmentQuestions.length &&
      !this.state.assessment_questions.length &&
      !this.state.deletion
    ) {
      var question_list = this.props.assessmentQuestions,
        i;

      for (i = 0; i < question_list.length; i++) {
        question_list[i]["question_number"] = i + 1; //adding "question_number" as prperty for each questions
      }
      this.setState({ assessment_questions: question_list });
      this.setState({ print_questions: question_list });
    }

    if (
      this.props.assessmentQuestions &&
      this.props.assessmentQuestions.length > 0 &&
      !this.props.question
    ) {
      await this.props.getQuestionContent(this.props.sharedQuestions[0]);
    }

    if (
      this.props.assessmentQuestions &&
      this.props.assessmentQuestions.length &&
      this.state.get_content
    ) {
      this.setState({ get_content: false });
      await this.props.getAllQuestionContents({
        questions: this.props.assessmentQuestions,
      });
    }

    let disabled
    let ques = await this.props.assessmentQuestions.filter(val => val?.sub_questions === null)
    for (let k = 0; k <= ques.length - 1; k++) {
      if (ques[k]?.mark_allocation === 0) {
        disabled = true
      }
    }

    if (
      this.state.assessment_questions &&
      this.state.assessment_questions.length &&
      _.isEqual({}, this.state.mark_allocation)
    ) {
      var list = {},
        pos = {};
      var i,
        total = 0;

      for (i = 0; i < this.state.assessment_questions.length; i++) {
        var question_id = this.state.assessment_questions[i].question_id; //getting each question_id
        /*pos = {
          1: "",
          2: "",
          3: ""
        } pos will be like this
        */
        pos[question_id] = "";
        if (this.state.assessment_questions[i].mark_allocation) {
          list[question_id] = this.props.assessmentQuestions[i].mark_allocation;
          total = total + this.props.assessmentQuestions[i].mark_allocation;
        } else {
          list[question_id] = "";
        }
      }

      this.setState({
        mark_allocation: list,
        total: total,
        position_list: pos, //taking the positions list of above quesitons
        disabled: disabled
      });
    }
    window.$("#finalize-tab").children().prop("disabled", false);
    window.$(".selectPicker").selectpicker("refresh");
    window.$("#question-difficulty").selectpicker("refresh");
    window.$("#question-type").selectpicker("refresh");
    window.$("#question-category").selectpicker("refresh");
  }

  onDatatableActionEvent() {
    window.$(document).on('click', '.view-question-btn', (e) => {
      const _target = e.target
      const idx = _target.getAttribute('dataindex')
      this.onViewFinalizeQuestion(this.state.assessment_questions[idx])
      window.$('#view-details').modal('show')
    })
  }

  handleChangeChk = (e) => {
    let checked = !this.state.chkbox;
    this.setState({ chkbox: checked });
  }

  handleChangeQuestionType(e) {
    let type = e.target.value;
    this.setState({ selectedType: type });
  }

  handleChangeQuestionDifficulty(e) {
    let difficulty = e.target.value;
    this.setState({ selectedDifficulty: difficulty });
  }

  handleChangeQuestionCategory(e) {
    let category = e.target.value;
    this.setState({ selectedCategory: category });
  }

  getQuestionCreator(user_id) {
    var id;

    if (user_id.startsWith("ASSR")) {
      id = user_id.replace(/^ASSR/, "");
    } else {
      id = user_id.replace(/^CASS/, "");
    }

    var staff_id = this.props.staffUserMap[id]["staff_id"];

    var creatorName = this.state.teacherDetailsMap[staff_id];

    return creatorName;
  }

  getMarks() {
    return 0;
  }

  searchChange(e) {
    let val = e.target.value;
    this.setState({
      searchValue: val,
    });
  }

  // checking whether all questions are MCQS or not
  checkingForAllMcqs() {
    var AllMcqs = true;
    var question_list = this.state.assessment_questions,
      i;
    for (i = 0; i < this.state.assessment_questions.length; i++) {
      if (question_list[i]["question_type"] !== "MCQ") {
        AllMcqs = false;
      }
    }

    return AllMcqs;
  }

  async onViewFinalizeQuestion(element, e) {
    
    this.setState({
      editorQuestionState: '',
      editorAnswerStates: '',
      editorAnswerStateDescriptive: ''
    })
    let tempAnswerStates = [];
    let tempAnswer = [];
    this.setState({ isViewModalLoading: true });
    await this.props.getLibraryQuestionContent(element);
    if(this.props?.libraryQuestionContent){
      let states = await JSON.parse(this.props?.libraryQuestionContent);
      this.setState({states: states});
      let tempQuestionState = EditorState.createWithContent(convertFromRaw(states[0]));
      this.setState({ editorQuestionState: tempQuestionState });
    }
    if (element?.question_type === questionTypeConstants.DESCRIPTIVE.name) {

      await this.props.getLibraryAnswerContent(element);
      let states = await JSON.parse(this.props?.libraryAnswerContent);
      let Ans = EditorState.createWithContent(convertFromRaw(states[0]))
      this.setState({ editorAnswerStateDescriptive: Ans })

    } else if (element?.question_type === questionTypeConstants.SUBQUESTION.name && element.is_subquestion === "true") {

      await this.props.getLibraryAnswerContent(element);
      let states = await JSON.parse(this.props?.libraryAnswerContent);
      let Ans = EditorState.createWithContent(convertFromRaw(states[0]))
      this.setState({ editorAnswerStateDescriptive: Ans })

    }
    else if (element?.question_type === questionTypeConstants.MCQ.name) {
      this.state.states.shift();
      this.state.states.forEach(state => {
        tempAnswerStates.push(EditorState.createWithContent(convertFromRaw(state)))
      });
      this.props.libraryQuestion.answer.split(',').map((answer) => {
        tempAnswer.push(parseInt(answer) > 0);
      });
      this.setState({
        editorAnswerStates: tempAnswerStates,
        selectedChoices: tempAnswer
      })

    } else if (element?.question_type === questionTypeConstants.SUBMISSION.name) {
      await this.props.getLibraryAnswerContent(element);
      let attachmentName = this.props?.libraryAnswerContent.replace(/^\[(.+)\]$/, "$1").replace(/"\"$/, "$1").split(',');
      this.setState({
        attachment: attachmentName
      });
    }
    this.setState({ isViewModalLoading: false });
  }

  orderQuestions() {
    var question_list = this.state.assessment_questions,
      i;
    for (i = 0; i < this.state.assessment_questions.length; i++) {
      question_list[i]["question_number"] = i + 1;
    }
    this.setState({
      assessment_questions: question_list,
    });
  }

  async onChangeMarks(e, el, i) {
    var marks = this.state.mark_allocation;
    marks[el.question_id] = e.target.value;
    this.setState({ mark_allocation: marks });
  }

  handleSelectQuestion(e) {
    // select multiple questions from list of questions in the assessment
    var Squestions = this.state.selectedQuestions;
    if (e.target.checked) {
      Squestions.push(e.target.value);
    } else {
      var index = Squestions.indexOf(e.target.value);
      if (index !== -1) {
        Squestions.splice(index, 1);
      }
    }
    this.setState({
      selectedQuestions: Squestions,
    });
  }
  async onDelete(e, el) {
    // Delete one selected question from an assessment
    this.setState({
      deletion: true,
    });

    await window.Swal.fire({
      text: "You will loose the current marks/position allocation. Do you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: !1,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: `btn font-weight-bold ${cancelClass}`,
        cancelButton: `btn font-weight-bold ${confirmClass}`,
      },
      onOpen: (modalElement) => {
        if (customer === customerConstants.EXO_CUSTOMER2) {
          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
        }
      }
    }).then(async (action) => {
      if (action.isConfirmed) {
        var assessment_questions = this.state.assessment_questions;
        var questions = this.state.to_delete;
        var marks = this.state.mark_allocation;
        var pos = this.state.position_list;
        if (el?.sub_questions !== null) {
          var ind = assessment_questions.indexOf(el);

          questions.push(el.question_id);

          if (index !== -1) {
            assessment_questions.splice(ind, 1);
          }

          delete pos[el.question_id];
          delete marks[el.question_id];
          let subs = el?.sub_questions.split(",")
          for (let i = 0; i <= subs.length - 1; i++) {

            var index = assessment_questions.findIndex((val) => (val?.question_id === parseInt(subs[i])))
            var total = this.state.total - marks[parseInt(subs[i])];

            questions.push(parseInt(subs[i]));

            if (index !== -1) {
              assessment_questions.splice(index, 1);
            }

            delete pos[parseInt(subs[i])];
            delete marks[parseInt(subs[i])];
            this.setState({ total: total })


          }
          this.props.getAllQuestionContents({ questions: assessment_questions });

          this.setState({
            to_delete: questions,
            assessment_questions: assessment_questions,
            mark_allocation: marks,
            position_list: pos,
            changes: true,
            total: total,
          });
          this.orderQuestions();
        } else {
          var index = assessment_questions.indexOf(el);
          var total = this.state.total - marks[el.question_id];

          questions.push(el.question_id);

          if (index !== -1) {
            assessment_questions.splice(index, 1);
          }

          delete pos[el.question_id];
          delete marks[el.question_id];

          this.props.getAllQuestionContents({ questions: assessment_questions });

          this.setState({
            to_delete: questions,
            assessment_questions: assessment_questions,
            mark_allocation: marks,
            position_list: pos,
            changes: true,
            total: total,
          });
          this.orderQuestions();
        }
      }
    });
  }

  async handleDeleteSelectedQuestions() {
    // Delete selected multiple questions
    if (this.state.selectedQuestions.length === 0) {
      await window.Swal.fire({
        text: "No questions selected.",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
      return;
    }
    this.setState({
      deletion: true,
    });

    await window.Swal.fire({
      text: "You will lose the current marks/position allocation. Do you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: !1,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: `btn font-weight-bold ${cancelClass}`,
        cancelButton: `btn font-weight-bold ${confirmClass}`,
      },
      onOpen: (modalElement) => {
        if (customer === customerConstants.EXO_CUSTOMER2) {
          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
        }
      }
    }).then(async (action) => {
      if (action.isConfirmed) {
        var questions = this.state.selectedQuestions;
        this.setState({ to_delete: questions });
        var assessment_questions = this.state.assessment_questions.slice();

        var marks = { ...this.state.mark_allocation }; // create a copy of the object
        var pos = { ...this.state.position_list }; // create a copy of the object
        var total = this.state.total;
        questions.forEach((question_id) => {
          let index = -1;
          assessment_questions.forEach((question, i) => {
            if (question.question_id == question_id) {
              index = i;
              if (index !== -1) {
                assessment_questions.splice(index, 1);
                total -= marks[question_id];
                delete marks[question_id];
                delete pos[question_id];
              }
            }
          });
        });

        this.setState({
          to_delete: questions,
          assessment_questions: assessment_questions,
          mark_allocation: marks,
          position_list: pos,
          changes: true,
          total: total,
          selectedQuestions: [],
        });
        this.orderQuestions();
        await this.onSave();
      }
    });
  }

  async onSave() {
    const assessment_id = this.props.assessment.assessment_id;
    const assessor_id = this.props.assessment.user_role_id;
    const user_id = sessionStorage.getItem("user_id");
    var delete_questions = this.state.to_delete;
    const user_role_id = this.props.assessment.user_role_id;

    var questions = this.state.assessment_questions;

    window.Swal.showLoading();
    if (delete_questions.length) {
      await this.props.removeMyQuestions({
        assessment_id,
        delete_questions,
        assessor_id,
        user_id,
      });
      if (this.props.updateError != "") {
        window.Swal.fire({
          title: messageTypes.FAILED_WARNING,
          text: this.props.updateError,
          icon: messageTypes.ERROR,
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
          }
        });
      } else {
        await this.props.saveUpdates({
          assessment_id,
          questions,
          user_role_id,
        });

        if (this.props.updateError == "") {
          this.setState({
            to_delete: [],
          });
          window.Swal.fire({
            title: messageTypes.SUCCESS_WARNING,
            text: this.props.updateSuccess,
            icon: messageTypes.SUCCESS,
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
          });
        } else {
          window.Swal.fire({
            itle: messageTypes.FAILED_WARNING,
            text: this.props.updateError,
            icon: messageTypes.ERROR,
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
          });
        }
      }
    } else {
      await this.props.saveUpdates({ assessment_id, questions, user_role_id });
      if (this.props.updateError == "") {
        window.Swal.fire({
          title: messageTypes.SUCCESS_WARNING,
          text: this.props.updateSuccess,
          icon: messageTypes.SUCCESS,
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
          }
        });
        let disabled
        let ques = await this.props.assessmentQuestions.filter(val => val?.sub_questions === null)
        for (let k = 0; k <= ques.length - 1; k++) {
          if (ques[k]?.mark_allocation === 0) {
            disabled = true
          }
        }
        this.setState({ disabled: disabled })
      } else {
        window.Swal.fire({
          title: messageTypes.FAILED_WARNING,
          text: this.props.updateError,
          icon: messageTypes.ERROR,
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
          }
        });
      }
    }
    this.setState({
      changes: false,
    });
  }

  async finalizeAssessment() {
    window.Swal.showLoading();
    await this.props.finalizeAssessment(this.props.assessment);
    if (this.props.finalizeError == "") {
      window.Swal.fire({
        title: messageTypes.SUCCESS_WARNING,
        text: this.props.finalizeSuccess,
        icon: messageTypes.SUCCESS,
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(routes.view_assessment);
          window.location.reload();
        }
      });
    } else {
      window.Swal.fire({
        title: messageTypes.FAILED_WARNING,
        text: this.props.finalizeError,
        icon: messageTypes.ERROR,
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
      });
    }
  }

  async makeEditableAssessment() {
    window.Swal.showLoading();
    await this.props.finalizeAssessment(this.props.assessment);
    if (this.props.finalizeError == "") {
      window.Swal.fire({
        title: messageTypes.SUCCESS_WARNING,
        text: this.props.finalizeSuccess,
        icon: messageTypes.SUCCESS,
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
      });
    } else {
      window.Swal.fire({
        title: messageTypes.FAILED_WARNING,
        text: this.props.finalizeError,
        icon: messageTypes.ERROR,
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(routes.view_assessment);
          window.location.reload();
        }
      });
    }
  }

  onPositionChange() {
    var assessmentQuestionsNew = this.props.assessmentQuestions;
    var questions = this.state.assessment_questions;
    var questionContent = this.props.questionContents;
    var position_list = this.state.position_list;
    var new_assQ = new Array(this.props.assessmentQuestions.length).fill("");
    var new_q = new Array(this.props.assessmentQuestions.length).fill("");
    var new_qcnt = new Array(this.props.assessmentQuestions.length).fill("");

    var tmp_assQ = [],
      tmp_q = [],
      tmp_qcnt = [];

    var i = 0,
      k = 0;

    for (i = 0; i < this.state.assessment_questions.length; i++) {
      var question_id = this.state.assessment_questions[i]["question_id"];
      if (position_list[question_id] != "" && position_list[question_id] > 0) {
        var newIndex = position_list[question_id] - 1;
        new_assQ[newIndex] = assessmentQuestionsNew[i];
        new_q[newIndex] = questions[i];
        new_qcnt[newIndex] = questionContent[i];
        position_list[question_id] = "";
      } else {
        tmp_assQ.push(assessmentQuestionsNew[i]);
        tmp_q.push(questions[i]);
        tmp_qcnt.push(questionContent[i]);
      }
    }

    for (i = 0; i < this.state.assessment_questions.length; i++) {
      if (new_assQ[i] == "") {
        new_assQ[i] = tmp_assQ[k];
        new_q[i] = tmp_q[k];
        new_qcnt[i] = tmp_qcnt[k];
        k = k + 1;
      }

      new_q[i]["question_number"] = i + 1;
    }

    this.setState({
      assessment_questions: new_q,
      questionContents: new_qcnt,
      position_list: position_list,
      changes: true,
    });

    this.props.applyPositionChangeLocal({ list: new_assQ, contents: new_qcnt });
  }

  //function for printing all available questions
  handlePrint = async () => {
    await this.props.getAllQuestionContentsByType({
      questions: this.state.assessment_questions,
    });

    this.setState({
      questions_contents_by_type: this.props.questionContentsByType,
    });

    let questionsByType = this.state.questions_contents_by_type
    let html = "";

    if (questionsByType["MCQ"]) {
      html += "<h3>Section 1: Multiple Choice Questions</h3>";
      html += "<p>Underline the suitable answer</p>";

      html += questionsByType["MCQ"]
        .map((q, i) => {
          const blocks = JSON.parse(q)[0].blocks;

          if (blocks && blocks.length > 0) {
            let questions = ""; // Move the initialization here
            let answers = ""; // Move the initialization here

            blocks.forEach((block) => {
              let text = block.text;
              let inlineStyleRanges = block.inlineStyleRanges || [];

              // Process inline style ranges for formatting
              if (inlineStyleRanges.length > 0) {
                let formattedText = ""
                let lastIndex = 0

                inlineStyleRanges.forEach((range) => {
                  const { offset, length, style } = range
                  const substring = text.slice(offset, offset + length)

                  // Append the preceding unformatted text
                  formattedText += text.slice(lastIndex, offset)

                  // Apply the appropriate formatting to the substring
                  if (style === "BOLD") {
                    formattedText += `<strong>${substring}</strong>`
                  } else if (style === "ITALIC") {
                    formattedText += `<em>${substring}</em>`
                  } else if (style === "UNDERLINE") {
                    formattedText += `<u>${substring}</u>`
                  }

                  lastIndex = offset + length
                })

                // Append the remaining unformatted text
                formattedText += text.slice(lastIndex)

                text = formattedText
              }

              questions += text
            })

            answers = JSON.parse(q)
              .slice(1)
              .map((a, j) => {
                let answerText = ""
                let answerImage = ""

                if (a.blocks && a.blocks.length > 0) {
                  answerText = a.blocks[0].text
                  let inlineStyleRanges = a.blocks[0].inlineStyleRanges || []

                  // Process inline style ranges for formatting
                  if (inlineStyleRanges.length > 0) {
                    let formattedText = ""
                    let lastIndex = 0

                    inlineStyleRanges.forEach((range) => {
                      const { offset, length, style } = range
                      const substring = answerText.slice(
                        offset,
                        offset + length
                      )

                      // Append the preceding unformatted text
                      formattedText += answerText.slice(lastIndex, offset)

                      // Apply the appropriate formatting to the substring
                      if (style === "BOLD") {
                        formattedText += `<strong>${substring}</strong>`
                      } else if (style === "ITALIC") {
                        formattedText += `<em>${substring}</em>`
                      } else if (style === "UNDERLINE") {
                        formattedText += `<u>${substring}</u>`
                      }

                      lastIndex = offset + length
                    })

                    // Append the remaining unformatted text
                    formattedText += answerText.slice(lastIndex)

                    answerText = formattedText
                  }
                }

                if (typeof a.entityMap === "object" && a.entityMap !== null) {
                  Object.values(a.entityMap).forEach((entityMap) => {
                    if (entityMap.type === "IMAGE") {
                      const imageUrl = entityMap.data.src
                      answerImage = `<img src="${imageUrl}" alt="Image" />`
                    }
                  })
                }

                return `<div>${String.fromCharCode(97 + j)}) ${answerImage ? answerImage : answerText
                  }</div>`
              })
              .join("")

            return `<div style="margin-left: 20px;">${i + 1
              }.${questions}<br><div style="margin-left: 35px;">${answers}</div></div>`
          }
        })
        .join("")
    }

    if (questionsByType["Descriptive"]) {
      html += "<h3>Section 2: Short Answer</h3>"
      html += "<p>Write a short answer</p>"

      html += questionsByType["Descriptive"]
        .map((q, i) => {
          const content = JSON.parse(q)[0]
          const blocks = content.blocks
          const entityMap = content.entityMap

          let question = ""
          let answers = ""

          if (entityMap && blocks && blocks.length > 0) {
            blocks.forEach((block) => {
              let text = block.text
              let inlineStyleRanges = block.inlineStyleRanges || []
              let entityRanges = block.entityRanges || []
              let formattedText = ""

              let currentIndex = 0
              inlineStyleRanges.forEach((range) => {
                const nonFormattedSubstring = text.slice(
                  currentIndex,
                  range.offset
                )
                const formattedSubstring = text.slice(
                  range.offset,
                  range.offset + range.length
                )
                formattedText += nonFormattedSubstring

                if (range.style === "BOLD") {
                  formattedText += `<strong>${formattedSubstring}</strong>`
                } else if (range.style === "ITALIC") {
                  formattedText += `<em>${formattedSubstring}</em>`
                } else if (range.style === "UNDERLINE") {
                  formattedText += `<u>${formattedSubstring}</u>`
                }

                currentIndex = range.offset + range.length
              })

              formattedText += text.slice(currentIndex)

              question += formattedText
            })
          }

          return `<div style="margin-left: 20px;font-family: Arial;">${i + 1
            }. ${question}<br></div>`
        })
        .join("")
    }

    if (questionsByType["Submission"]) {
      html += "<h3>Section 3: Essay</h3>"
      html += "<p>Write answer in additional paper and attach it</p>"

      html += questionsByType["Submission"]
        .map((q, i) => {
          const blocks = JSON.parse(q)[0].blocks

          let question = ""

          if (blocks && blocks.length > 0) {
            blocks.forEach((block) => {
              let text = block.text
              let inlineStyleRanges = block.inlineStyleRanges || []

              if (inlineStyleRanges.length > 0) {
                let formattedText = ""
                let lastIndex = 0

                inlineStyleRanges.forEach((range) => {
                  const { offset, length, style } = range
                  const substring = text.slice(offset, offset + length)

                  formattedText += text.slice(lastIndex, offset)

                  if (style === "BOLD") {
                    formattedText += `<strong>${substring}</strong>`
                  } else if (style === "ITALIC") {
                    formattedText += `<em>${substring}</em>`
                  } else if (style === "UNDERLINE") {
                    formattedText += `<u>${substring}</u>`
                  }
                  // Add additional conditions for other formatting options
                  else if (style === "STRIKETHROUGH") {
                    formattedText += `<del>${substring}</del>`
                  } else if (style === "HIGHLIGHT") {
                    formattedText += `<span style="background-color: yellow;">${substring}</span>`
                  }
                  // Add more conditions for other formatting options as needed

                  lastIndex = offset + length
                })

                formattedText += text.slice(lastIndex)

                text = formattedText
              }

              question += text
            })
          }

          return `<div style="margin-left: 20px;">${i + 1
            }. ${question}<br></div>`
        })
        .join("")
    }

    const fullHtml = `
    <html>
      <head>
        <title>Exam</title>
      </head>
      <body>
        <h4><u>Test Assessment</u></h4>
        <p>Instructions: Please read each question carefully and select the best answer.</p>
        ${html}
      </body>
    </html>
  `

    const popupWindow = window.open("", "_blank")
    popupWindow.document.write(fullHtml)
    popupWindow.print()
  }
  // function to get autoshuffle done for only for mcqs
  handleAutoShuffle = () => {
    this.setState(
      (prevState) => ({
        auto_shuffle: !prevState.auto_shuffle,
      }),
      () => {
        try {
          const requestOptions = {
            method: "POST",
            headers: APIHandler.getHeader(
              config.azure_ad_config.apis.ASSESSMENT.name
            ),
            body: JSON.stringify({
              auto_shuffle: this.state.auto_shuffle,
            }),
          }

          fetch(
            `${config.oat_api_url}/assessment/autoshuffle-mcqs/${this.props.assessment.assessment_id}`,
            requestOptions
          ).then(APIHandler.handleResponse)
        } catch (err) {
          return { error: "Some error occured. Please try again!" }
        }
      }
    )
  }

  onChangeAllMarks = (j) => {
    var list = this.state.mark_allocation
    const keys = Object.keys(list)
    for (let key of keys) {
      list[key] = j.target.value
    }
    this.setState({ mark_allocation: list, allMarks: j.target.value })
  }

  onSetAllMarks = () => {
    var total = this.state.total
    var question_list = this.state.assessment_questions.filter(val => val?.sub_questions === null)
    if (this.state.allMarks > 0) {
      for (var i = 0; i <= question_list.length - 1; i++) {
        if (question_list[i]?.sub_questions === null) {
          question_list[i].mark_allocation = this.state.allMarks
          this.onSetMarks(question_list[i], i);

        }
      }
      total = this.state.allMarks * question_list.length
      this.setState({
        total: total,
        changes: true
      })
    } else {
      window.Swal.fire({
        title: messageTypes.WARNING,
        text: "Invalid Mark Allocated.",
        icon: messageTypes.WARNING,
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
      })
    }
  }

  onSetMarks = (el, val) => {
    var list = this.state.mark_allocation
    var question_list = this.state.assessment_questions
    var total = this.state.total
    var question_id = el.question_id
    var index = _.findIndex(question_list, { question_id: question_id })
    if (
      !isNaN(parseFloat(list[question_id])) &&
      parseFloat(list[question_id]) != 0
    ) {
      if (parseFloat(question_list[index].mark_allocation)) {
        total = total - parseFloat(question_list[index].mark_allocation)
      }
      total = total + parseFloat(list[question_id])
      question_list[index].mark_allocation = parseFloat(list[question_id])
      this.setState({
        assessment_questions: question_list,
        total: total,
        changes: true,
      })
    } else if (list[question_id] == "") {
      this.setState({
        assessment_questions: question_list,
        total: total,
        changes: true,
      })
      list[question_id] =
        this.state.assessment_questions[index]["mark_allocation"]
      this.setState({
        mark_allocation: list,
      })
      window.Swal.fire({
        title: messageTypes.WARNING,
        text: "Not a Number. Mark allocated will be reverted to previous value.",
        icon: messageTypes.WARNING,
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
      })
    } else {
      list[question_id] =
        this.state.assessment_questions[index]["mark_allocation"]
      window.Swal.fire({
        title: messageTypes.WARNING,
        text: "Mark allocated is reverted to previous value.",
        icon: messageTypes.WARNING,
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
      })
      this.setState({
        mark_allocation: list,
      })
    }
  }

  async onDownloadAttachment(e, attach) {
    e.preventDefault()
    let fileReader;
    async function handleFileRead() {
      const content = fileReader.result;
    }
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    let containerName = config.storageBlobConfigs.containerName;
    // let content = await AzureStorage.downloadBlob("".concat(this.props.libraryQuestion?.created_by, "-sa-", this.props.libraryQuestion?.answer), attach);
    let content = await AzureStorage.downloadBlob(containerName, attach);
    fileReader.readAsArrayBuffer(content);
  }

  addQuestion = () => {
    if (this.state.changes) {
      window.Swal.fire({
        text: "You have unsaved changes in this page. Save them before adding new questions",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: !1,
        confirmButtonText: "Discard and proceed",
        cancelButtonText: "Stay on page",
        customClass: {
          confirmButton: `btn font-weight-bold ${cancelClass}`,
          cancelButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
          }
        }
      }).then(async (action) => {
        if (action.isConfirmed) {
          this.setState({
            add_question: true,
            mark_allocation: {},
            assessment_questions: [],
          })
        }
      })
    } else {
      this.setState({
        add_question: true,
        mark_allocation: {},
        assessment_questions: [],
      })
    }
  }

  renderStudentView = async() => {
    await this.props.getAssessmentQuestions(this.props.assessment.assessment_id);
    await this.props.getAllQuestionContents({
      questions: this.props.assessmentQuestions,
    });
    this.setState({studentView: true});
  }

  resetAll = () => {
    if (this.state.changes) {
      window.Swal.fire({
        text: "All the unsaved changes will be reverted. Do you want to proceed?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: !1,
        confirmButtonText: "Discard and reset",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: `btn font-weight-bold ${cancelClass}`,
          cancelButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
          }
        }
      }).then(async (action) => {
        if (action.isConfirmed) {
          this.props.getAssessmentQuestions(this.props.assessment.assessment_id)
          this.setState({
            deletion: false,
            assessment_questions: [],
            to_delete: [],
            total: "",
            errors: {},
            mark_allocation: {},
            position_list: {},
            changes: false,
            get_content: true,
            add_question: false,
            searchValue: "",
            selectedCategory: 0,
            selectedType: 0,
            selectedDifficulty: 0,
            chkbox: false,
            filteredQuestions: [],
            selectedQuestion: [],
          })
        }
      })
    } else {
      this.props.getAssessmentQuestions(this.props.assessment.assessment_id)
      this.setState({
        deletion: false,
        assessment_questions: [],
        to_delete: [],
        total: "",
        errors: {},
        mark_allocation: {},
        position_list: {},
        changes: false,
        get_content: true,
        add_question: false,
        searchValue: "",
        selectedCategory: 0,
        selectedType: 0,
        selectedDifficulty: 0,
        chkbox: false,
        filteredQuestions: [],
      })
    }
  }

  getEditability(user_id) {
    var role_permission =
      this.props.assessment["role_code"] == "CASS" ||
      user_id.endsWith(sessionStorage.getItem("user_id"))
    var assessment_state =
      this.props.assessment.assessment_state == "Ready" ||
      this.props.assessment.assessment_state == "Finished"
    return role_permission && !assessment_state
  }

  getEditabilityAssessment() {
    var assessment_state =
      this.props.assessment.assessment_state == "Ready" ||
      this.props.assessment.assessment_state == "Finished"
    return !assessment_state
  }

  questionsFilter(questions, type, difficulty, category) {
    let filteredQuestions = []
    var userId = DataHandler.getFromSession("user_id")
    var questionLibrary = this.props.questions
    var qType
    var qDifficulty
    var qCategory
    if (this.state.chkbox) {
      questions.map((question) => {
        if (question.added_by.includes(userId)) {
          questionLibrary.map((q) => {
            if (q.question_id === question.question_id) {
              this.props.allCategories.map((el, i) => {
                if (el.category_id === q.category_id) {
                  qCategory = el.name
                }
              })
              this.props.allQuestionTypes.map((el, i) => {
                if (el.type_id === q.qtype) {
                  qType = el.qtype
                }
              })
              this.props.allDifficulty.map((el, i) => {
                if (el.difficulty_id === q.difficulty_id) {
                  qDifficulty = el.difficulty_type
                }
              })
            }
          })
          if (category == 0 && type == 0 && difficulty == 0) {
            filteredQuestions.push(question)
          } else if (category == qCategory && type == 0 && difficulty == 0) {
            filteredQuestions.push(question)
          } else if (category == 0 && type == qType && difficulty == 0) {
            filteredQuestions.push(question)
          } else if (category == 0 && type == 0 && difficulty == qDifficulty) {
            filteredQuestions.push(question)
          } else if (
            category == qCategory &&
            type == qType &&
            difficulty == 0
          ) {
            filteredQuestions.push(question)
          } else if (
            category == qCategory &&
            type == 0 &&
            difficulty == qDifficulty
          ) {
            filteredQuestions.push(question)
          } else if (
            category == 0 &&
            type == qType &&
            difficulty == qDifficulty
          ) {
            filteredQuestions.push(question)
          } else if (
            category == qCategory &&
            type == qType &&
            difficulty == qDifficulty
          ) {
            filteredQuestions.push(question)
          }
        }
      })
    } else {
      questions.map((question) => {
        this.props.assessmentQuestions.map((q) => {
          if (q.question_id === question.question_id) {
            this.props.allCategories.map((el, i) => {
              if (el.category_id === q.question_category) {
                qCategory = el.name
              }
            })
            this.props.allQuestionTypes.map((el, i) => {
              if (el.qtype === q.question_type) {
                qType = el.qtype
              }
            })
            this.props.allDifficulty.map((el, i) => {
              if (el.difficulty_id === q.question_difficulty) {
                qDifficulty = el.difficulty_type
              }
            })
          }
        })

        if (category == 0 && type == 0 && difficulty == 0) {
          filteredQuestions.push(question)
        } else if (category == qCategory && type == 0 && difficulty == 0) {
          filteredQuestions.push(question)
        } else if (category == 0 && type == qType && difficulty == 0) {
          filteredQuestions.push(question)
        } else if (category == 0 && type == 0 && difficulty == qDifficulty) {
          filteredQuestions.push(question)
        } else if (category == qCategory && type == qType && difficulty == 0) {
          filteredQuestions.push(question)
        } else if (
          category == qCategory &&
          type == 0 &&
          difficulty == qDifficulty
        ) {
          filteredQuestions.push(question)
        } else if (
          category == 0 &&
          type == qType &&
          difficulty == qDifficulty
        ) {
          filteredQuestions.push(question)
        } else if (
          category == qCategory &&
          type == qType &&
          difficulty == qDifficulty
        ) {
          filteredQuestions.push(question)
        }
      })
    }

    return filteredQuestions
  }

  questionTable(showingQuestionsList) {

    const handleDragEnd = async (e) => {
      if (!e.destination) return;

      let tempData = Array.from(this.state.assessment_questions);
      let [source_data] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, source_data);

      const newAssessmentQuestions = [...tempData];

      const position_list = {};

      // Update position_list based on the new order
      newAssessmentQuestions.forEach((el, index) => {
        position_list[el.question_id] = index + 1;
      });

      // Update the state asynchronously
      await this.setState({
        assessment_questions: newAssessmentQuestions,
        position_list: position_list,
      });

      // Trigger onPositionChange after the state is updated
      this.onPositionChange();
      //this.onSave();
    }

    return (
      this.state.assessment_questions &&
      this.state.assessment_questions.length && (
        <>
          <div className="col-md-12 d-flex align-items-center">
            <div className="col-md-6">
              <div className="bg-gray-200 rounded-lg p-2 m-total-marks text-center">
                <h5 className="text-dark font-weight-bold d-block font-size-h5">
                  Total Marks
                </h5>
                <h3 className="text-dark font-weight-bold font-size-h5 m-0">
                  {this.state.total}
                </h3>
              </div>
            </div>
            <SearchInputWrap className="col-md-6 float-right">
              <SearchInput
                label="Search Questions"
                value={this.state.searchValue}
                onChange={this.searchChange}
              />
            </SearchInputWrap>
          </div>

          <div className="col-md-12" style={{ overflow: "auto", height: "90vh" }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="thead"></TableCell>
                    <TableCell className="thead"></TableCell>
                    <TableCell className="thead"></TableCell>
                    <TableCell className="thead">Question Title</TableCell>
                    <TableCell className="thead">Added By</TableCell>
                    <TableCell className="thead">Marks</TableCell>
                    <TableCell className="thead">Actions</TableCell>
                  </TableRow>
                </TableHead>

                <Droppable droppableId="droppable-1">
                  {(provider) => (
                    <TableBody
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                    >
                      {showingQuestionsList.map((el, i) => (
                        <Draggable
                          key={el.question_id}
                          draggableId={el.answer}
                          index={i}
                        >
                          {(provider) => (
                            <TableRow
                              {...provider.draggableProps}
                              ref={provider.innerRef}
                            >
                              <TableCell
                                className="tData"
                                {...provider.dragHandleProps}
                              >
                                <b>≡</b>
                              </TableCell>
                              <TableCell>
                                {el.sub_questions !== null ? "Main Question" : el.is_subquestion === "true" ? "Sub Question" : el?.question_type}
                              </TableCell>

                              <TableCell className="tData">
                                {el?.sub_questions == null && (
                                  <div>
                                    <input
                                      type="checkbox"
                                      value={el.question_id}
                                      onChange={(e) => this.handleSelectQuestion(e)} />
                                  </div>
                                )}
                              </TableCell>
                              <TableCell className="tData">
                                {(i + 1) + ". " + el.title}
                              </TableCell>
                              <TableCell className="tData">
                                {this.getQuestionCreator(el.added_by)}
                              </TableCell>
                              <TableCell className="tData">
                                {el.sub_questions === null && (
                                  <Marks
                                    viewAllocated={false}
                                    value={this.state.mark_allocation[el.question_id]}
                                    onChangeMarks={(e) => this.onChangeMarks(e, el, i)}
                                    onSetMarks={() => this.onSetMarks(el, i)}
                                    readOnly={!this.getEditability(el.added_by)}
                                    question={i} />
                                )}
                              </TableCell>
                              <TableCell className="tData">
                                <Button
                                  onClick={(e) => this.onViewFinalizeQuestion(el, e)}
                                  className="btn btn-icon action-btn btn-sm m-1 view-question-btn flaticon-eye-icon"
                                  dataIndex={i}
                                  dataToggle="modal"
                                  dataTarget="#view-details"
                                  dataBackDrop="static"
                                  title="View Details" />
                                <Button
                                  className="btn btn-icon btn-danger btn-sm flaticon-delete"
                                  dataIndex={i}
                                  title="Delete"
                                  onClick={(e) => this.onDelete(e, el)} />
                              </TableCell>
                            </TableRow>

                          )}
                        </Draggable>
                      ))}
                      {provider.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
          </div>
        </>
      )
    )
  }

  changeRoute() {
    window.location = routes.view_assessment
  }

  render() {
    if (this.state.add_question) {
      return <Redirect to={routes.finalize_new_question} />
    }
    if (this.state.studentView) {
      return <Redirect to={routes.student_view} />;
    }
    if (this.state.assessment_questions.length > 0) {
    }
    var questionOp = this.props.assessmentQuestions
    var qTypeNames = []
    var qCategoryNames = []
    var qDiffNames = []
    questionOp.map((q) => {
      if (!qTypeNames.includes(q.question_type)) {
        qTypeNames.push(q.question_type)
      }

      this.props.allCategories.map((el, i) => {
        if (el.category_id === q.question_category) {
          if (!qCategoryNames.includes(el.name)) {
            qCategoryNames.push(el.name)
          }
        }
      })

      this.props.allDifficulty.map((el, i) => {
        if (el.difficulty_id === q.question_difficulty) {
          if (!qDiffNames.includes(el.difficulty_type)) {
            qDiffNames.push(el.difficulty_type)
          }
        }
      })
    })

    var categoryOptions = []
    var typeOptions = []
    var difficultyOptions = []
    categoryOptions.push(
      <Option key={0} optionName={"None"} value={0} selected="selected" />
    )
    typeOptions.push(
      <Option key={0} optionName={"None"} value={0} selected="selected" />
    )
    difficultyOptions.push(
      <Option key={0} optionName={"None"} value={0} selected="selected" />
    )

    qCategoryNames.map((el, i) => {
      categoryOptions.push(<Option key={i + 1} optionName={el} value={el} />)
    })

    qTypeNames.map((el, i) => {
      typeOptions.push(<Option key={i + 1} optionName={el} value={el} />)
    })

    qDiffNames.map((el, i) => {
      difficultyOptions.push(<Option key={i + 1} optionName={el} value={el} />)
    })

    const filtered_questions = this.state.assessment_questions.filter(
      (question, i) => {
        return (
          question.title
            .toLowerCase()
            .indexOf(this.state.searchValue.toLowerCase()) !== -1
        )
      }
    )

    return (
      <AdminTemplate>
        <ContentWrapper>
          {this.props.loading && (
            <Spinner id="show" text="Loading, Please wait!" />
          )}
          {this.state.loadingSetup && <Spinner id="show" text="Please wait.." />}
          {/* {this.state.isViewModalLoading && <Spinner id="show" text="Please wait.." />} */}
          {/* begin::Card */}
          {this.state.assessment_title != undefined ? (
            <Card>
              <CardHeader>
                <CardTitle cardLabel={this.props.assessment.title}>
                  <Breadcrumb>
                    <BreadcrumbItem
                      to={routes.view_assessment}
                      breadcrumbItemName="Assessments Library"
                    />
                    <BreadcrumbItem
                      to={routes.finalize}
                      active="active"
                      breadcrumbItemName="Finalize"
                    />
                  </Breadcrumb>
                </CardTitle>
                <CardToolbar>

                  <Button
                    onClick={() => this.addQuestion()}
                    btnName="Add new question"
                    className="btn btn-primary mr-2 finalize-btn"
                    title="Add new question"
                    to="finalize-new-question"
                    disabled={
                      this.props.assessment.assessment_state == "Ready" ||
                      this.props.assessment.assessment_state == "Finished"
                    }
                  />

                  <Button
                    className="btn btn-danger btn-mr font-weight-bold mr-2 remove"
                    btnName="Delete"
                    // btnIconStyle="flaticon-trash"
                    onClick={() => this.handleDeleteSelectedQuestions()}
                    disabled={
                      this.props.assessment.assessment_state == "Ready" ||
                      this.props.assessment.assessment_state == "Finished"
                    }
                  />

                  <Button
                    onClick={() => this.resetAll()}
                    className="btn btn-primary finalize-btn mr-2"
                    btnName="Reset"
                  />

                  {/* <LinkButton
                    className="btn btn-primary finalize-btn mr-2"
                    linkName="Student View"
                    to={routes.student_view}
                    state={this.state.total}
                  /> */}

                  <Button
                    onClick={() => this.renderStudentView()}
                    className="btn btn-primary finalize-btn mr-2"
                    btnName="Student View"
                  />

                  <Button
                    className="btn btn-primary mr-2 finalize-btn"
                    btnName="Save Changes"
                    onClick={this.onSave}
                    disabled={
                      this.props.assessment.assessment_state == "Ready" ||
                      this.props.assessment.assessment_state == "Finished"
                    }
                  />

                  {this.props.assessment["role_code"] == "CASS" &&
                    this.props.assessment["assessment_state"] == "Initial" && (
                      <Button
                        className="btn btn-primary mr-2 finalize-btn"
                        btnName="Finalize"
                        btnIconStyle="flaticon2-correct"
                        onClick={this.finalizeAssessment}
                        disabled={this.state.disabled}
                      />
                    )}
                  {this.props.assessment["role_code"] == "CASS" &&
                    this.props.assessment["assessment_state"] == "Ready" && (
                      <Button
                        className="btn btn-primary mr-2 finalize-btn"
                        btnName="Make editable"
                        btnIconStyle="flaticon2-correct"
                        onClick={this.makeEditableAssessment}
                      />
                    )}

                  {/* to activate print button */}
                  {/* {this.props.assessment["assessment_state"] == "Ready" && (
                    <Button
                      className="btn btn-primary mr-2 finalize-btn"
                      btnName="Print"
                      onClick={() => this.handlePrint()}
                    />
                  )} */}

                  {/* <LinkButton className="btn btn-primary mr-2" linkName="Add new question" to="finalize-new-question" disabled={this.props.assessment.assessment_state == 'Ready' || this.props.assessment.assessment_state == 'Finished'} /> */}

                  <div style={{ width: "10px" }}></div>
                  {/* to autoshuffle checkBox */}
                  {this.props.assessment["assessment_state"] == "Initial" &&
                    this.checkingForAllMcqs() && (
                      <CheckBoxInline>
                        <CheckBox
                          checkBoxName="Autoshuffle MCQ"
                          // checked={this.state.auto_shuffle}
                          onChange={() => this.handleAutoShuffle()}
                        />
                      </CheckBoxInline>
                    )}
                </CardToolbar>
              </CardHeader>

              <div className="row mx-3 my-5 d-flex justify-content-center align-items-center"
                style={{
                  border: "1px solid #ffbc04",
                  borderRadius: "10px",
                  backgroundColor: "#fcf4dc",
                  borderLeft: "8px solid #ffbc04"
                }}
              >
                <div className="py-5 col-md-3 col-3 d-flex justify-content-end align-items-center">
                  <TipsAndUpdatesIcon sx={{ fontSize: "40px", color: "#ffbc04" }} />
                </div>
                <div className="py-5 col-md-9 col-9 d-flex align-items-center">
                  <p style={{ fontSize: "14px" }}>Make sure to click "<b>Save Changes</b>" after doing any modifications to this page!</p>
                </div>
              </div>

              <CardBody>
                <TableRow></TableRow>
                {/* begin::Search */}
                {this.state.assessment_questions &&
                  this.state.assessment_questions.length > 0 ? (
                  <>
                    <TableFilter>
                      <SearchFormGroup
                        labelName="My Questions"
                        className="col-lg-3 m-style"
                        labelColStyle="col-lg-8 col-6 col-form-label"
                        formColStyle="col-lg-4"
                      >
                        <CheckBoxInline>
                          <CheckBox
                            checked={this.state.chkbox}
                            onChange={this.handleChangeChk}
                          />
                        </CheckBoxInline>
                      </SearchFormGroup>

                      <SearchFormGroup
                        labelName="Category"
                        className="col-md-3 m-style"
                        labelColStyle="col-lg-12 col-form-label"
                      >
                        {categoryOptions.length && (
                          <Select
                            selectWrap="col-lg-12"
                            selectId="question-category"
                            id="question-category"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={categoryOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading && categoryOptions.length <= 0
                            }
                            disabled={
                              this.props.loading && categoryOptions.length <= 0
                            }
                            children={categoryOptions}
                            onChange={this.handleChangeQuestionCategory}
                          ></Select>
                        )}
                      </SearchFormGroup>

                      <SearchFormGroup
                        labelName="Type"
                        className="col-md-3 m-style"
                        labelColStyle="col-lg-12 col-form-label"
                      >
                        {typeOptions.length && (
                          <Select
                            selectWrap="col-lg-12"
                            selectId="question-type"
                            id="question-type"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={typeOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading && typeOptions.length <= 0
                            }
                            disabled={
                              this.props.loading && typeOptions.length <= 0
                            }
                            children={typeOptions}
                            onChange={this.handleChangeQuestionType}
                          ></Select>
                        )}
                      </SearchFormGroup>

                      <SearchFormGroup
                        labelName="Difficulty"
                        className="col-md-3 m-style"
                        labelColStyle="col-lg-12 col-form-label"
                      >
                        {difficultyOptions.length && (
                          <Select
                            selectWrap="col-lg-12"
                            selectId="question-difficulty"
                            id="question-difficulty"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={difficultyOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading && difficultyOptions.length <= 0
                            }
                            disabled={
                              this.props.loading && difficultyOptions.length <= 0
                            }
                            children={difficultyOptions}
                            onChange={this.handleChangeQuestionDifficulty}
                          ></Select>
                        )}
                      </SearchFormGroup>
                    </TableFilter>
                    {this.props.assessment.assessment_state == "Initial" && (
                      <Marks
                        viewAllocated={false}
                        onChangeMarks={(e) => this.onChangeAllMarks(e)}
                        onSetMarks={() => this.onSetAllMarks()}
                        viewAllMarks={true}
                      />
                    )}
                  </>
                ) : (
                  <div></div>
                )}
                {/* end::Search */}

                {this.state.assessment_questions &&
                  this.state.assessment_questions.length > 0 ? (
                  <FinalizeTab disabled={true}>
                    {this.questionTable(
                      this.questionsFilter(
                        filtered_questions,
                        this.state.selectedType,
                        this.state.selectedDifficulty,
                        this.state.selectedCategory
                      )
                    )}
                  </FinalizeTab>
                ) : (
                  <div style={{ height: "90vh" }}>
                    <span className="label label-lg label-light-warning label-inline">
                      No Questions Added!
                    </span>
                  </div>
                )}
                {/* View Question Modal */}
                <SelfGradeModal modalId="view-details" dataBackDrop="static" dataKeyboard="false">
                  <ModalHeader>
                    <h4 className="modal-title text-muted">{this.props.libraryQuestion?.title}</h4>
                    <Button className="btn btn-light-primary font-weight-bold" dataDismiss="modal" btnName="Close" />
                  </ModalHeader>
                  {this.state.isViewModalLoading ? (
                    <div>
                      <div className="loading-container mb-5 mt-10">
                        <ReactSpinner
                          animation="border"
                          role="status"
                          style={{ display: "block", margin: "auto" }}
                        >
                          <span className="sr-only">Loading...</span>
                        </ReactSpinner>
                      </div>
                    </div>
                  ) : (
                  <ModalBody>
                    <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                      {/* begin::Section */}
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-3">Question</h6>
                        <div className="text-dark-50 line-height-lg col-md-9">
                          <div>
                            <ViewEditor
                              editorState={this.state.editorQuestionState}
                            />
                          </div>
                        </div>
                      </div>

                      {/* end::Section */}
                      {/* begin::Section */}
                      {this.props.libraryQuestion?.sub_questions === null && (
                        <div className="row">
                          <h6 className="font-weight-bolder mb-3 col-lg-3 col-md-12">Model Answer</h6>
                          <div className="text-dark-50 line-height-lg col-lg-9 col-md-12">
                            <div>
                              {this.props.libraryQuestion?.question_type === questionTypeConstants.DESCRIPTIVE.name && this.props?.libraryAnswerContent &&
                                <div>
                                  <ViewEditor
                                    editorState={this.state.editorAnswerStateDescriptive}
                                  />
                                </div>

                              }
                              {this.props.libraryQuestion?.question_type === questionTypeConstants.SUBQUESTION.name && this.props?.libraryAnswerContent && this.props.libraryQuestion?.is_subquestion === "true" &&
                                <div>
                                  <ViewEditor
                                    editorState={this.state.editorAnswerStateDescriptive}
                                  />
                                </div>

                              }
                              {this.props.libraryQuestion?.question_type === questionTypeConstants.MCQ.name && this.props?.libraryQuestionContent &&
                                <div>

                                  {this.state.editorAnswerStates?.length > 0 &&
                                    this.state.editorAnswerStates?.map((editorState, i) => (
                                      <div className="view-questions-answer d-flex">


                                        <CheckBoxInline className="col-md-1">
                                          <CheckBox checked={this.state.selectedChoices[i]} />
                                        </CheckBoxInline>
                                        <div className="RichEditor-root">
                                          <ViewEditor
                                              editorState={this.state.editorAnswerStates[i]}
                                          />
                                        </div>
                                      </div>
                                    ))
                                  }
                                </div>
                              }
                              {this.props.libraryQuestion?.question_type === questionTypeConstants.SUBMISSION.name && this.state.attachment &&
                                <div className="RichEditor-root col-lg-8">
                                  {this.state.attachment.map(attach => {
                                    if (attach.replace(/"/g, '') != "[]") {
                                      return <div>
                                        <Button className="btn btn-outline-primary primary-button mr-2" btnIconStyle="flaticon-interface-3" inlineStyle={{ innerWidth: '400px' }}
                                          btnName={attach.replace(/"/g, '')} onClick={(e) => {
                                            this.onDownloadAttachment(e, attach.replace(/"/g, ''))
                                          }} />
                                        <br></br></div>
                                    }
                                    else {
                                      return <div>
                                      </div>
                                    }
                                  })
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {this.props.libraryQuestion.category_id && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Category :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'categories' in item).categories 
                                .find(category => category.category_id === this.props.libraryQuestion.category_id)?.name
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    {this.props.libraryQuestion.qtype && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Type :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'types' in item).types
                                .find(type => type.type_id === this.props.libraryQuestion.qtype)?.qtype
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    {this.props.libraryQuestion.language_id && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Language :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'languages' in item).languages
                                .find(language => language.language_id === this.props.libraryQuestion.language_id)?.language
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    {this.props.libraryQuestion.difficulty_id && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Difficulty :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'difficulty_types' in item).difficulty_types
                                .find(difficulty => difficulty.difficulty_id === this.props.libraryQuestion.difficulty_id)?.difficulty_type
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    {this.props.libraryQuestion.grade_id && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Grade :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'grades' in item).grades
                                .find(grade => grade.grade_id === this.props.libraryQuestion.grade_id)?.grade
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    {this.props.libraryQuestion.subject_id && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Subject :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'subjects' in item).subjects
                                .find(subject => subject.subject_id === this.props.libraryQuestion.subject_id)?.subject
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    {this.props.libraryQuestion.year_id && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Year :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'years' in item).years
                                .find(year => year.year_id === this.props.libraryQuestion.year_id)?.year
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    {this.props.libraryQuestion.term_id && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Term/Semester :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'terms' in item).terms
                                .find(term => term.term_id === this.props.libraryQuestion.term_id)?.term
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    {this.props.libraryQuestion.chapter_id && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Chapter/Module/Unit :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'chapters' in item).chapters
                                .find(chapter => chapter.chapter_id === this.props.libraryQuestion.chapter_id)?.chapter
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    {this.props.libraryQuestion.source_id && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Source :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'sources' in item).sources
                                .find(source => source.source_id === this.props.libraryQuestion.source_id)?.source
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    {this.props.libraryQuestion.author_id && (
                        <>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="row">
                            <h6 className="font-weight-bolder mb-3 col-md-4">
                            Author :
                            </h6>
                            <div className="text-dark-50 line-height-lg col-md-5">
                            <div className="inline-flex full-width align-center">
                                {
                                this.props.allQuestionMetaData.find(item => 'authors' in item).authors
                                .find(author => author.author_id === this.props.libraryQuestion.author_id)?.author
                                }
                            </div>
                            </div>
                        </div>
                        </>
                    )}
                  </ModalBody>
                )}
                </SelfGradeModal>
              </CardBody>
            </Card>
          ) : (
            <div>
              {" "}
              <Redirect to={routes.view_assessment} />
            </div>
          )}
          {/* end::Card */}
        </ContentWrapper>
      </AdminTemplate>
    )
  }
}

function mapStateToProps(state) {
  return {
    allTeachers: state.manageUserReducer.allTeachers,
    staffUserRoleMap: state.staffUserReducer.staffUserRoleMap,
    staffUserMap: state.staffUserReducer.staffUserMap,
    allStaffUsers: state.staffUserReducer.allStaffUsers,
    assessment: state.questionReducer.assessment,
    question: state.questionReducer.question,
    libraryQuestion: state.questionsLibraryReducer.question,
    questionContent: state.questionReducer.questionContent,
    libraryQuestionContent: state.questionsLibraryReducer.questionContent,
    libraryAnswerContent: state.questionsLibraryReducer.answerContent,
    questionContents: state.questionReducer.questionContents,
    questionContentsByType: state.questionReducer.questionContentsByType,
    questions: state.questionReducer.questions,
    assessmentQuestions: state.questionReducer.assessmentQuestions,
    loading: state.questionReducer.loading,
    finalizeSuccess: state.assessmentReducer.success,
    finalizeError: state.assessmentReducer.error,
    updateError: state.questionReducer.error,
    allCategories: state.questionsCreateReducer.allCategories,
    allQuestionTypes: state.questionsCreateReducer.allQuestionTypes,
    allDifficulty: state.questionsCreateReducer.allDifficulty,
    allQuestionMetaData: state.questionsCreateReducer.allQuestionMetaData,
  };
}

const mapActionsToProps = {
  createStaffUserMap: staffUserActions.createStaffUserMap,
  refreshPage: questionActions.refreshPage,
  getStaffUsers: staffUserActions.getAllStaffUsers,
  getAllTeachers: manageUserActions.getAllTeachers,
  getAllQuestions: questionActions.getAllQuestions,
  getAllLibraryQuestions: libraryActions.getAllQuestions,
  getQuestionContent: questionActions.getQuestionContent,
  getLibraryQuestionContent: libraryActions.getQuestionContent,
  getLibraryAnswerContent: libraryActions.getAnswerContent,
  refreshforAddingQuestions: questionActions.refreshforAddingQuestions,
  getAssessmentQuestions: questionActions.getAssessmentQuestions,
  getAllQuestionContentsByType: questionActions.getAllQuestionContentsByType,
  getAllQuestionContents: questionActions.getAllQuestionContents,
  removeMyQuestions: questionActions.removeMyQuestions,
  saveUpdates: questionActions.saveUpdates,
  finalizeAssessment: assessmentActions.finalizeAssessment,
  applyPositionChangeLocal: questionActions.applyPositionChangeLocal,
  getQuestionTypes: questionActions.getQuestionTypes,
  getQuestionCategories: questionActions.getQuestionCategories,
  getQuestionDifficulty: questionActions.getQuestionDifficulty,
  getQuestionMetaData: questionCreateActions.getQuestionMetaData,
};

export default connect(mapStateToProps, mapActionsToProps)(Finalize);
