import React, { useEffect, useState } from "react";
import AdminTemplate from "../../../../components/admin/layouts/Template.js";
import ContentWrapper from "../../../../components/common/ContentWrapper.js";
import Card from "../../../../components/common/Card.js";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardBody from "../../../../components/common/CardBody.js";
import CardTitle from "../../../../components/common/CardTitle.js";
import { Box, Chip, CircularProgress, IconButton, Typography } from "@mui/material";
import { PaperService } from "../../../../service/manage/paper/paper_service.js";
import { sweetStatusAlert } from "../../../../components/common/SweetAlert.js";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import '../Paper.css';
import FilterChips from './FilterChips.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination as Pagination,
  TextField,
} from "@mui/material";
import ReactSpinner from "react-bootstrap/Spinner";
import './edsiderPaperSearch.css'
import MaterialCustomTablePagination from "../../../../components/common/MaterialCustomTablePagination.js";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function not(a, b) {
  return a.filter((value) => !b.includes(value));
}

function intersection(a, b) {
  return a.filter((value) => b.includes(value));
}

function union(a, b) {
  return [...a, ...not(b, a)];
}


const EdsierPaperSearch = ({user}) => {

  const filterKeys = [
    "medium",
    "grade",
    "subject",
    "category",
    "year",
    "term",
    "chapter",
    "teacher",
    "entryOperator",
    "dateCreate",
    "status",
    "mapped",
    "breadcrumb"
  ];

  const filterKeyIds = [

    {
      "medium": 1,
      "grade": 2,
      "subject": 3,
      "category": 4,
      "year": 5,
      "term": 6,
      "chapter": 7,
      "teacher": 8,
      "entryOperator": 9,
      "dateCreate": 10,
      "status": 11,
      "mapped": 12,
      "breadcrumb": 13
    }

  ];

  const groupTitles = [
    "Languages",
    "Grades",
    "Subjects",
    "Categories",
    "Years",
    "Terms",
    "Chapters",
    "Teachers",
    "Entry Operators",
    "Date Created",
    "Statuses",
    "Paper Mapping",
    "Breadcrumb"
  ];

  const studentGroupTitles = [
    "Mediums",
    "Grades",
    "Subjects",
    "Categories",
    "Years",
  ];

  const [filters, setFilters] = useState({
    medium: null,
    grade: null,
    subject: null,
    category: null,
    year: null,
    term: null,
    chapter: null,
    teacher: null,
    entryOperator: null,
    dateCreate: null,
    status: null,
    mapped:null,
    breadcrumb:null
  });

  const [userType, setUserType] = useState(null);

  const [tableLoading, setTableLoading] = useState(false);

  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);

  const [off_set, setOffSet] = useState(0)
  const [limit, setLimit] = useState(10)
  const [sort_field, setSortField] = useState(3)
  const [sort_order, setSortOrder] = useState(null)

  const [total_count, setTotalCount] = useState(0)
  const [fetched_count, setFetchedCount] = useState(0)

  const [papers, setPapers] = useState([]);

  const [groupedFilters, setGroupedFilters] = useState([])

  const [checked, setChecked] = useState([]);

  const [filterSelections, setFilterSelections] = useState(
      userType && userType ==='student'?
      studentGroupTitles
      :userType ==='admin'?
      groupTitles
      :
  []);

  useEffect(() => {

    const defaultFilters = {
      medium: null,
      grade: null,
      subject: null,
      category: null,
      year: null,
      term: null,
      chapter: null,
      teacher: null,
      entryOperator: null,
      dateCreate: null,
      status: null,
      mapped:null,
    };

    let user_type = sessionStorage.getItem("userType")

    if (user_type && user_type === "student") {

      setChecked(studentGroupTitles)
      setFilterSelections(studentGroupTitles)
      
      
      let stu_grade = sessionStorage.getItem("studentGrade");
      let stu_medium = sessionStorage.getItem("studentMedium");
  
      if (stu_grade) {
        const gradeitem = { display_order: parseInt(stu_grade, 10) };
        defaultFilters.grade = [parseInt(stu_grade, 10)];
        handleChildCheckboxChange("grade", gradeitem);
      }
      if(stu_medium){
        const mediumitem = { display_order: parseInt(stu_medium, 10) };
        defaultFilters.medium = [parseInt(stu_medium, 10)];
        handleChildCheckboxChange("medium", mediumitem);
      }
  
      
      const statusitem = { display_order: 4 };
      const mappeditem = { display_order: 1 };

      defaultFilters.status = [4];
      defaultFilters.mapped = [1];

      handleChildCheckboxChange("status", statusitem); 
      handleChildCheckboxChange("mapped", mappeditem); 

    }
    else{

      if(user_type === "admin"){
        setChecked(groupTitles)
        setFilterSelections(groupTitles)
      }
      
    }

    setUserType(user_type)
    
    setFilters(defaultFilters);

  }, []);

  useEffect(() => {

    const fetchPapers = async () => {

      try {
        setTableLoading(true)

        const {
            medium,
            grade,
            subject,
            category,
            year,
            term,
            chapter,
            teacher,
            entryOperator,
            dateCreate,
            status,
            mapped,
            breadcrumb
        } = filters;

        const response = await PaperService.multiFilterPapers(
          medium,
          grade,
          subject,
          category,
          year,
          term,
          chapter,
          teacher,
          entryOperator,
          dateCreate,
          status,
          start_date,
          end_date,
          off_set,
          limit,
          sort_field,
          sort_order,
          mapped,
          breadcrumb
        );

        if (response && response.data) {

            const groupedData = response.data.results.reduce((acc, item) => {
                const { list_id } = item;
                if (!acc[list_id]) {
                    acc[list_id] = [];
                }
                acc[list_id].push(item);
                return acc;
            }, {});

            const listId20Data = groupedData[20] || [];
            setPapers(listId20Data?.map((item) => item) || []);
            setTotalCount(listId20Data[0]?.total_paper_count || 0);
            
            setFetchedCount(parseInt(listId20Data.length))

            
            const filteredGroupedData = Object.keys(groupedData).reduce((acc, key) => {
              if (key !== "20") {
                acc[key] = groupedData[key];
              }
              return acc;
            }, {});

            const groupedFilters = Object.keys(filteredGroupedData).map((key) => filteredGroupedData[key] || []);
            setGroupedFilters(groupedFilters);
            

        }

        setTableLoading(false);

      } catch (error) {
        sweetStatusAlert("Failed", "Failed to get papers", "error");
        setTableLoading(false)
      }
    };

    if(userType){
      fetchPapers();
    }
    
  }, [
    ...Object.values(filters),
    start_date,
    end_date,
    off_set,
    limit,
    sort_field,
    sort_order,
    userType
  ]);

  const isParentChecked = (filterKey, group) => {
    if (!filters[filterKey]) return false;
    return group.every((item) => filters[filterKey]?.includes(item.display_order));
  };
  
  const isParentIndeterminate = (filterKey, group) => {
    if (!filters[filterKey]) return false;
    return (
      group.some((item) => filters[filterKey]?.includes(item.display_order)) &&
      !isParentChecked(filterKey, group)
    );
  };

  const handleParentCheckboxChange = (filterKey, group) => {
    const allSelected = group.every((item) =>
      filters[filterKey]?.includes(item.display_order)
    );
  
    setFilters((prev) => ({
      ...prev,
      [filterKey]: allSelected
        ? null
        : group.map((item) => item.display_order),
    }));
  
  };

  const handleChildCheckboxChange = (filterKey, item) => {
    
    setFilters((prev) => {
      const currentFilter = prev[filterKey] || [];
      const isSelected = currentFilter.includes(item.display_order);
  
      if (isSelected) {
        
        const updatedFilter = currentFilter?.filter((text) => text !== item.display_order);
        return {
          ...prev,
          [filterKey]: updatedFilter.length > 0 ? updatedFilter : null,
        };
      } else {
        
        const updatedFilter = [...currentFilter, item.display_order];
        return {
          ...prev,
          [filterKey]: updatedFilter,
        };
      }
    });
  };

  const handleStartDateChange = (event) =>{

    const date = event.target.value; 
    const formattedDate = date.replace(/-/g, "");
    setStartDate(formattedDate);
  } 

  const handleEndDateChange = (event) =>{

    const date = event.target.value;
    const formattedDate = date.replace(/-/g, "");
    setEndDate(formattedDate);

  } 

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const customList = (title, items) => (
    
    <Card>
      <Box
        sx={{
          display: "flex",
          gap: "1px",
          padding: "4px",
          overflow: "hidden",  
          maxWidth: "60vw",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", flexShrink: 0 }}>
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
          
          <Box sx={{ mr: "10px" }}>
            <Typography variant="h7" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`${numberOfChecked(items)}/${items.length} selected`}
            </Typography>
          </Box>
  
          <Divider orientation="vertical" flexItem sx={{ mr: "10px" }}/>
        </Box>
  
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflowY: "auto",  
            maxHeight: "200px", 
            paddingLeft: "10px", 
            width: "100%",
          }}
        >
          {items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;
  
            return (
              <ListItemButton
                key={value}
                role="listitem"
                onClick={handleToggle(value)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  minWidth: "fit-content",
                  padding: "2px",
                  whiteSpace: "nowrap",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "30px",
                    marginRight: "4px",
                  }}
                >
                  <Checkbox
                    checked={checked.includes(value)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
  
                <ListItemText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    margin: 0,
                  }}
                  id={labelId}
                  primary={`${value}`}
                />
              </ListItemButton>
            );
          })}
        </Box>
      </Box>
    </Card>
  );
  
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded((prev) => !prev);
  };

  const clearAllFilters = () =>{

    const defaultFilters = {
      medium: null,
      grade: null,
      subject: null,
      category: null,
      year: null,
      term: null,
      chapter: null,
      teacher: null,
      entryOperator: null,
      dateCreate: null,
      status: null,
      mapped:null,
    };

    setFilters(defaultFilters);

  }

  return (
    <AdminTemplate>
      <ContentWrapper>
        <Card>
          <CardHeader>
              <Chip
                label={
                  <span style={{ fontSize: '1.3rem' }}>
                    Paper Count:{" "}
                    <strong>
                      {tableLoading ? (
                        <CircularProgress size={16} />
                      ) : (
                        total_count
                      )}
                    </strong>
                  </span>
                }
                variant="outlined"
                color="primary"
              />
            {/* <CardTitle cardLabel="Search Papers" /> */}
          </CardHeader>

          <CardBody>

          <Accordion className="filterAccordion" expanded={expanded} onChange={() => {}}>
            <AccordionSummary className="filterAccordionSummary" aria-controls="panel1-content" id="panel1-header">
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "16px" }}
              >
                <div className="filterpprs">
                  <FilterAltIcon />
                  &emsp;Filter&emsp;
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                      cursor: "pointer", // Make it clear this is clickable
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from affecting the Accordion
                      handleAccordionChange(); // Manually toggle the accordion state
                    }}
                  />
                </div>

                <label>
                  Start Date&nbsp;:&nbsp;{" "}
                  <input
                    type="date"
                    value={start_date?.replace(
                      /(\d{4})(\d{2})(\d{2})/,
                      "$1-$2-$3"
                    )}
                    onChange={handleStartDateChange}
                    style={{ padding: "5px", marginRight: "10px", borderRadius: "4px" }}
                  />
                </label>

                <label>
                  End Date&nbsp;:&nbsp;{" "}
                  <input
                    type="date"
                    value={end_date?.replace(
                      /(\d{4})(\d{2})(\d{2})/,
                      "$1-$2-$3"
                    )}
                    onChange={handleEndDateChange}
                    style={{ padding: "5px", borderRadius: "4px" }}
                  />
                </label>

                <div>{customList("Filter Selections", filterSelections)}</div>
              </Box>
              
            </AccordionSummary>

              <Box className="filterCardContainer" sx={{ overflowX: "auto", whiteSpace: "nowrap", display: "flex", padding: "8px", height:'40vh' }}>

                    {groupedFilters.map((group, index) => {
                      
                      const filterKey = filterKeys[index]; 
                      

                      const allowedGroupTitles = userType === "student" ? studentGroupTitles : groupTitles;

                      if(userType === "student"){

                        if (!allowedGroupTitles.includes(studentGroupTitles[index])) {
                          return null; 
                        }

                        if(!checked.includes(studentGroupTitles[index])){
                          return null
                        }

                      }
                      else if(userType === "admin"){

                        if (!allowedGroupTitles.includes(groupTitles[index])) {
                          return null; 
                        }

                        if(!checked.includes(groupTitles[index])){
                          return null
                        }

                      }
                      
                      const groupTitle = groupTitles[index];  

                      const isCheckedParentDisable = group.some(
                        (groupItem) => groupItem.flg_count === null
                      );

                      return (
                        <div className="filterCard" key={index} style={{ display: "inline-block", minWidth: "300px", marginRight: "16px", maxHeight:"30vh" }}>
                          <FormControlLabel
                            label={groupTitle}
                            disabled={tableLoading || isCheckedParentDisable}
                            
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              "& .MuiFormControlLabel-label": { fontWeight: "600" },
                            }}
                            control={
                              <Checkbox
                                checked={isParentChecked(filterKey, group)}
                                indeterminate={isParentIndeterminate(filterKey, group)}
                                onChange={() => handleParentCheckboxChange(filterKey, group)}
                                disabled={tableLoading}
                              />
                            }
                          />

                          <Box sx={{ display: "flex", flexDirection: "column", ml: 3, maxHeight: "20vh", overflowY: "auto" }}>
                            
                            {group.map((item) => {

                              const isAnyItemChecked = group.some(
                                (groupItem) => filters[filterKey]?.includes(groupItem.display_order)
                              );
                              
                              return(
                                <FormControlLabel
                                  key={item.list_id}
                                  label={`${item.list_text} ${item.list_count ? ` (${item.list_count})` : ""}`}
                                  disabled={!isAnyItemChecked && !item.flg_count || tableLoading}
                                  // disabled={!item.flg_count}
                                  control={
                                    <Checkbox
                                      checked={filters[filterKey]?.includes(item.display_order) || false}
                                      onChange={() => handleChildCheckboxChange(filterKey, item)}
                                      disabled={tableLoading}
                                    />
                                  }
                                />
                              )
                            })}
                          </Box>
                        </div>
                      );
                    })}
                  
              </Box>
              
          </Accordion>

          <FilterChips groupedFilters={groupedFilters} filters={filters} filterKeyIds={filterKeyIds} tableLoading={tableLoading} handleChildCheckboxChange={handleChildCheckboxChange} clearAllFilters={clearAllFilters}/>

            <Box sx={{ flexGrow: 1 }}>
              <Table>
                <TableHead>
                <TableRow>

                <TableCell
                    className="thead"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSortField(3); 
                      setSortOrder((prev) => {
                        if (prev === 1) { 
                          return null;
                        } else {
                          return 1;
                        }
                      });
                    }}
                  >
                    Paper Title{" "}
                    {sort_field === 3 && (
                      <span>
                        {sort_order === 1 ? "⬆️" : "⬇️"}
                      </span>
                    )}
                  </TableCell>

                  <TableCell
                      className="thead"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSortField(6); 
                        setSortOrder((prev) => {
                          if (prev === 1) { 
                            return null;
                          } else {
                            return 1;
                          }
                        });
                      }}
                  >
                  Grade{" "}
                  {sort_field === 6 && (
                      <span>
                        {sort_order === 1 ? "⬆️" : "⬇️"}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                      className="thead"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSortField(7); 
                        setSortOrder((prev) => {
                          if (prev === 1) { 
                            return null;
                          } else {
                            return 1;
                          }
                        });
                      }}
                  >
                    Subject{" "}
                    {sort_field === 7 && (
                      <span>
                        {sort_order === 1 ? "⬆️" : "⬇️"}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                      className="thead"
                  >
                    Actions
                  </TableCell>
          
                </TableRow>
                  </TableHead>

                  <TableBody>
                { papers && papers.length > 0 && !tableLoading?
                  (
                    papers?.map((value) => {
                      return(
                      <TableRow key={value}>

                        <TableCell className="tData">
                          {value?.list_text || ""}
                        </TableCell>

                        <TableCell className="tData">
                          {value?.p_grade || ""}
                        </TableCell>

                        <TableCell className="tData">
                          {value?.p_subject || ""}
                        </TableCell>

                        <TableCell className="tData">
                          <IconButton>
                            <PlaylistAddIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      )
                      
                    })
                  )
                  :
                  (
                    tableLoading?(
                      <div>
                          <div className="loading-container mb-5">
                            <ReactSpinner
                              animation="border"
                              role="status"
                              style={{ display: "block", margin: "auto" }}
                            >
                              <span className="sr-only">Loading...</span>
                            </ReactSpinner>
                          </div>
                        </div>
                    )
                    :(
                      <>
                      No Papers
                      </>
                      )
                  )
                }
                </TableBody>
              </Table>

              <MaterialCustomTablePagination
                totalCount={parseInt(total_count)}
                currentPage={off_set}
                limit={limit}
                handlePageChange={(e)=>setOffSet(e.target.value)}
                handleRowsPerPageChange={(e)=>setLimit(e.target.value)}
                // handlePageOffsetChange={this.handlePageOffsetChange}
                rowsPerPageOptions={[5, 10, 20]}
                offset={off_set}
              />
             
            </Box>

          </CardBody>
          
          {/* <FilterChips /> */}
        </Card>
      </ContentWrapper>
    </AdminTemplate>
  );
};

export default EdsierPaperSearch;
