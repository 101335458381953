import React, { Component } from "react";
import heroimg from "../assets/images/heroimg.png";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { routes } from "../../../helper/routes";
import edaMain1 from "../assets/images/edMain01.png";
import edaMain2 from "../assets/images/edMain02.png";
import edaMain3 from "../assets/images/edMain03.png";
import mobileHero from "../assets/images/mobileHero.png";

class Hero extends Component {
  handleSignUp = () => {
    this.props.history.push(routes.base_url);
  };

  render() {
    return (
      <>
        <div id="home" className="container home heroMainSec">
          <div className="row hero-section-container">
            <div className="col-md-6 col-sm-12 col-xs-12 hero-container" data-aos="fade-up">
              <h1 className="main-heading">Study Zone</h1>
              <h2 className="sub-heading">Your path to success</h2>
              <p className="paragraph">
              Online Cloud-based learning platform
              </p>
              <button
                type="button"
                onClick={this.handleSignUp}
                className="getstarted-btn btn2"
              >
                Get Registered &#10147;
              </button>
              <div className="mobileHero">
                <img src={mobileHero} alt="Image" className="mobileHeroImg" />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 hero-container heroImgs" data-aos="fade-right">
              <div className="image-container">
                <img src={edaMain1} alt="Image" className="centered-image edaMain1" />
                <img src={edaMain2} alt="Image" className="centered-image edaMain2" />
                <img src={edaMain3} alt="Image" className="centered-image edaMain3" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Hero);
