import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Modal, Button, Form, Row } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { RequestService } from "../../../../service/manage/registration/request_form.service";
import { sweetStatusAlert } from "../../../../components/common/SweetAlert";
import { routes } from "../../../../helper/routes";
import "./RegistrationForm.css";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#5458AF", // Blue color
    color: "white", // Text color
  },
});

class ParentStudentLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      email: "", // state to store email input
      registration_type: null,
      isLoading: false,
    };

    this.validator = new SimpleReactValidator();
  }

  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role);
    this.props.history.push(routes.auth_response);
  };

  handleSignUp = (userType) => {
    this.setState({ registration_type: userType, openDialog: true });
  };

  handleCloseDialog = () => {
    this.validator.hideMessages();
    this.forceUpdate();
    this.setState({ registration_type: null, openDialog: false, email: "" });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleSubmit = () => {

    const { registration_type, email } = this.state;

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    }
    else {
      this.setState({ isLoading: true });

      RequestService.checkEmail(email, registration_type)
        .then((response) => {
          if (response.data) {
            if (response.data.code === 1) {
              this.setState({ email: "", isLoading: false, openDialog: false });
              sweetStatusAlert(`${response.data.title && typeof response.data.title === "string" ? response.data.title : "Email exist"}`, `${typeof response.data.message === "string" ? response.data.message : "Email already exist in system"}`, "info")
            }
            else {
              const data = {
                email: email,
                registration_type: registration_type
              }

              this.setState({ isLoading: false, openDialog: false });

              this.props.history.push({
                pathname: routes.registration_form_view,
                info: {
                  "success": true,
                  "data": data
                },
              });
            }
          }
        })
    }


  };

  render() {

    const { classes } = this.props;

    const {
      isLoading
    } = this.state;
    return (
      <div>
        <div className="row signin-container">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 pt-10">
                <h1 className="text-center signin-heading">Welcome!</h1>
              </div>
              <div className="col-md-12">
                <p className="text-center signin-para">
                  Sign In to continue to your account
                </p>
              </div>
              <div className="col-md-12 d-flex justify-content-center pt-3">
                <div className="gradient-line"></div>
              </div>
            </div>
          </div>
          <div className="col-md-12 py-10">
            <div className="row d-flex justify-content-center">
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="login-img"></div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="d-flex flex-column align-items-center button-container">
                      <button
                        onClick={() => this.handleRoleSelect("student")}
                        className="signin-btn-container mb-3"
                      >
                        Sign-In
                      </button>
                      <div className="signin-note">
                        New user? Sign-Up,
                      </div>
                      <div className="signin-note">
                        <u><b onClick={() => this.handleSignUp("parent")}
                          className="signin-note-inside">
                          As a Parent
                        </b></u>
                        or
                        <u><b onClick={() => this.handleSignUp("student")}
                          className="signin-note-inside">
                          As a Student
                        </b></u>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <Modal
          show={this.state.openDialog}
          onHide={this.handleCloseDialog}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Enter your email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <p className="informingMsg">Only Gmail and Microsoft email IDs are currently accepted.</p>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
                <span className="form-error-message">
                  {this.validator.message(
                    "email",
                    this.state.email,
                    "required|email"
                  )}
                </span>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseDialog}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.handleSubmit}
              style={{ backgroundColor: "#5458AF" }}
              disabled={isLoading}
            >
              {isLoading ? (
                <div style={{ display: "inline-flex", alignItems: "center", minWidth: '50px' }}>
                  <i className="fas fa-spinner fa-spin"></i>
                </div>
              ) : (
                "Submit"
              )}
            </Button>

          </Modal.Footer>
        </Modal>
        <div className="col-md-12 d-flex justify-content-center pt-8 pb-2 pl-1 text-center">
          &copy; {new Date().getFullYear()} All Rights Reserved. Powered by
          EdSider (Pvt) Ltd. Supported by Infinity Innovators (Pvt) Ltd
        </div>
      </div>
    );
  }
}

export default ParentStudentLandingPage;