import React, { useEffect, useState } from 'react'
import AdminTemplate from "../../../../components/admin/layouts/Template.js";
import ContentWrapper from "../../../../components/common/ContentWrapper.js";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import EdPaperList from './FindPaperTabs/EdPaperList.js';
import EdEnrolledPaperList from '../../../candidate/EdEnrolledPaperList.js';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
function a11yProps(index) {
return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
};
}
  

export default function EdsiderFindPapers() {
    

    const [value, setValue] = useState(0);

    const [availablePaperControl, setAvailablePaperControl] = useState(false);
    const [enrollPaperControl, setEnrollPaperControl] = useState(false);

    const [paperListState, setPaperListState] = useState(
        {
            privilege_edit: 1,
            privilege_add: 1,
            privilege_delete: 1,
            breadcrumb2: "student",
            nodeTitle: "",
            mid: 108,
            main_table_id: 3,
            flg_list_active:null,
            owner_restriction: "True",
            lg_user_id: sessionStorage.getItem("manage_user_id"),
            lg_user_table_id: sessionStorage.getItem("table_log_id"),
            user_list_restriction: null,
            user_list_table_id:sessionStorage.getItem("table_log_id"),
          }
    )

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        setPaperListState(
            {
                privilege_edit: 1,
                privilege_add: 1,
                privilege_delete: 1,
                breadcrumb2: "student",
                nodeTitle: "",
                mid: 108,
                flg_list_active:null,
                main_table_id: 3,
                owner_restriction: "True",
                lg_user_id: sessionStorage.getItem("manage_user_id"),
                lg_user_table_id: sessionStorage.getItem("table_log_id"),
                user_list_restriction: "True",
              }
        )

    }, [])





  return (

    <AdminTemplate>

        <ContentWrapper>

        <Box sx={{ width: '100%' }}>
          {!enrollPaperControl && (
            <Box sx={{ borderBottom: 1, borderColor: 'divider', padding:'0px 25px' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Available Papers" {...a11yProps(0)} />
                <Tab label="Enrolled Papers" {...a11yProps(1)} />
                </Tabs>
            </Box>
          )}

        <Box
            sx={{
              height: enrollPaperControl ? '100vh' : 'auto',
              overflow: enrollPaperControl ? 'auto' : 'visible',
            }}
          >

            <CustomTabPanel value={value} index={0}>
                <EdPaperList state={paperListState} controller={setAvailablePaperControl} handleChange={handleChange}/>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <EdEnrolledPaperList state={paperListState} controller={setEnrollPaperControl}/>
            </CustomTabPanel>
          </Box>

        </Box>


        </ContentWrapper>

    </AdminTemplate>

  )
}